import { PayPalScriptProvider, PayPalMessages } from "@paypal/react-paypal-js";


const PayPalMessage = ({ style, forceReRender }) => {

    return (
        <PayPalScriptProvider
        options={{
            "client-id": process.env.PAYPAL_CLIENT_ID,
            components: "messages",
          }}
        >
           <PayPalMessages
            style={{ layout: 'custom' }}
            forceReRender={[{ layout: 'custom', }]}
           
          />
        </PayPalScriptProvider>
    )
}

export default PayPalMessage
