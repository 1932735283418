import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function FaqPagamenti({ className }: Props) {
  return (
    <IconBase name="faq-pagamenti" w={32} className={className}>
      <path d="M24.8099 7.12012H10.7699C9.25994 7.12012 8.01994 8.35012 8.01994 9.87012V10.7001H7.18994C5.67994 10.7001 4.43994 11.9301 4.43994 13.4501V22.1301C4.43994 23.6401 5.66994 24.8801 7.18994 24.8801H21.2299C22.7399 24.8801 23.9799 23.6501 23.9799 22.1301V21.3001H24.8099C26.3199 21.3001 27.5599 20.0701 27.5599 18.5501V9.87012C27.5599 8.35012 26.3299 7.12012 24.8099 7.12012ZM7.18994 11.7001H21.2299C22.1899 11.7001 22.9799 12.4801 22.9799 13.4501V14.2501H5.43994V13.4501C5.43994 12.4801 6.22994 11.7001 7.18994 11.7001ZM5.43994 14.7501H22.9799V16.7901H5.43994V14.7501ZM22.9799 22.1301C22.9799 23.0901 22.1999 23.8801 21.2299 23.8801H7.18994C6.22994 23.8801 5.43994 23.1001 5.43994 22.1301V17.2901H22.9799V22.1301ZM26.5599 18.5501C26.5599 19.5101 25.7799 20.3001 24.8099 20.3001H23.9799V13.4501C23.9799 11.9301 22.7499 10.7001 21.2299 10.7001H9.01994V9.87012C9.01994 8.91012 9.79994 8.12012 10.7699 8.12012H24.8099C25.7699 8.12012 26.5599 8.90012 26.5599 9.87012V18.5501Z" stroke="none"/>
      <path d="M11.9597 19.6401H7.46973C7.32973 19.6401 7.21973 19.7501 7.21973 19.8901C7.21973 20.0301 7.32973 20.1401 7.46973 20.1401H11.9597C12.0997 20.1401 12.2097 20.0301 12.2097 19.8901C12.2097 19.7501 12.0997 19.6401 11.9597 19.6401Z" stroke="none"/>
      <path d="M9.57998 21.4302H7.47998C7.33998 21.4302 7.22998 21.5402 7.22998 21.6802C7.22998 21.8202 7.33998 21.9302 7.47998 21.9302H9.57998C9.71998 21.9302 9.82998 21.8202 9.82998 21.6802C9.82998 21.5402 9.70998 21.4302 9.57998 21.4302Z" stroke="none"/>
    </IconBase>
  )
}

export default FaqPagamenti
