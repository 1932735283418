export const DOMAIN = 'https://www.vinicum.com'
export const BRAND = 'VINICUM'
export const ORA_LIMITE_SPEDIZIONE_DOMANI = 15
//export const FREE_SHIPPING = 60
export const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoicmlreTc4IiwiYSI6ImNpbHVwdHE4azAwNm53OG01MDJrd3dqbjEifQ.JBonJoW-YXex_iDp6sSvCw'
export const BLOG_LISTING_ITEMS_PER_PAGE = 12

export const GIORNI_FESTIVI = [
  '2023-01-06',
  '2023-01-09',
  '2023-08-15',
  '2023-11-01',
  '2023-12-08',
  '2023-12-25',
  '2023-12-26',
  '2024-01-01',
]
