import { useNode } from '@craftjs/core'
import React from 'react'

import { SeparatorSettings } from './SeparatorSettings'

import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'

export const defaultProps = {}

export const dynamicProps = []

export const Separator = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }

    const { children } = props

    const _ = props.renderValue

    const {
      id,
      connectors: { connect, drag },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode((state) => ({
      selected: state.events.selected,
      hovered: state.events.hovered,
      customs: state.data.custom,
    }))

    return <div className={'separator ' + props.className} ref={(ref) => props.retRef(ref)} />
  },
  defaultProps,
  dynamicProps
)

Separator.craft = withCraft({
  name: 'Separator',
  defaultProps: defaultProps,
  settings: SeparatorSettings,
})
