export const REDIRECTS = 'redirects/'
export const LISTING = 'listing/'
export const TOOLBAR = 'toolbar/'
export const TRADUZIONI = 'traduzioni/'
export const AMAZON_PAY = 'amazon-pay/'
export const AMAZON_PAY_GET_PAYLOAD = AMAZON_PAY + 'get_payload/'
export const PAYPAL = 'paypal/'
export const PAYPAL_CREATE_ORDER = PAYPAL + 'create_order/'
export const PAYPAL_CREATE_ORDER_WITHOUT_ADDRESS = PAYPAL + 'create_order_without_address/'
export const PAYPAL_CAPTURE_ORDER = 'capture_order/'
export const CHECK_REVALIDATE = 'check-revalidate/'
