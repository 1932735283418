import { useState, useEffect } from 'react'
import { useNode, useEditor } from '@craftjs/core'
import {
  getDynamicValue,
  setDynamicValue,
  isDynamicValue,
  deepValue,
} from '../../../../../utils/dynamic'

import { update } from '../../../../../components/DynamicDataBlock'

export const DynamicToolbarItem = ({
  propKey,
  propSubKey,
  value,
  values,
  setProp,
  onChange,
  defaultValue,
}) => {
  const { id, customs, props, parent, nodes } = useNode((node) => ({
    customs: node.data.custom,
    props: node.data.props,
    parent: node.data.parent,
    nodes: node.data.nodes,
  }))

  const { actions, query } = useEditor()

  const [state, setState] = useState()

  useEffect(() => {
    setState(value)
  }, [value])

  return (
    <>
      {state !== undefined && (
        <>
          <select
            value={isDynamicValue(state) ? getDynamicValue(state) : 'not_dynamic'}
            onChange={(e) => {
              setProp((props) => {
                if (e.target.value === 'not_dynamic') {
                  let value = defaultValue
                  props[propKey][propSubKey] = onChange ? onChange(value) : value
                } else {
                  let value = setDynamicValue(e.target.value)
                  props[propKey][propSubKey] = onChange ? onChange(value) : value
                }
              }, 1000)
            }}
          >
            <option value="not_dynamic">Not dynamic</option>
            {values.map((val) => {
              return (
                <option value={val} key={val}>
                  {val}
                </option>
              )
            })}
          </select>
          {props.block.repeatForEach && (
            <button onClick={() => update(id, query, actions)}>Update</button>
          )}
        </>
      )}
    </>
  )
}
