import React from 'react'

function Video({ className }) {
  return (
    <svg
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--video${className ? ' ' + className : ''}`}
    >
      <path d="M0 0h48v48H0z" fill="none" />
      <path d="M24 4C12.95 4 4 12.95 4 24s8.95 20 20 20 20-8.95 20-20S35.05 4 24 4zm-4 29V15l12 9-12 9z" />
    </svg>
  )
}

export default Video
