import { Shape } from 'components/atoms/Icons'
import React from 'react'

interface Props {
  icon: any
  title: string
  description: string
  className?: string
}

const DatoProdottoMain = (props: Props) => {
  const { icon = null, title = '', description = '', className = '' } = props

  return (
    <div className={`dato-prodotto-main ${className ? ` ${className}` : ''}`}>
      <span className="dato-prodotto-main__icon">
        <Shape className="dato-prodotto-main__icon__bg" />
        {icon}
      </span>
      <div className="dato-prodotto-main__content">
        <p className="dato-prodotto-main__title">{title}</p>
        <p className="dato-prodotto-main__description">{description}</p>
      </div>
    </div>
  )
}

export default DatoProdottoMain
