import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Shape({ className }: Props) {
  return (
    <IconBase name="shape" w={51} h={50} className={className}>
      <path d="M45.094 8.98942C34.9938 -0.707064 3.62174 5.55088 1.07669 18.9136C-1.46835 32.2763 9.01624 41.0428 27.2523 45.5586C45.4884 50.0744 57.4669 20.8678 45.094 8.98942Z" stroke="none"/>
    </IconBase>
  )
}

export default Shape
