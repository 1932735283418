import React from 'react'
import { Editor as CraftEditor, Frame, Element } from '@craftjs/core'
import { TextEditor } from '../components/TextEditor'
import { Text } from '../components/Text'
import { Button } from '../components/Button'
import { Image } from '../components/Image'
import { Video } from '../components/Video'
import { ReactBlock } from '../components/ReactBlock'
import { ProductCardBlock } from '../components/ProductCardBlock'
import { CustomCode } from '../components/CustomCode'
import { Quote } from '../components/Quote'
import { ProductsCarousel } from '../components/ProductsCarousel'
import { ROOT } from '../components/ROOT'
import { Separator } from '../components/Separator'

import { RenderNode } from './RenderNode'
import { Viewport } from './Viewport'
import { ConditionsWrapper } from 'react-pagebuilder/pagebuilder/ConditionsWrapper'

export const resolver = {
  ROOT,
  TextEditor,
  Text,
  Button,
  Image,
  Video,
  ReactBlock,
  ProductCardBlock,
  CustomCode,
  Quote,
  ProductsCarousel,
  Separator,
}

export const Editor = ({ json, editMode, withConditions }) => {
  const Wrapper = withConditions ? ConditionsWrapper : React.Fragment

  return (
    <>
      {editMode ? (
        <CraftEditor resolver={resolver} enabled={true} onRender={RenderNode}>
          <Viewport>
            <Frame data={json}>
              <Element is={ROOT} canvas></Element>
            </Frame>
          </Viewport>
        </CraftEditor>
      ) : (
        <CraftEditor resolver={resolver} enabled={false}>
          <Wrapper>
            <Frame data={json}></Frame>
          </Wrapper>
        </CraftEditor>
      )}
    </>
  )
}
