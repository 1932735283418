import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function AbbinamentoShape({ className }: Props) {
  return (
    <IconBase name="abbinamento-shape" w={1122} h={340} className={className}>
      <path d="M776.493 329.379C567.242 306.75 108.652 390.813 18.6336 280.199C-71.3852 169.585 179.641 -6.94562 494.444 0.526744C877.481 9.61876 1079.51 56.3108 1116.49 200.563C1153.47 344.816 985.745 352.008 776.493 329.379Z" stroke="none"/>
    </IconBase>
  )
}

export default AbbinamentoShape
