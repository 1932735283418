import React from 'react'
import { RadioMarked, RadioUnmarked } from 'components/atoms/Icons'

interface Props {
  title: string
  description?: any
  checked: boolean
  onClick: any
  actions?: any
  fullClick?: boolean
  moreContent?: any
  className?: string
}

const CheckoutOption = (props: Props) => {
  const {
    title = '',
    description = '',
    checked = false,
    onClick = () => {},
    actions = null,
    fullClick = false,
    moreContent = null,
    className = '',
  } = props

  const contentElement = (
    <>
      <span className="checkout-option__input">
        {checked ? <RadioMarked /> : <RadioUnmarked />}
      </span>
      <div className="checkout-option__content">
        {!!title && <p className="checkout-option__title">{title}</p>}
        {!!description && <p className="checkout-option__description">{description}</p>}
      </div>
    </>
  )

  const actionElement = !!actions && <div className="checkout-option__actions">{actions}</div>

  return fullClick ? (
    <button
      type="button"
      className={`checkout-option${checked ? ' checkout-option--checked' : ''}${
        className ? ` ${className}` : ''
      }`}
    >
      <div className="checkout-option__radio" onClick={onClick}>
        {contentElement}
      </div>
      {actionElement}
      {moreContent &&
      <div className="checkout-option__more-content">
        {moreContent}
      </div>
      }
    </button>
  ) : (
    <div
      className={`checkout-option${checked ? ' checkout-option--checked' : ''}${
        className ? ` ${className}` : ''
      }`}
    >
      <button type="button" className="checkout-option__radio" onClick={onClick}>
        {contentElement}
      </button>
      {actionElement}
      {moreContent &&
      <div className="checkout-option__more-content">
        {moreContent}
      </div>
      }
    </div>
  )
}

export default CheckoutOption
