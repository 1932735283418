import React, { useState, useEffect, useRef } from 'react'
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import { MAPBOX_ACCESS_TOKEN } from 'utils/settings'
import { getImageUrl } from 'utils/safe'
import { useTrans, useIsMobile } from 'hooks'
import { Close } from 'components/atoms/Icons'
import { ImageBox, Button } from 'components/atoms'

import { RES_SM_MIN } from 'utils'

mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN

interface Props {
  cantine: any[]
  className?: string
}

const CantineMappa = (props: Props) => {
  const { cantine = [], className = '' } = props

  const t = useTrans()
  const isMobile = useIsMobile()

  const features = cantine.map((cantina) => ({
    type: 'Feature',
    properties: {
      'logoImage': cantina.node.logo?.image
        ? getImageUrl(cantina.node.logo.image)
        : 'https://via.placeholder.com/100/FFFFFF/FFFFFF/',
      'indirizzo': cantina.node.indirizzo,
      'recapiti': cantina.node.recapiti,
      'sito': cantina.node.sito,
      'url': cantina.node.url,
      'marker-symbol': 'marker',
    },
    geometry: {
      type: 'Point',
      coordinates: [cantina.node.longitudine, cantina.node.latitudine],
    },
  }))

  const markers = {
    type: 'FeatureCollection',
    features: features,
  }

  const mapContainer = useRef(null)

  const lng = 13.62
  const lat = 44.5
  const [minZoom, setMinZoom] = useState(4)

  useEffect(() => {
    setMinZoom(window.innerWidth <= RES_SM_MIN ? 4 : 4.8)
    window.addEventListener("resize", () => setMinZoom(window.innerWidth <= RES_SM_MIN ? 4 : 4.8), false);
  }, [])

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/riky78/cilupy4nm00q3f7m0ttf0bp9x',
      center: [lng, lat],
      zoom: window.innerWidth <= RES_SM_MIN ? 4 : 4.8,
    })

    map.once('style.load', () => {
      map.addSource('markers', {
        type: 'geojson',
        data: markers,
      })

      map.addLayer({
        id: 'markers',
        interactive: true,
        type: 'symbol',
        source: 'markers',
        layout: {
          'icon-image': '{marker-symbol}-15',
          'icon-allow-overlap': true,
        },
      })
    })

    map.on('mousemove', function (e) {
      var features = map.queryRenderedFeatures(e.point, {
        layers: ['markers'],
      })
      map.getCanvas().style.cursor = features.length ? 'pointer' : ''
    })

    map.on('move', () => {
      if (map.getZoom() < minZoom)
        map.setZoom(minZoom)
    });

    map.on('click', function (e) {
      var features = map.queryRenderedFeatures(e.point, {
        layers: ['markers'],
      })

      if (!features.length) {
        return
      }

      var feature = features[0]

      new mapboxgl.Popup({ offset: [0, -15] })
        .setLngLat(feature.geometry.coordinates)
        .setHTML(
          '<div class="cantine-mappa__detail__image-box">' +
            '<img src="' +
            feature.properties.logoImage +
            '" class="cantine-mappa__detail__image" />' +
            '</div>' +
            '<div class="cantine-mappa__detail__contacts">' +
            (feature.properties.indirizzo ? '<p>' + feature.properties.indirizzo + '</p>' : '') +
            (feature.properties.recapiti ? '<p>' + feature.properties.recapiti + '</p>' : '') +
            '</div>' +
            (feature.properties.sito
              ? '<a href="' +
                feature.properties.sito +
                '" class="cantine-mappa__detail__website" rel="nofollow" target="_blank">' +
                feature.properties.sito +
                '</a>'
              : '') +
            '<div class="cantine-mappa__detail__cta">' +
            '<a class="button button--primary button--md" href="' +
            feature.properties.url +
            '" rel="noreferrer">' +
            '<span class="button__label">' +
            t('Scopri la cantina') +
            '</span>' +
            '</a>' +
            '</div>'
        )
        .addTo(map)
    })

    return () => map.remove()
  }, [])

  return (
    <div className={`cantine-mappa${className ? ` ${className}` : ''}`}>
      <div ref={mapContainer} className="cantine-mappa__map" />
    </div>
  )
}

export default CantineMappa
