import React, { useContext, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { useTrans } from 'hooks'
import { getFormFieldStatus } from 'utils/safe'
import { FormLayout, FormField, FormInput, FormCheckbox, FormSelect } from 'components/atoms'
import { province } from 'utils/province'
import { gql, useMutation, useQuery } from '@apollo/client'
import { NAZIONI } from 'utils/queries'
import { CartContext } from 'utils/context'

interface Props {
  className?: string
  formRef?: any
  indirizzo?: any
  refetchSistemiSpedizione?: (e: any) => void
  hideSaveAddress?: boolean
}

const CheckoutShippingAddressForm = (props: Props) => {
  const {
    className = '',
    formRef = null,
    indirizzo = null,
    refetchSistemiSpedizione = () => {},
    hideSaveAddress = false,
  } = props

  const t = useTrans()

  const validationSchema = yup.object().shape({
    nome: yup.string().required(t('Campo obbligatorio')),
    cognome: yup.string().required(t('Campo obbligatorio')),
    presso: yup.string().max(22, t('Troppo lungo, inserisci massimo 22 caratteri')),
    indirizzo: yup.string().max(25, t('Troppo lungo, inserisci massimo 25 caratteri')).required(t('Campo obbligatorio')),
    citta: yup.string().required(t('Campo obbligatorio')),
    cap: yup.string().max(10, t('Troppo lungo')).required(t('Campo obbligatorio')),
    provincia : yup.string().when("nazione", (nazione) => {
      if(nazione && nazione == 1)
        return yup.string().max(10, t('Troppo lungo')).required("Campo obbligatorio")
      else{
        return yup.string().max(10, t('Troppo lungo')).nullable()
      }
    }),
    telefono: yup
      .number()
      .typeError(t('Non è stato inserito un numero di telefono valido'))
      .positive(t('Valore negativo'))
      .integer(t('Valore intero'))
      .test('len', t('Numero troppo corto'), (val) => !val || (val && val.toString().length >= 6))
      .required(t('Campo obbligatorio')),
    nazione: yup.string().required(t('Campo obbligatorio')),
  })

  const initialValues = {
    nome: indirizzo?.nome || '',
    cognome: indirizzo?.cognome || '',
    indirizzo: indirizzo?.indirizzo || '',
    citta: indirizzo?.citta || '',
    cap: indirizzo?.cap || '',
    provincia: indirizzo?.provincia || '',
    telefono: indirizzo?.telefono || '',
    save_address: false,
    is_fatturazione: false,
    presso: indirizzo?.presso || '',
    nazione: indirizzo?.nazione.pk || 1,
  }

  const { data: optionsData } = useQuery(gql`${NAZIONI}`)
  const [options, setOptions] = useState([])
  const { productNotAvailableInternational } = useContext(CartContext)

  useEffect(() => {
    setOptions(optionsData?.nazioni.edges.map(({ node }) => ( {value: node.pk, label: node.nome})))
  }, [optionsData])

  return (
    <div className={`checkout-shipping-address-form ${className ? ` ${className}` : ''}`}>
      <Formik
        validateOnMount
        validateOnBlur
        validateOnChange
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values) => {}}
        innerRef={formRef}
      >
        {({ values, touched, errors, handleBlur, handleChange, setFieldValue, isValid }) => (
          <Form>
            <FormLayout>
              <FormField
                label={`${t('Nome')}*`}
                status={getFormFieldStatus({ touched, errors }, 'nome')}
                errorMessage={errors?.nome}
              >
                <FormInput
                  placeholder={t('Nome')}
                  value={values?.nome}
                  onBlur={handleBlur('nome')}
                  onChange={handleChange('nome')}
                />
              </FormField>
              <FormField
                label={`${t('Cognome')}*`}
                status={getFormFieldStatus({ touched, errors }, 'cognome')}
                errorMessage={errors?.cognome}
              >
                <FormInput
                  placeholder={t('Cognome')}
                  value={values?.cognome}
                  onBlur={handleBlur('cognome')}
                  onChange={handleChange('cognome')}
                />
              </FormField>
              <FormField
                layout='full'
                label={`${t('Indirizzo')}*`}
                status={getFormFieldStatus({ touched, errors }, 'indirizzo')}
                errorMessage={errors?.indirizzo}
              >
                <FormInput
                  placeholder={t('Indirizzo')}
                  value={values?.indirizzo}
                  onBlur={handleBlur('indirizzo')}
                  onChange={handleChange('indirizzo')}
                />
              </FormField>

              <FormField
                 
                  label={`${t('Nazione')}*`}
                  status={getFormFieldStatus({ touched, errors }, 'nazione')}
                  errorMessage={errors?.nazione}
                  className="nazione_select"
                >
                  <FormSelect
                    options={options}
                    value={values?.nazione}
                    placeholder={t('Nazione')}
                    onBlur={handleBlur('nazione')}
                    onChange={(selectedOption) => {
                      setFieldValue('nazione', selectedOption.value, true); 
                      if(selectedOption.value !== 1){
                        {setFieldValue('provincia', '', true) }
                      }                  
                    }}
                  />
                  {productNotAvailableInternational && values?.nazione != 1 && 
                    <div className="checkout-shipping-address-form__estero">
                      <img src='/images/alert_icon.png'></img>
                      <div>
                        <p className="checkout-shipping-address-form__estero__error__message">
                          {t("Alcuni prodotti non disponibili per la spedizione estero")}
                        </p>
                      </div>
                    </div>
                 }
                </FormField>
              
              <FormField
                label={`${t('Città')}*`}
                status={getFormFieldStatus({ touched, errors }, 'citta')}
                errorMessage={errors?.citta}
              >
                <FormInput
                  placeholder={t('Città')}
                  value={values?.citta}
                  onBlur={handleBlur('citta')}
                  onChange={handleChange('citta')}
                />
              </FormField>
              <FormField
                layout="sm"
                label={`${t('CAP')}*`}
                status={getFormFieldStatus({ touched, errors }, 'cap')}
                errorMessage={errors?.cap}
              >
                <FormInput
                  placeholder={t('CAP')}
                  value={values?.cap}
                  onBlur={handleBlur('cap')}
                  onChange={(e) => {
                    const cap = e.target.value
                    setFieldValue('cap', cap)
                    refetchSistemiSpedizione({
                      cap: cap
                    })
                  }}
                />
              </FormField>
              {
                values?.nazione === 1 &&
                  <FormField
                  layout="sm"
                  label={`${t('Provincia')}*`}
                  status={getFormFieldStatus({ touched, errors }, 'provincia')}
                  errorMessage={errors?.provincia}
                >
                  <FormSelect
                    options={province?.map((item) => ({
                      value: item.chiave,
                      label: item.nome,
                    }))}
                    value={values?.provincia}
                    placeholder={t('Provincia')}
                    onBlur={handleBlur('provincia')}
                    onChange={(selectedOption) => setFieldValue('provincia', selectedOption.value, true)}
                  />
                </FormField>
              }
              
              <FormField
                label={`${t('Numero di cellulare')}*`}
                status={getFormFieldStatus({ touched, errors }, 'telefono')}
                errorMessage={errors?.telefono}
              >
                <FormInput
                  placeholder={t('Numero di cellulare')}
                  value={values?.telefono}
                  onBlur={handleBlur('telefono')}
                  onChange={handleChange('telefono')}
                />
              </FormField>
              <FormField
                layout='full'
                label={`${t('Consegna presso')}`}
                status={getFormFieldStatus({ touched, errors }, 'presso')}
                errorMessage={errors?.presso}
              >
                <FormInput
                  placeholder={t('Consegna presso')}
                  value={values?.presso}
                  onBlur={handleBlur('presso')}
                  onChange={handleChange('presso')}
                />
              </FormField>
            </FormLayout>
            <div className="checkout-shipping-address-form__bottom">
              <p className="checkout-shipping-address-form__required-label">
                *{t('Campi obbligatori')}
              </p>
              {!hideSaveAddress && (
                <FormCheckbox
                  checked={values?.save_address}
                  onBlur={handleBlur('save_address')}
                  onChange={handleChange('save_address')}
                  label={t('Salva indirizzo per i prossimi acquisti')}
                  id="checkout-shipping-address-form__save-address"
                />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CheckoutShippingAddressForm
