import React from 'react'

interface Props {
  label: string
  variant?: 'primary' | 'dark' | 'light'
  size?: 'sm' | 'md'
  className?: string
}

const Label = (props: Props) => {
  const { label = '', variant = 'primary', size = 'md', className = '' } = props

  return (
    <div className={`label label--${variant} label--${size}${className ? ` ${className}` : ''}`}>
      {label}
    </div>
  )
}

export default Label
