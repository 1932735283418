import { ProdottoCard } from 'components/molecules'
import React from 'react'
import { useTrans } from 'hooks'


interface Props {
  prodotti?: any[]
  loadingPrezzi?: boolean
  showEmpty?: boolean
  onNotificationMessage?: (message: string) => void
  sezione?: string
  posizioneStart?: number
  className?: string
}

const ProdottiList = (props: Props) => {
  const {
    prodotti = [],
    loadingPrezzi = false,
    showEmpty = false,
    onNotificationMessage = () => {},
    sezione = '',
    posizioneStart = 0,
    className = '',
  } = props
  
  const t = useTrans()

  return (
    <div className={`prodotti-list ${className ? ` ${className}` : ''}`}>
      {prodotti.length ?
        prodotti.map((prodotto, index) => (
          <ProdottoCard
            prodotto={prodotto}
            loadingPrezzo={loadingPrezzi}
            key={`prodotti-listi__item__${prodotto.pk}`}
            onNotificationMessage={onNotificationMessage}
            isListing
            sezione={sezione}
            posizione={posizioneStart + index + 1}
          />
        ))
      :
        !showEmpty && (
          <div className="prodotti-list__empty">
            <p>{t("Ancora nessun prodotto disponibile. Torna a trovarci presto")}</p>
          </div>
        )
      }
    </div>
  )
}

export default ProdottiList
