import React from 'react'
import { useTrans } from 'hooks'
import { useSelector } from 'react-redux'
import { WContainer, WImage } from 'components/atoms'
import { NewsletterForm } from 'components/organisms'

import { Facebook, Instagram } from 'components/atoms/Icons'
import Link from 'next/link'

interface Props {
  className?: string
}

const Footer = (props: Props) => {
  const { menu } = useSelector((state: any) => state.menu)
  const { className = '' } = props

  const footerItems = menu?.edges?.filter(({ node }) => node?.key === 'footer')?.[0]?.node

  const t = useTrans()

  // FAKE
  const socialMenu = {
    children_visibili: [
      { id: 1, icon: <Facebook />, url: 'https://www.facebook.com/vinicum/' },
      { id: 2, icon: <Instagram />, url: 'https://www.instagram.com/vinicum/' },
    ],
  }

  return (
    <div className={`footer ${className ? ` ${className}` : ''}`}>
      <WContainer>
        <div className="footer__main">
          <div className="footer__content">
            <div className="footer__content-top">
              <div className="footer__logo">
                <WImage src="/images/logo-vinicum-rosso.png" maxWidth={130} maxHeight={100} />
              </div>

              <div
                className="trustpilot-widget"
                data-locale="it-IT"
                data-template-id="5419b6a8b0d04a076446a9ad"
                data-businessunit-id="5e7a6346600d1a0001be3bd6"
                data-style-height="54px"
                data-style-width="80%"
                data-theme="light"
              >
                <a
                  href="https://it.trustpilot.com/review/vinicum.com"
                  target="_blank"
                  rel="noopener"
                >
                  Trustpilot
                </a>
              </div>

              <div className="footer__social">
                <div>
                  <p className="footer__social__label"> {t('Seguici su')}</p>
                </div>
                <div className="footer__social__loghi">
                  {socialMenu.children_visibili.map((item) => (
                    <a href={item.url} target="_blank" key={`footer__social__item__${item.id}`}>
                      {item.icon}
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <div className="footer__menu">
              {footerItems?.children?.edges?.map(({ node }) => (
                <Link legacyBehavior href={node?.url}>
                  <a
                    target={node?.blank ? '_blank' : undefined}
                    key={`footer__menu__item__${node?.id}`}
                  >
                    {node?.name}
                  </a>
                </Link>
              ))}
            </div>
          </div>
          <div className="footer__newsletter-form">
            <p className="footer__newsletter-form__title">
              {t('Iscriviti alla newsletter per avere €10 di sconto sul tuo primo ordine*')}
            </p>
            <NewsletterForm />
          </div>
        </div>
        <div className="footer__bottom">
          {t(
            'Vinicum.com Copyright © 2016 GIV S.p.A. - P.IVA 03508110230 - C.F. / P.IVA / Reg. Imprese di Verona n. 03508110230R.E.A. di Verona n. 342008C.S.: 108.730.000,00 i.v.'
          )}
        </div>
      </WContainer>
    </div>
  )
}

export default Footer
