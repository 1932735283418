import React, { useEffect, useState } from 'react'
import { useTrans } from 'hooks'
import { getIconPremioByChiave } from 'utils/icon'
import { Button, LabelPremio, WContainer } from 'components/atoms'

import { groupBy } from 'lodash'

interface Props {
  className?: string
  premiRef?: any
  voti?: object[]
}

const PremiProdotto = (props: Props) => {
  const { className = '', premiRef = null, voti = [] } = props

  const t = useTrans()
  const LIMIT_SHOW_MORE = 3
  const [showMore, setShowMore] = useState(false)

  const [anniPremi, setAnniPremi] = useState([])

  useEffect(() => {
    if (voti) {
      const newVoti = []
      const groupByAnnata = groupBy(voti, 'annata')
      Object.keys(groupByAnnata).forEach((key) => {
        newVoti.unshift({
          label: key === 'null' ? '' : key,
          premi: groupByAnnata[key],
        })
      })
      setAnniPremi(newVoti)
    }
  }, [voti])

  // FAKE
  /*const anniPremiSenzaTitle = [
    {
      label: '',
      premi: [
        {
          id: 1,
          premio: { nome: 'Antonio Galloni', chiave: "" },
          valore: '92 P',
        },
        {
          id: 2,
          premio: { nome: 'Antonio Galloni', chiave: '' },
          valore: '92 P',
        },
        { id: 3, premio: { nome: 'Bibenda', chiave: 'bibenda' }, valore: '3' },
        {
          id: 4,
          premio: { nome: 'Rivista Altroconsumo', chiave: '' },
          valore: 'Miglior acquisto vino',
        },{
          id: 5,
          premio: { nome: 'Antonio Galloni', chiave: '' },
          valore: '92 P',
        },
        { id: 6, premio: { nome: 'Bibenda', chiave: 'bibenda' }, valore: '3' },
        {
          id: 7,
          premio: { nome: 'Rivista Altroconsumo', chiave: '' },
          valore: 'Miglior acquisto vino',
        },
        {
          id: 8,
          premio: { nome: 'Antonio Galloni', chiave: '' },
          valore: '92 P',
        },
        { id: 9, premio: { nome: 'Bibenda', chiave: 'bibenda' }, valore: '3' },
        {
          id: 10,
          premio: { nome: 'Rivista Altroconsumo', chiave: '' },
          valore: 'Miglior acquisto vino',
        },
        {
          id: 11,
          premio: { nome: 'Rivista Altroconsumo', chiave: '' },
          valore: 'Miglior acquisto vino',
        },
        {
          id: 12,
          premio: { nome: 'Rivista Altroconsumo', chiave: '' },
          valore: 'Miglior acquisto vino',
        },
        {
          id: 13,
          premio: { nome: 'Rivista Altroconsumo', chiave: '' },
          valore: 'Miglior acquisto vino',
        },
      ],
    },
  ]
  const anniPremiMock = [
    {
      label: '2012',
      premi: [
        {
          id: 1,
          premio: { nome: 'Antonio Galloni', chiave: "" },
          valore: '92 P',
        },
      ],
    },
    {
      label: '2011',
      premi: [
        {
          id: 2,
          premio: { nome: 'Antonio Galloni', chiave: '' },
          valore: '92 P',
        },
        { id: 3, premio: { nome: 'Bibenda', chiave: 'bibenda' }, valore: '3' },
        {
          id: 4,
          premio: { nome: 'Rivista Altroconsumo', chiave: '' },
          valore: 'Miglior acquisto vino',
        },
      ],
    },
    {
      label: '2010',
      premi: [
        {
          id: 5,
          premio: { nome: 'Antonio Galloni', chiave: '' },
          valore: '92 P',
        },
        { id: 6, premio: { nome: 'Bibenda', chiave: 'bibenda' }, valore: '3' },
        {
          id: 7,
          premio: { nome: 'Rivista Altroconsumo', chiave: '' },
          valore: 'Miglior acquisto vino',
        },
      ],
    },
    {
      label: '2009',
      premi: [
        {
          id: 8,
          premio: { nome: 'Antonio Galloni', chiave: '' },
          valore: '92 P',
        },
        { id: 9, premio: { nome: 'Bibenda', chiave: 'bibenda' }, valore: '3' },
        {
          id: 10,
          premio: { nome: 'Rivista Altroconsumo', chiave: '' },
          valore: 'Miglior acquisto vino',
        },
        {
          id: 11,
          premio: { nome: 'Rivista Altroconsumo', chiave: '' },
          valore: 'Miglior acquisto vino',
        },
        {
          id: 12,
          premio: { nome: 'Rivista Altroconsumo', chiave: '' },
          valore: 'Miglior acquisto vino',
        },
        {
          id: 13,
          premio: { nome: 'Rivista Altroconsumo', chiave: '' },
          valore: 'Miglior acquisto vino',
        },
      ],
    },
  ]*/

  const isAnnataVisibile = (index) => {
    return (
      anniPremi.filter((item, i) => i < index).reduce((sum, x) => sum + x.premi.length, 0) <
      LIMIT_SHOW_MORE
    )
  }

  const isValorePremioVisibile = (indexAnno, valorePremioIndex) => {
    return (
      anniPremi.filter((item, i) => i < indexAnno).reduce((sum, x) => sum + x.premi.length, 0) +
        valorePremioIndex <
      LIMIT_SHOW_MORE
    )
  }

  return anniPremi.length > 0 ? (
    <div className={`premi-prodotto ${className ? ` ${className}` : ''}`} ref={premiRef}>
      <WContainer>
        <div className="premi-prodotto__content">
          <p className="premi-prodotto__title">{t('Premi e riconoscimenti')}</p>
          <div className="premi-prodotto__date-list">
            {anniPremi.map((anno, index) =>
              isAnnataVisibile(index) || showMore ? (
                <div
                  className={`premi-prodotto__date-item${
                    anno.label ? '' : ' premi-prodotto__date-item--notitle'
                  }`}
                  key={`premi-prodotto__date-item__${anno.label || 'empty'}`}
                >
                  {!!anno.label && (
                    <p className="premi-prodotto__date-item__title">
                      {t('Annata {0}', [anno.label])}
                    </p>
                  )}
                  <div className="premi-prodotto__date-item__list">
                    {anno.premi.map((valorePremio, valorePremioIndex) =>
                      isValorePremioVisibile(index, valorePremioIndex) || showMore ? (
                        <div
                          className="premi-prodotto__item"
                          key={`premi-prodotto__item__${valorePremio.id}`}
                        >
                          <p className="premi-prodotto__item__label">{valorePremio.premio.nome}</p>
                          {getIconPremioByChiave(valorePremio.premio.stile) &&
                          parseInt(valorePremio.valore) ? (
                            <p className="premi-prodotto__item__value premi-prodotto__item__value--icons">
                              {[...Array(parseInt(valorePremio.valore))].map((_, index) => (
                                <span key={`label-premio__value__${valorePremio.id}__${index}`}>
                                  {getIconPremioByChiave(valorePremio.premio.stile)}
                                </span>
                              ))}
                            </p>
                          ) : (
                            <p className="premi-prodotto__item__value">{valorePremio.testo}</p>
                          )}
                        </div>
                      ) : (
                        <></>
                      )
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )
            )}
          </div>
          {anniPremi.reduce((sum, x) => sum + x.premi.length, 0) > LIMIT_SHOW_MORE && (
            <div className="premi-prodotto__cta">
              <Button
                label={showMore ? t('Nascondi premi') : t('Mostra tutti i premi')}
                variant="secondary"
                size="sm"
                onClick={() => setShowMore(!showMore)}
              />
            </div>
          )}
        </div>
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default PremiProdotto
