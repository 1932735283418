import React from 'react'
import { useTrans } from 'hooks'
import { Consegna, Pagamenti2, Assistenza } from 'components/atoms/Icons'
import { WContainer } from 'components/atoms'

interface ItemProps {
  icon: any
  title: string
  description?: string
}

interface Props {
  variant?: '' | 'white'
  className?: string
}

const PreFooterItem = (props: ItemProps) => {
  const { icon = null, title = '', description = '' } = props

  return (
    <div className="pre-footer-item">
      <span className="pre-footer-item__icon">{icon}</span>
      <div className="pre-footer-item__content">
        <p className="pre-footer-item__title">{title}</p>
        {description && (
          <div
            className="pre-footer-item__description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>
    </div>
  )
}
const PreFooter = (props: Props) => {
  const { variant = '', className = '' } = props

  const t = useTrans()

  return (
    <div
      className={`pre-footer${variant ? ` pre-footer--${variant}` : ''}${
        className ? ` ${className}` : ''
      }`}
    >
      <WContainer>
        <div className="pre-footer__list">
          <PreFooterItem
            icon={<Consegna />}
            title={t('Spedizione gratuita')}
            description={t('per ordini superiori a 60€')}
          />
          <PreFooterItem
            icon={<Pagamenti2 />}
            title={t('Pagamenti sicuri')}
            description={t('con Carta di Credito, Paypal, Amazon Pay, MyBank e Contrassegno.')}
          />
          <PreFooterItem
            icon={<Assistenza />}
            title={t('Assistenza clienti')}
            description={t(
              'al numero <b>350 16 22 708</b><br />e via mail: <b>support@vinicum.com</b>'
            )}
          />
        </div>
      </WContainer>
    </div>
  )
}

export default PreFooter
