export const setPagina = (pagina) => {
  return {
    type: 'SET_CURRENT_PAGE',
    pagina,
  }
}

export const resetPagina = () => ({
  type: 'RESET_CURRENT_PAGE',
})

export default function pagina(
  state = {
    pagina: {},
  },
  action
) {
  switch (action.type) {
    case 'SET_CURRENT_PAGE': {
      return Object.assign({}, state, {
        pagina: action.pagina,
      })
    }

    case 'RESET_CURRENT_PAGE': {
      return Object.assign({}, state, {
        pagina: {},
      })
    }

    default:
      return state
  }
}
