import { useNode } from '@craftjs/core'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'

import { defaultProps, dynamicProps } from './index'
import { getAllPaths, getDynamicValue, setDynamicValue } from '../../utils/dynamic'

export const CustomCodeSettings = () => {
  const { customs } = useNode(node => ({
    customs: node.data.custom,
  }))

  return (
    <>
      <ToolbarSection title="Settings" initOpen>
        <ToolbarItem
          propKey="block"
          propSubKey="code"
          type="code"
          label="Code"
          dynamic={dynamicProps.includes('code') && customs.dynamicData}
          dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
          defaultValue={defaultProps['code']}
        />
      </ToolbarSection>
    </>
  )
}
