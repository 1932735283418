import React from 'react'
import { Close } from 'components/atoms/Icons'

interface ContentProps {
  icon?: any
  title?: string
  description?: string
  children?: any
}

interface ButtonsProps {
  children?: any
}

interface Props {
  open: boolean
  setOpen: (v: boolean) => void
  closeButton?: boolean
  size?: 'md' | 'lg'
  className?: string
  children?: any
}

const ModalContent = (props: ContentProps) => {
  const { icon = null, title = '', description = '', children = null } = props

  return (
    <div className="modal__content">
      {(icon || title || description) && (
        <div className="modal__head">
          {icon && <p className="modal__icon">{icon}</p>}
          {title && <p className="modal__title">{title}</p>}
          {description && <p className="modal__description">{description}</p>}
        </div>
      )}
      {children}
    </div>
  )
}

const ModalButtons = (props: ButtonsProps) => {
  const { children = null } = props

  return <div className="modal__buttons">{children}</div>
}

const Modal = (props: Props) => {
  const {
    open = false,
    setOpen = () => {},
    closeButton = false,
    size = 'md',
    className = '',
    children = null,
  } = props

  const close = (e: any) => {
    e.stopPropagation()
    if (setOpen) setOpen(false)
  }

  return open ? (
    <>
      <div className="modal-overlay" />
      <div className={`modal${size ? ` modal--${size}` : ''}${className ? ` ${className}` : ''}`}>
        {closeButton && (
          <button type="button" className="modal__close" onClick={close}>
            <Close />
          </button>
        )}
        <div className="modal__body">{children}</div>
      </div>
    </>
  ) : (
    <></>
  )
}

Modal.Content = ModalContent
Modal.Buttons = ModalButtons

export default Modal
