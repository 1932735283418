import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function SpedizioneResi({ className }: Props) {
  return (
    <IconBase name="spedizione-resi" w={81} h={80} className={className}>
      <g clipPath="url(#spedizione-resi-clip0)">
      <path d="M66.1581 25.4505C66.0591 25.3019 65.9317 25.1745 65.7831 25.0755L40.2831 10.2505C40.0931 10.1408 39.8775 10.083 39.6581 10.083C39.4387 10.083 39.2231 10.1408 39.0331 10.2505L13.4581 25.0005C13.3168 25.0999 13.1976 25.2276 13.1081 25.3755C13.0007 25.5669 12.9407 25.7812 12.9331 26.0005V55.8505C12.9344 56.0686 12.9928 56.2826 13.1024 56.4712C13.2121 56.6598 13.3692 56.8164 13.5581 56.9255L39.0331 71.7505C39.2213 71.8653 39.4376 71.9261 39.6581 71.9261C39.8786 71.9261 40.0949 71.8653 40.2831 71.7505L65.7081 57.0005C65.897 56.8914 66.0541 56.7348 66.1638 56.5462C66.2734 56.3576 66.3318 56.1436 66.3331 55.9255V26.0755C66.3255 25.8562 66.2655 25.6419 66.1581 25.4505ZM50.7581 35.8755L56.8331 32.3255V47.2005L50.7581 50.7755V35.8755ZM49.4581 33.7255L26.5331 20.3255L32.6581 16.7755L55.6831 30.1505L49.4581 33.7255ZM25.2081 21.0505L48.2331 34.5005L39.5831 39.5005L16.5331 26.0755L25.2081 21.0505ZM39.5831 12.7005L62.5831 26.0755L56.8331 29.5005L33.8331 16.1255L39.5831 12.7005ZM15.3331 28.2505L37.9581 41.4255L38.3331 41.6255V68.5005L15.3331 55.1255V28.2505ZM40.8331 68.5005V41.6255L41.6831 41.1505L49.5081 36.6005V52.0005C49.5014 52.1077 49.5262 52.2146 49.5795 52.3079C49.6328 52.4012 49.7123 52.4768 49.8081 52.5255C49.9134 52.5617 50.0278 52.5617 50.1331 52.5255C50.2305 52.5579 50.3357 52.5579 50.4331 52.5255L57.7581 48.2005C57.8566 48.1529 57.9395 48.0783 57.9971 47.9853C58.0546 47.8923 58.0845 47.7848 58.0831 47.6755V31.6005L63.8331 28.2505V55.1255L40.8331 68.5005Z" stroke="none"/>
      <path d="M33.5582 46.7754H17.4582C17.3675 46.7762 17.2779 46.7967 17.1959 46.8355C17.1139 46.8744 17.0413 46.9306 16.9832 47.0004C16.9222 47.0671 16.877 47.1466 16.851 47.2332C16.825 47.3198 16.8189 47.4111 16.8332 47.5004L18.0832 53.9004C18.1071 54.0416 18.1807 54.1697 18.2907 54.2614C18.4008 54.3531 18.54 54.4024 18.6832 54.4004H34.7832C34.8782 54.4034 34.9726 54.3846 35.0592 54.3457C35.1458 54.3067 35.2225 54.2484 35.2832 54.1754C35.3425 54.014 35.3425 53.8368 35.2832 53.6754L34.0582 47.2754C34.032 47.1527 33.9709 47.0402 33.8822 46.9514C33.7934 46.8627 33.6809 46.8016 33.5582 46.7754ZM19.2082 53.1504L18.2082 48.0254H33.0332L34.0332 53.1504H19.2082Z" stroke="none"/>
      </g>
      <defs>
      <clipPath id="spedizione-resi-clip0">
      <rect width="53.5" height="61.925" transform="translate(12.833 10)" stroke="none"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default SpedizioneResi
