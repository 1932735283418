import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Vector6({ className }: Props) {
  return (
    <IconBase name="vector6" w={414} h={905} className={className}>
      <path d="M754.087 603.639C760.499 426.575 947.85 239.523 731.607 88.7589C515.364 -62.0056 157.851 27.5313 36.0774 282.49C-50.13 462.984 146.899 841.219 399.946 878.385C652.992 915.55 747.675 780.703 754.087 603.639Z" stroke="none"/>
    </IconBase>
  )
}

export default Vector6
