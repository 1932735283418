import { useNode, Element, useEditor } from '@craftjs/core'
import React, { useState, useEffect, useCallback } from 'react'
import { ButtonFloatingSettings, ButtonSettings } from './ButtonSettings'
import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'
import {
  deepValue,
  getAllPaths,
  getDynamicValue,
  setDynamicValue,
  isDynamicValue,
} from '../../utils/dynamic'

import { Button as MButton } from 'components/atoms'
import * as icons from 'components/atoms/Icons'

export const defaultProps = {
  url: '',
  openNewTab: false,
  btype: 'primary',
  size: 'md',
  iconName: '',
  iconPos: 'left',
  align: 'center',
}

export const dynamicProps = ['url']

export const Button = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }
    const { url, openNewTab, btype, size, iconName, iconPos, align } = blockProps

    const { children } = props

    const _ = props.renderValue

    const {
      id,
      connectors: { connect },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode((state) => ({
      selected: state.events.selected,
      dragged: state.events.dragged,
      hovered: state.events.hovered,
      customs: state.data.custom,
    }))

    const { enabled } = useEditor((state) => ({
      enabled: state.options.enabled,
    }))

    const [icon, setIcon] = useState()

    useEffect(() => {
      if (iconName) {
        setIcon(React.createElement(icons[iconName]))
      } else {
        setIcon(null)
      }
    }, [iconName])

    return (
      <div
        ref={(ref) => props.retRef(ref)}
        className={'block-button ' + props.className}
        style={{
          position: 'relative',
          textAlign: align,
        }}
      >
        {enabled ? (
          <MButton variant={btype} size={size} icon={icon} iconPos={iconPos}>
            {children.props.children[0]}
          </MButton>
        ) : (
          <MButton
            href={_(url)}
            blank={openNewTab}
            variant={btype}
            size={size}
            icon={icon}
            iconPos={iconPos}
          >
            {children.props.children[0]}
          </MButton>
        )}
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

Button.craft = withCraft({
  name: 'Button',
  defaultProps: defaultProps,
  settings: ButtonSettings,
  floatingSettings: ButtonFloatingSettings,
})
