import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Abbinamento({ className }: Props) {
  return (
    <IconBase name="abbinamento" w={40} className={className}>
      <g clipPath="url(#abbinamento-clip0)">
      <path d="M27.5298 22.2317C27.5607 22.0584 27.9198 21.5259 28.2356 21.0554C29.4057 19.3158 31.5849 16.0841 31.5849 11.4099C31.5849 7.54669 28.5265 0.129883 24.3476 0.129883C24.0071 0.129883 23.7285 0.408477 23.7285 0.748982V36.3224C23.8523 37.6906 24.5581 40.0122 26.4154 40.0122C28.6504 40.0122 30.099 36.4958 30.099 31.0601C30.099 27.525 28.8237 24.9743 28.0622 23.4513C27.827 22.9685 27.536 22.3803 27.5298 22.2317ZM26.4154 38.7617C25.4248 38.7617 25.0224 36.8672 24.9667 36.2543V1.44856C27.7712 2.21625 30.3467 7.96768 30.3467 11.4037C30.3467 15.7002 28.3841 18.6162 27.2079 20.362C26.6383 21.2102 26.2916 21.7241 26.2916 22.207C26.2916 22.6713 26.5516 23.1975 26.954 23.9962C27.7093 25.5129 28.8608 27.8098 28.8608 31.0539C28.8608 35.8767 27.6165 38.7617 26.4154 38.7617Z" stroke="none"/>
      <path d="M17.5312 0.12382C16.8688 0.12382 16.4416 0.340505 16.1568 0.650054C15.8225 0.272404 15.3458 0 14.6833 0C14.0209 0 13.538 0.272404 13.2037 0.650054C12.9189 0.346696 12.4917 0.12382 11.8293 0.12382C9.95341 0.12382 9 4.37703 9 12.7658C9 15.1741 11.755 17.1429 12.7517 17.7743C12.5412 20.0774 11.5074 31.5245 11.5074 33.9452C11.5074 35.6044 11.5074 40.0062 14.6771 40.0062C17.8469 40.0062 17.8469 35.6044 17.8469 33.9452C17.8469 31.5245 16.813 20.0836 16.6025 17.7743C17.5993 17.1429 20.3543 15.1803 20.3543 12.7658C20.3605 4.37703 19.4071 0.12382 17.5312 0.12382ZM15.6553 16.9014C15.4448 17.019 15.321 17.2543 15.3396 17.4957C15.352 17.6319 16.6149 31.3574 16.6149 33.939C16.6149 37.276 16.0206 38.7618 14.6833 38.7618C13.3461 38.7618 12.7517 37.276 12.7517 33.939C12.7517 31.3636 14.0147 17.6381 14.0271 17.4957C14.0518 17.2543 13.928 17.019 13.7113 16.9014C12.7455 16.3566 10.2382 14.555 10.2382 12.7658C10.2382 5.42331 11.1792 1.36202 11.8293 1.36202C12.2131 1.36202 12.4855 1.36202 12.4855 2.4764V9.70748C12.4855 10.048 12.7641 10.3266 13.1046 10.3266C13.4451 10.3266 13.7237 10.048 13.7237 9.70748V2.50116C13.7361 2.38353 13.8537 1.2382 14.6833 1.2382C15.5191 1.2382 15.6306 2.4083 15.6367 2.50735V9.70748C15.6367 10.048 15.9153 10.3266 16.2558 10.3266C16.5963 10.3266 16.8749 10.048 16.8749 9.70748V2.4764C16.8749 1.36202 17.1535 1.36202 17.5312 1.36202C18.1812 1.36202 19.1223 5.42331 19.1223 12.7658C19.1223 14.555 16.6211 16.3566 15.6553 16.9014Z" stroke="none"/>
      </g>
      <defs>
      <clipPath id="abbinamento-clip0">
      <rect width="22.5847" height="40" transform="translate(9)" stroke="none"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default Abbinamento
