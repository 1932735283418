import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function SpiritsLiquori({ className }: Props) {
  return (
    <IconBase name="spirits-liquori" w={32} className={className}>
      <path d="M27.4098 9.2599C27.4098 9.2499 27.3998 9.2399 27.3998 9.2199C27.3898 9.1399 27.3698 9.0699 27.3398 9.0099C26.5798 7.2499 17.8198 7.1499 15.9998 7.1499C14.1798 7.1499 5.41984 7.2499 4.65984 9.0099C4.62984 9.0799 4.60984 9.1499 4.59984 9.2199C4.59984 9.2299 4.58984 9.2399 4.58984 9.2599C4.58984 9.2699 4.59984 9.2699 4.59984 9.2799C4.59984 9.2899 4.59984 9.2999 4.59984 9.3199L6.15984 22.2899C6.15984 23.9399 12.2498 24.8499 16.4598 24.8499C20.9798 24.8499 25.8498 23.8699 25.8498 22.3499L27.4098 9.3199C27.4098 9.3099 27.4098 9.2899 27.4098 9.2799C27.3998 9.2699 27.4098 9.2699 27.4098 9.2599ZM24.2098 19.7299C22.9298 18.7499 18.5198 18.4599 15.9998 18.4599C13.4798 18.4599 9.06984 18.7399 7.78984 19.7299L5.84984 11.4299L5.70984 10.2899C8.27984 11.2999 14.4998 11.3699 15.9998 11.3699C17.4998 11.3699 23.7198 11.2999 26.2798 10.2899L26.1398 11.4199L24.2098 19.7299ZM24.0698 20.3199C24.0698 20.7899 21.2998 21.6799 15.9998 21.6799C10.6998 21.6799 7.91984 20.7999 7.91984 20.3199C7.91984 19.8399 10.6898 18.9599 15.9998 18.9599C21.2998 18.9599 24.0698 19.8499 24.0698 20.3199ZM15.9998 8.1499C21.5998 8.1499 25.3998 8.7699 26.2798 9.2599C25.3998 9.7499 21.5998 10.3699 15.9998 10.3699C10.3998 10.3699 6.59984 9.7499 5.71984 9.2599C6.59984 8.7699 10.3998 8.1499 15.9998 8.1499ZM16.4498 23.8499C11.4098 23.8499 7.48984 22.7699 7.14984 22.2299L6.39984 15.9499L7.43984 20.3799C7.43984 20.3899 7.44984 20.3899 7.44984 20.3999C7.63984 21.7999 13.0898 22.1799 16.0098 22.1799C18.9298 22.1799 24.3698 21.7999 24.5698 20.3999C24.5698 20.3899 24.5798 20.3899 24.5798 20.3799L25.6198 15.9199L24.8698 22.2399C24.6398 22.6799 21.7198 23.8499 16.4498 23.8499Z" stroke="none"/>
    </IconBase>
  )
}

export default SpiritsLiquori
