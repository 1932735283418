import React from 'react'
import { Accordion } from 'components/atoms'

interface Props {
  faqCategory: {
    id: number
    nome: string
    icon?: any
    faqs?: {
      id: number
      nome?: string
      descrizione?: string
    }[]
  }
  className?: string
}

const FaqCategoryDetail = (props: Props) => {
  const {
    faqCategory = {
      id: null,
      nome: '',
      icon: null,
      faqs: [],
    },
    className = '',
  } = props

  return (
    <div className={`faq-category-detail ${className ? ` ${className}` : ''}`}>
      <div className="faq-category-detail__head">
        <span className="faq-category-detail__icon">{faqCategory.icon}</span>
        <span className="faq-category-detail__title">{faqCategory.nome}</span>
      </div>
      <div className="faq-category-detail__list">
        {faqCategory.faqs.map((faq) => (
          <div className="faq-category-detail__item" key={`faq-category-detail__item__${faq.id}`}>
            <Accordion
              label={faq.nome}
              content={<div dangerouslySetInnerHTML={{ __html: faq.descrizione }} />}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default FaqCategoryDetail
