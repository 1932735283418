import React, { useState, useEffect, useRef } from 'react'
import { useTrans } from 'hooks'
import { Add, Close } from 'components/atoms/Icons'
import { Button, FormField, FormRadio } from 'components/atoms'
import { CheckoutAddressOption, CheckoutStepBlock } from 'components/molecules'
import { CheckoutBillingAddressForm } from 'components/organisms'

import { mockArray, mockIndirizzo } from 'utils/mock'
import { SET_INDIRIZZI, CREATE_INDIRIZZO, UPDATE_INDIRIZZO, DELETE_INDIRIZZO } from 'utils/queries'
import { gql, useMutation, useQuery } from '@apollo/client'
import { createIndirizzoInput, updateIndirizzoInput } from 'utils/indirizzo'

interface AddressFormProps {
  edit?: boolean
  indirizzo?: any
  formRef: any
  handleClose: any
  handleSubmit: any
}

const AddressForm = (props: AddressFormProps) => {
  const {
    edit = false,
    indirizzo = null,
    formRef = null,
    handleClose = () => {},
    handleSubmit = () => {},
  } = props

  const t = useTrans()

  return (
    <>
      <div className="checkout-billing-address-step-block__new-address">
        <button
          type="button"
          className="checkout-billing-address-step-block__new-address__close"
          onClick={handleClose}
        >
          <Close />
        </button>
        <div className="checkout-billing-address-step-block__new-address__content">
          <p className="checkout-billing-address-step-block__new-address__title">
            {edit ? t('Modifica indirizzo di fatturazione') : t('Nuovo indirizzo di fatturazione')}
          </p>
          <div className="checkout-billing-address-step-block__new-address__form">
            <CheckoutBillingAddressForm
              formRef={formRef}
              indirizzo={indirizzo}
              hideSaveAddress={edit ? true : false}
            />
          </div>
        </div>
        <div className="checkout-billing-address-step-block__new-address__actions">
          <Button
            variant="ghost"
            label={edit ? t('Annulla') : t('Elimina indirizzo')}
            onClick={handleClose}
            className="checkout-billing-address-step-block__new-address__action checkout-billing-address-step-block__new-address__action--close"
          />
          <Button
            label={edit ? t('Salva modifiche') : t('Usa questo indirizzo')}
            className="checkout-billing-address-step-block__new-address__action checkout-billing-address-step-block__new-address__action--confirm"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </>
  )
}

interface Props {
  className?: string
  indirizziFatturazione?: any
  billingAddressAttivo?: number
  richiediFattura?: boolean
  billingAddressRef?: any
  addedBillingAddress?: any
  setAddedBillingAddress?: (e: any) => void
  estero?: boolean
}

const CheckoutBillingAddressStepBlock = (props: Props) => {
  const {
    className = '',
    indirizziFatturazione = [],
    billingAddressAttivo = null,
    richiediFattura = null,
    billingAddressRef = null,
    addedBillingAddress = null,
    setAddedBillingAddress = () => {},
    estero = false,
  } = props

  const [indirizzi, setIndirizzi] = useState([])

  useEffect(() => {
    if (indirizziFatturazione?.indirizzi)
      setIndirizzi(indirizziFatturazione.indirizzi.edges.map(({ node }) => node))
  }, [indirizziFatturazione])

  const [createIndirizzo] = useMutation(
    gql`
      ${CREATE_INDIRIZZO}
    `
  )
  const [updateIndirizzo] = useMutation(
    gql`
      ${UPDATE_INDIRIZZO}
    `
  )
  const [setIndirizziCheckout] = useMutation(
    gql`
      ${SET_INDIRIZZI}
    `
  )
  const [deleteIndirizzo] = useMutation(
    gql`
      ${DELETE_INDIRIZZO}
    `
  )

  const t = useTrans()
  const [fattura, setFattura] = useState(null)
  const [addFormOpen, setAddFormOpen] = useState(false)
  const [indirizzoChecked, setIndirizzoChecked] = useState(null)
  const [indirizzoEdit, setIndirizzoEdit] = useState(null)

  const createBillingAddressRef = useRef<any>()

  const handleCreateBillingAddress = async () => {
    if (createBillingAddressRef?.current?.isValid) {
      const values = createBillingAddressRef.current.values
      await createIndirizzo({
        variables: {
          input: createIndirizzoInput(values),
        },
        refetchQueries: ['IndirizziFatturazione'],
      }).then((res) => {
        const indirizzo = res.data.createIndirizzo.indirizzo
        setAddedBillingAddress(indirizzo)

        handleSetIndirizziCheckout(indirizzo.pk)

        handleClose()
      })
    }
  }

  const handleUpdateBillingAddress = async (pk) => {
    if (createBillingAddressRef?.current?.isValid) {
      const values = createBillingAddressRef.current.values

      await deleteIndirizzo({
        variables: {
          input: {
            pk: pk,
          },
        },
        refetchQueries: ['IndirizziFatturazione'],
      }).then((res) => {
        values.save_address = true

        createIndirizzo({
          variables: {
            input: createIndirizzoInput(values),
          },
          refetchQueries: ['IndirizziFatturazione'],
        }).then((res) => {
          const indirizzo = res.data.createIndirizzo.indirizzo

          setAddedBillingAddress(indirizzo)

          handleSetIndirizziCheckout(indirizzo.pk)

          handleClose()
        })
      })
    }
  }

  useEffect(() => {
    if (billingAddressAttivo) {
      setIndirizzoChecked(billingAddressAttivo)
    }
  }, [billingAddressAttivo])

  const handleSetIndirizziCheckout = (newIndirizzoChecked) => {
    if (newIndirizzoChecked === indirizzoChecked) {
      return
    }

    setIndirizziCheckout({
      variables: {
        indirizzoFatturazione: newIndirizzoChecked,
      },
      refetchQueries: ['Checkout'],
    })
      .then((res) => {
        setIndirizzoChecked(newIndirizzoChecked)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (richiediFattura !== null) {
      setFattura(richiediFattura ? '1' : '0')
    }
  }, [richiediFattura])

  useEffect(() => {
    if (estero) handleSetRichiediFatturaCheckout(0)
  }, [estero])

  const handleSetRichiediFatturaCheckout = (value) => {
    setIndirizziCheckout({
      variables: {
        richiediFattura: value === '1' ? true : false,
      },
      refetchQueries: [estero ? '' : 'Checkout'],
    })
      .then((res) => {
        setFattura(value)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleOpen = (pk = null) => {
    if (pk) {
      setIndirizzoEdit(pk)
      setAddFormOpen(false)
    } else {
      setAddFormOpen(true)
      setIndirizzoEdit(null)
    }
  }

  const handleClose = () => {
    if (indirizzoEdit) {
      setIndirizzoEdit(null)
      setAddFormOpen(false)
    } else {
      setAddFormOpen(false)
    }
  }

  return (
    <CheckoutStepBlock
      titolo={t('Hai bisogno di fattura?')}
      className={`checkout-billing-address-step-block${className ? ` ${className}` : ''}`}
    >
      {!estero ? (
        <FormRadio
          id="checkout-billing-address-step-block__fattura"
          variant="checkbox"
          value={fattura}
          onChange={(e) => handleSetRichiediFatturaCheckout(e.target.value)}
          options={[
            { id: '1', label: t('Sì'), value: '1' },
            { id: '0', label: t('No'), value: '0' },
          ]}
          disabled={estero}
        />
      ) : (

        <div dangerouslySetInnerHTML={{ __html:         t(
          'Se sei una azienda e desideri la fattura per il tuo ordine estero, ti invitiamo a contattarci all’indirizzo email <a class="checkout-billing-address-step-block__email" href="mailto:support@vinicum.com">support@vinicum.com</a> prima di concludere l’ordine. Non potremo accogliere richieste di fatturazione a ordine concluso'
        ) }} />
          // 
      )}

      {fattura === '1' && (
        <div className="checkout-billing-address-step-block__fattura-form">
          {indirizzi.length === 0 ? (
            <CheckoutBillingAddressForm formRef={billingAddressRef} />
          ) : (
            <>
              <div className="checkout-billing-address-step-block__list">
                {indirizzi.map((indirizzo) => (
                  <>
                    {indirizzo.pk !== indirizzoEdit ? (
                      <CheckoutAddressOption
                        key={`checkout-billing-address-step-block__address-option__${indirizzo.pk}`}
                        indirizzo={indirizzo}
                        checked={indirizzoChecked === indirizzo.pk}
                        onClick={() => handleSetIndirizziCheckout(indirizzo.pk)}
                        onEdit={() => handleOpen(indirizzo.pk)}
                      />
                    ) : (
                      <AddressForm
                        key={`checkout-billing-address-step-block__address-option__${indirizzo.pk}`}
                        edit={true}
                        indirizzo={indirizzo}
                        formRef={createBillingAddressRef}
                        handleClose={() => handleClose()}
                        handleSubmit={() => handleUpdateBillingAddress(indirizzo.pk)}
                      />
                    )}
                  </>
                ))}
                {addedBillingAddress && addedBillingAddress.nascondi && (
                  <>
                    {addedBillingAddress.pk !== indirizzoEdit ? (
                      <CheckoutAddressOption
                        key={`checkout-billing-address-step-block__address-option__${addedBillingAddress.pk}`}
                        indirizzo={addedBillingAddress}
                        checked={indirizzoChecked === addedBillingAddress.pk}
                        onClick={() => handleSetIndirizziCheckout(addedBillingAddress.pk)}
                        onEdit={() => handleOpen(addedBillingAddress.pk)}
                      />
                    ) : (
                      <AddressForm
                        edit={true}
                        indirizzo={addedBillingAddress}
                        formRef={createBillingAddressRef}
                        handleClose={() => handleClose()}
                        handleSubmit={() => handleUpdateBillingAddress(addedBillingAddress.pk)}
                      />
                    )}
                  </>
                )}
              </div>
              {addFormOpen ? (
                <AddressForm
                  formRef={createBillingAddressRef}
                  handleClose={() => handleClose()}
                  handleSubmit={handleCreateBillingAddress}
                />
              ) : !addedBillingAddress ? (
                <div className="checkout-billing-address-step-block__add">
                  <Button
                    variant="ghost"
                    label={t('Invia ad un indirizzo diverso')}
                    icon={<Add />}
                    iconPos="left"
                    onClick={() => handleOpen()}
                  />
                </div>
              ) : null}
            </>
          )}
        </div>
      )}
    </CheckoutStepBlock>
  )
}

export default CheckoutBillingAddressStepBlock
