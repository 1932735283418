import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function DropdownArrowDown({ className }: Props) {
  return (
    <IconBase name="dropdown-arrow-down" w={24} className={className}>
      <path d="M12 15L18 9L6 9L12 15Z" stroke="none"/>
    </IconBase>
  )
}

export default DropdownArrowDown
