import dynamic from 'next/dynamic'

// dynamic import NON può ricevere come input una variabile
// .then((mod) => mod.ProductCard) è richiesto se NON è EXPORT DEFAULT
const ProductCard = dynamic(
  () => import('react-pagebuilder/other/ProductCard').then((mod) => mod.ProductCard),
  { loading: () => <p>Loading ProductCard ...</p> }
)

const getDynamicComponent = (name, props) => {
  switch (name) {
    case 'ProductCard': {
      return <ProductCard {...props} />
    }
  }

  return null
}

export default getDynamicComponent
