import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { useTrans } from 'hooks'
import { useRouter } from 'next/dist/client/router'
import { getFormFieldStatus } from 'utils/safe'
import { useCreateUtenteNewsletterMutation } from 'gql/graphql'
import { getCurrentDate } from 'utils'
import { trackingGAevent } from 'utils/gtm'
import { DOMAIN } from 'utils/settings'
import { useSelector } from 'react-redux'
import { executeIfInitialized, trackConnectifNewsletter } from 'utils/connectif'

import {
  FormLayout,
  FormField,
  FormInput,
  FormCheckbox,
  Button,
  NotificationBar,
} from 'components/atoms'
import { Alert } from 'components/atoms/Icons'
import Link from 'next/link'

interface Props {
  textSubmit?: string
  setOpen?: (v: boolean) => void
  className?: string
}

const NewsletterForm = (props: Props) => {
  const { textSubmit = null, setOpen = () => {}, className = '' } = props

  const t = useTrans()
  const router = useRouter()
  const [createUtenteNewsletter] = useCreateUtenteNewsletterMutation()
  const [error, setError] = useState<string | undefined | null>()

  const { pagine } = useSelector((state) => state.pagine)
  const privacy_policy = pagine?.edges.find((item) => item.node.chiave === 'privacy-policy')?.node

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t("Il formato dell'email dev'essere valido"))
      .required(t('Campo obbligatorio')),
    privacy: yup.boolean().required(t('Campo obbligatorio')).oneOf([true], t('Campo obbligatorio')),
  })

  const initialValues = {
    email: '',
    privacy: 0,
  }

  return (
    <div className={`newsletter-form ${className ? ` ${className}` : ''}`}>
      <Formik
        validateOnBlur
        validateOnChange
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values) => {
          if (values.privacy) {
            createUtenteNewsletter({
              variables: {
                input: {
                  email: values?.email,
                },
              },
            })
              .then((res) => {
                if (res?.data?.createUtenteNewsletter?.status) {
                  setError(null)
                  if (setOpen) setOpen(false)
                  trackingGAevent(2, 'user', 'newsletter', DOMAIN + router.asPath, getCurrentDate())
                  if (res?.data?.createUtenteNewsletter?.message === 'reiscritto')
                    router.push('/newsletter/conferma')
                  else router.push('/newsletter/thank-you/')
                } else setError(t(res?.data?.createUtenteNewsletter?.message))
              })
              .catch((err) => {
                setError(t(err?.data?.createUtenteNewsletter?.message))
              })
          } else setError(t("Non è stata accettata l'informativa"))
        }}
      >
        {({ values, touched, errors, handleBlur, handleChange, setFieldValue, isValid }) => (
          <Form>
            {error && (
              <NotificationBar
                label={error}
                variant="alert"
                icon={<Alert />}
                className="form-login__error"
              />
            )}
            <FormLayout>
              <FormField
                label={`${t('Inserisci la tua email')}`}
                status={getFormFieldStatus({ touched, errors }, 'email')}
                errorMessage={errors?.email}
                layout="full"
              >
                <FormInput
                  placeholder={t('La tua email')}
                  value={values?.email}
                  onBlur={handleBlur('email')}
                  onChange={handleChange('email')}
                />
              </FormField>
              <FormField
                layout="full"
                status={getFormFieldStatus({ touched, errors }, 'privacy')}
                errorMessage={errors?.privacy}
              >
                <div className="container-newsletter">
                  <FormCheckbox
                    id={className ? 'newsletter-form__privacyAlt' : 'newsletter-form__privacy'}
                    //   label={t('Ho letto e accetto l’{0}informativa sulla privacy{1}', [
                    //  '<Link legacyBehavior href="{privacy_policy.url}"><a target="_blank">',
                    //     '</a></Link>',
                    //   ])}
                    label={t('Ho letto e accetto')}
                    className="newsletter-form__privacy"
                    checked={!!values?.privacy}
                    onBlur={handleBlur('privacy')}
                    onChange={handleChange('privacy')}
                  />
                  <div className="form-checkbox__label privacy">
                    {' '}
                    <Link legacyBehavior href={privacy_policy.url}>
                      <a target="_blank">{t('l’informativa sulla privacy')}</a>
                    </Link>
                  </div>
                </div>
              </FormField>
            </FormLayout>
            <div className={className ? 'box__cta-box' : 'newsletter-form__cta-box'}>
              <Button
                type="submit"
                label={textSubmit ? textSubmit : t('Voglio iscrivermi alla newsletter')}
                disabled={Object.keys(touched).length === 0 || !isValid}
              />
            </div>
            <p
              className="newsletter-form__disclaimer"
              style={className === 'box' ? { display: 'none' } : {}}
            >
              {t('*Promozione valida per i nuovi clienti per un acquisto minimo di €60')}
            </p>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default NewsletterForm
