import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Remove({ className }: Props) {
  return (
    <IconBase name="remove" w={24} className={className}>
      <circle cx="12" cy="12" r="9" stroke="none"/>
      <rect x="15.4854" y="7" width="2" height="12" rx="1" transform="rotate(45 15.4854 7)" stroke="none"/>
      <rect x="7" y="8.41406" width="2" height="12" rx="1" transform="rotate(-45 7 8.41406)" stroke="none"/>
    </IconBase>
  )
}

export default Remove
