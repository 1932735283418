import {
  TipologiaFermo,
  TipologiaFrizzante,
  TipologiaSpumante,
  TipologiaChampagne,
  SpiritsAmari,
  SpiritsCocktail,
  SpiritsDistillati,
  SpiritsGin,
  SpiritsGrappa,
  SpiritsLiquori,
  SpiritsVodka,
  AbbinamentiAperitivo,
  BlogBuonoASapersi,
  BlogDiVino,
  BlogInCucina,
  BlogIspirazioni,
  BlogSpirits,
  AbbinamentiFormaggi,
  AbbinamentiPizza,
  AbbinamentiPrimi,
  AbbinamentiCarne,
  AbbinamentiPesce,
  AbbinamentiSushi,
  AbbinamentiVegetariano,
  AbbinamentiDolci,
  FiltriCantina,
  FiltriCartaVini,
  FiltriColore,
  FiltriAbbinamenti,
  FiltriRegione,
  FiltriTipo,
  ChiSiamoProduttori,
  ChiSiamoTracciabilita,
  ChiSiamoServizio,
  PremioGrappolo,
  PremioVitae,
  PremioCalice,
  PremioBottiglia,
  PremioStella,
} from 'components/atoms/Icons'

export const getIconByChiave = (chiave) => {
  if (chiave === 'rosso_fermo' || chiave === 'bianco_fermo' || chiave === 'rosato_fermo') {
    return <TipologiaFermo />
  }
  if (
    chiave === 'rosso_frizzante' ||
    chiave === 'bianco_frizzante' ||
    chiave === 'rosato_frizzante'
  ) {
    return <TipologiaFrizzante />
  }
  if (chiave === 'rosso_spumante' || chiave === 'bianco_spumante' || chiave === 'rosato_spumante') {
    return <TipologiaSpumante />
  }
  if (
    chiave === 'rosso_champagne' ||
    chiave === 'bianco_champagne' ||
    chiave === 'rosato_champagne'
  ) {
    return <TipologiaChampagne />
  }
  if (chiave === 'complementari-aperitivo' || chiave === 'aperitivo') {
    return <AbbinamentiAperitivo />
  }
  if (chiave === 'amari') {
    return <SpiritsAmari />
  }
  if (chiave === 'Cocktail' || chiave === 'cocktail') {
    return <SpiritsCocktail />
  }
  if (chiave === 'distillati') {
    return <SpiritsDistillati />
  }
  if (chiave === 'gin') {
    return <SpiritsGin />
  }
  if (chiave === 'grappa') {
    return <SpiritsGrappa />
  }
  if (chiave === 'liquori') {
    return <SpiritsLiquori />
  }
  if (chiave === 'vodka') {
    return <SpiritsVodka />
  }
}

export const getIconCategoriaNews = (id) => {
  switch (id) {
    case 3:
      return <BlogBuonoASapersi />
    case 4:
      return <BlogIspirazioni />
    case 5:
      return <BlogInCucina />
    case 6:
      return <BlogDiVino />
    case 8:
      return <BlogSpirits />
    default:
      return null
  }
}

export const getIconAbbinamentiByChiave = (chiave) => {
  if (chiave === 'aperitivo') {
    return <AbbinamentiAperitivo />
  }
  if (chiave === 'formaggi' || chiave === 'formaggi-salumi') {
    return <AbbinamentiFormaggi />
  }
  if (chiave === 'pizza') {
    return <AbbinamentiPizza />
  }
  if (chiave === 'primi') {
    return <AbbinamentiPrimi />
  }
  if (chiave === 'carne') {
    return <AbbinamentiCarne />
  }
  if (chiave === 'pesce') {
    return <AbbinamentiPesce />
  }
  if (chiave === 'sushi') {
    return <AbbinamentiSushi />
  }
  if (chiave === 'vegetariano') {
    return <AbbinamentiVegetariano />
  }
  if (chiave === 'dolci') {
    return <AbbinamentiDolci />
  }
}

export const getIconFiltriByChiave = (chiave) => {
  switch (chiave) {
    case 'cantina':
      return <FiltriCantina />
    case 'carta_vini':
      return <FiltriCartaVini />
    case 'colore':
      return <FiltriColore />
    case 'abbinamenti':
      return <FiltriAbbinamenti />
    case 'regione':
      return <FiltriRegione />
    case 'tipo':
      return <FiltriTipo />
    default:
      return null
  }
}

export const getIconChiSiamoMenuByChiave = (chiave) => {
  switch (chiave) {
    case 'produttori':
      return <ChiSiamoProduttori />
    case 'tracciabilita':
      return <ChiSiamoTracciabilita />
    case 'servizio':
      return <ChiSiamoServizio />
    default:
      return null
  }
}

export const getIconPremioByChiave = (chiave) => {
  switch (chiave) {
    case 'premio-grappolo':
      return <PremioGrappolo />
    case 'premio-vitae':
      return <PremioVitae />
    case 'premio-calice':
      return <PremioCalice />
    case 'premio-bottiglia':
      return <PremioBottiglia />
    case 'premio-stella':
      return <PremioStella />
    default:
      return null
  }
}
