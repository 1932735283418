import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function CaratteristicheColore({ className }: Props) {
  return (
    <IconBase name="caratteristiche-colore" w={32} className={className}>
      <path d="M21.2099 27.4199C18.1999 27.4199 16.7699 24.8699 16.4999 24.3399V18.3199C16.6499 18.2799 16.7899 18.2399 16.9299 18.1699C17.8599 17.7199 19.3699 16.7899 21.2599 14.8999C24.4399 11.7199 20.8999 5.22994 20.7499 4.94994C20.6599 4.78994 20.4899 4.68994 20.3099 4.68994H11.6799C11.4999 4.68994 11.3299 4.78994 11.2399 4.94994C11.0899 5.21994 7.54995 11.7199 10.7299 14.8999C12.6199 16.7899 14.1299 17.7199 15.0599 18.1699C15.1999 18.2399 15.3399 18.2799 15.4899 18.3199V24.3099C15.1499 24.8599 13.4399 27.4099 10.7799 27.4099C10.4999 27.4099 10.2799 27.6299 10.2799 27.9099C10.2799 28.1899 10.4999 28.4099 10.7799 28.4099H21.1899C21.4699 28.4099 21.6899 28.1899 21.6899 27.9099C21.6899 27.6299 21.4799 27.4199 21.2099 27.4199ZM11.4499 14.1899C9.11995 11.8499 11.3999 6.87994 11.9899 5.68994H20.0099C20.6099 6.87994 22.8899 11.8499 20.5499 14.1899C18.6599 16.0799 17.1999 16.9299 16.4899 17.2699C16.1799 17.4199 15.8099 17.4199 15.4999 17.2699C14.7999 16.9299 13.3299 16.0799 11.4499 14.1899ZM13.9199 27.4199C14.8599 26.7899 15.5499 25.9899 15.9599 25.4199C16.3399 25.9999 16.9699 26.7899 17.8999 27.4199H13.9199Z" stroke="none"/>
      <path d="M15.9999 16.7399C16.0699 16.7399 16.1399 16.7199 16.2099 16.6899C16.9399 16.3399 18.8399 15.1099 20.0999 13.7299C20.6699 13.1099 20.9499 12.2499 20.9199 11.1799C20.9199 11.0399 20.8099 10.9399 20.6699 10.9399H11.3199C11.1799 10.9399 11.0699 11.0499 11.0699 11.1799C11.0499 12.2799 11.3199 13.1399 11.8999 13.7399C13.3299 15.2199 15.0099 16.3199 15.7899 16.6999C15.8599 16.7199 15.9299 16.7399 15.9999 16.7399ZM11.5799 11.4299H20.4299C20.4099 12.2499 20.1799 12.9099 19.7399 13.3899C18.5399 14.6999 16.6899 15.9099 16.0099 16.2399C15.4899 15.9899 13.8199 14.9899 12.2699 13.3899C11.8299 12.9299 11.5999 12.2799 11.5799 11.4299Z" stroke="none"/>
    </IconBase>
  )
}

export default CaratteristicheColore
