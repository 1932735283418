import React from 'react'

interface Props {
  label?: string
  active?: boolean
  className?: string
  onClick?: (e: any) => void
}

const FiltroBoolean = (props: Props) => {
  const { label = '', active = false, className = '', onClick = () => {} } = props

  const classNames = `filtro-boolean${active ? ' filtro-boolean--active' : ''}${
    className ? ` ${className}` : ''
  }`

  return onClick ? (
    <button type="button" className={classNames} onClick={onClick}>
      {label}
    </button>
  ) : (
    <div className={classNames}>{label}</div>
  )
}

export default FiltroBoolean
