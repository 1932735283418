import React from 'react'
import { priceNotation } from 'utils/safe'
import { CheckoutOption } from 'components/molecules'

interface Props {
  metodoSpedizione: any
  checked: boolean
  onClick: (e: any) => void
  className?: string
}

const CheckoutShippingMethodOption = (props: Props) => {
  const { metodoSpedizione = {}, checked = false, onClick = () => {}, className = '' } = props

  return (
    <CheckoutOption
      title={metodoSpedizione.nome}
      description={metodoSpedizione.descrizione}
      checked={checked}
      onClick={onClick}
      fullClick
      className={`checkout-shipping-method-option${className ? ` ${className}` : ''}`}
      actions={
        <>
          <div onClick={onClick} className="checkout-shipping-method-option__price">
            {priceNotation(metodoSpedizione.prezzoScontato)}
          </div>
        </>
      }
    />
  )
}

export default CheckoutShippingMethodOption
