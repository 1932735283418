import React from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/it'

interface Props {
  data?: string
  className?: string
  children?: any
}

const MyAccountOrdiniBlock = (props: Props) => {
  const { data = '', className = '', children = null } = props

  // TODO : dinamicizzare il locale sulla base del canale
  return (
    <div className={`myaccount-ordini-block ${className ? ` ${className}` : ''}`}>
      {data && (
        <p className="myaccount-ordini-block__title">
          {dayjs(`${data}-01`).locale('it').format('MMMM YYYY')}
        </p>
      )}
      <div className="myaccount-ordini-block__content">{children}</div>
    </div>
  )
}

export default MyAccountOrdiniBlock
