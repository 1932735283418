//Serve per identificare se Connectif è inizializzato. Gli passi qualsiasi funzione
var utente_global = {}

export const executeIfInitialized = (callback, param) => {
  try {
    if (window.connectif && window.connectif.managed && window.connectif.managed.isInitialized()) {
      param ? callback(param) : callback()
    } else {
      document.addEventListener(
        'connectif.managed.initialized',
        function onConnectifInitialized() {
          param ? callback(param) : callback()
        },
        { once: true }
      )
    }
  } catch (error) {
    console.log('Errore tracciamento Connectif:', error)
  }
}

export const updateConnectif = (utente) => {
  //return utente
  if (utente)
    utente_global = {
      primaryKey: utente.email,
      _name: utente.nome,
      _surname: utente.cognome,
      _newsletter_subscription_status: utente.privacyCommerciale ? 'Subscribed' : 'Unsubscribed',
    }
  return utente_global
}

export const trackConnectifPageVisit = () => {
  window.connectif.managed.sendEvents([{ type: 'page-visit' }], {
    entityInfo: utente_global,
  })
}

export const trackConnectifProductDetail = (prodotto) => {
  var id = prodotto?.attributi?.annata ? prodotto?.codice + '-' + prodotto?.attributi?.annata : ''
  window.connectif.managed.sendEvents(
    [
      {
        type: 'product-visited',
        product: {
          name: prodotto?.nome || '',
          productDetailUrl: 'https://www.vinicum.com' + prodotto.url,
          productId: prodotto?.varianteProdotto?.sku || id,
          unitPrice: prodotto?.prezzoScontato?.toFixed(2) || '',
          availability:
            prodotto.acquistabile && prodotto?.varianteProdotto ? 'instock' : 'outofstock',
          imageUrl: prodotto.mainImage || '',
          description: prodotto.descrizione || '',
          brand: prodotto?.cantina?.nome || '',
          discountedPercentage: prodotto?.scontoPercentuale,
        },
      },
      // { "type": "page-visit" }
    ],
    {
      entityInfo: utente_global,
    }
  )
}

export const trackConnectifContact = (contatto) => {
  window.connectif.managed.sendEvents([{ type: 'page-visit' }], {
    entityInfo: {
      primaryKey: contatto['email'],
      _name: contatto['nome'],
      _surname: contatto['cognome'],
    },
  })
}

export const trackConnectifNewsletter = (email) => {
  window.connectif.managed.sendEvents(
    [
      {
        type: 'newsletter-subscribe',
        email: email,
      },
    ],
    {
      entityInfo: utente_global,
    }
  )
}

export const trackConnectifLogin = (email) => {
  window.connectif.managed.sendEvents(
    [
      {
        type: 'login',
      },
    ],
    {
      entityInfo: utente_global,
    }
  )
}

export const trackConnectifRegister = (email) => {
  window.connectif.managed.sendEvents(
    [
      {
        type: 'register',
      },
    ],
    {
      entityInfo: utente_global,
    }
  )
}
export const trackConnectifSearch = (search_text) => {
  window.connectif.managed.sendEvents(
    [
      {
        type: 'search',
        searchText: search_text,
      },
    ],
    {
      entityInfo: utente_global,
    }
  )
}

function getSessionID() {
  var sessionID = localStorage.getItem('sessionID')
  if (!sessionID) {
    sessionID = generateUniqueID()
    localStorage.setItem('sessionID', sessionID)
  }
  return sessionID
}

function generateUniqueID() {
  var timestamp = new Date().getTime()
  var randomNum = Math.floor(Math.random() * 1000)
  return 'session_' + timestamp + '_' + randomNum
}

export const trackConnectifCart = (carrello) => {
  const products = []

  for (let i = 0; carrello && i < carrello?.items.length; i += 1) {
    const prodotto = carrello.items[i]?.prodotto
    products.push({
      quantity: carrello.items[i]?.quantita || '',
      price:
        carrello.items[i]?.quantita *
        (carrello.items[i]?.omaggio ? 0 : prodotto?.prodotto?.prezzoScontato || 0),
      name: prodotto?.prodotto?.nome || '',
      productDetailUrl: 'https://www.vinicum.com' + prodotto?.prodotto?.url,
      productId: prodotto?.sku || '',
      unitPrice: carrello.items[i]?.omaggio ? 0 : prodotto?.prodotto?.prezzoScontato || 0,
      imageUrl: prodotto?.prodotto?.mainImage || '',
      description: prodotto?.prodotto?.descrizione || '',
      brand: prodotto?.prodotto?.cantina?.nome || '',
    })
  }

  var uniqueSessionID = getSessionID()
  const cart = {
    cartId: uniqueSessionID,
    totalQuantity: carrello?.items.length,
    totalPrice: carrello?.prezzo || 0,
    products: products,
  }
  window.connectif.managed.sendEvents([], {
    entityInfo: utente_global,
    cart: cart,
  })
}

export const trackConnectifPurchase = (ordine) => {
  const products = []
  const righeProdotti = ordine?.righeOrdine?.filter(
    (riga) => riga.tipologia?.toLowerCase() === 'prodotto'
  )
  for (let i = 0; righeProdotti && i < righeProdotti.length; i += 1) {
    const prodotto = righeProdotti[i]?.prodotto
    products.push({
      quantity: righeProdotti[i]?.quantitaOrdinata,
      price:
        righeProdotti[i]?.quantitaOrdinata *
        (righeProdotti[i]?.omaggio ? 0 : righeProdotti[i]?.prezzoUnitarioScontato || 0),
      name: prodotto?.prodotto?.nome || '',
      productDetailUrl: 'https://www.vinicum.com' + prodotto?.prodotto?.url,
      productId: prodotto?.sku || '',
      unitPrice: righeProdotti[i]?.omaggio ? 0 : righeProdotti[i]?.prezzoUnitarioScontato || 0,
      imageUrl: prodotto?.prodotto?.mainImage || '',
      description: prodotto?.prodotto?.descrizione || '',
      brand: prodotto?.prodotto?.cantina?.nome || '',
    })
  }

  const purchase = {
    cartId: getSessionID(),
    purchaseId: ordine.codice,
    totalQuantity: ordine.numeroProdotti,
    totalPrice: ordine?.prezzoTotaleScontato || 0,
    products: products,
  }
  window.connectif.managed.sendEvents([
    { type: 'purchase', entityInfo: utente_global, purchase: purchase },
  ])
  localStorage.removeItem('sessionID')
}
