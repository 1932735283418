import React from 'react'
import { BreadcrumbJsonLd } from 'next-seo'
import { DOMAIN } from 'utils/settings'

interface ItemProps {
  titolo: string
  url?: string
  last?: boolean
  variant?: '' | 'white'
}

interface Props {
  breadcrumbs: {
    titolo: string
    url?: string
  }[]
  variant?: '' | 'white'
  className?: string
}

const BreadcrumbsItem = (props: ItemProps) => {
  const { titolo = '', url = '', last = false, variant = '' } = props

  return (
    <div className={`breadcrumbs-item${variant ? ` breadcrumbs-item--${variant}` : ''}`}>
      {last || !url ? (
        <span className={`breadcrumbs-item__label${last ? ` breadcrumbs-item__label--last` : ''}`}>
          {titolo}
        </span>
      ) : (
        <a className="breadcrumbs-item__label breadcrumbs-item__label--link" href={url}>
          {titolo}
        </a>
      )}
    </div>
  )
}

const Breadcrumbs = (props: Props) => {
  const { breadcrumbs = [], variant = '', className = '' } = props

  const jsonLdItems = breadcrumbs.map((item, i) => ({
    position: i + 1,
    name: item.titolo ? item.titolo.replace(/"/g, '') : '',
    item: `${DOMAIN}${item.url || '/'}`, // per homepage
  }))

  return (
    <section className={`breadcrumbs ${className ? ` ${className}` : ''}`}>
      {breadcrumbs.map((item, index) => (
        <BreadcrumbsItem
          titolo={item.titolo}
          url={item.url}
          last={index === breadcrumbs.length - 1}
          variant={variant}
          key={index}
        />
      ))}
      <BreadcrumbJsonLd itemListElements={jsonLdItems} />
    </section>
  )
}

Breadcrumbs.Item = BreadcrumbsItem

export default Breadcrumbs
