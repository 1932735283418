import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { useTrans } from 'hooks'
import { getFormFieldStatus } from 'utils/safe'
import { FormLayout, FormField, FormInput, FormCheckbox, FormRadio, FormSelect } from 'components/atoms'

import { privatoToTipo } from 'utils/indirizzo'
import { province } from 'utils/province'

interface Props {
  className?: string
  formRef?: any
  indirizzo?: any
  hideSaveAddress?: boolean
}

const CheckoutBillingAddressForm = (props: Props) => {
  const { className = '', formRef = null, indirizzo = null, hideSaveAddress = false } = props

  const t = useTrans()

  // FAKE (prendere valori da backend?)
  const typeOptions = [
    { id: '1', label: t('Privato'), value: '1' },
    { id: '2', label: t('Azienda'), value: '2' },
    { id: '3', label: t('Libero professionista'), value: '3' },
  ]

  const [type, setType] = useState(privatoToTipo(indirizzo?.privato))

  const handleChangeTipo = (e, setFieldValue) => {
    setType(e.target.value)
    setFieldValue('tipo', e.target.value)
  }

  // TODO: sistemare le validazioni
  const validationSchema = yup.object().shape({
    tipo: yup.string().required(t('Campo obbligatorio')),
    nome: type === '2' ? yup.string() : yup.string().required(t('Campo obbligatorio')), // Obbligatorio per tutti tranne azienda
    cognome: type === '2' ? yup.string() : yup.string().required(t('Campo obbligatorio')), // Obbligatorio per tutti tranne azienda
    indirizzo: yup.string().required(t('Campo obbligatorio')),
    citta: yup.string().required(t('Campo obbligatorio')),
    cap: yup.string().max(10, t('Troppo lungo')).required(t('Campo obbligatorio')),
    provincia: yup.string().max(10, t('Troppo lungo')).required(t('Campo obbligatorio')),
    telefono: yup
      .number()
      .typeError(t('Non è stato inserito un numero di telefono valido'))
      .positive(t('Valore negativo'))
      .integer(t('Valore intero'))
      .test('len', t('Numero troppo corto'), (val) => !val || (val && val.toString().length >= 6)),
    cf: yup.string().required(t('Campo obbligatorio')),
    ragione_sociale: type === '2' ? yup.string().required(t('Campo obbligatorio')) : yup.string(), // Obbligatorio solo per azienda
    piva: type === '1' ? yup.string() : yup.string().required(t('Campo obbligatorio')), // Obbligatorio per tutti tranne privato
    codice_destinatario_sdi: yup.string(),
    pec: yup.string(),
  })

  const initialValues = {
    tipo: privatoToTipo(indirizzo?.privato),
    nome: indirizzo?.nome || '',
    cognome: indirizzo?.cognome || '',
    indirizzo: indirizzo?.indirizzo || '',
    citta: indirizzo?.citta || '',
    cap: indirizzo?.cap || '',
    provincia: indirizzo?.provincia || '',
    telefono: indirizzo?.telefono || '',
    cf: indirizzo?.cf || '',
    ragione_sociale: indirizzo?.nome || '',
    piva: indirizzo?.piva || '',
    codice_destinatario_sdi: indirizzo?.codice_destinatario_sdi || '',
    pec: indirizzo?.pec || '',
    save_address: false,
    is_fatturazione: true,
  }

  return (
    <div className={`checkout-billing-address-form ${className ? ` ${className}` : ''}`}>
      <Formik
        validateOnMount
        validateOnBlur
        validateOnChange
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values) => {}}
        innerRef={formRef}
      >
        {({ values, touched, errors, handleBlur, handleChange, setFieldValue, isValid }) => (
          <Form>
            <FormLayout>
              <FormField
                layout="full"
                label={t('Cosa sei?')}
                status={getFormFieldStatus({ touched, errors }, 'tipo')}
                errorMessage={errors?.tipo}
              >
                <FormRadio
                  id={'checkout-billing-address-form__type'}
                  value={values?.tipo}
                  options={typeOptions}
                  onChange={(e) => handleChangeTipo(e, setFieldValue)}
                />
              </FormField>
              {type !== '' && (
                <>
                  {/* Azienda con Ragione sociale, altri con Nome e Cognome */}
                  {type === '2' ? (
                    <FormField
                      layout="full"
                      label={`${t('Ragione sociale')}*`}
                      status={getFormFieldStatus({ touched, errors }, 'ragione_sociale')}
                      errorMessage={errors?.ragione_sociale}
                    >
                      <FormInput
                        placeholder={t('Ragione sociale')}
                        value={values?.ragione_sociale}
                        onBlur={handleBlur('ragione_sociale')}
                        onChange={handleChange('ragione_sociale')}
                      />
                    </FormField>
                  ) : (
                    <>
                      <FormField
                        label={`${t('Nome')}*`}
                        status={getFormFieldStatus({ touched, errors }, 'nome')}
                        errorMessage={errors?.nome}
                      >
                        <FormInput
                          placeholder={t('Nome')}
                          value={values?.nome}
                          onBlur={handleBlur('nome')}
                          onChange={handleChange('nome')}
                        />
                      </FormField>
                      <FormField
                        label={`${t('Cognome')}*`}
                        status={getFormFieldStatus({ touched, errors }, 'cognome')}
                        errorMessage={errors?.cognome}
                      >
                        <FormInput
                          placeholder={t('Cognome')}
                          value={values?.cognome}
                          onBlur={handleBlur('cognome')}
                          onChange={handleChange('cognome')}
                        />
                      </FormField>
                    </>
                  )}

                  <FormField
                    layout="full"
                    label={`${t('Indirizzo')}*`}
                    status={getFormFieldStatus({ touched, errors }, 'indirizzo')}
                    errorMessage={errors?.indirizzo}
                  >
                    <FormInput
                      placeholder={t('Indirizzo')}
                      value={values?.indirizzo}
                      onBlur={handleBlur('indirizzo')}
                      onChange={handleChange('indirizzo')}
                    />
                  </FormField>
                  <FormField
                    layout={type !== '1' ? "sm" : "md"}
                    label={`${t('CAP')}*`}
                    status={getFormFieldStatus({ touched, errors }, 'cap')}
                    errorMessage={errors?.cap}
                  >
                    <FormInput
                      placeholder={t('CAP')}
                      value={values?.cap}
                      onBlur={handleBlur('cap')}
                      onChange={handleChange('cap')}
                    />
                  </FormField>
                  {type !== '1' &&
                  <FormField
                    layout="sm"
                    label={`${t('Provincia')}*`}
                    status={getFormFieldStatus({ touched, errors }, 'provincia')}
                    errorMessage={errors?.provincia}
                  >
                    <FormSelect
                      options={province?.map((item) => ({
                        value: item.chiave,
                        label: item.nome,
                      }))}
                      value={values?.provincia}
                      placeholder={t('Provincia')}
                      onBlur={handleBlur('provincia')}
                      onChange={(selectedOption) => setFieldValue('provincia', selectedOption.value, true)}
                    />
                  </FormField>
                  }
                  <FormField
                    label={`${t('Città')}*`}
                    status={getFormFieldStatus({ touched, errors }, 'citta')}
                    errorMessage={errors?.citta}
                  >
                    <FormInput
                      placeholder={t('Città')}
                      value={values?.citta}
                      onBlur={handleBlur('citta')}
                      onChange={handleChange('citta')}
                    />
                  </FormField>
                  {type === '1' && (
                    <FormField
                      label={`${t('Provincia')}*`}
                      status={getFormFieldStatus({ touched, errors }, 'provincia')}
                      errorMessage={errors?.provincia}
                    >
                      <FormSelect
                        options={province?.map((item) => ({
                          value: item.chiave,
                          label: item.nome,
                        }))}
                        value={values?.provincia}
                        placeholder={t('Provincia')}
                        onBlur={handleBlur('provincia')}
                        onChange={(selectedOption) => setFieldValue('provincia', selectedOption.value, true)}
                      />
                    </FormField>
                  )}
                  <FormField
                    label={`${t('Codice fiscale')}*`}
                    status={getFormFieldStatus({ touched, errors }, 'cf')}
                    errorMessage={errors?.cf}
                  >
                    <FormInput
                      placeholder={t('Codice fiscale')}
                      value={values?.cf}
                      onBlur={handleBlur('cf')}
                      onChange={handleChange('cf')}
                    />
                  </FormField>
                  {type !== '1' && (
                    <>
                      <FormField
                        label={`${t('Partita IVA')}*`}
                        status={getFormFieldStatus({ touched, errors }, 'piva')}
                        errorMessage={errors?.piva}
                      >
                        <FormInput
                          placeholder={t('Partita IVA')}
                          value={values?.piva}
                          onBlur={handleBlur('piva')}
                          onChange={handleChange('piva')}
                        />
                      </FormField>
                      <FormField
                        label={t('Codice destinatario SDI')}
                        status={getFormFieldStatus({ touched, errors }, 'codice_destinatario_sdi')}
                        errorMessage={errors?.codice_destinatario_sdi}
                      >
                        <FormInput
                          placeholder={t('Codice destinatario SDI')}
                          value={values?.codice_destinatario_sdi}
                          onBlur={handleBlur('codice_destinatario_sdi')}
                          onChange={handleChange('codice_destinatario_sdi')}
                        />
                      </FormField>
                      <FormField
                        label={t('PEC')}
                        status={getFormFieldStatus({ touched, errors }, 'pec')}
                        errorMessage={errors?.pec}
                      >
                        <FormInput
                          placeholder={t('PEC')}
                          value={values?.pec}
                          onBlur={handleBlur('pec')}
                          onChange={handleChange('pec')}
                        />
                      </FormField>
                    </>
                  )}
                </>
              )}
            </FormLayout>
            {type !== '' && (
              <div className="checkout-billing-address-form__bottom">
                <p className="checkout-billing-address-form__required-label">
                  *{t('Campi obbligatori')}
                </p>
                {!hideSaveAddress && (
                  <FormCheckbox
                    checked={values?.save_address}
                    onBlur={handleBlur('save_address')}
                    onChange={handleChange('save_address')}
                    label={t('Salva indirizzo per i prossimi acquisti')}
                    id="checkout-billing-address-form__save-address"
                  />
                )}
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CheckoutBillingAddressForm
