import React, { useEffect, useState } from 'react'
//import { useSelector } from 'react-redux'
import { useUtente } from 'hooks'
import { useRouter } from 'next/router'
import { PageBuilder } from 'react-pagebuilder/pagebuilder/PageBuilder'

const PlaceholderReact = ({ contentTypeId, objectId, pkey }) => {
  //const { utente } = useSelector((state) => state.utente)
  const { utente, loading } = useUtente()
  const router = useRouter()
  const { locale: lang } = { locale: router.locale }
  const [content, setContent] = useState()

  useEffect(() => {
    let content = null
    if (loading) {
      content = null
    } else if (utente && utente.edit) {
      content = (
        <div className="custom-content active">
          <iframe
            className="pagebuilder-placeholder"
            src={`/${router.locale}/pagebuilder/?contentTypeId=${contentTypeId}&objectId=${objectId}&key=${pkey}`}
          />
        </div>
      )
    } else {
      content = (
        <div className="custom-content">
          <PageBuilder contentTypeId={contentTypeId} objectId={objectId} pkey={pkey} />
        </div>
      )
    }
    setContent(content)
  }, [JSON.stringify(utente), loading, contentTypeId, objectId, pkey])

  return <>{content && content}</>
}

export default PlaceholderReact
