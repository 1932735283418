import React from 'react'
import { ArrowUp } from 'components/atoms/Icons'

interface Props {
  className?: string
}

const BackToTop = (props: Props) => {
  const { className = '' } = props

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <button
      type="button"
      className={`back-to-top ${className ? ` ${className}` : ''}`}
      onClick={goToTop}
    >
      <ArrowUp />
    </button>
  )
}

export default BackToTop
