import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function WineFormato({ className }: Props) {
  return (
    <IconBase name="wine-formato" w={40} h={41} className={className}>
      <g clipPath="url(#wine-formato-clip0)">
      <path d="M33.125 13.1C32.9705 13.0039 32.8241 12.8952 32.6875 12.775C32.4352 12.4954 32.2854 12.1384 32.2625 11.7625V7.5C32.3782 7.34672 32.4538 7.16689 32.4821 6.97693C32.5105 6.78697 32.4909 6.59292 32.425 6.4125C32.0875 5.6 30.9 5.5 29.925 5.5C28.95 5.5 27.675 5.6 27.35 6.425C27.2904 6.60862 27.2775 6.80419 27.3124 6.99405C27.3473 7.18391 27.429 7.36209 27.55 7.5125V11.7625C27.5346 12.1377 27.3887 12.4958 27.1375 12.775C26.9963 12.8951 26.8459 13.0038 26.6875 13.1C26.1585 13.388 25.7203 13.8178 25.4222 14.3411C25.1241 14.8645 24.9779 15.4606 25 16.0625V32.9875C25 33.4375 25.4875 34.9125 29.925 34.9125C34.3625 34.9125 34.8 33.4375 34.8375 32.9375V16.0625C34.8572 15.458 34.7073 14.8601 34.4046 14.3365C34.1019 13.8129 33.6587 13.3846 33.125 13.1ZM26.225 18.375C27.3892 18.85 28.6447 19.0593 29.9 18.9875C31.1512 19.0595 32.4028 18.8502 33.5625 18.375V24.85C32.4384 25.4424 31.1633 25.6861 29.9 25.55C28.6326 25.6871 27.3532 25.4434 26.225 24.85V18.375ZM27.3625 14.1875C27.5842 14.0476 27.7933 13.8886 27.9875 13.7125C28.4773 13.1993 28.7581 12.5218 28.775 11.8125V7.275C28.7648 7.12994 28.7127 6.99097 28.625 6.875C29.0336 6.77393 29.4545 6.73184 29.875 6.75C30.2963 6.73068 30.718 6.77706 31.125 6.8875C31.0274 6.99279 30.9737 7.13142 30.975 7.275V11.7625C30.9918 12.4718 31.2726 13.1493 31.7625 13.6625C31.9567 13.8386 32.1658 13.9976 32.3875 14.1375C32.7472 14.3086 33.0477 14.5832 33.2504 14.9262C33.453 15.2692 33.5487 15.6648 33.525 16.0625V17.6125C32.4162 18.2129 31.1567 18.4778 29.9 18.375C28.6329 18.5054 27.3554 18.262 26.225 17.675V16.0625C26.2013 15.6648 26.2969 15.2692 26.4996 14.9262C26.7023 14.5832 27.0027 14.3086 27.3625 14.1375V14.1875ZM29.8625 33.7125C26.9125 33.7125 26.2875 33.025 26.1875 32.8875V25.525C27.3531 25.9942 28.6082 26.1991 29.8625 26.125C31.1127 26.1993 32.3638 25.9943 33.525 25.525V32.8375C33.4625 32.975 32.8375 33.6625 29.9 33.6625L29.8625 33.7125Z" stroke="none"/>
      <path d="M22.325 15.3873C22.1779 15.2964 22.0398 15.1918 21.9125 15.0748C21.6844 14.8179 21.5481 14.4926 21.525 14.1498V10.1123C21.6354 9.96361 21.707 9.78969 21.7332 9.60633C21.7594 9.42297 21.7394 9.23597 21.675 9.0623C21.35 8.2748 20.2125 8.1748 19.25 8.1748C18.2875 8.1748 17.1 8.2748 16.75 9.0748C16.6872 9.25101 16.6715 9.44055 16.7043 9.6247C16.7372 9.80885 16.8176 9.98123 16.9375 10.1248V14.1498C16.9203 14.4939 16.7832 14.8212 16.55 15.0748C16.4251 15.1947 16.2867 15.2995 16.1375 15.3873C15.6315 15.6603 15.2122 16.0698 14.9271 16.5691C14.6421 17.0685 14.5028 17.6378 14.525 18.2123V33.0623C14.525 33.4873 14.9875 34.9123 19.2125 34.9123C23.4375 34.9123 23.9 33.4873 23.9 33.0123V18.2123C23.9258 17.6416 23.7921 17.075 23.5139 16.576C23.2357 16.077 22.824 15.6654 22.325 15.3873ZM15.8125 20.3873C16.9051 20.8168 18.0779 21.0045 19.25 20.9373C20.4105 20.9951 21.5699 20.8033 22.65 20.3748V26.4373C21.592 26.9842 20.3972 27.2102 19.2125 27.0873C18.0279 27.2087 16.8335 26.9829 15.775 26.4373L15.8125 20.3873ZM16.875 16.4373C17.0892 16.3025 17.2901 16.1477 17.475 15.9748C17.9403 15.484 18.2075 14.8384 18.225 14.1623V9.9123C18.2179 9.78219 18.1746 9.65665 18.1 9.54981C18.477 9.46345 18.8634 9.42564 19.25 9.4373C19.6325 9.42356 20.0151 9.46141 20.3875 9.54981C20.3167 9.65769 20.2777 9.78331 20.275 9.9123V14.1623C20.2925 14.8384 20.5597 15.484 21.025 15.9748C21.2099 16.1477 21.4108 16.3025 21.625 16.4373C21.9507 16.6005 22.2214 16.8556 22.4036 17.1711C22.5858 17.4866 22.6715 17.8486 22.65 18.2123V19.6248C21.592 20.1717 20.3972 20.3977 19.2125 20.2748C18.0403 20.3888 16.86 20.1632 15.8125 19.6248V18.2123C15.7918 17.8425 15.8818 17.4749 16.0711 17.1565C16.2603 16.8381 16.5402 16.5833 16.875 16.4248V16.4373ZM19.25 33.6748C16.525 33.6748 15.9125 33.0498 15.8125 32.9123V27.1248C16.9051 27.5543 18.0779 27.742 19.25 27.6748C20.4096 27.7365 21.569 27.549 22.65 27.1248V32.8623C22.65 33.0373 21.975 33.6623 19.25 33.6623V33.6748Z" stroke="none"/>
      <path d="M11.9875 18.6751C11.8593 18.604 11.7416 18.5157 11.6375 18.4126C11.443 18.1995 11.3281 17.9257 11.3125 17.6376V14.0876C11.4133 13.9486 11.4776 13.7864 11.4995 13.6161C11.5213 13.4457 11.5 13.2726 11.4375 13.1126C11.1375 12.3876 10.1 12.2876 9.22498 12.2876C8.34998 12.2876 7.27498 12.3876 6.98748 13.1251C6.92606 13.2867 6.9076 13.4615 6.93389 13.6324C6.96018 13.8033 7.03033 13.9644 7.13748 14.1001V17.6376C7.1253 17.9241 7.01497 18.1978 6.82498 18.4126C6.7139 18.5128 6.59237 18.6008 6.46248 18.6751C6.01397 18.9189 5.64064 19.2807 5.38287 19.7213C5.1251 20.1619 4.9927 20.6647 4.99998 21.1751V33.1376C4.99998 33.5376 5.41248 34.8501 9.21248 34.8501C13.0125 34.8501 13.4 33.6001 13.4375 33.1001V21.2376C13.459 20.7177 13.3347 20.2022 13.0784 19.7493C12.8222 19.2964 12.4443 18.9243 11.9875 18.6751ZM6.24998 22.9001C7.20158 23.2475 8.2132 23.4005 9.22498 23.3501C10.2368 23.4005 11.2484 23.2475 12.2 22.9001V28.7376C11.2713 29.1705 10.2464 29.3556 9.22498 29.2751C8.20355 29.3556 7.17866 29.1705 6.24998 28.7376V22.9001ZM7.16248 19.7126C7.35513 19.5936 7.53522 19.4554 7.69998 19.3001C8.12751 18.8504 8.37256 18.2579 8.38748 17.6376V13.8876C8.38453 13.7968 8.36327 13.7075 8.32498 13.6251C8.92082 13.5251 9.52915 13.5251 10.125 13.6251C10.0853 13.707 10.064 13.7966 10.0625 13.8876V17.6376C10.0774 18.2579 10.3225 18.8504 10.75 19.3001C10.9196 19.4496 11.0992 19.5874 11.2875 19.7126C11.5754 19.8454 11.8166 20.062 11.9794 20.3341C12.1422 20.6062 12.2191 20.9211 12.2 21.2376V22.1876C11.2734 22.6272 10.2468 22.8127 9.22498 22.7251C8.20321 22.8127 7.17651 22.6272 6.24998 22.1876V21.2376C6.23262 20.9214 6.31023 20.6072 6.47284 20.3354C6.63545 20.0637 6.87562 19.8468 7.16248 19.7126ZM9.22498 33.6626C6.93748 33.6626 6.36248 33.1626 6.24998 33.0376V29.4501C7.20158 29.7975 8.2132 29.9505 9.22498 29.9001C10.2368 29.9505 11.2484 29.7975 12.2 29.4501V33.0376C12.0875 33.1626 11.5125 33.6626 9.22498 33.6626Z" stroke="none"/>
      </g>
      <defs>
      <clipPath id="wine-formato-clip0">
      <rect width="29.8125" height="29.4125" transform="translate(5 5.5)" stroke="none"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default WineFormato
