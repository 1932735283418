/* eslint-disable unicorn/no-object-as-default-parameter */
export default function reducer(
  state = {
    utente: null,
    csrfToken: '',
  },
  action
) {
  switch (action.type) {
    case 'SET_CURRENT_USER': {
      return {
        ...state,
        utente: action.payload.data?.me,
      }
    }

    case 'REMOVE_CURRENT_USER': {
      return {
        ...state,
        utente: null,
      }
    }

    case 'SET_TOKEN': {
      return {
        ...state,
        csrfToken: action.payload?.headers ? action.payload.headers['x-csrftoken'] : '',
      }
    }

    default: {
      return state
    }
  }
}
