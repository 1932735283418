import React, { useState } from 'react'
import { useTrans } from 'hooks'
import { ArrowLeft } from 'components/atoms/Icons'
import { WContainer, Button } from 'components/atoms'
import { Props as ButtonProps } from 'components/atoms/Button/Button'
import { MyAccountSidebar, Page } from 'components/organisms'

interface Props {
  pagina: any
  className?: string
  containerVariant?: '' | 'xs' | 'none' | 'fluid'
  backButton?: ButtonProps
  activeMenuKey?: string
  hideSidebar?: boolean
  hideOpenMenuMobile?: boolean
  actions?: any
  children?: any
}

const MyAccountPage = (props: Props) => {
  const {
    pagina = {},
    className = '',
    containerVariant = '',
    backButton = null,
    activeMenuKey = '',
    hideSidebar = false,
    hideOpenMenuMobile = false,
    actions = null,
    children = null,
  } = props

  const t = useTrans()
  const [menuMobileOpen, setMenuMobileOpen] = useState(false)

  return (
    <Page
      pagina={{}}
      hideHead
      prefooterVariant="white"
      containerVariant="none"
      className="myaccount-page__page"
    >
      <div
        className={`myaccount-page${hideSidebar ? ' myaccount-page--no-sidebar' : ''}${
          menuMobileOpen ? ' myaccount-page--menu-mobile-open' : ''
        }${className ? ` ${className}` : ''}`}
      >
        {!hideSidebar && (
          <MyAccountSidebar
            mobileOpen={menuMobileOpen}
            setMobileOpen={setMenuMobileOpen}
            activeMenuKey={activeMenuKey}
          />
        )}
        <div className="myaccount-page__main">
          <WContainer variant={containerVariant}>
            {!hideSidebar && !hideOpenMenuMobile && (
              <Button
                label={t('Torna al profilo')}
                variant="ghost"
                colorVariant="black"
                icon={<ArrowLeft />}
                iconPos="left"
                onClick={() => setMenuMobileOpen(true)}
                className="myaccount-page__open-menu-mobile"
              />
            )}
            <div className="myaccount-page__head">
              {backButton && (
                <Button
                  {...{
                    variant: 'ghost',
                    colorVariant: 'black',
                    icon: <ArrowLeft />,
                    iconPos: 'left',
                    ...backButton,
                    className: `myaccount-page__back${
                      backButton.className ? ` ${backButton.className}` : ''
                    }`,
                  }}
                />
              )}
              {(pagina?.titolo || actions) && (
                <div className="myaccount-page__top">
                  {pagina?.titolo && (
                    <h1 className="myaccount-page__title">
                      {pagina?.icon && (
                        <span className="myaccount-page__title-icon">{pagina?.icon}</span>
                      )}
                      <span>{pagina?.titolo}</span>
                    </h1>
                  )}
                  {!!actions && <div className="myaccount-page__actions">{actions}</div>}
                </div>
              )}
              {pagina?.descrizione && (
                <div
                  className="myaccount-page__description"
                  dangerouslySetInnerHTML={{ __html: pagina?.descrizione }}
                />
              )}
            </div>
            {children}
          </WContainer>
        </div>
      </div>
    </Page>
  )
}

export default MyAccountPage
