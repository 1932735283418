import React, { useEffect } from 'react'
import { useNode, useEditor, Element } from '@craftjs/core'
import { Image } from '../Image'
import { Button as PbButton } from 'react-pagebuilder/pb-components/atoms'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'
import { addNodeTree, deleteNodeTree } from '../../utils/node'

import { ProductCardBlock } from '../ProductCardBlock'
import { Bin, Edit } from 'react-pagebuilder/pb-components/icons'

export const ProductsCarouselSettings = () => {
  const {
    connectors: { hover },
    actions,
    query,
  } = useEditor()

  const {
    id,
    props,
    nodes,
    actions: { setProp },
  } = useNode((node) => ({
    props: node.data.props,
    nodes: node.data.nodes,
  }))

  const addSlide = () => {
    let elements = <ProductCardBlock />
    const nodeTree = query.parseReactElement(elements).toNodeTree()
    addNodeTree(nodeTree, id, actions)
  }

  const selectSlide = (index) => {
    setProp((props) => {
      props.block.current = index
    })
    actions.selectNode(nodes[index])
  }

  const deleteSlide = (index) => {
    if (nodes.length > 1) {
      if (index === 0) {
        setProp((props) => {
          props.block.current = 0
        })
      } else {
        setProp((props) => {
          props.block.current = index - 1
        })
      }

      const slideId = nodes[index]
      deleteNodeTree(slideId, actions)
    }
  }

  const onHover = (index) => {
    actions.history.ignore().setProp(id, (props) => {
      props.block.current = index
    })
  }
  const onLeave = (index) => {
    actions.history.ignore().setProp(id, (props) => {
      props.block.current = null
    })
  }

  return (
    <>
      <ToolbarSection title="Products" initOpen>
        <ToolbarItem type="other">
          <p className="products-carousel-settings__slides-num">
            # Products: <strong>{nodes.length}</strong>
          </p>
          <PbButton onClick={() => addSlide()} label="Add Product" />
          <p className="products-carousel-settings__list__title">Products list</p>
          <div className="products-carousel-settings__slides">
            {Array.from(Array(nodes.length).keys()).map((slide, index) => {
              return (
                <div
                  key={index}
                  className={`products-carousel-settings__slides-item ${
                    index === props.block.current ? 'active' : ''
                  }`}
                  ref={(ref) => hover(ref, nodes[index])}
                  onMouseMove={() => onHover(index)}
                  onMouseLeave={onLeave}
                >
                  <PbButton
                    variant="ghost"
                    label={'Product ' + (index + 1)}
                    onClick={() => selectSlide(index)}
                    icon={<Edit />}
                    iconPos="right"
                  />
                  {nodes.length > 1 && (
                    <PbButton variant="ghost" onClick={() => deleteSlide(index)} icon={<Bin />} />
                  )}
                </div>
              )
            })}
          </div>
        </ToolbarItem>
      </ToolbarSection>
    </>
  )
}
