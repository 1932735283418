import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function BlogInCucina({ className }: Props) {
  return (
    <IconBase name="blog-in-cucina" w={32} className={className}>
      <path d="M21.72 17.16C22.39 15.95 23.45 13.48 23.45 9.78997C23.45 6.07997 21.33 3.55997 20.07 2.39997C19.74 2.09997 19.29 2.01997 18.88 2.18997C18.48 2.36997 18.23 2.74997 18.23 3.18997V17.63V18.13V19.13V19.63V29C18.23 29.56 18.69 30.02 19.25 30.02H21.01C21.57 30.02 22.03 29.56 22.03 29V19.62V19.12V17.62C22.04 17.41 21.91 17.23 21.72 17.16ZM19.29 3.09997C19.33 3.07997 19.37 3.08997 19.4 3.11997C20.54 4.17997 22.46 6.44997 22.46 9.77997C22.46 13.89 21.04 16.41 20.54 17.12H19.25L19.29 3.09997ZM21.04 29C21.04 29.01 21.03 29.02 21.02 29.02L19.24 29V20.12H21.04V29ZM19.24 19.12V18.12H20.53H21.03V19.12H19.24Z" stroke="none"/>
      <path d="M15.7899 3.4701C15.7699 2.8501 15.2599 2.3501 14.6399 2.3501C14.1299 2.3501 13.6999 2.6801 13.5499 3.1201C13.3899 2.6701 12.9699 2.3501 12.4599 2.3501C11.9499 2.3501 11.5199 2.6801 11.3699 3.1201C11.2099 2.6701 10.7899 2.3501 10.2799 2.3501C9.64992 2.3501 9.12992 2.8701 9.12992 3.4401C9.09992 3.6701 8.41992 9.0101 8.41992 10.9501C8.41992 12.7601 10.2599 14.1901 10.9699 14.6701C10.8299 16.7901 10.1799 26.5301 10.3399 27.7301C10.4799 28.7901 11.1199 30.0201 12.4599 30.0201C13.7999 30.0201 14.4399 28.7801 14.5799 27.7301C14.7399 26.5301 14.0899 16.8001 13.9499 14.6701C14.6499 14.1901 16.4999 12.7701 16.4999 10.9501C16.4899 9.0601 15.8499 3.9401 15.7899 3.4701ZM13.1699 13.9801C13.0099 14.0801 12.9099 14.2601 12.9299 14.4401C13.2599 19.2401 13.7099 26.7101 13.5899 27.5901C13.5699 27.7401 13.3699 29.0201 12.4599 29.0201C11.5499 29.0201 11.3499 27.7401 11.3299 27.5901C11.2099 26.7101 11.6599 19.2401 11.9899 14.4401C11.9999 14.2501 11.9099 14.0701 11.7499 13.9801C11.7299 13.9701 9.42992 12.5801 9.42992 10.9501C9.42992 9.0701 10.1199 3.6201 10.1299 3.5001C10.1299 3.4201 10.1999 3.3501 10.2799 3.3501C10.3599 3.3501 10.4299 3.4201 10.4299 3.5401L10.8699 9.8301C10.8899 10.0901 11.1099 10.3001 11.3699 10.3001C11.6299 10.3001 11.8499 10.1001 11.8699 9.8301L12.2999 3.5001C12.2999 3.3301 12.5999 3.3001 12.5999 3.5301L13.0399 9.8201C13.0599 10.0801 13.2799 10.2901 13.5399 10.2901C13.7999 10.2901 14.0199 10.0901 14.0399 9.8201L14.4799 3.4901C14.4799 3.3201 14.7799 3.3201 14.7799 3.4901C14.7799 3.5101 14.7799 3.5301 14.7799 3.5501C14.7899 3.6001 15.4799 9.0601 15.4799 10.9401C15.4899 12.5801 13.1899 13.9701 13.1699 13.9801Z" stroke="none"/>
    </IconBase>
  )
}

export default BlogInCucina
