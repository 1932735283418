import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function FiltriCartaVini({ className }: Props) {
  return (
    <IconBase name="filtri-carta-vini" w={50} className={className}>
      <path d="M29.5311 14.9687C29.2968 14.8124 29.0624 14.6562 28.8905 14.4843C28.2811 13.8749 28.2655 12.9687 28.2655 12.9687V6.8437C28.5468 6.45307 28.7186 5.9062 28.4999 5.37495C28.1561 4.5312 27.078 4.14057 24.9999 4.14057C22.8749 4.14057 21.7811 4.5312 21.453 5.37495C21.2343 5.92182 21.4374 6.4687 21.7343 6.85932V12.9687C21.7343 12.9843 21.7186 13.8749 21.1093 14.4843C20.9374 14.6562 20.703 14.7968 20.4686 14.9687C19.5311 15.6093 18.1093 16.5624 18.1093 19.0937V43.2031C18.1405 43.6406 18.6249 45.8437 25.0155 45.8437C31.3905 45.8437 31.8749 43.6406 31.9218 43.1406V19.0781C31.9061 16.5624 30.4843 15.6093 29.5311 14.9687ZM19.6561 22.2031C20.5624 22.7031 22.1561 23.1406 24.9999 23.1406C27.8436 23.1406 29.4374 22.7031 30.3436 22.2031V31.5312C29.7811 31.9999 28.3905 32.6093 24.9999 32.6093C21.6249 32.6093 20.2343 31.9999 19.6561 31.5312V22.2031ZM21.3436 16.2656C21.6718 16.0468 21.9843 15.8281 22.2186 15.5937C23.2968 14.5156 23.2968 13.0468 23.2968 12.9843V6.56245C23.2968 6.3437 23.203 6.14057 23.0468 5.99995C23.0311 5.98432 23.0155 5.9687 22.9999 5.95307C23.2186 5.85932 23.7499 5.70307 24.9999 5.70307C26.2499 5.70307 26.7811 5.85932 26.9843 5.95307C26.9686 5.9687 26.953 5.98432 26.9374 5.99995C26.7968 6.14057 26.703 6.3437 26.703 6.56245V12.9687C26.703 13.0312 26.7186 14.5156 27.7811 15.5781C28.0155 15.8124 28.328 16.0312 28.6561 16.2499C29.5155 16.8281 30.328 17.3906 30.328 19.0781V21.2656C29.7499 21.7343 28.3593 22.3437 24.9843 22.3437C21.6093 22.3437 20.2186 21.7343 19.6405 21.2656V19.0781C19.6561 17.3906 20.4686 16.8437 21.3436 16.2656ZM24.9999 44.2968C20.4374 44.2968 19.7186 43.1406 19.6718 43.1406V32.4531C20.578 32.9531 22.1718 33.3906 25.0155 33.3906C27.8749 33.3906 29.4686 32.9531 30.3593 32.4531V43.0156C30.3436 43.0156 29.7968 44.2968 24.9999 44.2968Z" stroke="none"/>
    </IconBase>
  )
}

export default FiltriCartaVini
