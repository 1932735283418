import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function PremioGrappolo({ className }: Props) {
  return (
    <IconBase name="premio-grappolo" w={52} className={className}>
      <circle cx="10.5" cy="16.5" r="7.5" stroke="none"/>
      <circle cx="26.5" cy="16.5" r="7.5" stroke="none"/>
      <circle cx="18.5" cy="30.5" r="7.5" stroke="none"/>
      <circle cx="26.5" cy="44.5" r="7.5" stroke="none"/>
      <circle cx="34.5" cy="30.5" r="7.5" stroke="none"/>
      <circle cx="42.5" cy="16.5" r="7.5" stroke="none"/>
      <rect x="11" width="32" height="6" rx="3" stroke="none"/>
    </IconBase>
  )
}

export default PremioGrappolo
