import Script from 'next/script'
import { useState, useEffect, useRef } from 'react'
import { api } from 'utils'
import { AMAZON_PAY_GET_PAYLOAD } from 'utils/endpoint'

interface Props {
  colore?: 'Gold' | 'LightGray' | 'DarkGray'
  type?: 'PayAndShip' | 'PayOnly' | 'SignIn'
  placement?: 'Main' | 'Product' | 'Cart' | 'Checkout' | 'Other'
  setMessaggioRegalo?: any
  messaggioRegalo?: any
}

const AmazonPayButton = (props: Props) => {
  const {
    colore = 'LightGray',
    type = 'PayAndShip',
    placement = 'Cart',
    setMessaggioRegalo = () => {},
    messaggioRegalo = {},
  } = props

  const [payload, setPayload] = useState()
  const [signature, setSignature] = useState()

  const messaggioRegaloRef = useRef(null)

  useEffect(() => {
    api
      .get(`${AMAZON_PAY_GET_PAYLOAD}`)
      .then((res) => {
        const data = res.data
        setPayload(data.payload)
        setSignature(data.signature)
        loadScript(data.payload, data.signature)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const loadScript = (payload, signature) => {
    const element = document.getElementById('amazon-pay-button-script')
    if (element) {
      document.body.removeChild(element)
    }
    const newElement = document.createElement('script')
    newElement.setAttribute('id', 'amazon-pay-button-script')
    newElement.innerHTML = `
            window.amazon.Pay.renderButton('#AmazonPayButton', {
                // set checkout environment
                merchantId: '${process.env.AMAZON_PAY_MERCHANT_ID}',
                publicKeyId: '${process.env.AMAZON_PAY_PUBLIC_KEY_ID}',
                ledgerCurrency: 'EUR',             
                // customize the buyer experience
                checkoutLanguage: 'it_IT',
                productType: '${type}',
                placement: '${placement}',
                buttonColor: '${colore}',
                // configure Create Checkout Session request
                createCheckoutSessionConfig: {                     
                    payloadJSON: '${payload}',
                    signature: '${signature}',
                    publicKeyId: '${process.env.AMAZON_PAY_PUBLIC_KEY_ID}',
                }   
            });
        `
    document.body.appendChild(newElement)
  }

  useEffect(() => {
    messaggioRegaloRef.current = messaggioRegalo
  }, [messaggioRegalo])

  useEffect(() => {
    if (payload && signature) {
      const element = document.getElementById('AmazonPayButton')
      if (element) {
        element.addEventListener('click', async () => {
          await setMessaggioRegalo(
            messaggioRegaloRef.current?.includiMessaggio,
            messaggioRegaloRef.current?.messaggio,
            messaggioRegaloRef.current?.messaggioFirma
          )
        })
      }
    }
  }, [payload, signature])

  return (
    <div className="AmazonPayButton__container">
      <div id="AmazonPayButton"></div>
    </div>
  )
}

export default AmazonPayButton
