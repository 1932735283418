import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Localize({ className }: Props) {
  return (
    <IconBase name="localize" w={24} className={className}>
      <path d="M12.1123 2.06445V6.11218" strokeWidth="2" fill="none"/>
      <path d="M12.1123 18V22.0477" strokeWidth="2" fill="none"/>
      <path d="M6.11182 12.1118H2.06431" strokeWidth="2" fill="none"/>
      <path d="M22.0479 12.1118H18.0003" strokeWidth="2" fill="none"/>
      <circle cx="12" cy="12" r="6" strokeWidth="2" fill="none"/>
      <circle cx="12" cy="12" r="3" stroke="none"/>
    </IconBase>
  )
}

export default Localize
