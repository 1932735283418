import React, { useState } from 'react'
import { useTrans } from 'hooks'
import { privatoToTipo } from 'utils/indirizzo'
import { Edit } from 'components/atoms/Icons'
import {
  MyAccountFormIndirizzoSpedizione,
  MyAccountFormIndirizzoFatturazione,
} from 'components/organisms'

interface Props {
  indirizzo: {
    privato?: string
    nome?: string
    cognome?: string
    indirizzo?: string
    numeroCivico?: string
    citta?: string
    cap?: string
    provincia?: string
    cellulare?: string
    cf?: string
    piva?: string
    codice_destinatario_sdi?: string
    pec?: string
    nazione?: any
  }
  fatturazione?: boolean
  className?: string
}

const MyAccountIndirizzoCard = (props: Props) => {
  const { indirizzo = null, fatturazione = false, className = '' } = props

  const t = useTrans()
  const [edit, setEdit] = useState(false)
  const type = privatoToTipo(indirizzo?.privato)
  const formArgs = {
    indirizzo,
    afterSubmit: () => setEdit(false),
    onCancel: () => setEdit(false),
  }

  return edit ? (
    fatturazione ? (
      <MyAccountFormIndirizzoFatturazione
        titolo={t('Modifica indirizzo di fatturazione')}
        {...formArgs}
      />
    ) : (
      <MyAccountFormIndirizzoSpedizione
        titolo={t('Modifica indirizzo di spedizione')}
        {...formArgs}
      />
    )
  ) : (
    <div className={`myaccount-indirizzo-card ${className ? ` ${className}` : ''}`}>
      <div className="myaccount-indirizzo-card__content">
        <p className="myaccount-indirizzo-card__title">
          {type === '2' ? (
            <>{indirizzo.nome}</>
          ) : (
            <>
              {indirizzo.nome} {indirizzo.cognome}
            </>
          )}
        </p>
        <p className="myaccount-indirizzo-card__description">
          {fatturazione ? (
            <>
              {indirizzo.indirizzo}
              {indirizzo.numeroCivico ? ', ' : ''}
              {indirizzo.numeroCivico}
              <br />
              {indirizzo.cap}
              {indirizzo.citta} {indirizzo.provincia ? <>({indirizzo.provincia})</> : <></>}
              {indirizzo.cf ? (
                <>
                  <br />
                  {indirizzo.cf}
                </>
              ) : (
                <></>
              )}
              {indirizzo.piva ? (
                <>
                  <br />
                  {t('PI')}: {indirizzo.piva}
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              {indirizzo.indirizzo}
              {indirizzo.numeroCivico ? `,${indirizzo.numeroCivico}` : ''} - {indirizzo.cap} -{' '}
              {indirizzo.citta} {indirizzo.provincia ? `(${indirizzo.provincia})` : ''} - {' '}
              {indirizzo.nazione.isoCode.toUpperCase()}
            </>
          )}
        </p>
      </div>
      <button
        type="button"
        className="myaccount-indirizzo-card__edit"
        onClick={() => setEdit(true)}
      >
        <Edit />
      </button>
    </div>
  )
}

export default MyAccountIndirizzoCard
