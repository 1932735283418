import React from 'react'

export interface TriggerProps {
  icon?: any
  label?: string
  active?: boolean
  onClick?: () => void
}

export interface Props {
  active?: boolean
  className?: string
  children?: any
}

const TabTrigger = (props: TriggerProps) => {
  const { icon = null, label = '', active = false, onClick = () => {} } = props

  return (
    <button
      type="button"
      className={`tab-trigger${active ? ` tab-trigger--active` : ''}`}
      onClick={onClick}
    >
      {icon && <span className="tab-trigger__icon">{icon}</span>}
      {label && <span className="tab-trigger__label">{label}</span>}
    </button>
  )
}

const Tab = (props: Props) => {
  const { active = false, className = '', children = null } = props

  return active ? (
    <div className={`tab${active ? ` tab--active` : ''}${className ? ` ${className}` : ''}`}>
      {children}
    </div>
  ) : (
    <></>
  )
}

Tab.Trigger = TabTrigger

export default Tab
