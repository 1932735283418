import React, { useState } from 'react'
import { Close } from 'react-pagebuilder/pb-components/icons'

const NotificationBar = ({ children, type = 'alert' }) => {
  const [closing, setClosing] = useState(false)
  const [close, setClose] = useState(false)

  const closeMe = () => {
    setClosing(true)
    setTimeout(() => {
      setClose(true)
      setClosing(false)
    }, 500)
  }

  return !close ? (
    <div
      className={`pb-notification-bar pb-notification-bar--${type}${
        closing ? ' pb-notification-bar--closing' : ''
      }`}
    >
      <button
        type="button"
        aria-label="Close"
        className="pb-notification-bar__close"
        onClick={closeMe}
      >
        <Close />
      </button>
      {children}
    </div>
  ) : (
    <></>
  )
}

export default NotificationBar
