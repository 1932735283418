import Link from 'next/link'
import React from 'react'
import { Loader } from 'react-pagebuilder/pb-components/icons'

const Button = ({
  label = '',
  type = 'button',
  variant = 'primary',
  size = 'md',
  disabled = false,
  icon = null,
  iconPos = 'left',
  onClick = () => {},
  href = null,
  newPage = false,
  className = '',
  loading = false,
  external = false,
}) => {
  const classeNames = `
    pb-button 
    pb-button--${variant} 
    pb-button--${size}
    ${!label ? ' pb-button--icon' : ''}
    ${icon && iconPos === 'right' ? ' pb-button--icon-right' : ''}
    ${loading ? ' pb-button--loading' : ''}
    ${className ? ` ${className}` : ''}`

  const buttonContent = (
    <>
      {icon && <span className="pb-button__icon">{icon}</span>}
      {label && <span className="pb-button__label">{label}</span>}
      {loading && (
        <span className="pb-button__loader">
          <Loader />
        </span>
      )}
    </>
  )

  return href ? (
    external ? (
      <a className={classeNames} href={href} target={newPage ? '_blank' : null} rel="noreferrer">
        {buttonContent}
      </a>
    ) : (
      <Link legacyBehavior href={href}>
        <a className={classeNames} rel="noreferrer">
          {buttonContent}
        </a>
      </Link>
    )
  ) : (
    <button type={type} className={classeNames} disabled={disabled} onClick={onClick}>
      {buttonContent}
    </button>
  )
}

export default Button
