import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function WineBollicine({ className }: Props) {
  return (
    <IconBase name="wine-bollicine" w={40} className={className}>
      <circle cx="14.5" cy="16.5" r="4" fill="none"/>
      <circle cx="27" cy="19" r="5.5" fill="none"/>
      <circle cx="20" cy="30" r="5.5" fill="none"/>
      <circle cx="31" cy="29" r="2.5" fill="none"/>
      <circle cx="23" cy="8" r="2.5" fill="none"/>
      <circle cx="9" cy="25" r="2.5" fill="none"/>
    </IconBase>
  )
}

export default WineBollicine
