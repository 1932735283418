import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function WineBicchiere({ className }: Props) {
  return (
    <IconBase name="wine-bicchiere" w={40} className={className}>
      <g clipPath="url(#bicchiere-clip0)">
      <path d="M28.8758 40H11.1838C10.8049 40 10.4948 39.69 10.4948 39.3111C10.4948 38.9321 10.8049 38.6221 11.1838 38.6221C15.8341 38.6221 18.8104 34.1164 19.3409 33.2415V22.9625C18.1903 22.5215 15.2141 21.0885 11.2044 17.072C5.87892 11.7534 11.8383 0.819842 12.1001 0.35825C12.2172 0.137788 12.4514 0 12.6995 0H27.3601C27.6082 0 27.8424 0.137788 27.9664 0.35825C28.2213 0.819842 34.1807 11.7534 28.862 17.0789C24.8455 21.0954 21.8693 22.5284 20.7256 22.9693V33.2759C21.139 34.1164 23.6123 38.6221 28.8827 38.6221C29.2616 38.6221 29.5716 38.9321 29.5716 39.3111C29.5716 39.69 29.2616 40 28.8758 40ZM15.9513 38.6221H23.8259C21.8899 37.4647 20.643 35.8319 19.9816 34.7709C19.2444 35.8181 17.8803 37.4578 15.9513 38.6221ZM13.1197 1.37788C12.169 3.24492 8.06976 11.9945 12.1759 16.1006C16.3233 20.248 19.2926 21.4881 20.0298 21.7499C20.7601 21.4881 23.7294 20.2549 27.8837 16.1006C31.9898 11.9945 27.8975 3.24492 26.9468 1.37788H13.1197Z" stroke="none"/>
      </g>
      <defs>
      <clipPath id="bicchiere-clip0">
      <rect width="22.0599" height="40" transform="translate(9)" stroke="none"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default WineBicchiere
