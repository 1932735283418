import React from 'react'
import { getIconChiSiamoMenuByChiave } from 'utils/icon'
import {useTrans} from "../../../hooks";

interface Props {
  className?: string
    items?: {
    pk: number
    name: string
    key: string
    description: string
  }[]
}

const ChiSiamoMenu = (props: Props) => {
  const { 
    className = '',
     items = [],
  } = props

  const t = useTrans()

  // FAKE 
  // const menu = {
  //   nome: 'Benvenuto nella più grande cantina d\'Italia',
  //   children: [
  //     { pk: 1, chiave: 'produttori', nome: 'Produttori & made in Italy', description: 'Non siamo distributori, ma produttori + rif. al territorio' },
  //     { pk: 2, chiave: 'tracciabilita', nome: 'Tracciabilità', description: 'Dalle nostre cantine direttamente a casa tua' },
  //     { pk: 3, chiave: 'servizio', nome: 'Servizio', description: 'non mera vendita online, ma consulenza e supporto a tuttotondo' },
  //   ]
  // }
  //
    const menu = {
    nome: t('Benvenuto nella più grande cantina d\'Italia'),
    children: items
  }


  return menu ? (
    <div className={`chi-siamo-menu ${className ? ` ${className}` : ''}`}>
      {menu.nome && <p className="chi-siamo-menu__title">{menu.nome}</p>}
      <div className="chi-siamo-menu__list">
        {menu.children.map((item) => (
          <div className="chi-siamo-menu__item" key={`chi-siamo-menu__item__${item.pk}`}>
            <div className="chi-siamo-menu__item__icon">{getIconChiSiamoMenuByChiave(item.key)}</div>
            <p className="chi-siamo-menu__item__title">{item.name}</p>
            <p className="chi-siamo-menu__item__description">{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  ): <></>
}

export default ChiSiamoMenu
