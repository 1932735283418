import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function FaqConsegna({ className }: Props) {
  return (
    <IconBase name="faq-consegna" w={32} className={className}>
      <path d="M28.8197 15.78L25.6297 11.05C25.5397 10.91 25.3797 10.83 25.2197 10.83H20.6597V9.02002C20.6597 8.74002 20.4397 8.52002 20.1597 8.52002H6.73975C6.45975 8.52002 6.23975 8.74002 6.23975 9.02002C6.23975 9.30002 6.45975 9.52002 6.73975 9.52002H19.6597V11.33V20.29H13.8997C13.6597 19.04 12.5697 18.09 11.2497 18.09C9.92975 18.09 8.83975 19.04 8.59975 20.29H6.73975C6.45975 20.29 6.23975 20.51 6.23975 20.79C6.23975 21.07 6.45975 21.29 6.73975 21.29H8.59975C8.83975 22.54 9.92975 23.49 11.2497 23.49C12.5697 23.49 13.6597 22.54 13.8997 21.29H20.1597H21.6297C21.8697 22.54 22.9597 23.49 24.2797 23.49C25.5997 23.49 26.6897 22.54 26.9297 21.29H28.4097C28.6897 21.29 28.9097 21.07 28.9097 20.79V16.06C28.9097 15.96 28.8797 15.86 28.8197 15.78ZM27.6297 15.8H23.7397V11.82H24.9497L27.6297 15.8ZM11.2497 22.48C10.3097 22.48 9.54975 21.72 9.54975 20.78C9.54975 19.84 10.3097 19.08 11.2497 19.08C12.1897 19.08 12.9497 19.84 12.9497 20.78C12.9497 21.72 12.1897 22.48 11.2497 22.48ZM24.2797 22.48C23.3397 22.48 22.5797 21.72 22.5797 20.78C22.5797 19.84 23.3397 19.08 24.2797 19.08C25.2197 19.08 25.9797 19.84 25.9797 20.78C25.9797 21.72 25.2197 22.48 24.2797 22.48ZM26.9297 20.28C26.6897 19.03 25.5997 18.08 24.2797 18.08C22.9597 18.08 21.8697 19.03 21.6297 20.28H20.6597V11.82H23.2397V16.05C23.2397 16.19 23.3497 16.3 23.4897 16.3H27.8997V20.28H26.9297Z" stroke="none"/>
      <path d="M4.84961 12.25H11.2896C11.4296 12.25 11.5396 12.14 11.5396 12C11.5396 11.86 11.4296 11.75 11.2896 11.75H4.84961C4.70961 11.75 4.59961 11.86 4.59961 12C4.59961 12.14 4.70961 12.25 4.84961 12.25Z" stroke="none"/>
      <path d="M5.25 14.3398C5.12 14.3398 5 14.4598 5 14.5898C5 14.7198 5.11 14.8398 5.25 14.8398H8.6C8.74 14.8398 8.85 14.7298 8.85 14.5898C8.85 14.4498 8.74 14.3398 8.6 14.3398H5.25Z" stroke="none"/>
      <path d="M9.93984 17.1899C9.93984 17.0499 9.82984 16.9399 9.68984 16.9399H3.33984C3.19984 16.9399 3.08984 17.0499 3.08984 17.1899C3.08984 17.3299 3.19984 17.4399 3.33984 17.4399H9.68984C9.82984 17.4399 9.93984 17.3299 9.93984 17.1899Z" stroke="none"/>
    </IconBase>
  )
}

export default FaqConsegna
