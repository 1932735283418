import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function CaratteristicheFormato({ className }: Props) {
  return (
    <IconBase name="caratteristiche-formato" w={32} className={className}>
      <path d="M26.5701 10.32C26.4401 10.23 26.3101 10.15 26.2201 10.06C25.9001 9.73998 25.8901 9.25998 25.8901 9.25998V5.82998C26.0501 5.59998 26.1401 5.27998 26.0101 4.95998C25.7401 4.30998 24.7901 4.22998 23.9901 4.22998C23.1701 4.22998 22.1901 4.30998 21.9401 4.96998C21.8201 5.28998 21.9201 5.60998 22.0901 5.83998V9.23998C22.0901 9.23998 22.0801 9.72998 21.7601 10.05C21.6701 10.14 21.5401 10.22 21.4101 10.31C20.8701 10.67 20.0601 11.23 20.0601 12.68V26.22C20.0901 26.58 20.4501 27.76 23.9901 27.76C27.5301 27.76 27.9001 26.58 27.9201 26.18V12.68C27.9201 11.24 27.1101 10.68 26.5701 10.32ZM21.0501 14.5C21.5701 14.76 22.4701 14.99 23.9801 14.99C25.4901 14.99 26.3901 14.77 26.9101 14.5V19.68C26.5701 19.93 25.7901 20.24 23.9801 20.24C22.1701 20.24 21.3801 19.93 21.0501 19.68V14.5ZM21.9701 11.15C22.1601 11.02 22.3301 10.9 22.4701 10.76C23.0801 10.15 23.1001 9.33998 23.1001 9.24998V5.65998C23.1001 5.55998 23.0501 5.43998 22.9801 5.33998C23.1301 5.28998 23.4301 5.23998 23.9901 5.23998C24.5701 5.23998 24.8601 5.29998 25.0001 5.34998C24.9301 5.43998 24.8901 5.54998 24.8901 5.66998V9.25998C24.8901 9.34998 24.9101 10.16 25.5201 10.77C25.6601 10.91 25.8301 11.03 26.0201 11.16C26.5101 11.49 26.9301 11.78 26.9301 12.7V13.94C26.5901 14.19 25.8101 14.5 24.0001 14.5C22.1901 14.5 21.4001 14.19 21.0701 13.94V12.7C21.0501 11.77 21.4801 11.48 21.9701 11.15ZM23.9901 26.77C21.6401 26.77 21.1301 26.22 21.0601 26.11V20.26C21.5801 20.52 22.4801 20.75 23.9901 20.75C25.5001 20.75 26.4001 20.53 26.9201 20.26V26.11C26.8501 26.22 26.3401 26.77 23.9901 26.77Z" stroke="none"/>
      <path d="M17.93 12.1499C17.81 12.0699 17.69 11.9899 17.6 11.8999C17.31 11.5999 17.29 11.1599 17.29 11.1599V7.92988C17.44 7.69988 17.53 7.39988 17.41 7.08988C17.15 6.45988 16.24 6.37988 15.47 6.37988C14.68 6.37988 13.75 6.45988 13.5 7.08988C13.38 7.39988 13.48 7.70988 13.64 7.92988V11.1399C13.64 11.1399 13.63 11.5899 13.33 11.8899C13.24 11.9799 13.12 12.0499 13 12.1399C12.49 12.4899 11.71 13.0199 11.71 14.3999V26.2699C11.74 26.6199 12.08 27.7499 15.46 27.7499C18.84 27.7499 19.18 26.6099 19.21 26.2299V14.4099C19.23 13.0199 18.45 12.4899 17.93 12.1499ZM12.72 16.1399C13.22 16.3799 14.06 16.5799 15.47 16.5799C16.88 16.5799 17.72 16.3799 18.22 16.1399V20.9899C17.89 21.2199 17.15 21.5099 15.47 21.5099C13.79 21.5099 13.04 21.2199 12.72 20.9899V16.1399ZM13.57 12.9699C13.75 12.8499 13.92 12.7299 14.04 12.5999C14.62 12.0199 14.64 11.2399 14.64 11.1499V7.74988C14.64 7.65988 14.6 7.54988 14.54 7.45988C14.69 7.41988 14.97 7.37988 15.46 7.37988C15.96 7.37988 16.23 7.42988 16.37 7.46988C16.31 7.54988 16.28 7.64988 16.28 7.74988V11.1499C16.28 11.2399 16.3 12.0199 16.88 12.5999C17.01 12.7299 17.17 12.8499 17.35 12.9699C17.81 13.2799 18.2 13.5499 18.2 14.3999V15.5499C17.87 15.7799 17.13 16.0699 15.45 16.0699C13.77 16.0699 13.02 15.7799 12.7 15.5499V14.3999C12.72 13.5499 13.12 13.2799 13.57 12.9699ZM15.47 26.7699C13.29 26.7699 12.8 26.2699 12.72 26.1599V21.5699C13.22 21.8099 14.06 22.0099 15.47 22.0099C16.88 22.0099 17.72 21.8099 18.22 21.5699V26.1599C18.15 26.2699 17.66 26.7699 15.47 26.7699Z" stroke="none"/>
      <path d="M9.66008 14.7799C9.55008 14.7099 9.45008 14.6399 9.38008 14.5699C9.14008 14.3299 9.13008 13.9499 9.13008 13.9499V11.1099C9.27008 10.8999 9.34008 10.6099 9.23008 10.3299C8.98008 9.73992 8.15008 9.66992 7.45008 9.66992C6.74008 9.66992 5.89008 9.74992 5.66008 10.3399C5.55008 10.6299 5.64008 10.9099 5.78008 11.1299V13.9499C5.78008 13.9499 5.77008 14.3299 5.53008 14.5699C5.45008 14.6499 5.35008 14.7099 5.25008 14.7799C4.78008 15.0999 4.08008 15.5699 4.08008 16.8299V26.3999C4.10008 26.7199 4.42008 27.7699 7.46008 27.7699C10.5001 27.7699 10.8101 26.7199 10.8401 26.3699V16.8299C10.8301 15.5699 10.1301 15.0899 9.66008 14.7799ZM5.08008 18.1499C5.54008 18.3499 6.27008 18.5099 7.46008 18.5099C8.65008 18.5099 9.38008 18.3499 9.84008 18.1499V22.8199C9.51008 23.0199 8.82008 23.2499 7.46008 23.2499C6.10008 23.2499 5.41008 23.0299 5.08008 22.8199V18.1499ZM5.81008 15.6099C5.97008 15.4999 6.12008 15.3899 6.24008 15.2799C6.77008 14.7499 6.79008 14.0399 6.79008 13.9599V10.9499C6.79008 10.8799 6.77008 10.7999 6.74008 10.7299C6.88008 10.6999 7.10008 10.6799 7.46008 10.6799C7.82008 10.6799 8.05008 10.7099 8.18008 10.7399C8.15008 10.8099 8.13008 10.8799 8.13008 10.9499V13.9599C8.13008 14.0399 8.15008 14.7499 8.68008 15.2799C8.80008 15.3999 8.95008 15.4999 9.11008 15.6099C9.52008 15.8899 9.84008 16.0999 9.84008 16.8299V17.5899C9.51008 17.7899 8.82008 18.0199 7.46008 18.0199C6.10008 18.0199 5.41008 17.7999 5.08008 17.5899V16.8299C5.08008 16.0999 5.40008 15.8799 5.81008 15.6099ZM7.45008 26.7699C5.62008 26.7699 5.16008 26.3699 5.07008 26.2699V23.3899C5.53008 23.5899 6.26008 23.7499 7.45008 23.7499C8.64008 23.7499 9.37008 23.5899 9.83008 23.3899V26.2699C9.75008 26.3699 9.28008 26.7699 7.45008 26.7699Z" stroke="none"/>
    </IconBase>
  )
}

export default CaratteristicheFormato
