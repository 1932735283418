import React, { useState, useContext, useEffect } from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { useTrans } from 'hooks'
import { getFormFieldStatus } from 'utils/safe'
import { Info, Promo } from 'components/atoms/Icons'
import { Button, FormField, FormInput } from 'components/atoms'
import { CartContext } from 'utils/context'
import { trackingPromoCodeGAevent } from 'utils/gtm'
import { useSelector } from 'react-redux'
import Link from 'next/link'

interface Props {
  className?: string
  open?: boolean
  setOpen?: any
}

const PromoCodeForm = (props: Props) => {
  const { className = '', open = false, setOpen = '' } = props

  const { cart, setPromoCode, removePromoCode } = useContext(CartContext)

  const t = useTrans()
  //const [open, setOpen] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [nonApplicabileError, setNonApplicabileError] = useState(false)
  const [errorNonLoggato, setErrorNonLoggato] = useState(false)

  const { pagine } = useSelector((state) => state.pagine)
  const login = pagine?.edges.find((item) => item.node.chiave === 'login')?.node

  const validationSchema = yup.object().shape({
    codice: yup.string().required(t('Campo obbligatorio')),
  })

  const initialValues = {
    codice: cart.promoCode || '',
  }

  useEffect(() => {
    if (cart && cart.promoCode) {
      if (
        cart.promoApplicata?.conCodice ||
        cart.sistemaSpedizione?.promoApplicata?.conCodice ||
        cart.items
          .filter((item) => !item.omaggio)
          .find((item) => item.prodotto.prodotto.promoApplicata?.conCodice)
      ) {
        setOpen(true)
        setSuccess(true)
        setError(false)
        setNonApplicabileError(false)
      } else {
        setOpen(true)
        setSuccess(false)
        setError(false)
        setNonApplicabileError(true)
      }
    }
  }, [cart])

  const removePromo = async (codice) => {
    const status = await removePromoCode(codice)
    if (status) {
      setSuccess(false)
      setError(false)
      setNonApplicabileError(false)
    }
  }

  return (
    <div
      className={`promo-code-form${open ? ' promo-code-form--open' : ''}${
        className ? ` ${className}` : ''
      }`}
    >
      <button type="button" onClick={() => setOpen(!open)} className="promo-code-form__head">
        <span className="promo-code-form__icon">
          <Promo />
        </span>
        <span className="promo-code-form__label">{t('Aggiungi un codice sconto')}</span>
      </button>
      <div className="promo-code-form__body">
        <div className="promo-code-form__body-content">
          <Formik
            validateOnBlur
            validateOnChange
            validationSchema={validationSchema}
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={async (values) => {
              const { status, message } = await setPromoCode(values.codice)
              if (!status) {
                setSuccess(false)
                setError(true)
                setNonApplicabileError(false)
                setErrorNonLoggato(false)
                if (message == 'not_logged') {
                  setErrorNonLoggato(true)
                  setSuccess(false)
                  setError(false)
                  setNonApplicabileError(false)
                }
              }
              if (status) trackingPromoCodeGAevent(7, 'coupon', 'use', values.codice.toUpperCase())
            }}
          >
            {({ values, touched, errors, handleBlur, handleChange, handleSubmit }) => (
              <div className="promo-code-form__form">
                <FormField
                  status={getFormFieldStatus({ touched, errors }, 'codice')}
                  errorMessage={errors?.codice}
                  className=""
                >
                  <FormInput
                    value={values?.codice}
                    onBlur={handleBlur('codice')}
                    onChange={handleChange('codice')}
                    disabled={cart.promoCode}
                    placeholder={t('Inserisci codice')}
                  />
                </FormField>
                {cart.promoCode ? (
                  <Button
                    type="button"
                    label={t('Rimuovi')}
                    variant="ghost"
                    colorVariant="black"
                    onClick={() => removePromo(values?.codice)}
                  />
                ) : (
                  <Button
                    type="submit"
                    label={t('Utilizza')}
                    disabled={!values?.codice?.length}
                    onClick={handleSubmit}
                  />
                )}
              </div>
            )}
          </Formik>
          {success && (
            <div className="promo-code-form__success">
              <Info />
              <span>{t('Il codice è stato applicato al tuo ordine')}</span>
            </div>
          )}
          {errorNonLoggato && (
            <div className="promo-code-form__error">
              <Info />
              <span>
                {t('Il codice è valido solo per utenti registrati.')}{' '}
                <Link legacyBehavior href={login.url}>
                  <a target="_blank">
                    <b>{t('Accedi')}</b>
                  </a>
                </Link>
              </span>
            </div>
          )}
          {error && (
            <div className="promo-code-form__error">
              <Info />
              <span>{t('Il codice inserito non è valido')}</span>
            </div>
          )}
          {nonApplicabileError && (
            <div className="promo-code-form__error">
              <Info />
              <span>{t('Promozione non valida per i prodotti inseriti')}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PromoCodeForm
