import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function SpiritsAmari({ className }: Props) {
  return (
    <IconBase name="spirits-amari" w={32} className={className}>
      <path d="M24.2802 7.70021C24.2802 7.66021 24.2602 7.62022 24.2602 7.57022C24.2602 7.56022 24.2602 7.54022 24.2502 7.53022C23.9502 5.71022 18.7802 5.22021 16.0002 5.22021C13.2202 5.22021 8.05022 5.71022 7.75022 7.53022C7.75022 7.54022 7.75021 7.56022 7.74021 7.57022C7.73021 7.62022 7.72021 7.66021 7.72021 7.70021C7.72021 7.71021 7.72021 7.71021 7.72021 7.72021C7.72021 7.73021 7.72021 7.73021 7.72021 7.74021L8.83021 23.7502C8.83021 25.4302 12.9302 26.7902 16.3202 26.7902C19.9702 26.7902 23.1602 25.3702 23.1602 23.7902L24.2702 7.75022C24.2702 7.74022 24.2702 7.74022 24.2702 7.73022C24.2802 7.71022 24.2802 7.71021 24.2802 7.70021ZM20.9902 19.2102C20.2602 18.3502 18.3702 17.7602 16.0002 17.7602C13.6302 17.7602 11.7402 18.3502 11.0102 19.2102L8.83021 9.32021L8.81022 9.02022C10.5402 9.91022 13.9302 10.1802 16.0002 10.1802C18.0702 10.1802 21.4602 9.91022 23.1902 9.02022L23.1702 9.33021L20.9902 19.2102ZM20.8202 19.9902C20.8202 20.8102 18.8402 21.7202 16.0002 21.7202C13.1602 21.7202 11.1802 20.8102 11.1802 19.9902C11.1802 19.1702 13.1602 18.2602 16.0002 18.2602C18.8402 18.2602 20.8202 19.1702 20.8202 19.9902ZM16.0002 6.22021C20.7202 6.22021 23.2702 7.26021 23.2802 7.70021V7.71022C23.2602 8.14022 20.7102 9.18022 16.0002 9.18022C11.2902 9.18022 8.74022 8.14022 8.72022 7.71022V7.70021C8.73022 7.27021 11.2802 6.22021 16.0002 6.22021ZM22.1702 23.7402C22.1702 24.3602 19.9602 25.7802 16.3302 25.7802C12.4602 25.7802 9.84022 24.2502 9.84022 23.7102L9.08021 12.7102L10.7002 20.0402C10.7002 20.0502 10.7102 20.0502 10.7102 20.0602C10.7902 21.2902 13.0402 22.2202 16.0102 22.2202C18.9802 22.2202 21.2302 21.3002 21.3102 20.0602C21.3102 20.0502 21.3202 20.0502 21.3202 20.0402L22.9402 12.7002L22.1702 23.7402Z" stroke="none"/>
    </IconBase>
  )
}

export default SpiritsAmari
