/* eslint-disable import/no-unresolved */
/* eslint-disable react-hooks/exhaustive-deps */
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMeQuery } from 'gql/graphql'
import * as Sentry from "@sentry/browser"

export default function useUtente() {
  const router = useRouter()
  const { data, loading, refetch } = useMeQuery()
  const {utente, csrfToken} = useSelector((state: any) => state?.utente)
  const dispatch = useDispatch()

  useEffect(() => {
    if (data) {
      dispatch({ type: 'SET_CURRENT_USER', payload: { data } })
      if(data?.me?.email) {
        Sentry.setUser({ email: data.me.email })
      }
    }
  }, [data])

  useEffect(() => {
    refetch()
  }, [router.asPath])

  useEffect(() => {
    if (csrfToken)
      document.cookie = `csrftoken=${csrfToken}; expires=${new Date(
        Date.now() + 86400 * 1000 * 364
      ).toUTCString()}; path=/; secure`
  }, [csrfToken])

  const removeCurrentUser = () => {
    dispatch({ type: 'REMOVE_CURRENT_USER' })
    Sentry.configureScope(scope => scope.setUser(null))
  }

  return {
    utente: utente || (data?.me && { ...data?.me }),
    loading,
    refetchUtente: refetch,
    removeCurrentUser,
  }
}
