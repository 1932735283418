import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function HeartFilled({ className }: Props) {
  return (
    <IconBase name="heart-filled" w={20} h={18} className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M14.5 0.5C15.9 0.5 17.1511 1.04819 18.0514 1.94855C18.9518 2.84892 19.5 4.1 19.5 5.5C19.5 9.17741 16.1043 12.1345 11.1261 16.6488L9.99873 17.675L8.88626 16.67C3.88459 12.1248 0.5 9.1713 0.5 5.5C0.5 4.1 1.04819 2.84892 1.94855 1.94855C2.84892 1.04819 4.1 0.5 5.5 0.5C7.09309 0.5 8.62153 1.24245 9.61932 2.41417C9.75592 2.57458 9.883 2.74353 10.0001 2.9197C10.117 2.74353 10.2441 2.57458 10.3807 2.41417C11.3785 1.24245 12.9069 0.5 14.5 0.5Z" stroke="none" fill="#CD1A4A"/>
    </IconBase>
  )
}

export default HeartFilled
