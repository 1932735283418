import React from 'react'
import { getImageUrl } from 'utils/safe'

interface Props {
  imageUrl: string
  className: string
  notification?: boolean
  notificationValue?: number
}

const Avatar = (props: Props) => {
  const { imageUrl = '', className = '', notification = false, notificationValue = 0 } = props

  return (
    <div className={`avatar ${className ? ` ${className}` : ''}`}>
      <img src={imageUrl || getImageUrl('avatar.png')} className="avatar__image" alt="" title="" />
      {notification && (
        <span
          className={`avatar__notification${
            notificationValue > 0 ? '' : ` avatar__notification--empty`
          }`}
        >
          {notificationValue > 0 ? notificationValue : ''}
        </span>
      )}
    </div>
  )
}

export default Avatar
