import { useNode, Element, useEditor } from '@craftjs/core'
import React, { useState, useEffect } from 'react'
import { ImageSettings } from './ImageSettings'

import {
  deepValue,
  getAllPaths,
  getDynamicValue,
  setDynamicValue,
  isDynamicValue,
} from '../../utils/dynamic'

import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'

import { WImage } from 'components/atoms'

export const defaultProps = {
  // src: "https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg",
  src: 'https://dummyimage.com/300x200/737173/ffffff.png',
  alt: 'Image',
  align: 'left',
}

export const dynamicProps = ['src']

export const Image = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }
    const { src, alt, align } = blockProps

    const _ = props.renderValue

    const {
      id,
      connectors: { connect, drag },
      actions: { setProp, setCustom },
      selected,
      hovered,
      customs,
    } = useNode((state) => ({
      selected: state.events.selected,
      dragged: state.events.dragged,
      hovered: state.events.hovered,
      customs: state.data.custom,
    }))

    return (
      <div // img non supporta :after
        ref={(ref) => props.retRef(ref)}
        className={'block-image ' + props.className}
        style={{
          position: 'relative',
          textAlign: align,
        }}
      >
        <WImage src={_(src)} title={_(src)} alt={alt} />
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

Image.craft = withCraft({
  name: 'Image',
  defaultProps: defaultProps,
  settings: ImageSettings,
})
