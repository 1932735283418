import React from 'react'
import { useTrans } from 'hooks'
import { Edit } from 'components/atoms/Icons'
import { Button } from 'components/atoms'

interface Props {
  icon?: any
  editLabel?: string
  editIcon?: any
  fields: {
    label: string
    value: any
  }[]
  fieldsMobile?:
    | {
        label: string
        value: any
      }[]
    | null
  className?: string
  onEdit: () => void
  disabled?: boolean
}

const MyAccountEditCard = (props: Props) => {
  const {
    icon = null,
    editLabel = '',
    editIcon = null,
    fields = [],
    fieldsMobile = null,
    className = '',
    onEdit = null,
    disabled = false,
  } = props

  const t = useTrans()

  return (
    <div className={`myaccount-edit-card ${className ? ` ${className}` : ''}`}>
      {icon && <span className="myaccount-edit-card__icon">{icon}</span>}
      <div
        className={`myaccount-edit-card__fields${
          fieldsMobile ? ' myaccount-edit-card__fields--desktop' : ''
        }`}
      >
        {fields.map((field) => (
          <div className="myaccount-edit-card__field">
            {field.label && <div className="myaccount-edit-card__label">{field.label}</div>}
            <div className="myaccount-edit-card__value">{field.value}</div>
          </div>
        ))}
      </div>
      {fieldsMobile && (
        <div className="myaccount-edit-card__fields myaccount-edit-card__fields--mobile">
          {fieldsMobile.map((field) => (
            <div className="myaccount-edit-card__field">
              {field.label && <div className="myaccount-edit-card__label">{field.label}</div>}
              <div className="myaccount-edit-card__value">{field.value}</div>
            </div>
          ))}
        </div>
      )}
      {!disabled && (
        <Button
          label={editLabel}
          labelMobile={t('Modifica')}
          variant="ghost"
          size="sm"
          icon={editIcon}
          iconPos="left"
          hideIconMobile
          className="myaccount-edit-card__button"
          onClick={onEdit}
        />
      )}
    </div>
  )
}

export default MyAccountEditCard
