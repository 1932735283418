import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function ChiSiamoServizio({ className }: Props) {
  return (
    <IconBase name="chi-siamo-servizio" w={300} h={227} className={className}>
      <g clip-path="url(#chisiamoservizio-clip0)">
        <path opacity="0.18" d="M74.966 24.5715C65.7321 35.9446 59.9817 50.0849 52.0235 62.5081C46.1358 71.791 38.9528 80.1026 32.9669 89.3463C21.0344 107.755 13.8318 130.197 15.1467 152.62C16.4616 175.042 26.9222 197.18 44.3596 209.456C55.0655 217.002 67.7339 220.584 80.3337 222.909C122.755 230.76 168.414 224.715 204.663 199.682C210.884 195.384 216.841 190.537 223.592 187.279C240.931 178.899 263.422 180.842 275.993 165.455C283.558 156.192 285.227 142.944 284.971 130.59C284.667 115.753 281.763 100.17 272.725 89.0421C260.949 74.4503 241.716 70.5448 225.8 61.566C210.286 52.862 197.774 39.173 184.448 26.9462C152.39 -2.48266 106.269 -13.9834 74.966 24.5715Z" fill="#861E32"/>
        <path d="M228.773 146.359C228.775 147.267 228.59 148.166 228.231 149C227.871 149.834 227.344 150.585 226.683 151.207C225.55 152.283 224.088 152.947 222.532 153.091C222.241 153.115 221.949 153.115 221.659 153.091L172.918 152.109C171.049 152.089 169.262 151.341 167.937 150.023C166.612 148.705 165.854 146.922 165.823 145.054C165.82 144.146 166.002 143.247 166.358 142.412C166.714 141.577 167.236 140.823 167.894 140.197C169.247 138.91 171.051 138.206 172.918 138.234H174.576C174.233 137.031 174.058 135.786 174.056 134.535C174.054 132.746 174.416 130.976 175.121 129.333C175.827 127.689 176.86 126.207 178.158 124.977C179.304 123.881 180.632 122.995 182.083 122.357C183.963 121.537 185.999 121.136 188.049 121.179L192.191 121.268C194.767 121.32 197.281 122.064 199.472 123.42C201.662 124.777 203.448 126.698 204.643 128.981C205.837 128.413 207.148 128.134 208.47 128.166H209.54C211.758 128.189 213.88 129.076 215.454 130.639C217.029 132.203 217.931 134.318 217.969 136.536V136.939C217.968 137.703 217.856 138.464 217.635 139.196L221.668 139.274C223.541 139.3 225.331 140.053 226.657 141.376C227.984 142.699 228.742 144.486 228.773 146.359Z" fill="white"/>
        <path d="M222.532 153.091C222.241 153.115 221.949 153.115 221.659 153.091L172.918 152.109C171.049 152.089 169.262 151.341 167.937 150.023C166.612 148.705 165.854 146.922 165.823 145.054C165.82 144.146 166.002 143.247 166.358 142.412C166.714 141.577 167.236 140.823 167.894 140.197C165.931 152.237 178.541 150.51 178.541 150.51H210.207L222.532 153.091Z" fill="url(#chisiamoservizio-paint0_linear)"/>
        <path d="M178.148 139.686L174.606 138.342C174.263 137.139 174.088 135.894 174.086 134.643C174.083 132.854 174.446 131.084 175.151 129.441C175.856 127.797 176.89 126.315 178.188 125.085C179.333 123.989 180.662 123.103 182.113 122.465C172.31 130.865 178.148 139.686 178.148 139.686Z" fill="url(#chisiamoservizio-paint1_linear)"/>
        <path opacity="0.23" d="M119.016 214.873C153.452 214.873 181.367 189.659 181.367 158.557C181.367 127.454 153.452 102.24 119.016 102.24C84.5806 102.24 56.665 127.454 56.665 158.557C56.665 189.659 84.5806 214.873 119.016 214.873Z" fill="#861E32"/>
        <path d="M196.43 149.224C196.446 162.299 192.191 175.021 184.311 185.454L184.183 185.63C175.549 197.01 163.15 204.95 149.201 208.033C135.253 211.115 120.663 209.138 108.037 202.456C95.4113 195.773 85.5722 184.82 80.2768 171.553C74.9815 158.286 74.5747 143.568 79.1292 130.029C83.6838 116.489 92.903 105.01 105.14 97.6401C117.378 90.2706 131.836 87.4912 145.934 89.7982C160.031 92.1052 172.85 99.3484 182.099 110.234C191.349 121.12 196.428 134.939 196.43 149.224Z" fill="#861E32"/>
        <path d="M116.828 119.599C113.521 120.963 109.87 121.287 106.475 122.406C103.08 123.525 99.6748 125.781 98.8014 129.275C98.3501 131.031 98.5856 132.984 97.722 134.574C96.5739 136.713 93.8852 137.518 92.2563 139.284C89.1554 142.699 91.1965 148.557 94.9842 151.197C98.772 153.837 103.61 154.239 108.143 155.014C109.384 155.113 110.566 155.581 111.539 156.358C114.178 158.88 111.254 163.443 112.373 166.917C113.001 168.88 114.855 170.224 115.699 172.108C117.318 175.7 114.865 179.664 113.246 183.256C110.326 189.808 110.062 197.237 112.51 203.98C112.397 204.143 112.273 204.297 112.137 204.442C105.679 201.608 99.7651 197.669 94.6604 192.803C89.6853 185.826 85.7503 178.222 86.1625 169.861C86.3882 165.327 87.8993 160.951 88.1839 156.437C88.6549 149.107 86.0055 141.148 89.597 134.75C91.5595 131.208 95.3866 127.322 93.2474 123.888C89.4694 124.437 85.3971 124.525 81.9331 123.211C86.1837 114.356 92.5346 106.674 100.433 100.835C108.331 94.9963 117.537 91.1767 127.249 89.7094C130.978 100.366 128.014 114.889 116.828 119.599Z" fill="url(#chisiamoservizio-paint2_linear)"/>
        <path d="M196.43 149.224C196.434 153.034 196.076 156.836 195.36 160.578C193.76 161.879 192.261 163.299 190.876 164.827C186.117 170.401 185.086 178.133 184.311 185.434C184.311 185.591 184.311 185.748 184.262 185.895C184.262 185.797 184.262 185.709 184.183 185.611C183.805 183.736 182.988 181.977 181.799 180.478C180.445 179.016 178.041 178.29 176.392 179.438C175.607 177.476 174.819 175.53 174.027 173.6C169.121 173.6 164.057 173.6 159.445 171.863C154.833 170.126 151.016 166.799 147.326 163.551C147.811 157.923 147.705 152.26 147.012 146.653C161.345 145.098 174.602 138.317 184.252 127.607C175.622 127.176 167.065 129.384 159.72 133.936C158.15 134.917 156.56 136.016 154.725 136.173C153.562 136.202 152.403 136.043 151.291 135.702L139.427 132.69C137.945 125.055 143.234 117.391 150.035 113.614C155.137 110.787 161.329 111.337 166.226 108.864C169.582 107.176 170.563 104.223 173.497 102.054H173.556C180.684 107.679 186.444 114.845 190.406 123.014C194.367 131.184 196.427 140.145 196.43 149.224Z" fill="url(#chisiamoservizio-paint3_linear)"/>
        <path d="M154.843 137.027C154.489 138.185 154.156 139.539 154.843 140.511C155.18 140.926 155.624 141.242 156.128 141.423C156.714 141.703 157.366 141.815 158.012 141.748C158.658 141.68 159.273 141.435 159.788 141.04C160.327 140.451 160.708 139.736 160.897 138.96C161.814 136.145 162.471 133.251 162.86 130.315C162.934 130.045 162.934 129.761 162.86 129.491C162.706 129.208 162.46 128.986 162.163 128.863C160.603 128.029 158.159 127.361 157.256 129.206C156.148 131.581 155.628 134.515 154.843 137.027Z" fill="#E4A98A"/>
        <path d="M159.563 138.371C159.892 138.326 160.228 138.356 160.544 138.46C160.759 138.579 160.947 138.743 161.093 138.941L164.479 142.738C165.136 143.474 165.823 144.485 165.362 145.358C165.2 145.601 164.985 145.805 164.734 145.954C164.482 146.104 164.201 146.195 163.91 146.222C162.27 146.372 160.622 146.048 159.16 145.289C158.12 144.838 157.09 144.308 156.079 143.818C155.328 143.477 154.671 142.958 154.166 142.306C153.39 141.129 153.184 138.303 154.538 137.4C155.235 136.958 155.844 137.272 156.501 137.577C157.441 138.089 158.492 138.362 159.563 138.371Z" fill="#861E32"/>
        <path d="M173.605 149.96C173.631 150.179 173.608 150.4 173.536 150.608C173.419 150.81 173.249 150.976 173.045 151.089C172.402 151.532 171.647 151.787 170.867 151.825C170.477 151.84 170.089 151.764 169.733 151.604C169.378 151.444 169.064 151.204 168.816 150.902C168.455 150.331 168.236 149.682 168.178 149.009C167.891 147.561 167.749 146.088 167.756 144.612C167.739 144.526 167.741 144.436 167.762 144.35C167.783 144.265 167.822 144.184 167.877 144.115C167.932 144.046 168.002 143.99 168.081 143.95C168.16 143.911 168.247 143.889 168.335 143.886C169.552 143.631 171.976 142.993 172.594 144.485C173.224 146.244 173.565 148.093 173.605 149.96Z" fill="#E4A98A"/>
        <path d="M178.756 149.813C179.802 149.87 180.835 150.068 181.828 150.402C182.321 150.573 182.774 150.843 183.159 151.196C183.544 151.548 183.852 151.976 184.065 152.453C184.263 152.92 184.288 153.442 184.134 153.925C183.795 154.642 183.2 155.205 182.466 155.505C178.978 157.358 175.044 158.204 171.102 157.948C170.091 157.989 169.103 157.637 168.345 156.967C167.798 156.156 167.542 155.184 167.619 154.209L167.481 149.597C168.369 149.637 169.236 149.882 170.013 150.314C171.465 150.804 172.035 149.627 173.232 149.332C174.792 148.969 177.167 149.656 178.756 149.813Z" fill="#861E32"/>
        <path d="M159.563 138.371C159.892 138.326 160.228 138.356 160.544 138.46C160.759 138.579 160.947 138.743 161.093 138.941L164.479 142.738C165.136 143.474 165.823 144.485 165.362 145.358C165.2 145.601 164.985 145.805 164.734 145.954C164.482 146.104 164.201 146.195 163.91 146.222C162.27 146.372 160.622 146.048 159.16 145.289C158.12 144.838 157.09 144.308 156.079 143.818C155.328 143.477 154.671 142.958 154.166 142.306C153.39 141.129 153.184 138.303 154.538 137.4C155.235 136.958 155.844 137.272 156.501 137.577C157.441 138.089 158.492 138.362 159.563 138.371Z" fill="url(#chisiamoservizio-paint4_linear)"/>
        <path d="M178.756 149.813C179.802 149.87 180.835 150.068 181.828 150.402C182.321 150.573 182.774 150.843 183.159 151.196C183.544 151.548 183.852 151.976 184.065 152.453C184.263 152.92 184.288 153.442 184.134 153.925C183.795 154.642 183.2 155.205 182.466 155.505C178.978 157.358 175.044 158.204 171.102 157.948C170.091 157.989 169.103 157.637 168.345 156.967C167.798 156.156 167.542 155.184 167.619 154.209L167.481 149.597C168.369 149.637 169.236 149.882 170.013 150.314C171.465 150.804 172.035 149.627 173.232 149.332C174.792 148.969 177.167 149.656 178.756 149.813Z" fill="url(#chisiamoservizio-paint5_linear)"/>
        <path d="M122.853 99.846C120.742 99.6628 118.618 99.6957 116.514 99.9442C116.239 99.9582 115.972 100.043 115.738 100.189C115.604 100.316 115.497 100.468 115.423 100.636C115.349 100.805 115.309 100.987 115.307 101.171C115.012 104.017 117.269 106.489 119.703 107.991C126.287 112.073 134.589 111.916 141.86 114.585C142.554 114.769 143.17 115.171 143.617 115.733C143.92 116.342 144.068 117.016 144.049 117.696C144.372 121.533 146.502 124.987 147.208 128.765C147.915 132.543 147.159 136.497 147.738 140.305C150.123 137.763 154.185 137.361 156.57 134.849C158.807 132.533 159.249 129.079 159.582 125.88L160.485 117.107C160.653 116.474 160.583 115.802 160.291 115.217C159.998 114.632 159.502 114.173 158.896 113.928C148.16 106.99 135.58 100.945 122.853 99.846Z" fill="url(#chisiamoservizio-paint6_linear)"/>
        <path d="M166.265 98.9629C167.257 99.1618 168.176 99.6265 168.924 100.307C169.692 101.376 170.041 102.688 169.906 103.997C169.882 113.308 168.122 122.533 164.715 131.198C164.683 131.503 164.576 131.795 164.405 132.049C164.234 132.303 164.004 132.511 163.733 132.655C163.463 132.8 163.162 132.876 162.856 132.876C162.549 132.877 162.248 132.803 161.977 132.66C159.936 132.11 157.925 131.455 155.952 130.698L158.621 113.035C158.748 112.544 158.721 112.027 158.542 111.553C158.345 111.217 158.069 110.934 157.738 110.729C154.693 108.514 151.478 106.545 148.121 104.841C145.442 103.487 142.371 101.897 141.644 99.0316C141.494 98.542 141.463 98.0233 141.556 97.5194C141.648 97.0155 141.86 96.5413 142.174 96.1368C142.599 95.7198 143.098 95.3869 143.646 95.1555C145.422 94.2821 147.66 93.1929 149.701 93.3695C151.34 93.5364 151.968 94.4784 153.332 95.1751C157.119 97.0592 162.153 97.7657 166.265 98.9629Z" fill="#282328"/>
        <path d="M148.032 70.8686C149.347 74.4896 149.995 78.3264 151.153 81.9866C151.406 82.9568 151.861 83.8628 152.487 84.6459C153.32 85.4443 154.328 86.0359 155.431 86.373C156.969 86.9683 158.532 87.4884 160.122 87.9332C161.182 88.2178 162.448 88.7084 162.565 89.7878C162.59 90.1478 162.516 90.5077 162.349 90.828C161.939 91.559 161.349 92.1729 160.634 92.6109C159.92 93.049 159.105 93.2966 158.267 93.3303C156.596 93.3751 154.931 93.102 153.361 92.5256C151.931 92.2363 150.62 91.5294 149.593 90.4944C149.009 89.6336 148.599 88.6668 148.386 87.6486C146.775 81.9322 144.541 76.4103 141.723 71.1826C140.88 69.8021 140.22 68.3177 139.76 66.7668C139.113 64.0585 139.103 57.6408 141.899 59.9959C144.156 61.8996 147.041 68.1799 148.032 70.8686Z" fill="#E4A98A"/>
        <path d="M137.327 98.6194C138.981 97.9784 140.591 97.2277 142.145 96.3723C148.033 93.1144 143.215 91.5149 141.252 87.5996C139.849 84.8225 140.418 81.496 141.144 78.4834C142.429 73.1844 144.147 67.6892 142.91 62.4001C142.756 61.5417 142.421 60.7257 141.929 60.0057C141.569 59.5591 141.153 59.1601 140.693 58.8184C139.45 57.7447 138.019 56.9097 136.473 56.3553C133.529 55.3741 130.193 56.0904 127.396 57.4936C116.406 63.0085 114.06 76.933 114.502 88.0706C114.62 91.1616 115.101 94.4293 117.093 96.7942C119.085 99.1591 121.901 100.062 124.786 100.415C129.05 100.851 133.356 100.234 137.327 98.6194Z" fill="white"/>
        <path d="M120.939 92.3196C121.028 93.3649 121.364 94.374 121.92 95.2634C122.902 96.647 124.707 97.1573 126.366 97.5596L133.833 99.4045C134.743 99.6804 135.689 99.8225 136.64 99.8264C138.681 99.7283 140.437 98.4232 142.057 97.1671C142.47 96.884 142.829 96.5283 143.116 96.1172C138.995 95.7246 134.883 95.3125 130.782 94.7139C128.368 94.3607 125.816 93.87 124.04 92.2116C123.122 91.3052 122.424 90.2009 121.999 88.9832C121.705 88.2276 121.567 86.3043 120.713 86.1375C120.498 88.1985 120.574 90.2796 120.939 92.3196Z" fill="url(#chisiamoservizio-paint7_linear)"/>
        <path d="M135.678 64.9809C135.677 65.2903 135.554 65.5867 135.335 65.8052C135.131 65.9253 134.892 65.9737 134.657 65.9425C133.889 65.9065 133.136 65.7061 132.451 65.3547C131.766 65.0034 131.164 64.5094 130.687 63.9059C130.209 63.3024 129.866 62.6035 129.68 61.8563C129.495 61.109 129.473 60.3308 129.614 59.574C129.79 58.5927 130.212 57.7488 130.477 56.8264C130.997 54.9815 130.909 52.9012 132.077 51.3802C133.088 53.6934 134.241 55.9416 135.531 58.1119C135.86 58.5434 136.09 59.0431 136.202 59.5744C136.314 60.1057 136.306 60.6554 136.178 61.1833L135.678 64.9809Z" fill="#E4A98A"/>
        <path d="M136.257 60.2315L135.816 63.1753C135.816 63.1753 130.762 60.6731 131.419 56.0316C132.077 51.3901 136.257 60.2315 136.257 60.2315Z" fill="url(#chisiamoservizio-paint8_linear)"/>
        <path d="M142.164 57.3661C141.905 58.3092 141.356 59.1468 140.594 59.7605C140.231 60.0067 139.825 60.183 139.397 60.2805C137.894 60.5721 136.338 60.3807 134.951 59.7338C133.564 59.0869 132.417 58.0177 131.674 56.6792C130.974 55.3234 130.575 53.8325 130.504 52.3084C130.433 50.7843 130.692 49.2627 131.262 47.8476C131.729 46.2956 132.786 44.9885 134.206 44.207C134.982 43.9009 135.822 43.7918 136.651 43.8894C137.48 43.987 138.271 44.2883 138.955 44.7664C140.814 46.0642 142.251 47.8795 143.087 49.9868C143.803 52.1358 142.9 55.3152 142.164 57.3661Z" fill="#E4A98A"/>
        <path d="M164.351 111.298C165.609 112.899 166.603 114.691 167.295 116.606C171.154 125.913 173.662 135.724 174.743 145.741C171.592 146.133 168.405 146.133 165.254 145.741C165.007 138.669 163.917 131.652 162.006 124.839C161.668 123.373 161.088 121.973 160.289 120.698C158.886 118.667 156.579 117.49 154.401 116.391C149.304 113.914 143.976 111.942 138.494 110.503C136.179 109.865 133.843 109.276 131.566 108.54C129.653 107.893 124.285 105.518 122.735 104.232C121.922 103.616 121.243 102.842 120.737 101.957C120.231 101.072 119.909 100.093 119.791 99.0807C119.791 99.5909 134.746 100.611 135.815 100.494C139.417 100.111 143.666 98.0503 147.218 99.5124C149.995 100.651 152.537 103.025 155.068 104.664C158.14 106.588 161.888 108.452 164.351 111.298Z" fill="#473F47"/>
        <path d="M147.964 110.542L129.712 101.622L151.114 92.5453L169.376 100.523L147.964 110.542Z" fill="#430C16"/>
        <path d="M120.164 68.6607C120.164 69.0042 120.075 69.3378 120.036 69.642C119.497 74.6662 119.987 79.5923 120.174 84.587C120.291 87.6781 120.694 93.5658 123.981 95.1457C130.124 98.0895 141.978 96.9709 141.978 96.9709C143.352 96.7505 144.757 96.8073 146.109 97.1377C146.845 97.3634 147.522 97.7461 148.238 98.0208C150.829 99.0021 153.724 98.5115 156.452 98.0208C156.922 97.9651 157.376 97.8149 157.786 97.5793C158.262 97.1564 158.553 96.5632 158.595 95.9279C158.637 95.2926 158.428 94.6662 158.012 94.184C157.126 93.2535 155.975 92.6194 154.715 92.3686C151.27 91.2441 147.58 91.1014 144.058 91.9565C143.608 92.1081 143.149 92.2326 142.684 92.3294C142.139 92.4177 141.588 92.4571 141.036 92.4471H136.855C132.047 92.4471 127.514 92.506 126.787 86.8832C126.519 84.8008 126.467 82.6962 126.63 80.603C126.915 76.825 127.906 73.0765 127.7 69.2986C127.688 68.1004 127.388 66.9227 126.827 65.8641C126.542 65.3384 126.127 64.8946 125.622 64.5751C125.117 64.2555 124.539 64.0709 123.942 64.0389C123.324 64.085 122.728 64.2899 122.212 64.6337C121.696 64.9775 121.278 65.4486 120.998 66.0015C120.563 66.831 120.281 67.7317 120.164 68.6607Z" fill="#E4A98A"/>
        <path d="M176.892 82.5263L151.379 95.5088L147.964 110.542L169.376 100.523L176.892 82.5263Z" fill="#430C16"/>
        <path d="M143.421 51.9494C143.393 51.0152 143.046 50.1185 142.439 49.4079C142.131 49.0502 141.766 48.7453 141.36 48.5051C140.791 48.1715 140.653 48.142 140.28 48.6817C139.8 49.3784 139.643 50.1929 139.201 50.9191C138.718 51.7244 138.044 52.3985 137.238 52.8816C136.333 53.4433 135.28 53.7202 134.216 53.6765C133.793 53.6126 133.363 53.6126 132.94 53.6765C132.798 53.7033 132.664 53.7613 132.547 53.8461C132.43 53.9309 132.333 54.0405 132.263 54.1671C132.162 54.5342 132.126 54.916 132.155 55.2956C132.067 56.2769 131.096 56.9736 130.556 57.8273C130.016 58.6811 129.859 59.9469 129.212 60.8595C129.091 61.0426 128.926 61.1914 128.731 61.2913C128.269 61.4875 127.749 61.2324 127.269 61.1441C125.846 60.8988 124.698 62.2235 123.481 63.0282C121.037 64.6375 117.838 64.2253 114.953 63.7445C114.07 63.6377 113.209 63.3926 112.402 63.0183C111.53 62.5039 110.756 61.8395 110.116 61.0558C109.046 59.8782 107.986 58.6811 106.936 57.4839C106.546 57.1006 106.236 56.6436 106.024 56.1395C105.911 55.788 105.881 55.4153 105.935 55.0503C106.031 54.3688 106.233 53.7066 106.534 53.0877C107.299 51.3705 108.192 49.506 109.919 48.7308C111.646 47.9556 113.688 48.5345 115.591 48.3383C115.823 48.3378 116.049 48.2658 116.239 48.1322C116.39 47.961 116.498 47.7556 116.553 47.5336C117.348 44.894 118.339 42.0286 120.714 40.6646C122.543 39.7856 124.606 39.514 126.601 39.8894C128.577 40.2626 130.592 40.3781 132.597 40.2328C133.578 40.0758 134.56 39.752 135.541 39.5557C137.836 39.0459 140.238 39.395 142.292 40.537C143.306 41.1404 144.154 41.9862 144.761 42.9985C145.367 44.0107 145.712 45.1577 145.766 46.3364C145.727 46.7968 145.727 47.2597 145.766 47.7201C145.835 48.1518 146.021 48.5738 146.09 49.0056C146.13 49.3788 146.091 49.7562 145.974 50.1132C145.858 50.4701 145.668 50.7984 145.416 51.0765C145.164 51.3545 144.856 51.5761 144.512 51.7265C144.168 51.877 143.796 51.953 143.421 51.9494Z" fill="#4F2626"/>
        <path d="M119.271 71.4476C118.969 74.2247 118.936 77.0247 119.173 79.8082C119.161 80.0497 119.223 80.2892 119.35 80.4951C119.558 80.7234 119.846 80.8638 120.154 80.8876C121.743 81.1834 123.356 81.3377 124.972 81.3488C125.199 81.4213 125.439 81.4429 125.675 81.412C125.911 81.3811 126.138 81.2985 126.338 81.1702C126.539 81.0418 126.709 80.8707 126.835 80.6692C126.962 80.4677 127.043 80.2407 127.072 80.0044C127.955 76.3148 128.829 72.6251 129.496 68.8864C129.761 67.3851 129.948 65.6972 129.045 64.4804C127.74 62.7141 124.217 62.5179 122.519 63.8033C120.468 65.3832 119.595 69.0729 119.271 71.4476Z" fill="white"/>
        <path d="M147.042 66.7374L149.819 73.3905C149.956 73.6492 150.011 73.9437 149.976 74.2345C149.905 74.4731 149.769 74.6872 149.583 74.8527C148.227 76.2871 146.616 77.4582 144.834 78.3068C143.19 76.8383 141.856 75.0569 140.909 73.0667C139.986 70.7018 140.192 68.072 140.467 65.5501C140.634 63.9506 140.575 58.8086 143.293 60.8006C145.187 62.1646 146.188 64.6865 147.042 66.7374Z" fill="white"/>
        <path d="M96.8095 129.147C96.8104 130.054 96.6264 130.952 96.2687 131.785C95.911 132.619 95.3871 133.371 94.7291 133.995C93.5951 135.069 92.1336 135.733 90.5783 135.879C90.2874 135.894 89.9959 135.894 89.705 135.879L40.9644 134.898C39.0932 134.877 37.3037 134.128 35.9769 132.808C34.65 131.489 33.8904 129.703 33.8599 127.832C33.859 126.925 34.043 126.028 34.4007 125.194C34.7584 124.361 35.2822 123.609 35.9402 122.985C36.6074 122.343 37.3946 121.84 38.2568 121.503C39.1189 121.166 40.039 121.003 40.9644 121.022H42.6228C42.2751 119.817 42.1 118.568 42.1027 117.313C42.0994 115.524 42.4615 113.754 43.1668 112.111C43.8722 110.467 44.9058 108.985 46.2045 107.755C47.3487 106.66 48.6775 105.777 50.1296 105.145C52.0095 104.325 54.0453 103.923 56.0958 103.967L60.2369 104.056C62.7971 104.103 65.2984 104.832 67.4835 106.167C69.6686 107.502 71.4588 109.395 72.6698 111.651C73.867 111.092 75.1758 110.814 76.4968 110.836H77.5664C79.784 110.864 81.9036 111.755 83.4754 113.319C85.0473 114.884 85.9478 116.999 85.9859 119.217V119.619C85.9898 120.38 85.8807 121.137 85.6621 121.866L89.6951 121.954C91.59 121.973 93.4009 122.739 94.7334 124.086C96.066 125.433 96.8122 127.252 96.8095 129.147Z" fill="white"/>
        <path d="M90.5783 135.879C90.2874 135.894 89.9959 135.894 89.705 135.879L40.9644 134.898C39.0932 134.877 37.3037 134.128 35.9769 132.808C34.65 131.489 33.8904 129.703 33.8599 127.832C33.859 126.925 34.043 126.028 34.4007 125.194C34.7584 124.361 35.2822 123.609 35.9402 122.985C34.0267 135.015 46.5872 133.298 46.5872 133.298H78.2533L90.5783 135.879Z" fill="url(#chisiamoservizio-paint9_linear)"/>
        <path d="M46.1553 122.475L42.6227 121.13C42.275 119.925 42.0999 118.676 42.1026 117.421C42.0993 115.632 42.4614 113.862 43.1667 112.219C43.872 110.575 44.9057 109.093 46.2044 107.863C47.3485 106.768 48.6774 105.885 50.1295 105.253C40.3461 113.653 46.1553 122.475 46.1553 122.475Z" fill="url(#chisiamoservizio-paint10_linear)"/>
        <path d="M225.378 72.1149C225.378 73.0227 225.193 73.9211 224.834 74.7548C224.474 75.5885 223.948 76.3398 223.288 76.9624C222.15 78.0369 220.685 78.7001 219.127 78.8465C218.836 78.8714 218.544 78.8714 218.253 78.8465L169.523 77.8652C167.668 77.8404 165.894 77.0977 164.575 75.7931C163.256 74.4885 162.493 72.7234 162.448 70.8686C162.437 69.9525 162.616 69.044 162.972 68.1999C163.328 67.3558 163.855 66.594 164.518 65.9622C165.876 64.676 167.682 63.9716 169.552 63.9996H171.201C170.858 62.7963 170.683 61.5514 170.681 60.3002C170.678 58.5117 171.04 56.7416 171.746 55.0981C172.451 53.4547 173.484 51.9725 174.782 50.7424C175.927 49.6454 177.256 48.7585 178.708 48.1224C180.587 47.3012 182.623 46.8993 184.674 46.9448L188.815 47.0331C191.375 47.0804 193.876 47.8102 196.06 49.1471C198.244 50.484 200.031 52.3796 201.238 54.6381C202.432 54.0713 203.743 53.7923 205.065 53.8236H206.135C208.353 53.8463 210.475 54.7336 212.049 56.2969C213.623 57.8601 214.526 59.9758 214.564 62.194V62.5964C214.563 63.3609 214.451 64.1213 214.23 64.8533L218.263 64.9318C220.155 64.955 221.961 65.7214 223.292 67.0654C224.624 68.4094 225.373 70.2232 225.378 72.1149Z" fill="white"/>
        <path d="M219.137 78.8465C218.846 78.8714 218.554 78.8714 218.263 78.8465L169.533 77.8652C167.676 77.8429 165.9 77.1013 164.578 75.7965C163.257 74.4916 162.493 72.7251 162.448 70.8686C162.437 69.9525 162.616 69.044 162.972 68.1999C163.328 67.3558 163.855 66.594 164.518 65.9622C162.556 78.0026 175.165 76.2755 175.165 76.2755H206.831L219.137 78.8465Z" fill="url(#chisiamoservizio-paint11_linear)"/>
        <path d="M174.724 65.4421L171.181 64.0977C170.838 62.8944 170.663 61.6495 170.661 60.3982C170.658 58.6098 171.021 56.8397 171.726 55.1962C172.431 53.5528 173.465 52.0706 174.763 50.8405C175.907 49.7435 177.236 48.8566 178.688 48.2205C168.914 56.6203 174.724 65.4421 174.724 65.4421Z" fill="url(#chisiamoservizio-paint12_linear)" fillOpacity="0.2"/>
        <path d="M133.009 53.6961C132.867 53.7229 132.733 53.7809 132.616 53.8657C132.499 53.9505 132.402 54.0601 132.332 54.1867C132.231 54.5538 132.194 54.9356 132.224 55.3152C132.136 56.2965 131.164 56.9932 130.625 57.8469C130.085 58.7006 129.928 59.9665 129.28 60.8791C129.16 61.0622 128.994 61.211 128.799 61.3109C128.338 61.5071 127.818 61.252 127.337 61.1637C125.914 60.9184 124.766 62.2431 123.549 63.0478C121.106 64.6571 117.907 64.2449 115.022 63.7641C114.114 63.6582 113.23 63.4063 112.402 63.0183C111.53 62.5038 110.756 61.8394 110.116 61.0557C109.046 59.8782 107.986 58.681 106.936 57.4838C106.546 57.1006 106.236 56.6436 106.024 56.1395C105.911 55.7879 105.881 55.4153 105.935 55.0503C106.393 54.774 106.868 54.5281 107.358 54.3143C108.862 53.507 110.621 53.3135 112.265 53.7746C113.58 54.2849 114.512 55.4526 115.64 56.2867C117.214 57.3886 119.128 57.8949 121.041 57.7151C122.954 57.5353 124.74 56.6812 126.081 55.3054C126.576 54.6539 127.157 54.0728 127.808 53.5783C128.976 52.9405 131.351 53.0386 133.009 53.6961Z" fill="url(#chisiamoservizio-paint13_linear)"/>
      </g>
      <defs>
      <linearGradient id="chisiamoservizio-paint0_linear" x1="196.175" y1="174.973" x2="191.729" y2="117.706" gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none"/>
      <stop offset="0.13" stopOpacity="0.69" stroke="none" fill="none"/>
      <stop offset="0.25" stopOpacity="0.32" stroke="none" fill="none"/>
      <stop offset="1" stopOpacity="0" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id="chisiamoservizio-paint1_linear" x1="1469.47" y1="2717.24" x2="1946.32" y2="2613.83" gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none"/>
      <stop offset="0.13" stopOpacity="0.69" stroke="none" fill="none"/>
      <stop offset="0.25" stopOpacity="0.32" stroke="none" fill="none"/>
      <stop offset="1" stopOpacity="0" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id="chisiamoservizio-paint2_linear" x1="5793.44" y1="29935" x2="-23.7597" y2="-12098.4" gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none"/>
      <stop offset="0.13" stopOpacity="0.69" stroke="none" fill="none"/>
      <stop offset="0.25" stopOpacity="0.32" stroke="none" fill="none"/>
      <stop offset="1" stopOpacity="0" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id="chisiamoservizio-paint3_linear" x1="10659.5" y1="21619.1" x2="8085.72" y2="-9473.3" gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none"/>
      <stop offset="0.13" stopOpacity="0.69" stroke="none" fill="none"/>
      <stop offset="0.25" stopOpacity="0.32" stroke="none" fill="none"/>
      <stop offset="1" stopOpacity="0" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id="chisiamoservizio-paint4_linear" x1="-10482.2" y1="1458.35" x2="-10683.2" y2="1589.74" gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none"/>
      <stop offset="0.13" stopOpacity="0.69" stroke="none" fill="none"/>
      <stop offset="0.25" stopOpacity="0.32" stroke="none" fill="none"/>
      <stop offset="1" stopOpacity="0" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id="chisiamoservizio-paint5_linear" x1="-14811.4" y1="1644.45" x2="-14869.6" y2="1394.2" gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none"/>
      <stop offset="0.13" stopOpacity="0.69" stroke="none" fill="none"/>
      <stop offset="0.25" stopOpacity="0.32" stroke="none" fill="none"/>
      <stop offset="1" stopOpacity="0" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id="chisiamoservizio-paint6_linear" x1="-42393.8" y1="3608.26" x2="-45155" y2="7880.38" gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none"/>
      <stop offset="0.13" stopOpacity="0.69" stroke="none" fill="none"/>
      <stop offset="0.25" stopOpacity="0.32" stroke="none" fill="none"/>
      <stop offset="1" stopOpacity="0" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id="chisiamoservizio-paint7_linear" x1="3605.89" y1="1132.25" x2="3152.86" y2="2217.93" gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none"/>
      <stop offset="0.13" stopOpacity="0.69" stroke="none" fill="none"/>
      <stop offset="0.25" stopOpacity="0.32" stroke="none" fill="none"/>
      <stop offset="1" stopOpacity="0" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id="chisiamoservizio-paint8_linear" x1="-3850.89" y1="534.17" x2="-3951.19" y2="660.575" gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none"/>
      <stop offset="0.13" stopOpacity="0.69" stroke="none" fill="none"/>
      <stop offset="0.25" stopOpacity="0.32" stroke="none" fill="none"/>
      <stop offset="1" stopOpacity="0" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id="chisiamoservizio-paint9_linear" x1="4111.92" y1="2246.55" x2="4098.51" y2="1488.95" gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none"/>
      <stop offset="0.13" stopOpacity="0.69" stroke="none" fill="none"/>
      <stop offset="0.25" stopOpacity="0.32" stroke="none" fill="none"/>
      <stop offset="1" stopOpacity="0" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id="chisiamoservizio-paint10_linear" x1="258.028" y1="2397.79" x2="734.887" y2="2294.44" gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none"/>
      <stop offset="0.13" stopOpacity="0.69" stroke="none" fill="none"/>
      <stop offset="0.25" stopOpacity="0.32" stroke="none" fill="none"/>
      <stop offset="1" stopOpacity="0" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id="chisiamoservizio-paint11_linear" x1="11665.9" y1="1439.23" x2="11652.5" y2="681.889" gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none"/>
      <stop offset="0.13" stopOpacity="0.69" stroke="none" fill="none"/>
      <stop offset="0.25" stopOpacity="0.32" stroke="none" fill="none"/>
      <stop offset="1" stopOpacity="0" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id="chisiamoservizio-paint12_linear" x1="1438.28" y1="1340.01" x2="1915.12" y2="1236.6" gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none"/>
      <stop offset="0.13" stopOpacity="0.69" stroke="none" fill="none"/>
      <stop offset="0.25" stopOpacity="0.32" stroke="none" fill="none"/>
      <stop offset="1" stopOpacity="0" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id="chisiamoservizio-paint13_linear" x1="4276.04" y1="941.126" x2="3713.11" y2="-111.874" gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none" stroke="none" fill="none"/>
      <stop offset="0.13" stopOpacity="0.69" stroke="none" fill="none"/>
      <stop offset="0.25" stopOpacity="0.32" stroke="none" fill="none"/>
      <stop offset="1" stopOpacity="0" stroke="none" fill="none"/>
      </linearGradient>
      <clipPath id="chisiamoservizio-clip0">
      <rect width="300" height="226.108" stroke="none"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default ChiSiamoServizio
