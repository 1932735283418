import React from 'react'
import { Props as ButtonProps } from 'components/atoms/Button/Button'
import { Button } from 'components/atoms'

import { Facebook, GoogleColor } from '../Icons'

interface Props extends ButtonProps {
  social: 'facebook' | 'google'
}

const SocialButton = ({ icon, social, className, ...props }: Props) => {
  const socialIcon =
    icon || (social === 'facebook' ? <Facebook /> : social === 'google' ? <GoogleColor /> : null)

  return (
    <Button
      {...{
        icon: socialIcon,
        iconPos: 'left',
        variant: social === 'google' ? 'secondary' : 'primary',
        ...props,
        className: `social-button social-button--${social}${className ? ` ${className}` : ''}`,
      }}
    />
  )
}

export default SocialButton
