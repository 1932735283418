import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function SottocategorieListShape2({ className }: Props) {
  return (
    <IconBase name="sottocategorie-list-shape2" w={81} h={80} className={className}>
      <path d="M18.6835 73.3298C39.1523 82.4334 82.9633 55.9679 79.5319 34.4754C76.1005 12.983 55.5585 5.48781 25.6612 8.60168C-4.23609 11.7155 -6.39123 62.1776 18.6835 73.3298Z" stroke="none"/>
    </IconBase>
  )
}

export default SottocategorieListShape2
