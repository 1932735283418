import React from 'react'
import { useTrans } from 'hooks'
import { ImageBox, Button } from 'components/atoms'
import { ProdottoCard } from 'components/molecules'

import CantinaBoxBg from 'components/atoms/Icons/CantinaBoxBg'

interface Props {
  cantina: {
    nome?: string
    slug?: string
    logoTrasparente?: {
      image?: string
    }
    statement?: string
    url?: string
    prodotti?: {
      edges?: any[]
    }
  }
  className?: string
}

const CantinaBox = (props: Props) => {
  const {
    cantina = {
      nome: '',
      slug: '',
      logoTrasparente: {
        image: '',
      },
      statement: '',
      url: '',
      prodotti: {
        edges: [],
      },
    },
    className = '',
  } = props

  const t = useTrans()

  return (
    <div className={`cantina-box ${className ? ` ${className}` : ''}`}>
      <CantinaBoxBg className="cantina-box__bg" />
      <div className="cantina-box__content">
        <div className="cantina-box__head">
          <div className="cantina-box__head__left">
            <p className="cantina-box__intro">{t('La cantina')}</p>
            <p className="cantina-box__title">{cantina.nome}</p>
          </div>
          <ImageBox
            src={cantina.logoTrasparente.image}
            maxWidth={100}
            maxHeight={100}
            classPrefix="cantina-box"
          />
        </div>
        <div
          className="cantina-box__description"
          dangerouslySetInnerHTML={{ __html: cantina.statement }}
        ></div>
        <div className="cantina-box__actions">
          <Button label={t('Tutti i vini')} href={`cantina/${cantina.slug}`} />
          <Button label={t('Scopri la cantina')} variant="secondary" href={cantina.url} />
        </div>
      </div>
      <div className="cantina-box__list">
        {cantina.prodotti.edges.slice(0, 2).map((item, i) => (
          <ProdottoCard prodotto={item.node} key={'prodotto_cantina_' + i} />
        ))}
      </div>
    </div>
  )
}

export default CantinaBox
