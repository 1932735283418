import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function RadioUnmarked({ className }: Props) {
  return (
    <IconBase name="radio-unmarked" w={24} className={className}>
      <rect x="4" y="4" width="16" height="16" rx="8" strokeWidth="2" fill="none"/>
    </IconBase>
  )
}

export default RadioUnmarked
