import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function AbbinamentoBg({ className }: Props) {
  return (
    <IconBase name="abbinamento-bg" w={50} className={className}>
      <path d="M44.7607 8.98963C34.6606 -0.706854 3.28852 5.55109 0.743471 18.9138C-1.80157 32.2765 8.68302 41.043 26.9191 45.5588C45.1552 50.0746 57.1336 20.8681 44.7607 8.98963Z" stroke="none"/>
    </IconBase>
  )
}

export default AbbinamentoBg
