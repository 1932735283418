import React from 'react'

interface Props {
  className?: string
  children: any
}

const MyAccountSection = (props: Props) => {
  const { className = '', children = null } = props

  return <div className={`myaccount-section ${className ? ` ${className}` : ''}`}>{children}</div>
}

export default MyAccountSection
