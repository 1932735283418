import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function CantinaBoxBg({ className }: Props) {
  return (
    <IconBase name="cantina-box-bg" w={957} h={554} className={className}>
      <path d="M644.521 34.1489C449.683 41.4455 241.839 -74.1179 78.4157 83.4404C-85.0078 240.999 17.4718 479.152 299.28 545.472C498.782 592.422 912.631 432.983 950.688 257.79C988.745 82.5969 839.359 26.8523 644.521 34.1489Z" stroke="none"/>
    </IconBase>
  )
}

export default CantinaBoxBg
