import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Vector3({ className }: Props) {
  return (
    <IconBase name="vector3" w={1164} h={702} className={className}>
      <path d="M751.437 9.16644C514.948 40.951 241.848 -81.6405 69.0582 137.158C-103.732 355.956 61.513 646.268 416.543 697.829C667.88 734.331 1145.9 484.193 1162.75 257.515C1179.6 30.8366 987.926 -22.6182 751.437 9.16644Z" stroke="none"/>
    </IconBase>
  )
}

export default Vector3
