import React from 'react'
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useTrans } from 'hooks'
import { ProdottoCard } from 'components/molecules'

SwiperCore.use([Pagination])

interface Props {
  confezioni: {
    id: number
    imageUrl?: string
    cantina?: any
    nome: string
    prezzo: number
    prezzoScontato: number
    sconto?: string
    promo?: string
    omaggio?: string
    magnum?: boolean
    voti?: any[]
    wishlist?: boolean
    link?: string
  }[]
  className?: string
}

const RegaloConfezioni = (props: Props) => {
  const { confezioni = [], className = '' } = props

  const t = useTrans()

  return (
    <div className={`regalo-confezioni${className ? ` ${className}` : ''}`}>
      <p className="regalo-confezioni__title">{t('Rendi speciale il tuo regalo')}</p>
      <p className="regalo-confezioni__description">
        {t('Aggiungi al tuo ordine una delle nostre confezioni regalo')}
      </p>
      <div className="regalo-confezioni__list">
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          pagination={{ clickable: true }}
          watchOverflow
          breakpoints={{
            320: {
              slidesPerView: 1.5,
            },
            576: {
              slidesPerView: 2.5,
            },
            991: {
              slidesPerView: 4,
            },
          }}
        >
          {confezioni.map((prodotto) => (
            <SwiperSlide key={`regalo-confezione__${prodotto.pk}`}>
              <ProdottoCard prodotto={prodotto} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default RegaloConfezioni
