import { useNode } from '@craftjs/core'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'

import { defaultProps, dynamicProps } from './index'
import { getAllPaths, getDynamicValue, setDynamicValue } from '../../utils/dynamic'

export const QuoteSettings = () => {
  const { customs } = useNode((node) => ({
    customs: node.data.custom,
  }))

  return (
    <>
      {customs.dynamicData && (
        <ToolbarSection title="Dynamic Data" initOpen>
          <ToolbarItem
            propKey="block"
            propSubKey="titolo"
            type="text"
            label="Titolo"
            onlyDynamic={true}
            dynamic={dynamicProps.includes('titolo')}
            dynamicValues={getAllPaths(customs.dynamicData)}
            defaultValue={defaultProps['titolo']}
          />
        </ToolbarSection>
      )}
      <ToolbarSection title="Style" initOpen>
        <ToolbarItem
          propKey="block"
          propSubKey="colore"
          type="text"
          label="Colore"
          dynamic={dynamicProps.includes('colore') && customs.dynamicData}
          dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
          defaultValue={defaultProps['colore']}
        />
        <ToolbarItem
          propKey="block"
          propSubKey="size"
          type="select"
          label="Size"
          values={['', 'sm']}
          dynamic={dynamicProps.includes('size') && customs.dynamicData}
          dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
          defaultValue={defaultProps['size']}
        />
        <ToolbarItem
          propKey="block"
          propSubKey="noQuotes"
          type="switch"
          label="No quotes"
          dynamic={dynamicProps.includes('noQuotes') && customs.dynamicData}
          dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
          defaultValue={defaultProps['noQuotes']}
        />
      </ToolbarSection>
    </>
  )
}
