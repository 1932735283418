import { useNode, Element, useEditor } from '@craftjs/core'
import React, { useState, useEffect } from 'react'
import { ProductsCarouselSettings } from './ProductsCarouselSettings'

import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'

import { ProdottiSlider } from 'components/organisms'

import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { ProductCardBlock } from '../ProductCardBlock'

SwiperCore.use([Pagination])

export const defaultProps = {
  current: null,
  slidesPerView: 2,
  loop: false,
}

export const dynamicProps = []

export const ProductsCarousel = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }
    const { current, slidesPerView, loop } = blockProps

    const { children } = props

    const {
      id,
      connectors: { connect, drag },
      selected,
      hovered,
      actions: { setProp },
    } = useNode((node) => ({
      selected: node.events.selected,
      dragged: node.events.dragged,
      hovered: node.events.hovered,
    }))

    const { enabled, actions } = useEditor((state) => ({
      enabled: state.options.enabled,
    }))

    return (
      <div
        ref={(ref) => props.retRef(ref)}
        className={
          'block-products-carousel ' +
          (props.selected ? 'block-products-carousel--selected ' : '') +
          props.className
        }
      >
        <Swiper
          spaceBetween={16}
          slidesPerView="auto"
          pagination={{ clickable: true }}
          watchOverflow
          allowTouchMove={!props.selected}
        >
          {children.props.children.map((slide, index) => (
            <SwiperSlide key={`prodotti-slider__slide__${index}`}>
              {/*<ProductCardBlock pk={slide.id} active={index === current} />*/}
              {slide}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

ProductsCarousel.craft = withCraft({
  name: 'Products Carousel',
  defaultProps: defaultProps,
  settings: ProductsCarouselSettings,
})
