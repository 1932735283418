import TagManager from 'react-gtm-module'
import replace from 'lodash/replace'
import { getFormattedDate } from 'utils'

const GTM_PAGE_DEFAULT = '404'
const GTM_ID = 'GTM-3D8QNM'

export const initGTMdataLayer = () => {
  TagManager.initialize({
    gtmId: GTM_ID,
    dataLayerName: 'dataLayer',
  })


}

export const sendGTMData = (data) => {
 
  TagManager.dataLayer({
    dataLayer: data,
    dataLayerName: 'dataLayer',
  })
}

const getGTMProps = (paginaCorrente, router, prodotto) => {
  const page = paginaCorrente?.pagina?.chiave || GTM_PAGE_DEFAULT
  let channel = 'corporate'
  let pageType = 'corporate'
  let shopCategory = 'other'
  let firstLevelCategory = ''
  let secondLevelCategory = ''
  let lingua = 'it'

  if (page.includes('categoria') || page.includes('prodotto')  || page.includes('carrello') || page.includes('checkout')) channel = 'store'

  if (page.includes('homepage')) pageType = 'home'
  if (page.includes('categoria')) pageType = 'listing'
  if (page.includes('prodotto')) pageType = 'product'
  if (page.includes('cantine')) pageType = 'cantina'
  if (page.includes('cantine-detail')) pageType = 'cantina'
  if (page.includes('momenti_vinicum')) pageType = 'momenti_vinicum'
  if (page.includes('chi-siamo')) pageType = 'il_nostro_mondo'
  if (page.includes('news')) pageType = 'i_racconti'
  if (page.includes('checkout')) pageType = 'checkout'
  if (page.includes('carrello')) pageType = 'checkout'
  if (page.includes('checkout-success')) pageType = 'thankyou'
  if (page.includes('myaccount')) pageType = 'profilo'


  if (page.includes('categoria') && router?.asPath) {
    const parts = router?.asPath.split('/')
    if (parts.length == 3) {
      firstLevelCategory = parts[parts.indexOf('i-vini')] || ''
      secondLevelCategory = parts[parts.indexOf('i-vini') + 1] || ''
      shopCategory='first-level'
    }
    if(parts.length == 4) {
      firstLevelCategory = parts[parts.indexOf('i-vini')] || ''
      secondLevelCategory = parts[parts.indexOf('i-vini') + 2] || ''
      shopCategory='first-level'
    }
     if(parts.length == 5) {
      firstLevelCategory = parts[parts.indexOf('i-vini') + 2] || ''
      secondLevelCategory = parts[parts.indexOf('i-vini') + 3] || ''
      shopCategory='second-level'
    }
  }

  if (page.includes('prodotto')) shopCategory='product'
  if (page.includes('carrello') || page.includes('checkout')) shopCategory = 'checkout'


  return {
    channel,
    pageType,
    shopCategory,
    productAvailability: prodotto ? (prodotto.disponibilita > 0 ? 'disponibile' : 'out-of-stock') : '',
    firstLevelCategory,
    secondLevelCategory,
    promo: prodotto ? (prodotto.promoApplicata ? 'in-sale' : 'no-sale') : '',
    lingua,
  }
}

const getUtenteProps = (utente) => {
  const login = utente ? 'logged' : 'not_logged'
  const userId = utente ? utente?.pk : 'not_logged'
  const dateOfRegistration = utente ? getFormattedDate(utente?.dataRegistrazione) : 'not_logged'

  return {
    login,
    userId,
    dateOfRegistration,
  }
}

// toLower + standardizzazione parametri
function uniformData(param) {
  return replace(replace(param, / /g, '_'), /-/g, '_').toLowerCase()
}

export const trackingProductDetail = (prodotto) => {
  const product = []
  product.push({
    name: uniformData(prodotto?.nome) || '',
    id: prodotto?.varianteProdotto?.sku || '',
    price: prodotto?.prezzoScontato?.toFixed(2) || '',
    brand: prodotto?.cantina?.nome || '',
    category: uniformData(prodotto?.categoria?.alias) || '',
    quantity: 1,
    dimension1: uniformData(prodotto?.attributi?.annata) || '',
    dimension2: uniformData(prodotto?.attributi?.denominazione) || '',
    dimension3: uniformData(prodotto?.attributi?.regione) || '',
    dimension4: uniformData(prodotto?.attributi?.vitigno) || '',
    dimension5: uniformData(prodotto?.attributi?.gradazione) || '',
    dimension6: uniformData(prodotto?.attributi?.formato) || '',
    dimension7: prodotto?.attributi?.abbinamento || '',
  })

  sendGTMData({
    event: 'productDetail',
    ecommerce: {
      currencyCode: 'EUR',
      detail: {
        actionField: {
          list: prodotto?.promoApplicata ? "Category Offerte" : "Category Listing",
        },
        products: product,
      },
    },
  })
}

export const trackingProductClick = (prodotto, sezione, posizione) => {
  const products = []
  products.push({
    name: uniformData(prodotto?.nome) || '',
    id: prodotto?.varianteProdotto?.sku || '',
    price: prodotto?.omaggio ? 0 : (prodotto?.prezzoScontato?.toFixed(2) || ''),
    brand: prodotto?.cantina?.nome || '',
    category: uniformData(prodotto?.categoria?.alias) || '',
    list: sezione,
    position: posizione,
    dimension1: uniformData(prodotto?.annata) || '',
    dimension2: uniformData(prodotto?.denominazione) || '',
    dimension3: uniformData(prodotto?.regione) || '',
    dimension4: uniformData(prodotto?.vitigno) || '',
    dimension5: uniformData(prodotto?.gradazione) || '',
    dimension6: uniformData(prodotto?.formato) || '',
    dimension7: prodotto?.abbinamenti || '',
  })

  sendGTMData({
    event: 'productClick',
    ecommerce: {
      currencyCode: 'EUR',
      click: {
        actionField: {
          list: sezione
        },
        products,
      },
    },
  })
}

export const trackingProductImpression = (prodotti, sezione, startIndex = 0) => {
  const products = []
  for (let i = 0; prodotti && i < prodotti.length; i+=1) {
    const prodotto = prodotti[i]
    products.push({
      name: uniformData(prodotto?.nome) || '',
      id: prodotto?.varianteProdotto?.sku || '',
      price: prodotto?.omaggio ? 0 : (prodotto?.prezzoScontato?.toFixed(2) || ''),
      brand: prodotto?.cantina?.nome || '',
      category: uniformData(prodotto?.categoria?.alias) || '',
      list: sezione,
      position: i + 1 + startIndex, // per infinite scroll
      dimension1: uniformData(prodotto?.annata) || '',
      dimension2: uniformData(prodotto?.denominazione) || '',
      dimension3: uniformData(prodotto?.regione) || '',
      dimension4: uniformData(prodotto?.vitigno) || '',
      dimension5: uniformData(prodotto?.gradazione) || '',
      dimension6: uniformData(prodotto?.formato) || '',
      dimension7: prodotto?.abbinamenti || '',
    })
  }

  if (products.length > 0)
    sendGTMData({
      event: 'productsImpression',
      ecommerce: {
        currencyCode: 'EUR',
        impressions: products,
      },
    })
}

// export const trackingPromoView = (promotions) => {
//   let promo = []
//   for (let i = 0; promotions && i < promotions.length; i++) {
//     let promotion = promotions[i]
//     promo.push({
//       id: ('000' + i).slice(-4),
//       name: uniformData(promotion?.nome),
//       creative: 'slider',
//       position: i + 1,
//     })
//   }
//   sendGTMData({
//     event: 'promoView',
//     ecommerce: {
//       promoView: {
//         promotions: promo,
//       },
//     },
//   })
// }

// export const trackingPromoClick = (promotion, position) => {
//   let promo = []
//   promo.push({
//     id: ('000' + position).slice(-4),
//     name: uniformData(promotion?.nome),
//     creative: 'slider',
//     position: position + 1,
//   })
//   sendGTMData({
//     event: 'promoClick',
//     ecommerce: {
//       promoClick: {
//         promotions: promo,
//       },
//     },
//   })
// }

export const trackingAddRemoveFromCart = (item, quantita) => {
  const product = []
  if(item?.prodotto){
    const prodotto = item?.prodotto
    product.push({
      name: uniformData(prodotto?.prodotto?.nome) || '',
      id: prodotto?.sku || '',
      price: item?.omaggio ? 0 : (prodotto?.prodotto?.prezzoScontato?.toFixed(2) || ''),
      brand: prodotto?.prodotto?.cantina?.nome || '',
      category: uniformData(prodotto?.prodotto?.categoria?.alias) || '',
      quantity: Math.abs(quantita),
      dimension1: uniformData(prodotto?.prodotto?.attributi?.annata) || '',
      dimension2: uniformData(prodotto?.prodotto?.attributi?.denominazione) || '',
      dimension3: uniformData(prodotto?.prodotto?.attributi?.regione) || '',
      dimension4: uniformData(prodotto?.prodotto?.attributi?.vitigno) || '',
      dimension5: uniformData(prodotto?.prodotto?.attributi?.gradazione) || '',
      dimension6: uniformData(prodotto?.prodotto?.attributi?.formato) || '',
      dimension7: prodotto?.prodotto?.attributi?.abbinamento || '',
    })
  } else {
    const prodotto = item
    product.push({
      name: uniformData(prodotto?.nome) || '',
      id: prodotto?.varianteProdotto?.sku || '',
      price: prodotto?.omaggio ? 0 : (prodotto?.prezzoScontato?.toFixed(2) || ''),
      brand: prodotto?.cantina?.nome  || '',
      category: uniformData(prodotto?.categoria?.alias) || '',
      quantity: Math.abs(quantita),
      dimension1: uniformData(prodotto?.annata) || '',
      dimension2: uniformData(prodotto?.denominazione) || '',
      dimension3: uniformData(prodotto?.regione) || '',
      dimension4: uniformData(prodotto?.vitigno) || '',
      dimension5: uniformData(prodotto?.gradazione) || '',
      dimension6: uniformData(prodotto?.formato) || '',
      dimension7: prodotto?.abbinamenti || '',
    })
  }

  if (quantita > 0)
    sendGTMData({
      event: 'addToCart',
      ecommerce: {
        currencyCode: 'EUR',
        add: {
          products: product,
        },
      },
    })
  else
    sendGTMData({
      event: 'removeFromCart',
      ecommerce: {
        currencyCode: 'EUR',
        remove: {
          products: product,
        },
      },
    })
}

export const trackingAddToCartListing = (prodotto) => {
  const product = []

  product.push({
    name: uniformData(prodotto?.nome) || '',
    id: prodotto?.varianteProdotto?.sku || '',
    price: prodotto?.omaggio ? 0 : (prodotto?.prezzoScontato?.toFixed(2) || ''),
    brand: prodotto?.cantina?.nome || '',
    category: uniformData(prodotto?.categoria?.alias) || '',
    quantity: 1,
    dimension1: uniformData(prodotto?.annata) || '',
    dimension2: uniformData(prodotto?.denominazione) || '',
    dimension3: uniformData(prodotto?.regione) || '',
    dimension4: uniformData(prodotto?.vitigno) || '',
    dimension5: uniformData(prodotto?.gradazione) || '',
    dimension6: uniformData(prodotto?.formato) || '',
    dimension7: prodotto?.abbinamenti || '',
  })


  sendGTMData({
    event: 'addToCart-listing',
    ecommerce: {
      currencyCode: 'EUR',
      add: {
        products: product,
      },
    },
  })
}

export const trackingRemoveProductFromCart = (item) => {
  const product = []
  const prodotto = item?.prodotto

  product.push({
      name: uniformData(prodotto?.prodotto?.nome) || '',
      id: prodotto?.sku || '',
      price: item?.omaggio ? 0 : (prodotto?.prodotto?.prezzoScontato?.toFixed(2) || ''),
      brand: prodotto?.prodotto?.cantina?.nome || '',
      category: uniformData(prodotto?.prodotto?.categoria?.alias) || '',
      quantity: item?.quantita || '',
      dimension1: uniformData(prodotto?.prodotto?.attributi?.annata) || '',
      dimension2: uniformData(prodotto?.prodotto?.attributi?.denominazione) || '',
      dimension3: uniformData(prodotto?.prodotto?.attributi?.regione) || '',
      dimension4: uniformData(prodotto?.prodotto?.attributi?.vitigno) || '',
      dimension5: uniformData(prodotto?.prodotto?.attributi?.gradazione) || '',
      dimension6: uniformData(prodotto?.prodotto?.attributi?.formato) || '',
      dimension7: prodotto?.prodotto?.attributi?.abbinamento || '',
  })


  sendGTMData({
    event: 'removeFromCart',
    ecommerce: {
      remove: {
        products: product,
      },
    },
  })
}

export const trackingCheckout = (cart, step, value) => {
  const products = []
  let checkoutOpt = ''
  switch (step) {
    case 1:
      break
    case 2:
      break
    case 3:
      checkoutOpt = uniformData(value) || ''
      break
    default:
      checkoutOpt = ''
  }


  for (let i = 0; cart && i < cart?.items.length; i+=1) {
    const prodotto = cart.items[i]?.prodotto
    products.push({
      name: uniformData(prodotto?.prodotto?.nome) || '',
      id: prodotto?.sku || '',
      price: cart.items[i]?.omaggio ? 0 : (prodotto?.prodotto?.prezzoScontato?.toFixed(2) || ''),
      brand: prodotto?.prodotto?.cantina?.nome || '',
      category: uniformData(prodotto?.prodotto?.categoria?.alias) || '',
      quantity: cart.items[i]?.quantita || '',
      dimension1: uniformData(prodotto?.prodotto?.attributi?.annata) || '',
      dimension2: uniformData(prodotto?.prodotto?.attributi?.denominazione) || '',
      dimension3: uniformData(prodotto?.prodotto?.attributi?.regione) || '',
      dimension4: uniformData(prodotto?.prodotto?.attributi?.vitigno) || '',
      dimension5: uniformData(prodotto?.prodotto?.attributi?.gradazione) || '',
      dimension6: uniformData(prodotto?.prodotto?.attributi?.formato) || '',
      dimension7: prodotto?.prodotto?.attributi?.abbinamento || '',
    })
  }

  if (cart?.items.length > 0)
    sendGTMData({
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: {
            step: step,
            option: checkoutOpt,
          },
          products: products,
        },
      },
    })
}

export const trackingPurchase = (ordine) => {
  const products = []
  const righeProdotti = ordine?.righeOrdine?.filter((riga) => riga.tipologia?.toLowerCase() === 'prodotto')
  for (let i = 0; righeProdotti && i < righeProdotti.length; i+=1) {
    const prodotto = righeProdotti[i]?.prodotto
    products.push({
      name: uniformData(prodotto?.prodotto?.nome) || '',
      id: prodotto?.sku || '',
      price: righeProdotti[i]?.omaggio ? 0 : (righeProdotti[i]?.prezzoUnitarioScontato?.toFixed(2) || ''),
      brand: prodotto?.prodotto?.cantina?.nome || '',
      category: uniformData(prodotto?.prodotto?.categoria?.alias) || '',
      quantity: righeProdotti[i]?.quantitaOrdinata || '',
      dimension1: uniformData(prodotto?.prodotto?.attributi?.annata) || '',
      dimension2: uniformData(prodotto?.prodotto?.attributi?.denominazione) || '',
      dimension3: uniformData(prodotto?.prodotto?.attributi?.regione) || '',
      dimension4: uniformData(prodotto?.prodotto?.attributi?.vitigno) || '',
      dimension5: uniformData(prodotto?.prodotto?.attributi?.gradazione) || '',
      dimension6: uniformData(prodotto?.prodotto?.attributi?.formato) || '',
      dimension7: prodotto?.prodotto?.attributi?.abbinamento || '',
    })
  }

  const spedizione = ordine?.righeOrdine.find(
    (riga) => riga.tipologia?.toLowerCase() === 'spedizione'
  )
  const contrassegno = ordine?.righeOrdine.find(
    (riga) => riga.tipologia?.toLowerCase() === 'pagamento'
  )

  const speseSpedizione = spedizione ? spedizione.prezzoTotaleScontato : 0
  const speseContrassegno = contrassegno ? contrassegno.prezzoTotaleScontato : 0
  sendGTMData({
    event: 'purchase',
    dayToPurchase: ordine?.deltaGiorniAcquisto,
    ecommerce: {
      currencyCode: 'EUR',
      purchase: {
        actionField: {
          id: "it_italy-" + ordine?.codice,
          affiliation: "",
          revenue: ordine?.prezzoTotaleScontato?.toFixed(2) || '',
          tax: "",
          shipping: speseSpedizione + speseContrassegno,
          coupon: ordine?.promozione ? ordine.promozione?.codice : '',
        },
        products: products,
      },
    },
  })
}

export const trackingGAevent = (id, category, action, label, value) => {
  const data = {
    event: 'GAevent',
    eventID: id,
    eventCategory: uniformData(category),
    eventAction: uniformData(action),
    eventLabel: uniformData(label),
  }
  if (value) data.eventValue = uniformData(value)

  sendGTMData(data)
}

export const trackingPromoCodeGAevent = (id, category, action, label, value) => {
  const data = {
    event: 'GAevent',
    eventID: id,
    eventCategory: uniformData(category),
    eventAction: uniformData(action),
    eventLabel: label,
  }
  if (value) data.eventValue = uniformData(value)

  sendGTMData(data)
}

export const updateGTMDataLayer = (paginaCorrente, utente, router, prodotto=null) => {
  const gtmProps = getGTMProps(paginaCorrente, router, prodotto)
  const utenteProps = getUtenteProps(utente)

  sendGTMData({
    event: 'wSetup',
    ...gtmProps,
    ...utenteProps,
  })

  sendGTMData({
    event: 'virtual_pageview',
    pagePath: router.asPath,
  })
}

//Function per ATT App
function eventATT(response) {
  window.dataLayer.push({'event': 'ATTClosed','attResponse': response,}); 
}