import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function WineRegione({ className }: Props) {
  return (
    <IconBase name="wine-regione" w={40} className={className}>
      <g clipPath="url(#regione-clip0)">
      <path d="M31.0552 31.4668C26.9084 31.4668 23.5327 34.8425 23.5327 38.9893C23.5327 39.3034 23.7897 39.5605 24.1039 39.5605C24.418 39.5605 24.6751 39.3034 24.6751 38.9893C24.6751 35.4708 27.5367 32.6092 31.0552 32.6092C32.3975 32.6092 33.6769 33.0204 34.7622 33.7972C35.0192 33.98 35.3791 33.9229 35.5561 33.6659C35.7389 33.4088 35.6818 33.0547 35.4248 32.8719C34.1453 31.9523 32.6317 31.4668 31.0552 31.4668Z" stroke="none"/>
      <path d="M31.0549 27.7485C24.8575 27.7485 19.814 32.7921 19.814 38.9894C19.814 39.3036 20.071 39.5606 20.3851 39.5606C20.6993 39.5606 20.9563 39.3036 20.9563 38.9894C20.9563 33.4204 25.4858 28.8909 31.0549 28.8909C33.1911 28.8909 35.2302 29.5478 36.9552 30.7929C37.2122 30.9757 37.5664 30.9186 37.7549 30.6616C37.9376 30.4045 37.8805 30.0504 37.6235 29.8619C35.6986 28.4796 33.431 27.7485 31.0549 27.7485Z" stroke="none"/>
      <path d="M39.76 26.8344C37.2125 25.0067 34.1967 24.0356 31.0494 24.0356C22.8073 24.0356 16.1016 30.7413 16.1016 38.9835C16.1016 39.2977 16.3586 39.5547 16.6727 39.5547C16.9869 39.5547 17.2439 39.2977 17.2439 38.9835C17.2439 31.3696 23.4356 25.178 31.0494 25.178C33.9568 25.178 36.7384 26.0691 39.0917 27.7598C39.3487 27.9425 39.7029 27.8854 39.8914 27.6284C40.0741 27.3771 40.017 27.0172 39.76 26.8344Z" stroke="none"/>
      <path d="M2.71883 24.4412C2.49607 24.6639 2.49607 25.0238 2.71883 25.2465C2.94159 25.4693 3.30144 25.4693 3.5242 25.2465C6.01456 22.7619 10.0585 22.7619 12.5432 25.2465C13.4914 26.1947 14.1082 27.3942 14.3253 28.7079C14.371 28.9878 14.6109 29.1877 14.8908 29.1877C14.9193 29.1877 14.9536 29.1877 14.9821 29.182C15.2906 29.1306 15.5019 28.8393 15.4505 28.5251C15.1935 26.9715 14.4681 25.555 13.3486 24.4412C10.4241 21.5053 5.649 21.5053 2.71883 24.4412Z" stroke="none"/>
      <path d="M15.1764 22.6135C16.1359 23.5731 16.8785 24.6869 17.3811 25.9264C17.4725 26.1491 17.6839 26.2805 17.9123 26.2805C17.9866 26.2805 18.0551 26.2691 18.1294 26.2405C18.4207 26.1206 18.5635 25.7893 18.4435 25.498C17.8781 24.1157 17.0498 22.8762 15.9874 21.8081C12.1776 17.9983 6.18592 17.4271 1.7364 20.4487C1.47366 20.6258 1.40511 20.9799 1.58218 21.2426C1.75925 21.5054 2.11338 21.5739 2.37613 21.3969C6.37441 18.6837 11.755 19.1921 15.1764 22.6135Z" stroke="none"/>
      <path d="M17.7979 19.9918C18.7175 20.9114 19.5 21.951 20.1283 23.0819C20.2311 23.2704 20.4253 23.379 20.631 23.379C20.7224 23.379 20.8195 23.3561 20.9051 23.3104C21.1793 23.1562 21.2821 22.8078 21.1279 22.5336C20.4539 21.3113 19.6028 20.186 18.609 19.1865C13.7197 14.2971 6.18002 13.3832 0.273983 16.976C0.00552633 17.1416 -0.0801512 17.4901 0.0854921 17.7585C0.251135 18.027 0.599557 18.1126 0.868014 17.947C6.31711 14.6341 13.2798 15.4738 17.7979 19.9918Z" stroke="none"/>
      <path d="M28.3703 14.7997C30.735 14.7997 32.6542 12.8748 32.6542 10.5158C32.6542 8.15682 30.7293 6.23193 28.3703 6.23193C26.0113 6.23193 24.0864 8.15682 24.0864 10.5158C24.0864 12.8748 26.0056 14.7997 28.3703 14.7997ZM28.3703 7.3743C30.101 7.3743 31.5118 8.78513 31.5118 10.5158C31.5118 12.2465 30.101 13.6573 28.3703 13.6573C26.6396 13.6573 25.2288 12.2465 25.2288 10.5158C25.2288 8.78513 26.6396 7.3743 28.3703 7.3743Z" stroke="none"/>
      <path d="M27.7993 16.1366V19.4666C27.7993 19.7808 28.0563 20.0378 28.3705 20.0378C28.6847 20.0378 28.9417 19.7808 28.9417 19.4666V16.1366C28.9417 15.8225 28.6847 15.5654 28.3705 15.5654C28.0563 15.5654 27.7993 15.8225 27.7993 16.1366Z" stroke="none"/>
      <path d="M28.3705 5.47237C28.6847 5.47237 28.9417 5.21534 28.9417 4.90119V1.57118C28.9417 1.25703 28.6847 1 28.3705 1C28.0563 1 27.7993 1.25703 27.7993 1.57118V4.90119C27.7993 5.21534 28.0563 5.47237 28.3705 5.47237Z" stroke="none"/>
      <path d="M33.4199 10.516C33.4199 10.8302 33.677 11.0872 33.9911 11.0872H37.3211C37.6352 11.0872 37.8923 10.8302 37.8923 10.516C37.8923 10.2019 37.6352 9.94482 37.3211 9.94482H33.9911C33.6712 9.94482 33.4199 10.2019 33.4199 10.516Z" stroke="none"/>
      <path d="M19.4257 11.0872H22.7557C23.0698 11.0872 23.3269 10.8302 23.3269 10.516C23.3269 10.2019 23.0698 9.94482 22.7557 9.94482H19.4257C19.1115 9.94482 18.8545 10.2019 18.8545 10.516C18.8545 10.8302 19.1115 11.0872 19.4257 11.0872Z" stroke="none"/>
      <path d="M32.3633 7.09449C32.5118 7.09449 32.6546 7.03737 32.7688 6.92885L35.0993 4.59842C35.322 4.37566 35.322 4.01581 35.0993 3.79305C34.8765 3.57029 34.5167 3.57029 34.2939 3.79305L31.9635 6.12348C31.7407 6.34624 31.7407 6.70608 31.9635 6.92885C32.072 7.03737 32.2148 7.09449 32.3633 7.09449Z" stroke="none"/>
      <path d="M23.995 14.086L21.6417 16.4393C21.4189 16.6621 21.4189 17.0219 21.6417 17.2447C21.7559 17.3589 21.8987 17.4103 22.0472 17.4103C22.1957 17.4103 22.3385 17.3532 22.4528 17.2447L24.806 14.8914C25.0288 14.6686 25.0288 14.3088 24.806 14.086C24.5833 13.8633 24.2177 13.8633 23.995 14.086Z" stroke="none"/>
      <path d="M31.9635 14.1085C31.7407 14.3312 31.7407 14.6911 31.9635 14.9138L34.2939 17.2443C34.4081 17.3585 34.5509 17.4099 34.6994 17.4099C34.8479 17.4099 34.9907 17.3528 35.105 17.2443C35.3277 17.0215 35.3277 16.6617 35.105 16.4389L32.7745 14.1085C32.5461 13.8857 32.1862 13.8857 31.9635 14.1085Z" stroke="none"/>
      <path d="M24.0007 6.95726C24.1149 7.0715 24.2577 7.1229 24.4062 7.1229C24.5547 7.1229 24.6975 7.06578 24.8117 6.95726C25.0345 6.7345 25.0345 6.37465 24.8117 6.15189L22.447 3.78719C22.2243 3.56443 21.8644 3.56443 21.6417 3.78719C21.4189 4.00995 21.4189 4.3698 21.6417 4.59256L24.0007 6.95726Z" stroke="none"/>
      </g>
      <defs>
      <clipPath id="regione-clip0">
      <rect width="40" height="38.5606" transform="translate(0 1)" stroke="none"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default WineRegione
