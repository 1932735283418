import React, { useState, useEffect } from 'react'
import { useNode, useEditor } from '@craftjs/core'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'
import { FormSelect } from 'react-pagebuilder/pb-components/atoms'
import { useSetProdottiQuery } from 'gql/graphql'
import { defaultProps, dynamicProps } from './index'

export const ProductCardBlockSettings = () => {
  const {
    props,
    customs,
    actions: { setProp },
  } = useNode((node) => ({
    props: node.data.props,
    parentId: node.data.parent,
    customs: node.data.custom,
  }))

  const { data, loading, error, refetch } = useSetProdottiQuery({
    variables: {
      chiave: "pagebuilder"
    },
  })

  return (
    <>
      <ToolbarSection title="Settings" initOpen>
        {/* <ToolbarItem label={"Prodotto"} type="other">
          {data ? (
            <FormSelect
              value={props.block.slug}
              onChange={(e) =>
                setProp((props) => {
                  const value = e.target.value
                  props.block.slug = value
                }, 1000)
              }
              options={data.setProdotti.map((val) => ({ value: val.slug, label: val.nome }))}
            />
          ) : (
            <div className="empty-message">
              No products found
            </div>
          )}
        </ToolbarItem> */}
        <ToolbarItem
          propKey="block"
          propSubKey="slug"
          type="select"
          label="Prodotto"
          values={data?.setProdotti ? data.setProdotti.map((val) => val.slug) : []}
          nullable={true}
          dynamic={dynamicProps.includes('slug') && customs.dynamicData}
          dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
          defaultValue={defaultProps['slug']}
        />
      </ToolbarSection>
    </>
  )
}
