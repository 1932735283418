import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function PremioCalice({ className }: Props) {
  return (
    <IconBase name="premio-calice" w={20} className={className}>
      <g clip-path="url(#premiocalice-clip0)">
      <path d="M11.6083 11.5122C12.7476 10.7815 13.8005 9.92441 14.7471 8.95715C16.9194 6.7849 15.4265 2.67963 14.6131 0.880591C14.4984 0.611492 14.308 0.381467 14.0651 0.218407C13.8222 0.0553462 13.5372 -0.0337468 13.2447 -0.0380695H6.86194C6.57312 -0.0394245 6.29012 0.0430272 6.04723 0.199291C5.80434 0.355556 5.612 0.578922 5.49351 0.842313C4.68012 2.64136 3.18729 6.74662 5.35954 8.91887C6.30194 9.90183 7.35524 10.7721 8.4983 11.5122C8.75487 11.6722 9.0362 11.7886 9.33084 11.8567V18.5552H6.80452C6.66576 18.5627 6.5353 18.6237 6.44068 18.7254C6.34606 18.8272 6.29466 18.9618 6.29734 19.1007V19.4548C6.29732 19.5978 6.35347 19.7351 6.4537 19.8371C6.55394 19.9391 6.69023 19.9977 6.83323 20.0002H13.3308C13.4738 19.9977 13.6101 19.9391 13.7104 19.8371C13.8106 19.7351 13.8667 19.5978 13.8667 19.4548V19.1007C13.8667 18.9586 13.8103 18.8223 13.7098 18.7218C13.6093 18.6213 13.473 18.5648 13.3308 18.5648H10.7662V11.8662C11.0645 11.7952 11.349 11.6756 11.6083 11.5122Z" stroke="none"/>
      </g>
      <defs>
      <clipPath id="premiocalice-clip0">
      <rect width="11.6364" height="20" transform="translate(4.23047)" stroke="none"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default PremioCalice
