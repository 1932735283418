import React from 'react'
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useTrans } from 'hooks'
import { ProdottoCard } from 'components/molecules'

SwiperCore.use([Pagination])

interface Props {
  prodotti?: any[]
  sidebar?: boolean
  desktopColumn?: boolean
  title?: string
  className?: string
}

const ProdottiCorrelati = (props: Props) => {
  const {
    prodotti = [],
    sidebar = false,
    desktopColumn = false,
    title = '',
    className = '',
  } = props

  const t = useTrans()

  return (
    <div
      className={`prodotti-correlati${desktopColumn ? ' prodotti-correlati--desktop-column' : ''}${
        sidebar ? ' prodotti-correlati--sidebar' : ''
      } ${className ? ` ${className}` : ''}`}
    >
      <p className="prodotti-correlati__title">{title || t('Lasciati ispirare')}</p>
      {desktopColumn && (
        <div className="prodotti-correlati__vertical-list">
          {prodotti.map((prodotto) => (
            <div
              className="prodotti-correlati__vertical-list__item"
              key={`prodotto-correlato__vertical-list__${prodotto.pk}`}
            >
              <ProdottoCard prodotto={prodotto} />
            </div>
          ))}
        </div>
      )}
      <div className="prodotti-correlati__list">
        <Swiper
          spaceBetween={16}
          slidesPerView={1}
          pagination={{ clickable: true }}
          watchOverflow
          breakpoints={
            sidebar
              ? {
                  0: {
                    slidesPerView: 'auto',
                  },
                  1300: {
                    slidesPerView: 4,
                  },
                }
              : {
                  0: {
                    slidesPerView: 'auto',
                  },
                  991: {
                    slidesPerView: 4,
                  },
                }
          }
        >
          {prodotti.map((prodotto) => (
            <SwiperSlide key={`prodotto-correlato__${prodotto.pk}`}>
              <ProdottoCard prodotto={prodotto} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default ProdottiCorrelati
