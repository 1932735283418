import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function List({ className }: Props) {
  return (
    <IconBase name="list" w={24} className={className}>
      <line x1="7" y1="12" x2="21" y2="12" strokeWidth="2" fill="none"/>
      <line x1="7" y1="5" x2="21" y2="5" strokeWidth="2" fill="none"/>
      <line x1="7" y1="19" x2="21" y2="19" strokeWidth="2" fill="none"/>
      <line x1="3" y1="12" x2="5" y2="12" strokeWidth="2" strokeLinejoin="round" fill="none"/>
      <line x1="3" y1="5" x2="5" y2="5" strokeWidth="2" strokeLinejoin="round" fill="none"/>
      <line x1="3" y1="19" x2="5" y2="19" strokeWidth="2" strokeLinejoin="round" fill="none"/>
    </IconBase>
  )
}

export default List
