import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { useTrans } from 'hooks'
import { getFormFieldStatus } from 'utils/safe'
import { UPDATE_INDIRIZZO, CREATE_INDIRIZZO, DELETE_INDIRIZZO } from 'utils/queries'
import { gql, useMutation } from '@apollo/client'
import { updateIndirizzoInput, createIndirizzoInput, privatoToTipo } from 'utils/indirizzo'
import { Button, FormField, FormInput, FormRadio, FormLayout, FormSelect } from 'components/atoms'
import { Bin } from 'components/atoms/Icons'
import { province } from 'utils/province'

interface Props {
  titolo?: string
  indirizzo?: {
    pk?: number
    privato?: string
    nome?: string
    cognome?: string
    indirizzo?: string
    citta?: string
    cap?: string
    provincia?: string
    telefono?: string
    cf?: string
    ragione_sociale?: string
    piva?: string
    codiceDestinatarioUnivoco?: string
    pec?: string
  }
  afterSubmit: () => void
  onCancel: (e: any) => void
  className?: string
}

const MyAccountFormIndirizzoFatturazione = (props: Props) => {
  const {
    titolo = '',
    indirizzo = null,
    afterSubmit = () => {},
    onCancel = () => {},
    className = '',
  } = props

  const [updateIndirizzo] = useMutation(
    gql`
      ${UPDATE_INDIRIZZO}
    `
  )
  const [createIndirizzo] = useMutation(
    gql`
      ${CREATE_INDIRIZZO}
    `
  )
  const [deleteIndirizzo] = useMutation(
    gql`
      ${DELETE_INDIRIZZO}
    `
  )
  const [type, setType] = useState(privatoToTipo(indirizzo?.privato) || '')
  const t = useTrans()

  const handleChangeTipo = (e, setFieldValue) => {
    setType(e.target.value)
    setFieldValue('tipo', e.target.value)
  }

  function handleUpdateSubmit(values) {
    updateIndirizzo({
      variables: {
        input: updateIndirizzoInput(indirizzo.pk, values),
      },
      refetchQueries: ['IndirizziFatturazione'],
    }).then((res) => {
      afterSubmit()
    })
  }

  function handleCreateSubmit(values) {
    createIndirizzo({
      variables: {
        input: createIndirizzoInput(values),
      },
      refetchQueries: ['IndirizziFatturazione'],
    }).then((res) => {
      afterSubmit()
    })
  }

  function handleDelete() {
    deleteIndirizzo({
      variables: {
        input: {
          pk: indirizzo.pk,
        },
      },
      refetchQueries: ['IndirizziFatturazione'],
    }).then((res) => {
      afterSubmit()
    })
  }

  const typeOptions = [
    { id: '1', label: t('Privato'), value: '1' },
    { id: '2', label: t('Azienda'), value: '2' },
    { id: '3', label: t('Libero professionista'), value: '3' },
  ]

  const validationSchema = yup.object().shape({
    tipo: yup.string().required(t('Campo obbligatorio')),
    nome:
      type === '2'
        ? yup.string().nullable()
        : yup.string().required(t('Campo obbligatorio')).nullable(), // Obbligatorio per tutti tranne azienda
    cognome:
      type === '2'
        ? yup.string().nullable()
        : yup.string().required(t('Campo obbligatorio')).nullable(), // Obbligatorio per tutti tranne azienda
    indirizzo: yup.string().required(t('Campo obbligatorio')),
    citta: yup.string().required(t('Campo obbligatorio')),
    cap: yup.string().max(10, t('Troppo lungo')).required(t('Campo obbligatorio')),
    provincia: yup.string().max(10, t('Troppo lungo')).required(t('Campo obbligatorio')).nullable(),
    // telefono: yup
    //   .string()
    //   .required('Campo obbligatorio')
    //   .matches(/^\d+$/gi, 'Non è stato inserito un numero di telefono valido')
    //   .min(6, 'Numero troppo corto')
    //   .test(
    //     'validate',
    //     'Non è stato inserito un numero di telefono valido',
    //     (val) => Number.isInteger(Number.parseFloat(val)) && Number.parseFloat(val) >= 0
    //   )
    //   .nullable(),
    cf: yup.string().required(t('Campo obbligatorio')),
    ragione_sociale:
      type === '2'
        ? yup.string().required(t('Campo obbligatorio')).nullable()
        : yup.string().nullable(), // Obbligatorio solo per azienda
    piva:
      type === '1'
        ? yup.string().nullable()
        : yup.string().required(t('Campo obbligatorio')).nullable(), // Obbligatorio per tutti tranne privato
    codice_destinatario_sdi: yup.string().nullable(),
    pec: yup.string().nullable(),
  })

  return (
    <div className={`myaccount-form-indirizzo-fatturazione ${className ? ` ${className}` : ''}`}>
      {!!titolo && <p className="myaccount-form-indirizzo-fatturazione__title">{titolo}</p>}
      <Formik
        validateOnBlur
        validateOnChange
        validationSchema={validationSchema}
        onSubmit={(values) => {
          if (indirizzo) {handleDelete();handleCreateSubmit(values)}
          else handleCreateSubmit(values)
        }}
        initialValues={{
          tipo: privatoToTipo(indirizzo?.privato),
          nome: indirizzo?.nome,
          cognome: indirizzo?.cognome,
          indirizzo: indirizzo?.indirizzo,
          citta: indirizzo?.citta,
          cap: indirizzo?.cap,
          provincia: indirizzo?.provincia,
          telefono: indirizzo?.telefono,
          cf: indirizzo?.cf,
          ragione_sociale: indirizzo?.nome,
          piva: indirizzo?.piva,
          codice_destinatario_sdi: indirizzo?.codiceDestinatarioUnivoco,
          pec: indirizzo?.pec,
          save_address: true,
          is_fatturazione: true,
        }}
      >
        {({ values, touched, errors, handleBlur, handleChange, setFieldValue }) => (
          <Form>
            <FormLayout>
              <FormField
                layout="full"
                label={t('Cosa sei?')}
                status={getFormFieldStatus({ touched, errors }, 'tipo')}
                errorMessage={errors?.tipo}
              >
                <FormRadio
                  id={`checkout-billing-address-form${
                    indirizzo?.pk ? `__${indirizzo.pk}` : ''
                  }__type`}
                  value={values?.tipo}
                  options={typeOptions}
                  onChange={(e) => handleChangeTipo(e, setFieldValue)}
                />
              </FormField>
              {type !== '' && (
                <>
                  {/* Azienda con Ragione sociale, altri con Nome e Cognome */}
                  {type === '2' ? (
                    <FormField
                      layout="full"
                      label={`${t('Ragione sociale')}*`}
                      status={getFormFieldStatus({ touched, errors }, 'ragione_sociale')}
                      errorMessage={errors?.ragione_sociale}
                    >
                      <FormInput
                        placeholder={t('Ragione sociale')}
                        value={values?.ragione_sociale}
                        onBlur={handleBlur('ragione_sociale')}
                        onChange={handleChange('ragione_sociale')}
                      />
                    </FormField>
                  ) : (
                    <>
                      <FormField
                        label={`${t('Nome')}*`}
                        status={getFormFieldStatus({ touched, errors }, 'nome')}
                        errorMessage={errors?.nome}
                      >
                        <FormInput
                          placeholder={t('Nome')}
                          value={values?.nome}
                          onBlur={handleBlur('nome')}
                          onChange={handleChange('nome')}
                        />
                      </FormField>
                      <FormField
                        label={`${t('Cognome')}*`}
                        status={getFormFieldStatus({ touched, errors }, 'cognome')}
                        errorMessage={errors?.cognome}
                      >
                        <FormInput
                          placeholder={t('Cognome')}
                          value={values?.cognome}
                          onBlur={handleBlur('cognome')}
                          onChange={handleChange('cognome')}
                        />
                      </FormField>
                    </>
                  )}

                  <FormField
                    layout="full"
                    label={`${t('Indirizzo')}*`}
                    status={getFormFieldStatus({ touched, errors }, 'indirizzo')}
                    errorMessage={errors?.indirizzo}
                  >
                    <FormInput
                      placeholder={t('Indirizzo')}
                      value={values?.indirizzo}
                      onBlur={handleBlur('indirizzo')}
                      onChange={handleChange('indirizzo')}
                    />
                  </FormField>
                  <FormField
                    layout={type !== '1' ? "sm" : "md"}
                    label={`${t('CAP')}*`}
                    status={getFormFieldStatus({ touched, errors }, 'cap')}
                    errorMessage={errors?.cap}
                  >
                    <FormInput
                      placeholder={t('CAP')}
                      value={values?.cap}
                      onBlur={handleBlur('cap')}
                      onChange={handleChange('cap')}
                    />
                  </FormField>
                  {type !== '1' &&
                  <FormField
                    layout="sm"
                    label={`${t('Provincia')}*`}
                    status={getFormFieldStatus({ touched, errors }, 'provincia')}
                    errorMessage={errors?.provincia}
                  >
                    <FormSelect
                      options={province?.map((item) => ({
                        value: item.chiave,
                        label: item.nome,
                      }))}
                      value={values?.provincia}
                      placeholder={t('Provincia')}
                      onBlur={handleBlur('provincia')}
                      onChange={(selectedOption) => setFieldValue('provincia', selectedOption.value, true)}
                    />
                  </FormField>
                  }
                  <FormField
                    label={`${t('Città')}*`}
                    status={getFormFieldStatus({ touched, errors }, 'citta')}
                    errorMessage={errors?.citta}
                  >
                    <FormInput
                      placeholder={t('Città')}
                      value={values?.citta}
                      onBlur={handleBlur('citta')}
                      onChange={handleChange('citta')}
                    />
                  </FormField>
                  {type === '1' && (
                    <FormField
                      label={`${t('Provincia')}*`}
                      status={getFormFieldStatus({ touched, errors }, 'provincia')}
                      errorMessage={errors?.provincia}
                    >
                      <FormSelect
                        options={province?.map((item) => ({
                          value: item.chiave,
                          label: item.nome,
                        }))}
                        value={values?.provincia}
                        placeholder={t('Provincia')}
                        onBlur={handleBlur('provincia')}
                        onChange={(selectedOption) => setFieldValue('provincia', selectedOption.value, true)}
                      />
                    </FormField>
                  )}
                  <FormField
                    label={`${t('Codice fiscale')}*`}
                    status={getFormFieldStatus({ touched, errors }, 'cf')}
                    errorMessage={errors?.cf}
                  >
                    <FormInput
                      placeholder={t('Codice fiscale')}
                      value={values?.cf}
                      onBlur={handleBlur('cf')}
                      onChange={handleChange('cf')}
                    />
                  </FormField>
                  {type !== '1' && (
                    <>
                      <FormField
                        label={`${t('Partita IVA')}*`}
                        status={getFormFieldStatus({ touched, errors }, 'piva')}
                        errorMessage={errors?.piva}
                      >
                        <FormInput
                          placeholder={t('Partita IVA')}
                          value={values?.piva}
                          onBlur={handleBlur('piva')}
                          onChange={handleChange('piva')}
                        />
                      </FormField>
                      <FormField
                        label={t('Codice destinatario SDI')}
                        status={getFormFieldStatus({ touched, errors }, 'codice_destinatario_sdi')}
                        errorMessage={errors?.codice_destinatario_sdi}
                      >
                        <FormInput
                          placeholder={t('Codice destinatario SDI')}
                          value={values?.codice_destinatario_sdi}
                          onBlur={handleBlur('codice_destinatario_sdi')}
                          onChange={handleChange('codice_destinatario_sdi')}
                        />
                      </FormField>
                      <FormField
                        label={t('PEC')}
                        status={getFormFieldStatus({ touched, errors }, 'pec')}
                        errorMessage={errors?.pec}
                      >
                        <FormInput
                          placeholder={t('PEC')}
                          value={values?.pec}
                          onBlur={handleBlur('pec')}
                          onChange={handleChange('pec')}
                        />
                      </FormField>
                    </>
                  )}
                </>
              )}
            </FormLayout>
            <div className="myaccount-form-indirizzo-fatturazione__actions">
              <div className="myaccount-form-indirizzo-fatturazione__actions__delete">
                {indirizzo && (
                  <Button
                    icon={<Bin />}
                    iconPos="left"
                    label={t('Elimina indirizzo')}
                    size="sm"
                    variant="ghost"
                    onClick={() => handleDelete()}
                  />
                )}
              </div>
              <div className="myaccount-form-indirizzo-fatturazione__actions__buttons">
                <Button label={t('Annulla')} size="sm" variant="secondary" onClick={onCancel} />
                <Button type="submit" label={t('Salva modifiche')} size="sm" variant="primary" />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default MyAccountFormIndirizzoFatturazione
