import React, { useEffect } from 'react'
import { useTrans } from 'hooks'
import useUtente from 'hooks/useUtente'

import { MyAccountMenu } from '..'
import { Close } from 'components/atoms/Icons'

interface Props {
  mobileOpen?: boolean
  setMobileOpen?: (v: boolean) => void
  activeMenuKey?: string
}

const MyAccountSidebar = (props: Props) => {
  const { utente, loading } = useUtente()
  const { mobileOpen = false, setMobileOpen = () => {}, activeMenuKey = '' } = props

  useEffect(() => {
    if (mobileOpen) document.body.classList.add('w-noscroll')
    else document.body.classList.remove('w-noscroll')
  }, [mobileOpen])

  const t = useTrans()

  return (
    <>
      {mobileOpen && (
        <div className="myaccount-sidebar-overlay" onClick={() => setMobileOpen(false)} />
      )}
      <div className={`myaccount-sidebar${mobileOpen ? ' myaccount-sidebar--open' : ''}`}>
        <button className="myaccount-sidebar__close" onClick={() => setMobileOpen(false)}>
          <Close />
        </button>
        <div className="myaccount-sidebar__content">
          <p className="myaccount-sidebar__title">{t('Ciao {0}', [utente?.nome])}</p>
          <p className="myaccount-sidebar__title-mobile">{t('Il mio profilo')}</p>
          <MyAccountMenu activeItemKey={activeMenuKey} />
        </div>
      </div>
    </>
  )
}

export default MyAccountSidebar
