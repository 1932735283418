import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function PremioVitae({ className }: Props) {
  return (
    <IconBase name="premio-vitae" w={52} className={className}>
      <path d="M49.614 0L44.9193 4.44564C46.9557 6.75724 47.5745 9.87109 44.9193 12.3603C42.2641 14.8494 33.1431 14.6719 30.3964 14.6719C28.8809 14.6719 21.37 14.6719 14.9483 14.6719L15.8576 5.84084L10.3136 7.8956L9.58117 14.675C7.00177 14.675 5.2527 14.675 5.2527 14.675L1 19.387H9.06971L6.69237 41.3869C6.69237 41.3869 4.69388 53.1257 12.5994 51.9112C18.7117 50.9758 22.0267 45.2681 22.273 44.5578C22.7402 43.2039 22.273 41.3774 21.0543 43.6605C19.8356 45.9435 16.1544 48.9908 13.1835 47.2468C11.5039 46.2606 12.2111 41.2981 12.2111 41.2981L14.4685 19.387H26.0016C26.0016 19.387 41.5601 20.1861 47.6692 12.4491C53.7752 4.71517 49.614 0 49.614 0Z" stroke="none"/>
    </IconBase>
  )
}

export default PremioVitae
