import React from 'react'

interface ContentProps {
  children?: any
}

interface ButtonsProps {
  children?: any
}

interface Props {
  title?: string
  children?: any
  buttons?: any
  Content?: any
  Buttons?: any
}

const FormStepContent = (props: ContentProps) => {
  const { children = null } = props

  return <div className="form-step__content">{children}</div>
}

const FormStepButtons = (props: ButtonsProps) => {
  const { children = null } = props

  return <div className="form-step__buttons">{children}</div>
}

const FormStep = (props: Props) => {
  const { title = '', children = [], buttons = [] } = props

  return (
    <div className="form-step">
      {title && <p className="form-step__title">{title}</p>}
      {children && <FormStepContent>{children}</FormStepContent>}
      {buttons && <FormStepButtons>{buttons}</FormStepButtons>}
    </div>
  )
}

FormStep.Content = FormStepContent
FormStep.Buttons = FormStepButtons

export default FormStep
