import React from 'react'
import Link from 'next/link'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper'
import WImage from 'components/atoms/WImage/WImage'

SwiperCore.use([Pagination])

interface Props {
  items: []
  className?: string
}

const HeroSlider = (props: Props) => {
  const { items = [], className = '' } = props

  return (
    <div className={`hero-slider ${className ? ` ${className}` : ''}`}>
      <Swiper slidesPerView={1} spaceBetween={0} pagination={{ clickable: true }}>
        {items?.map((item, key) => {
          if (item.node.url)
            return (
              <SwiperSlide key={`hero-slider_${key}`}>
                <Link legacyBehavior href={item.node.url}>
                  <a className="hero-slider__item">
                    <div
                      className={`hero-slider__bg hero-slider__bg--desktop${
                        !item.node.imageTablet && !item.node.imageMobile
                          ? ' hero-slider__bg--tablet hero-slider__bg--mobile'
                          : ''
                      }`}
                    >
                      <WImage
                        layout="intrinsic"
                        maxWidth={1920}
                        maxHeight={430}
                        objectFit="cover"
                        src={item.node.image}
                        alt={item.node.image.nome}
                        title={item.node.image.nome}
                        priority={key === 0 ? true : false}
                        loading={key === 0 ? 'eager' : 'lazy'}
                      />
                    </div>
                    {item.node.imageTablet && (
                      <div
                        className={`hero-slider__bg hero-slider__bg--tablet ${
                          !item.node.image ? 'hero-slider__bg--desktop' : ''
                        }${!item.node.imageMobile ? 'hero-slider__bg--mobile' : ''}`}
                      >
                        <WImage
                          layout="intrinsic"
                          maxWidth={1150}
                          maxHeight={430}
                          objectFit="cover"
                          src={item.node.imageTablet}
                          alt={item.node.imageTablet.nome}
                          title={item.node.imageTablet.nome}
                          priority={key === 0 ? true : false}
                          loading={key === 0 ? 'eager' : 'lazy'}
                        />
                      </div>
                    )}
                    {item.node.imageMobile && (
                      <div
                        className={`hero-slider__bg hero-slider__bg--mobile ${
                          !item.node.imageTablet ? 'hero-slider__bg--tablet' : ''
                        } ${
                          !item.node.imageTablet && !item.node.image
                            ? 'hero-slider__bg--desktop'
                            : ''
                        }`}
                      >
                        <WImage
                          layout="intrinsic"
                          maxWidth={800}
                          maxHeight={280}
                          objectFit="cover"
                          src={item.node.imageMobile}
                          alt={item.node.imageMobile.nome}
                          title={item.node.imageMobile.nome}
                          priority={key === 0 ? true : false}
                          loading={key === 0 ? 'eager' : 'lazy'}
                        />
                      </div>
                    )}
                  </a>
                </Link>
              </SwiperSlide>
            )
        })}
      </Swiper>
    </div>
  )
}

export default HeroSlider
