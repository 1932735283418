import React from 'react'
import Link from 'next/link'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useTrans } from 'hooks'
import { useSelector } from 'react-redux'
import { getIconAbbinamentiByChiave } from 'utils/icon'
import { AbbinamentoShape } from 'components/atoms/Icons'

interface ItemProps {
  item: {
    icon: any
    node: {
      title?: string
      url?: string
      name?: string
    }
  }
}

interface Props {
  className?: string
}

const AbbinamentiMenuItem = (props: ItemProps) => {
  const { item = null } = props

  return item.node.url ? (
    <Link legacyBehavior href={item.node.url}>
      <a className="abbinamenti-menu-item">
        <span className="abbinamenti-menu-item__icon">{item.icon}</span>
        <span className="abbinamenti-menu-item__title">{item.node.title || item.node.name}</span>
      </a>
    </Link>
  ) : (
    <a className="abbinamenti-menu-item">
      <span className="abbinamenti-menu-item__icon">{item.icon}</span>
      <span className="abbinamenti-menu-item__title">{item.node.title || item.node.name}</span>
    </a>
  )
}

const AbbinamentiMenu = (props: Props) => {
  const { className = '' } = props

  const t = useTrans()
  // const { data: abbinamenti } = useMenuAbbinamentiQuery()

  const { menu } = useSelector((state) => state.menu)
  const hpmenu = menu?.edges.find((item) => item.node.key === 'hp')
  const abbinamenti = hpmenu?.node.children.edges.find(
    (item) => item.node.key === 'abbinamenti'
  )?.node

  const getIconAbbinamenti = () => {
    return abbinamenti?.children?.edges.map((o) => ({
      ...o,
      icon: getIconAbbinamentiByChiave(o.node.stile),
    }))
  }
  const items = getIconAbbinamenti()

  return (
    <div className={`abbinamenti-menu ${className ? ` ${className}` : ''}`}>
      <div className="abbinamenti-menu__bg">
        <AbbinamentoShape />
      </div>
      <p className="abbinamenti-menu__title">{t('Cerca per abbinamento')}</p>
      <p className="abbinamenti-menu__description">
        {t('Cerca il vino adatto ai tuoi prossimi piatti per una cena perfetta.')}
      </p>
      <div className="abbinamenti-menu__list">
        <Swiper
          spaceBetween={8}
          slidesPerView={2.5}
          breakpoints={{
            0: {
              enabled: true,
              slidesPerView: 2.5,
            },
            576: {
              slidesPerView: 4.5,
            },
            768: {
              slidesPerView: 6.5,
            },
            992: {
              slidesPerView: 7.5,
            },
            1028: {
              enabled: false,
              slidesPerView: 9,
            },
          }}
        >
          {items?.map((item) => (
            <SwiperSlide key={`abbinamenti-menu__item__${item.node.id}`}>
              <AbbinamentiMenuItem item={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default AbbinamentiMenu
