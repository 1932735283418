import React, { useContext, useEffect, useState } from 'react'
import Link from 'next/link'
import { useTrans, useWishlist } from 'hooks'
import { priceNotation } from 'utils/safe'
import { Cart, Check, HeartEmpty, HeartFilled } from 'components/atoms/Icons'
import {
  Button,
  ImageBox,
  Label,
  LabelPremio,
  QuantityHandler,
  NotificationBar,
  SkeletonLoadingPrezzoProdotto,
} from 'components/atoms'

import { getPromoLabel, getSconto } from 'utils/prodotto'
import { CartContext } from 'utils/context'
import { getIconPremioByChiave } from 'utils/icon'
import { executeIfInitialized, trackConnectifCart } from 'utils/connectif'

import { useRouter } from 'next/router'
import {
  trackingAddRemoveFromCart,
  trackingAddToCartListing,
  trackingProductClick,
} from 'utils/gtm'
import { useIsMobile } from 'utils/hooks'

interface Props {
  variant?: 'default' | 'light'
  prodotto: {
    pk: number
    varianteProdotto: {
      pk: number
    }
    mainImage?: string
    cantina?: any
    nomeFantasia?: string
    nome: string
    prezzo: number
    prezzoScontato: number
    prezzoOmnibus: number
    sconto?: string
    promoApplicata?: {
      alias?: string
      percentuale?: boolean
      valore?: number
      dal?: string
      al?: string
      mostraCounter?: boolean
      timeUntil?: string
    }
    omaggio?: {
      nome?: string
    }
    magnum?: boolean
    testoFlash?: string
    voti?: any[]
    inWishlist?: boolean
    disponibilita?: number
    url?: string
  }
  cartQuantity?: number
  disponibilita?: number
  loadingPrezzo?: boolean
  isListing?: boolean
  sezione?: string
  posizione?: number
  onNotificationMessage?: (message: string) => void
  className?: string
}

const ProdottoCard = (props: Props) => {
  const {
    variant = 'default',
    prodotto = {
      pk: 0,
      varianteProdotto: {
        pk: 0,
      },
      mainImage: '',
      cantina: {},
      nomeFantasia: '',
      nome: '',
      prezzo: 0,
      prezzoScontato: 0,
      prezzoOmnibus: 0,
      sconto: '',
      promoApplicata: {
        alias: '',
        percentuale: false,
        valore: 0,
        dal: '',
        al: '',
        mostraCounter: false,
        timeUntil: '',
      },
      omaggio: {
        nome: '',
      },
      magnum: false,
      testoFlash: '',
      voti: [],
      url: '',
      inWishlist: false,
      disponibilita: 0,
    },
    cartQuantity = 0,
    disponibilita = 0,
    loadingPrezzo = false,
    isListing = false,
    sezione = '',
    posizione = 1,
    onNotificationMessage = () => {},
    className = '',
  } = props

  const t = useTrans()
  const router = useRouter()
  const isMobile = useIsMobile()
  const [wishlist, setWishlist] = useState(prodotto.inWishlist)
  const [addError, setAddError] = useState(false)

  const HandleClick = () => {
    if (isListing) trackingProductClick(prodotto, sezione, posizione)
    router.push(prodotto?.url)
  }

  useEffect(() => {
    if (prodotto) {
      setWishlist(prodotto.inWishlist)
    }
  }, [prodotto])

  const handleWishlist = useWishlist()

  const onWishlistClick = () => {
    handleWishlist(wishlist, prodotto).then((response) => {
      setWishlist(response)
    })
  }

  const { cart, addToCart } = useContext(CartContext)
  const [quantitaCarrello, setQuantitaCarrello] = useState(0)

  useEffect(() => {
    if (cart) {
      const item = cart.items.find((item) => item.prodotto.prodotto.pk === prodotto.pk)
      if (item) {
        setQuantitaCarrello(item.quantita)

        return
      }
    }
    setQuantitaCarrello(0)
  }, [cart])

  const onQuantitaChange = (quantita) => {
    if (quantitaCarrello) {
      addToCart(prodotto.varianteProdotto, quantita - quantitaCarrello, false).then(
        ({ status, message, quantitaMax }) => {
          if (message) {
            setAddError(true)
            onNotificationMessage(message)
          } else {
            setAddError(false)
          }
          if (status && quantitaMax) {
            trackingAddRemoveFromCart(prodotto, quantitaMax - quantitaCarrello)
            if (cart) executeIfInitialized(trackConnectifCart, cart)
          } else if (status) {
            trackingAddRemoveFromCart(prodotto, quantita - quantitaCarrello)
            if (cart) executeIfInitialized(trackConnectifCart, cart)
          }
        }
      )
    } else {
      addToCart(prodotto.varianteProdotto, 1, false)
      trackingAddToCartListing(prodotto)
    }
  }

  const [voti, setVoti] = useState([])

  useEffect(() => {
    if (prodotto && prodotto.voti) {
      const voti = prodotto.voti
        .filter((voto) => {
          return (
            voto.valore &&
            (getIconPremioByChiave(voto.premio.stile) || voto.premio.sigla) &&
            voto.ordinamento > 0
          )
        })
        .slice(0, 3)
      setVoti(voti)
    }
  }, [prodotto])

  const imageBoxContent = (
    <ImageBox
      classPrefix="prodotto-card"
      src={prodotto.mainImage}
      maxWidth={220}
      maxHeight={220}
      layout="intrinsic"
      objectFit="contain"
      alt={prodotto.nomeFantasia}
      title={prodotto.nomeFantasia}
    />
  )

  return (
    <div
      style={{ position: 'relative' }}
      className={`prodotto-card${variant !== 'default' ? ` prodotto-card--${variant}` : ''} ${
        className ? ` ${className}` : ''
      }`}
    >
      <div
        style={{
          cursor: prodotto?.url ? 'pointer' : undefined,
          top: 0,
          left: 0,
          position: 'absolute',
          zIndex: 0,
          width: '100%',
          height: '100%',
        }}
        onClick={() => prodotto?.url && HandleClick()}
      ></div>
      {prodotto.url ? (
        <Link legacyBehavior href={prodotto.url}>
          <a
            onClick={() => (isListing ? trackingProductClick(prodotto, sezione, posizione) : null)}
          >
            {imageBoxContent}
          </a>
        </Link>
      ) : (
        imageBoxContent
      )}

      {/* Visualizzo sempre per una questione di spazi - min-height */}
      <p className="prodotto-card__cantina">
        {prodotto.cantina?.nome ? prodotto.cantina.nome : ' '}
      </p>

      <p className="prodotto-card__title">
        {prodotto.url ? (
          <Link legacyBehavior href={prodotto.url}>
            <a
              onClick={() =>
                isListing ? trackingProductClick(prodotto, sezione, posizione) : null
              }
            >
              {prodotto.nomeFantasia}
            </a>
          </Link>
        ) : (
          prodotto.nomeFantasia
        )}
      </p>

      {loadingPrezzo ? (
        <SkeletonLoadingPrezzoProdotto vista2={isMobile} />
      ) : (
        <>
          <div className="prodotto-card__price">
            <span className="prodotto-card__price__final">
              {priceNotation(prodotto.prezzoScontato)}
            </span>
            {prodotto.prezzo !== prodotto.prezzoScontato && (
              <span className="prodotto-card__price__full">{priceNotation(prodotto.prezzo)}</span>
            )}
          </div>

          <div>
            {prodotto.prezzo !== prodotto.prezzoScontato && (
              <>
                <span className="prodotto-card__omnibus">
                  {t('Prezzo piu basso negli ultimi 30 giorni')}:
                </span>
                <span className="prodotto-card__omnibus">
                  {priceNotation(prodotto.prezzoOmnibus)}
                </span>
              </>
            )}
          </div>

          {variant !== 'light' && (
            <div className="prodotto-card__promo">
              {/* Lascio il div perchè la spaziatura deve rimanere lo stesso */}
              {prodotto.promoApplicata && prodotto.promoApplicata.mostraCounter && (
                <Label label={getPromoLabel(prodotto)} variant="primary" size="sm" />
              )}
            </div>
          )}

          {prodotto.disponibilita > 0 ? (
            quantitaCarrello > 0 ? (
              <div className="prodotto-card__cart-actions">
                <NotificationBar
                  icon={<Check />}
                  variant="success"
                  size="sm"
                  label={
                    <>
                      {t('Aggiunto a carrello')}
                      <br />
                      {t('Modifica la quantità')}
                    </>
                  }
                />
                <QuantityHandler
                  value={quantitaCarrello}
                  onValueChange={(newValue, actions) => onQuantitaChange(newValue)}
                  size="sm"
                  className="prodotto-card__quantity-handler"
                  error={addError}
                />
              </div>
            ) : (
              <div className="prodotto-card__cta-box">
                <Button
                  variant="primary"
                  size="sm"
                  label={t('Aggiungi al carrello')}
                  labelMobile={t('Aggiungi')}
                  icon={<Cart />}
                  iconPos="right"
                  className="prodotto-card__cta"
                  onClick={() => onQuantitaChange(1)}
                />
              </div>
            )
          ) : (
            <p className="prodotto-card__non-disponibile">{t('Prodotto non disponibile')}</p>
          )}
        </>
      )}

      {variant !== 'light' && (
        <>
          {(prodotto.testoFlash || prodotto.magnum || prodotto.voti?.length > 0) && (
            <div className="prodotto-card__labels">
              {prodotto.testoFlash && (
                <Label label={prodotto.testoFlash} variant="dark" size="sm" />
              )}
              {prodotto.magnum && <Label label={t('Magnum 1.5L')} variant="light" size="sm" />}
              {voti?.map((v) => (
                <LabelPremio
                  size="sm"
                  label={v.premio.sigla}
                  icon={getIconPremioByChiave(v.premio.stile)}
                  value={v.valore}
                  iconValue={v.valore}
                  key={`prodotto-card__label__${v.id}`}
                />
              ))}
            </div>
          )}
          <button
            type="button"
            className="prodotto-card__wishlist"
            onClick={() => onWishlistClick()}
          >
            {wishlist ? <HeartFilled /> : <HeartEmpty />}
          </button>
        </>
      )}
    </div>
  )
}

export default ProdottoCard
