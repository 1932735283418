import React, { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { useRouter } from 'next/dist/client/router'
import { useTrans } from 'hooks'
import { useCreateUtenteMutation } from 'gql/graphql'
import { getFormFieldStatus } from 'utils/safe'
import { trackingGAevent } from 'utils/gtm'
import { DOMAIN } from 'utils/settings'
import { Alert } from 'components/atoms/Icons'
import {
  FormLayout,
  FormField,
  FormInput,
  FormInputPassword,
  Button,
  FormCheckbox,
  FormCheckboxPrivacy,
  NotificationBar,
  OptinLabel,
} from 'components/atoms'
import { IinitialVaues } from './FormRegistrazione.interfaces'
import { getFormattedDate } from 'utils'
import { useSelector } from 'react-redux'
import Link from 'next/link'

const validationSchema = yup.object().shape({
  nome: yup.string().nullable().required('Campo obbligatorio'),
  cognome: yup.string().nullable().required('Campo obbligatorio'),
  email: yup
    .string()
    .email("Il formato dell'email dev'essere valido")
    .nullable()
    .required('Campo obbligatorio'),
  password: yup.string().min(8, 'Minimo 8 caratteri').nullable().required(),
  maggiorenne: yup.boolean().required().oneOf([true], 'Campo obbligatorio'),
  privacy: yup.boolean().required().oneOf([true], 'Campo obbligatorio'),
  newsletter: yup.boolean().required(),
})

const FormDati = ({ props }) => {
  const t = useTrans()
  const router = useRouter()
  const { social, email, nome, cognome } = router.query

  const { pagine } = useSelector((state) => state.pagine)
  const privacy_policy = pagine?.edges.find((item) => item.node.chiave === 'privacy-policy')?.node

  useEffect(() => {
    if (social !== undefined) {
      nome && props.setFieldValue('nome', decodeURIComponent(nome?.toString()))
      email && props.setFieldValue('email', decodeURIComponent(email?.toString()))
      cognome && props.setFieldValue('cognome', decodeURIComponent(cognome?.toString()))
    }
  }, [nome, cognome, email, social])

  return (
    <>
      <FormField
        label={`${t('Nome')}*`}
        status={getFormFieldStatus(props, 'nome')}
        errorMessage={props?.errors?.nome}
      >
        <FormInput
          readOnly={!!nome}
          onBlur={props.handleBlur('nome')}
          placeholder="Nome"
          value={props.values?.nome}
          onChange={props.handleChange('nome')}
        />
      </FormField>
      <FormField
        label={`${t('Cognome')}*`}
        status={getFormFieldStatus(props, 'cognome')}
        errorMessage={props?.errors?.cognome}
      >
        <FormInput
          readOnly={!!cognome}
          onBlur={props.handleBlur('cognome')}
          placeholder="Cognome"
          value={props.values.cognome}
          onChange={props.handleChange('cognome')}
        />
      </FormField>
      <FormField
        label={`${t('Email')}*`}
        status={getFormFieldStatus(props, 'email')}
        errorMessage={props?.errors?.email}
      >
        <FormInput
          readOnly={!!email}
          onBlur={props.handleBlur('email')}
          placeholder="Email"
          value={props.values.email}
          onChange={props.handleChange('email')}
        />
      </FormField>
      <FormField
        label={`${t('Password')}*`}
        status={getFormFieldStatus(props, 'password')}
        errorMessage={props?.errors?.password}
      >
        <FormInputPassword
          togglePassword
          onBlur={props.handleBlur('password')}
          placeholder="Password"
          value={props.values?.password}
          onChange={props.handleChange('password')}
        />
      </FormField>
      <FormField
        layout="full"
        status={getFormFieldStatus(props, 'maggiorenne')}
        errorMessage={props?.errors?.maggiorenne}
      >
        <FormCheckbox
          id="form-registrazione__maggiorenne"
          label={t('Dichiaro di avere più di 18 anni')}
          onBlur={props.handleBlur('maggiorenne')}
          checked={props.values?.maggiorenne}
          onChange={props.handleChange('maggiorenne')}
        />
      </FormField>
      <FormField
        layout="full"
        status={getFormFieldStatus(props, 'privacy')}
        errorMessage={props?.errors?.privacy}
      >
        <FormCheckboxPrivacy
          id="form-registrazione__privacy"
          label={<OptinLabel type="privacy" url={privacy_policy.url} />}
          className="newsletter-form__privacy"
          onBlur={props.handleBlur('privacy')}
          checked={props.values?.privacy}
          onChange={props.handleChange('privacy')}
        />
      </FormField>
      <FormField
        layout="full"
        status={getFormFieldStatus(props, 'newsletter')}
        errorMessage={props?.errors?.newsletter}
      >
        <FormCheckbox
          id="form-registrazione__privacy-commerciale"
          label={t(
            'Acconsento al trattamento dei miei dati personali per ricevere offerte e comunicazioni commerciali da parte di Vinicum.'
          )}
          onBlur={props.handleBlur('newsletter')}
          checked={props.values?.newsletter}
          onChange={props.handleChange('newsletter')}
        />
      </FormField>
    </>
  )
}

const FormRegistrazione = () => {
  const [createUtente, { loading }] = useCreateUtenteMutation()
  const [error, setError] = useState<string | undefined | null>()
  const router = useRouter()
  const t = useTrans()

  const initialValues: IinitialVaues = {
    nome: '',
    cognome: '',
    email: '',
    password: '',
    maggiorenne: false,
    privacy: false,
    newsletter: false,
  }

  return (
    <div className="form-registrazione">
      <Formik
        validateOnMount={false}
        validateOnBlur
        validateOnChange
        validationSchema={validationSchema}
        onSubmit={(values) => {
          createUtente({
            variables: {
              input: {
                nome: values?.nome,
                cognome: values?.cognome,
                email: values?.email,
                password: values?.password,
                privacy: values?.privacy,
                privacyCommerciale: values?.newsletter,
              },
            },
          })
            .then((res) => {
              // ? utilizzare res.data.createUtente.utente per accedere ai dati dell'utente per i tracciamenti
              if (res?.data?.createUtente?.status) {
                setError(null)

                if (res?.data?.createUtente?.utente) {
                  trackingGAevent(
                    1,
                    'user',
                    'register',
                    res?.data?.createUtente?.utente?.pk,
                    getFormattedDate(res?.data?.createUtente?.utente?.dataRegistrazione)
                  )
                }
                if (values?.newsletter) {
                  trackingGAevent(
                    2,
                    'user',
                    'newsletter',
                    DOMAIN + router.asPath,
                    getFormattedDate(res?.data?.createUtente?.utente?.dataRegistrazione)
                  )
                }

                router.push('/registrazione/thank-you')
              } else setError(t("L'email inserita è già utilizzata"))
            })
            .catch((err) => console.log(err))
        }}
        initialValues={initialValues}
      >
        {(props) => (
          <Form>
            {error && (
              <NotificationBar
                label={error}
                variant="alert"
                icon={<Alert />}
                className="form-registrazione__error"
              />
            )}
            <FormLayout>
              <FormDati props={props} />
            </FormLayout>

            <div className="form-registrazione__submit-box">
              <Button
                type="submit"
                loading={loading}
                label={t('Registrati')}
                disabled={!props.touched || !props.isValid}
                variant="primary"
                className="form-registrazione__submit"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default FormRegistrazione
