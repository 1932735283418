import React, { useState } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useLocalStorage, useTrans } from 'hooks'
import { useRouter } from 'next/dist/client/router'
import { useLoginMutation } from 'gql/graphql'
import { getFormFieldStatus } from 'utils/safe'
import { DOMAIN } from 'utils/settings'
import { trackingGAevent } from 'utils/gtm'
import { Alert } from 'components/atoms/Icons'
import {
  Button,
  FormCheckbox,
  FormField,
  FormInput,
  FormInputPassword,
  FormLayout,
  NotificationBar,
} from 'components/atoms'
import { executeIfInitialized, trackConnectifLogin } from 'utils/connectif'

const FormLogin = () => {
  const [login, { loading }] = useLoginMutation()
  const [error, setError] = useState<string | undefined | null>()
  const [, setUserId] = useLocalStorage('userId', null)
  const [, setToken] = useLocalStorage('token', null)
  const router = useRouter()
  const t = useTrans()

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t("Il formato dell'email dev'essere valido"))
      .required(t('Campo obbligatorio'))
      .nullable(),
    password: yup.string().required(t('Campo obbligatorio')).nullable(),
    ricordati: yup.boolean().nullable(),
  })

  return (
    <div className="form-login">
      <Formik
        validateOnMount={false}
        validateOnChange
        onSubmit={(values) =>
          login({
            variables: {
              username: values.email,
              password: values.password,
            },
          })
            .then((res) => {
              executeIfInitialized(trackConnectifLogin)
              trackingGAevent(
                5,
                'user',
                'login-step-2',
                DOMAIN +
                  ((Array.isArray(router.query?.next) ? router.query.next[0] : router.query.next) ||
                    '/account')
              )
              setToken(res.data?.tokenAuth?.token)
              setUserId(res.data?.tokenAuth?.user?.pk)
              setError(null)
              // ? utilizzare res.data.tokenAuth.user per accedere ai dati dell'utente per i tracciamenti
              router.push(
                (Array.isArray(router.query?.next) ? router.query.next[0] : router.query.next) ||
                  '/account'
              )
            })
            .catch((err) => {
              setError('Le credenziali inserite non sono corrette.')
            })
        }
        initialValues={{ email: '', password: '', ricordati: false }}
        validationSchema={validationSchema}
      >
        {(props) => (
          <>
            {error && (
              <NotificationBar
                label={error}
                variant="alert"
                icon={<Alert />}
                className="form-login__error"
              />
            )}
            <FormLayout>
              <FormField
                label="Email"
                layout="full"
                status={getFormFieldStatus(props, 'email')}
                errorMessage={props?.errors?.email}
              >
                <FormInput
                  onBlur={props.handleBlur('email')}
                  placeholder="Email"
                  value={props.values.email}
                  onChange={props.handleChange('email')}
                />
              </FormField>
              <FormField
                label="Password"
                layout="full"
                status={getFormFieldStatus(props, 'password')}
                errorMessage={props?.errors?.password}
              >
                <FormInputPassword
                  placeholder="Password"
                  onBlur={props.handleBlur('password')}
                  value={props.values.password}
                  onChange={props.handleChange('password')}
                  togglePassword
                />
              </FormField>
            </FormLayout>
            <div className="form-login__helpers">
              <FormCheckbox
                label="Ricordati di me"
                id="ricordati"
                checked={props.values.ricordati}
                onChange={(e) => props.setFieldValue('ricordati', e.target.checked)}
              />
              <Button
                href="/recupera-password"
                label={t('Hai dimenticato la password?')}
                variant="ghost"
                size="sm"
              />
            </div>
            <Button
              type="submit"
              loading={loading}
              label="Accedi"
              onClick={() => props.handleSubmit()}
              disabled={Object.values(props.errors).length > 0}
              className="form-login__submit"
            />
          </>
        )}
      </Formik>
    </div>
  )
}

export default FormLogin
