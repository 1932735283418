import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function SottocategorieListShape3({ className }: Props) {
  return (
    <IconBase name="sottocategorie-list-shape3" w={81} h={80} className={className}>
      <path d="M24.7343 6.40611C46.5204 1.18957 84.7678 35.2038 77.4729 55.7095C70.1779 76.2152 48.6133 79.8369 19.7859 71.3209C-9.04155 62.8048 -1.95424 12.7965 24.7343 6.40611Z" stroke="none"/>
    </IconBase>
  )
}

export default SottocategorieListShape3
