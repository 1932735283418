import { useMutation, useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ADD_ITEM_WISHLIST, REMOVE_ITEM_WISHLIST, MENU_GET } from './queries'
import { RES_SM_MIN, graphene } from './index'
// import { scrollToTarget } from 'utils'

export function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      function handleResize() {
        const width = window.innerWidth > 0 ? window.innerWidth : screen.width
        setIsMobile(width < RES_SM_MIN)
      }
      window.addEventListener('resize', handleResize)
      handleResize()
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [])

  return isMobile
}

// export function useUtente() {
//   const router = useRouter()
//   const { loading, data, refetch } = useQuery(ME)

//   const dispatch = useDispatch()
//   const { utente } = useSelector((state) => state.utente)

//   useEffect(() => {
//     if (data) dispatch({ type: 'SET_ME', payload: { data } })
//   }, [data])

//   useEffect(() => {
//     refetch()
//   }, [router.asPath])

//   return { utente, loading }
// }

export function useMenu() {
  const router = useRouter()
  // const { loading, data, refetch } = useQuery(MENU, { fetchPolicy: 'cache-first' })
  const { locale } = app?.router
  const {
    loading,
    data: { data },
    refetch,
  } = graphene.get('', { params: { query: MENU_GET.replace('__siteId__', "1"), lang: locale } })

  const dispatch = useDispatch()
  const { menu, fetching } = useSelector((state) => state.menu)

  useEffect(() => {
    dispatch({ type: 'SET_MENU', payload: { loading, data } })
  }, [data])

  useEffect(() => {
    refetch()
  }, [router.asPath])

  return [menu, loading, fetching]
}

// export function useScroll(loading, timing) {
//   const [scrolled, setScrolled] = useState(false)

//   useEffect(() => {
//     if (!loading && !scrolled) {
//       scrollToTarget(timing)
//       setScrolled(true)
//     }
//   }, [loading])

//   return [scrolled]
// }

export function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

// export function useTrans() {
//   const { traduzioni } = useSelector((state) => state.traduzioni)
//   const mounted = useRef(false)

//   useEffect(() => {
//     mounted.current = true
//   }, [traduzioni])

//   useEffect(() => {
//     mounted.current = true
//   }, [])

//   const replaceSostituzioni = (value, sostituzioni) => {
//     let result = value
//     if (sostituzioni?.length) {
//       for (let i in sostituzioni)
//         result = result.replace('{' + String(i) + '}', String(sostituzioni[i]))
//     }
//     return result
//   }

//   const getTraduzione = (chiave, sostituzioni) => {
//     try {
//       const { valore } = traduzioni.find((t) => t.chiave === chiave)
//       return replaceSostituzioni(valore, sostituzioni)
//     } catch (error) {
//       //! Attenzione attualmente commentata perchè rallenta
//       api.post(TRADUZIONI, { chiave }).catch((error) => console.log(error))
//       return replaceSostituzioni(chiave, sostituzioni)
//     }
//   }

//   return getTraduzione
// }

export function useWishlist() {
  const [addItemWishlist, { data: dataAddItemWishlist }] = useMutation(ADD_ITEM_WISHLIST)
  const [removeItemWishlist, { data: dataRemoveItemWishlist }] = useMutation(REMOVE_ITEM_WISHLIST)

  const handleWishlist = async (active, prodotto) => {
    if (active) {
      const response = await removeItemWishlist({
        variables: {
          item: prodotto.pk,
        },
        refetchQueries: ['Wishlist'],
      })
      return !response.data.removeItemWishlist.status
    } else {
      const response = await addItemWishlist({
        variables: {
          item: prodotto.pk,
        },
        refetchQueries: ['Wishlist'],
      })
      return response.data.addItemWishlist.status
    }
  }

  return handleWishlist
}

export function useIsClient(initialValue) {
  const [clientSide, setClientSide] = useState(initialValue)

  useEffect(() => {
    handleClientSide()
  }, [])

  function handleClientSide() {
    setClientSide(true)
  }

  return clientSide
}
