import React from 'react'
import { useTrans } from 'hooks'
import { Button } from 'components/atoms'

interface Props {
  onCancel: () => void
  className?: string
  children: any
}

const MyAccountInlineForm = (props: Props) => {
  const { onCancel = null, className = '', children = null } = props

  const t = useTrans()

  return (
    <div className={`myaccount-inline-form ${className ? ` ${className}` : ''}`}>
      <div className="myaccount-inline-form__content">{children}</div>
      <div className="myaccount-inline-form__buttons">
        <Button label={t('Annulla')} size="sm" variant="ghost" onClick={onCancel} />
        <Button type="submit" label={t('Salva modifiche')} size="sm" variant="primary" />
      </div>
    </div>
  )
}

export default MyAccountInlineForm
