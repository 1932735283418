import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function FaqSpedizioniResi({ className }: Props) {
  return (
    <IconBase name="faq-spedizioni-resi" w={32} className={className}>
      <path d="M26.6298 9.7998C26.6198 9.7898 26.5998 9.7798 26.5898 9.7598C26.5498 9.6998 26.5098 9.6498 26.4398 9.6098L16.2398 3.6798C16.0798 3.5898 15.8898 3.5898 15.7398 3.6798L5.5498 9.6098C5.4898 9.6498 5.4498 9.6998 5.3998 9.7598C5.3898 9.7698 5.3798 9.7798 5.3698 9.7998C5.3298 9.8698 5.2998 9.9598 5.2998 10.0498V21.9598C5.2998 22.1398 5.3898 22.2998 5.5498 22.3898L15.7498 28.3198C15.8298 28.3598 15.9098 28.3898 15.9998 28.3898C16.0898 28.3898 16.1698 28.3698 16.2498 28.3198L26.4498 22.3898C26.5998 22.2998 26.6998 22.1398 26.6998 21.9598V10.0498C26.6998 9.9598 26.6698 9.8698 26.6298 9.7998ZM20.4698 13.9598L22.8998 12.5498V18.4898L20.4698 19.9198V13.9598ZM19.9498 13.0998L10.7498 7.7498L13.1998 6.3198L22.3998 11.6698L19.9498 13.0998ZM10.2498 8.0398L19.4498 13.3898L15.9998 15.3998L6.7998 10.0498L10.2498 8.0398ZM15.9998 4.6898L25.1998 10.0398L22.8998 11.3798L13.6998 6.0298L15.9998 4.6898ZM6.2998 10.9198L15.3498 16.1798L15.4998 16.2698V27.0198L6.2998 21.6698V10.9198ZM16.4998 27.0098V16.2698L16.8398 16.0698L19.9698 14.2498V20.3598C19.9698 20.4498 20.0198 20.5298 20.0998 20.5798C20.1398 20.5998 20.1798 20.6098 20.2198 20.6098C20.2598 20.6098 20.3098 20.5998 20.3498 20.5698L23.2798 18.8398C23.3598 18.7998 23.3998 18.7098 23.3998 18.6298V12.2598L25.6998 10.9198V21.6698L16.4998 27.0098Z" stroke="none"/>
      <path d="M13.59 18.3198H7.15001C7.08001 18.3198 7.00001 18.3498 6.96001 18.4098C6.91001 18.4698 6.89001 18.5398 6.91001 18.6198L7.40001 21.1798C7.42001 21.2998 7.53001 21.3798 7.65001 21.3798H14.09C14.16 21.3798 14.24 21.3498 14.28 21.2898C14.33 21.2298 14.35 21.1598 14.33 21.0798L13.84 18.5198C13.81 18.3998 13.71 18.3198 13.59 18.3198ZM7.85001 20.8798L7.45001 18.8198H13.38L13.78 20.8798H7.85001Z" stroke="none"/>
    </IconBase>
  )
}

export default FaqSpedizioniResi
