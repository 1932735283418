import React, { useState } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useRecuperaPasswordMutation } from 'gql/graphql'
import { useRouter } from 'next/router'
import { useTrans } from 'hooks'
import { getFormFieldStatus } from 'utils/safe'
import { Alert } from 'components/atoms/Icons'
import { Button, FormField, FormInput, FormLayout, NotificationBar } from 'components/atoms'

const FormRecuperaPassword = () => {
  const t = useTrans()
  const router = useRouter()
  const [error, setError] = useState(null)
  const [recuperaPassword, { loading }] = useRecuperaPasswordMutation()

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required(t('Questo campo è obbligatorio'))
      .email(t("Il formato dell'email dev'essere valido"))
      .nullable(),
  })
  return (
    <div className="form-recupera-password">
      <Formik
        validateOnMount={false}
        validationSchema={validationSchema}
        initialValues={{
          email: '',
        }}
        onSubmit={(values) =>
          recuperaPassword({
            variables: {
              email: values?.email,
            },
          })
            .then((res) =>
              res?.data?.recuperaPassword?.status
                ? router.push('/recupera-password/thank-you')
                : setError(t("L'email inserita non è stata trovata"))
            )
            .catch(
              (err) => setError(t('Il link per il cambio password non è più valido.'))
            )
        }
      >
        {(props) => (
          <>
            {error && (
              <NotificationBar
                label={error}
                variant="alert"
                icon={<Alert />}
                className="form-reset-password__error"
              />
            )}
            <FormLayout>
              <FormField
                label={`${t('Email')}*`}
                layout="full"
                status={getFormFieldStatus(props, 'email')}
                errorMessage={props.errors.email}
              >
                <FormInput
                  value={props.values.email}
                  onBlur={props.handleBlur('email')}
                  onChange={props.handleChange('email')}
                  placeholder="Inserisci la tua email"
                />
              </FormField>
            </FormLayout>

            <Button
              loading={loading}
              type="submit"
              disabled={!!props.errors.email || !props.dirty}
              variant="primary"
              label={t('Invia nuova password')}
              onClick={() => props.handleSubmit()}
              className="form-recupera-password__submit"
            />
          </>
        )}
      </Formik>
    </div>
  )
}

export default FormRecuperaPassword
