import React from 'react'

interface Props {
  label?: string
  className?: string
}

const NotificationBadge = (props: Props) => {
  const { label = '', className = '' } = props

  return label ? (
    <span
      className={`notification-badge notification-badge--labeled${
        className ? ` ${className}` : ''
      }`}
    >
      {label}
    </span>
  ) : (
    <span className={`notification-badge${className ? ` ${className}` : ''}`} />
  )
}

export default NotificationBadge
