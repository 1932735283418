import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function CaratteristicheConsumo({ className }: Props) {
  return (
    <IconBase name="caratteristiche-consumo" w={32} className={className}>
      <path d="M24.32 8.6201H23.57V6.5601C23.57 5.6201 22.8 4.8501 21.86 4.8501C20.92 4.8501 20.15 5.6201 20.15 6.5601V8.6201H11.83V6.5601C11.83 5.6201 11.06 4.8501 10.12 4.8501C9.17996 4.8501 8.41996 5.6201 8.41996 6.5601V8.6201H7.67996C6.31996 8.6201 5.20996 9.7301 5.20996 11.0901V24.6901C5.20996 26.0501 6.31996 27.1601 7.67996 27.1601H24.32C25.68 27.1601 26.79 26.0501 26.79 24.6901V11.0901C26.79 9.7201 25.68 8.6201 24.32 8.6201ZM21.16 6.5601C21.16 6.1701 21.48 5.8501 21.87 5.8501C22.26 5.8501 22.58 6.1701 22.58 6.5601V11.7401C22.58 12.1301 22.26 12.4501 21.87 12.4501C21.48 12.4501 21.16 12.1301 21.16 11.7401V6.5601ZM9.41996 6.5601C9.41996 6.1701 9.73996 5.8501 10.13 5.8501C10.52 5.8501 10.84 6.1701 10.84 6.5601V11.7401C10.84 12.1301 10.52 12.4501 10.13 12.4501C9.73996 12.4501 9.41996 12.1301 9.41996 11.7401V6.5601ZM7.67996 9.6201H8.42996V11.7401C8.42996 12.6801 9.19996 13.4501 10.14 13.4501C11.08 13.4501 11.85 12.6801 11.85 11.7401V9.6201H20.17V11.7401C20.17 12.6801 20.94 13.4501 21.88 13.4501C22.82 13.4501 23.59 12.6801 23.59 11.7401V9.6201H24.34C25.15 9.6201 25.81 10.2801 25.81 11.0901V15.4301H6.20996V11.0901C6.20996 10.2701 6.86996 9.6201 7.67996 9.6201ZM24.32 26.1501H7.67996C6.86996 26.1501 6.20996 25.4901 6.20996 24.6801V15.9201H25.79V24.6801C25.79 25.4901 25.13 26.1501 24.32 26.1501Z" stroke="none"/>
      <path d="M22.13 19.3501H9.04004C8.90004 19.3501 8.79004 19.4601 8.79004 19.6001C8.79004 19.7401 8.90004 19.8501 9.04004 19.8501H22.13C22.27 19.8501 22.38 19.7401 22.38 19.6001C22.38 19.4601 22.27 19.3501 22.13 19.3501Z" stroke="none"/>
      <path d="M17.22 22.6201L9.04004 22.6701C8.90004 22.6701 8.79004 22.7801 8.79004 22.9201C8.79004 23.0601 8.90004 23.1701 9.04004 23.1701L17.22 23.1201C17.36 23.1201 17.47 23.0101 17.47 22.8701C17.47 22.7301 17.35 22.6201 17.22 22.6201Z" stroke="none"/>
    </IconBase>
  )
}

export default CaratteristicheConsumo
