import { useNode, useEditor } from '@craftjs/core'
import React from 'react'

import { ProductCardBlockSettings } from './ProductCardBlockSettings'

import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'

import { ProductCard } from '../../other/ProductCard'

export const defaultProps = {
  slug: "",
  active: false,
}

export const dynamicProps = []

export const ProductCardBlock = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }
    const { slug } = blockProps

    const {
      id,
      connectors: { connect },
      selected,
      hovered,
    } = useNode((state) => ({
      selected: state.events.selected,
      hovered: state.events.hovered,
      customs: state.data.custom,
    }))

    const { enabled } = useEditor((state) => ({
      enabled: state.options.enabled,
    }))

    return (
      <div
        ref={(ref) => props.retRef(ref)}
        className={
          'product-card-block ' +
          (props.active ? 'product-card-block--active ' : '') +
          (enabled ? 'product-card-block__mouse-disabled ' : '') +
          props.className
        }
        style={{ position: 'relative' }}
      >
        <ProductCard slug={slug} />
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

ProductCardBlock.craft = withCraft({
  name: 'ProductCardBlock',
  defaultProps: defaultProps,
  settings: ProductCardBlockSettings,
})
