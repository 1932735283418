import React from 'react'
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ImageBox, WContainer } from 'components/atoms'

SwiperCore.use([Pagination])

interface Props {
  className?: string
  items?: {
    pk: number
    image: string
  }[]
}

const ChiSiamoSlider = (props: Props) => {

  const { 
    className = '',
    items = [],
  } = props

  const menu = {
    name: 'Dalla Valtellina alla Sicilia, un viaggio alla scoperta della nostra Italia del vino',
    children: items
  }

  return menu ? (
    <div className={`chi-siamo-slider ${className ? ` ${className}` : ''}`}>
      <WContainer>
        {!!menu.name && <p className="chi-siamo-slider__title">{menu.name}</p>}
      </WContainer>
      {menu.children.length > 0 && (
        <div className="chi-siamo-slider__slider">
          <Swiper
            loop={true}
            spaceBetween={0}
            slidesPerView={1.2}
            pagination={{ clickable: true }}
            watchOverflow
            breakpoints={{
              768: {
                spaceBetween: 46,
                slidesPerView: 1
              }
            }}
          >
            {menu.children?.map((item) => (
              <SwiperSlide key={`chi-siamo-slider__item__${item.pk}`}>
                <div className="chi-siamo-slider__item">
                  <ImageBox
                    src={item.image}
                    maxWidth={752}
                    maxHeight={423}
                    classPrefix="chi-siamo-slider__item"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </div>
  ) : <></>
}

export default ChiSiamoSlider
