import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import Link from 'next/link'

import {
  SottocategorieListShape1,
  SottocategorieListShape2,
  SottocategorieListShape3,
} from 'components/atoms/Icons'

interface ItemProps {
  sottocategoria: {
    voceMenu: string
    icon: any
    url: string
    active?: boolean
  }
  bgIcon: any
}

interface Props {
  sottocategorie: {
    voceMenu: string
    icon: any
    url: string
    active?: boolean
  }[]
  className?: string
}

const SottocategorieListItem = (props: ItemProps) => {
  const { sottocategoria = null, bgIcon = null } = props

  return (
    <a
      className={`sottocategorie-list-item${
        sottocategoria.active ? ' sottocategorie-list-item--active' : ''
      }`}
      href={sottocategoria.url}
    >
      <span className="sottocategorie-list-item__icon">
        {bgIcon}
        {sottocategoria.icon}
      </span>
      <span className="sottocategorie-list-item__title">{sottocategoria.voceMenu}</span>
    </a>
  )
}

const SottocategorieList = (props: Props) => {
  const { sottocategorie = [], className = '' } = props

  return sottocategorie.length > 0 ? (
    <div className={`sottocategorie-list ${className ? ` ${className}` : ''}`}>
      <Swiper slidesPerView="auto" spaceBetween={20}>
        {sottocategorie.map((sottocategoria, index) => (
          <SwiperSlide key={index}>
            <SottocategorieListItem
              sottocategoria={sottocategoria}
              bgIcon={
                index % 3 === 0 ? (
                  <SottocategorieListShape1 />
                ) : index % 3 === 1 ? (
                  <SottocategorieListShape2 />
                ) : (
                  <SottocategorieListShape3 />
                )
              }
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  ) : (
    <></>
  )
}

export default SottocategorieList
