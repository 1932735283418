import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function TipologiaFrizzante({ className }: Props) {
  return (
    <IconBase name="tipologia-frizzante" w={32} className={className}>
      <path d="M12.7401 17.5996C9.76008 17.5996 7.33008 20.0296 7.33008 23.0096C7.33008 25.9896 9.76008 28.4196 12.7401 28.4196C15.7301 28.4196 18.1501 25.9896 18.1501 23.0096C18.1501 20.0296 15.7301 17.5996 12.7401 17.5996ZM12.7401 27.4296C10.3101 27.4296 8.33008 25.4496 8.33008 23.0196C8.33008 20.5896 10.3101 18.6096 12.7401 18.6096C15.1701 18.6096 17.1501 20.5896 17.1501 23.0196C17.1501 25.4496 15.1701 27.4296 12.7401 27.4296Z" stroke="none"/>
      <path d="M13.24 12.9896C13.24 10.7296 11.4 8.88965 9.14004 8.88965C6.88004 8.88965 5.04004 10.7296 5.04004 12.9896C5.04004 15.2496 6.88004 17.0896 9.14004 17.0896C11.4 17.0896 13.24 15.2496 13.24 12.9896ZM6.05004 12.9896C6.05004 11.2796 7.44004 9.88965 9.15004 9.88965C10.86 9.88965 12.25 11.2796 12.25 12.9896C12.25 14.6996 10.86 16.0896 9.15004 16.0896C7.44004 16.0896 6.05004 14.6996 6.05004 12.9896Z" stroke="none"/>
      <path d="M14.3101 13.7396C14.3101 15.5796 15.8101 17.0896 17.6601 17.0896C19.5001 17.0896 21.0101 15.5896 21.0101 13.7396C21.0101 11.8896 19.5101 10.3896 17.6601 10.3896C15.8101 10.3896 14.3101 11.8896 14.3101 13.7396ZM17.6501 11.3896C18.9401 11.3896 20.0001 12.4396 20.0001 13.7396C20.0001 15.0296 18.9501 16.0896 17.6501 16.0896C16.3601 16.0896 15.3001 15.0396 15.3001 13.7396C15.3101 12.4496 16.3601 11.3896 17.6501 11.3896Z" stroke="none"/>
      <path d="M22.9299 16.8599C20.7099 16.8599 18.8999 18.6699 18.8999 20.8899C18.8999 23.1099 20.7099 24.9199 22.9299 24.9199C25.1499 24.9199 26.9599 23.1099 26.9599 20.8899C26.9599 18.6699 25.1499 16.8599 22.9299 16.8599ZM22.9299 23.9099C21.2599 23.9099 19.8999 22.5499 19.8999 20.8799C19.8999 19.2099 21.2599 17.8499 22.9299 17.8499C24.5999 17.8499 25.9599 19.2099 25.9599 20.8799C25.9599 22.5499 24.5999 23.9099 22.9299 23.9099Z" stroke="none"/>
      <path d="M23.6103 11.3899C25.4503 11.3899 26.9603 9.88994 26.9603 8.03994C26.9603 6.18994 25.4603 4.68994 23.6103 4.68994C21.7603 4.68994 20.2603 6.18994 20.2603 8.03994C20.2603 9.88994 21.7603 11.3899 23.6103 11.3899ZM23.6103 5.69994C24.9003 5.69994 25.9603 6.74994 25.9603 8.04994C25.9603 9.34994 24.9103 10.3999 23.6103 10.3999C22.3103 10.3999 21.2603 9.34994 21.2603 8.04994C21.2603 6.74994 22.3203 5.69994 23.6103 5.69994Z" stroke="none"/>
      <path d="M14.2901 8.36982C15.6101 8.36982 16.6901 7.28982 16.6901 5.96982C16.6901 4.64982 15.6101 3.56982 14.2901 3.56982C12.9701 3.56982 11.8901 4.64982 11.8901 5.96982C11.8901 7.28982 12.9701 8.36982 14.2901 8.36982ZM14.2901 4.56982C15.0601 4.56982 15.6901 5.19982 15.6901 5.96982C15.6901 6.73982 15.0601 7.36982 14.2901 7.36982C13.5201 7.36982 12.8901 6.73982 12.8901 5.96982C12.8901 5.19982 13.5201 4.56982 14.2901 4.56982Z" stroke="none"/>
    </IconBase>
  )
}

export default TipologiaFrizzante
