import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Youtube({ className }: Props) {
  return (
    <IconBase name="youtube" w={24} className={className}>
      <mask id="youtube-mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="3" width="24" height="18">
      <path d="M0 3H24V21H0V3Z" stroke="none"/>
      </mask>
      <g mask="url(#youtube-mask0)">
      <path fillRule="evenodd" clipRule="evenodd" d="M9 16.0001V8.00013L17 11.9931L9 16.0001ZM19.615 3.18413C16.011 2.93813 7.984 2.93913 4.385 3.18413C0.488 3.45013 0.029 5.80413 0 12.0001C0.029 18.1851 0.484 20.5491 4.385 20.8151C7.985 21.0611 16.011 21.0621 19.615 20.8151C23.512 20.5501 23.971 18.1961 24 12.0001C23.971 5.81513 23.516 3.45113 19.615 3.18413Z" stroke="none"/>
      </g>
    </IconBase>
  )
}

export default Youtube
