import React from 'react'

interface Props {
  label?: string
  size?: 'sm' | 'md'
  value?: string
  icon?: any
  iconValue?: number
  colorVariant?: '' | 'white'
  className?: string
}

const LabelPremio = (props: Props) => {
  const {
    label = '',
    size = 'md',
    value = '',
    icon = null,
    iconValue = 0,
    colorVariant = '',
    className = '',
  } = props

  return (
    <div
      className={`label-premio label-premio--${size}${
        colorVariant ? ` label-premio--${colorVariant}` : ''
      }${className ? ` ${className}` : ''}`}
    >
      {icon && iconValue > 0 && size === 'sm' ? (
        <span className="label-premio__label label-premio__label--icons">{icon}</span>
      ) : (
        <span className="label-premio__label">{label}</span>
      )}
      {icon && iconValue > 0 ? (
        size !== 'sm' ? (
          <span className="label-premio__value label-premio__value--icons">
            <span>{value}</span>
            {[...Array(iconValue)].map((index) => (
              <span key={`label-premio__value__${index}`}>{icon}</span>
            ))}
          </span>
        ) : (
          <span className="label-premio__value">{iconValue}</span>
        )
      ) : (
        <span className="label-premio__value">{value}</span>
      )}
    </div>
  )
}

export default LabelPremio
