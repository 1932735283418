import React from 'react'
import { FormError } from 'components/atoms'

interface Props {
  type?: 'text' | 'number' | 'email'
  placeholder?: string
  name?: string
  value?: string | number
  size?: 'md'
  className?: string
  readOnly?: boolean
  disabled?: boolean
  status?: '' | 'success' | 'error'
  errorMessage?: string
  onBlur?: (e: any) => void
  onFocus?: (e: any) => void
  onChange?: (e: any) => void
}

const FormInput = (props: Props) => {
  const {
    type = 'text',
    placeholder = '',
    name = '',
    value = '',
    size = 'md',
    className = '',
    readOnly = false,
    disabled = false,
    status = '',
    errorMessage = '',
    onBlur = () => {},
    onFocus = () => {},
    onChange = () => {},
  } = props

  const classNames = `
    form-input 
    form-input--${size}
    ${status ? ` form-input--${status}` : ''}
    ${className ? ` ${className}` : ''}`

  return (
    <div className={classNames}>
      <input
        type={type}
        name={name}
        value={value}
        className="form-input__input"
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
      />
      <FormError
        className="form-input__error"
        message={errorMessage}
        visible={status === 'error'}
      />
    </div>
  )
}

export default FormInput
