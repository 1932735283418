/* eslint-disable no-case-declarations */
import { combineReducers } from 'redux'
import { HYDRATE } from 'next-redux-wrapper'
import menu from './containers/menu'
import utente from './containers/utente'
import traduzioni from './containers/traduzioni'
import pagina from "./containers/pagina"
import pagine from "./containers/pagine"

const rootReducer = (state, action) => {
  switch (action.type) {
    case HYDRATE:
      const nextState = {
        ...state, // use previous state
        ...action.payload, // apply delta from hydration
      }
      return nextState

    default:
      return combineReducers({
        menu,
        utente,
        traduzioni,
        pagina,
        pagine,
      })(state, action)
  }
}

export default rootReducer
