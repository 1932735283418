import React from 'react'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ImageBox, WContainer } from 'components/atoms'
import { ChevronLeft, ChevronRight } from 'components/atoms/Icons'

SwiperCore.use([Navigation, Pagination])

interface Props {
  className?: string
  items?: {
    pk: number
    image: string
  }[]
}

const ChiSiamoReferenze = (props: Props) => {

  const { 
    className = '',
    items = [],
  } = props

  const menu = {
    name: 'Parlano di noi',
    children: items
  }

  return menu ? (
    <div className={`chi-siamo-referenze ${className ? ` ${className}` : ''}`}>
      <WContainer>
        {!!menu.name && <p className="chi-siamo-referenze__title">{menu.name}</p>}
        {menu.children.length > 0 && (
          <div className="chi-siamo-referenze__slider">
            <button type="button" className="chi-siamo-referenze__slider__button-prev">
              <ChevronLeft />
            </button>
            <button type="button" className="chi-siamo-referenze__slider__button-next">
              <ChevronRight />
            </button>
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              navigation={{
                nextEl: '.chi-siamo-referenze__slider__button-next',
                prevEl: '.chi-siamo-referenze__slider__button-prev'
              }}
              pagination={{ clickable: true }}
              watchOverflow
              breakpoints={{
                768: {
                  slidesPerView: 4
                }
              }}
            >
              {menu.children?.map((item) => (
                <SwiperSlide key={`chi-siamo-referenze__item__${item.pk}`}>
                  <div className="chi-siamo-referenze__item">
                    <ImageBox
                      src={item.image}
                      maxWidth={252}
                      maxHeight={200}
                      classPrefix="chi-siamo-referenze__item"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
      </WContainer>
    </div>
  ) : <></>
}

export default ChiSiamoReferenze
