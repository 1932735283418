import React from 'react'
import { useTrans } from 'hooks'
import { Button, WImage } from 'components/atoms'
import { ArrowRight } from 'components/atoms/Icons'

interface Props {
  image?: string
  name?: string
  title?: string
  description?: string
  url?: string
  className?: string
}

const BoxMarketing = (props: Props) => {
  const { image = '', title = '', description = '', url = '', className = '', name = '' } = props

  const t = useTrans()

  return (
    <div
      className={`box-marketing ${className ? ` ${className}` : ''}`}
    >
      <div className="box-marketing__content">
        {(title || name) && <p className="box-marketing__title">{title || name}</p>}
        {description && (
          <div
            className="box-marketing__description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
        {url && (
          <Button
            label={t('Scoprili tutti')}
            icon={<ArrowRight />}
            iconPos="right"
            href={url}
            className="box-marketing__cta"
          />
        )}
      </div>
      <div className='box-marketing__image'>
        <div className='box-marketing__image--desktop'>
          <WImage 
            layout='intrinsic'
            maxWidth={560}
            maxHeight={357}
            objectFit='cover'
            src={image}
            alt={(title || name)}
            title={(title || name)}
            />
        </div>
        <div className='box-marketing__image--mobile'>
          <WImage
            layout='intrinsic'
            maxWidth={725}
            maxHeight={300}
            objectFit='cover'
            src={image}
            alt={(title || name)}
            title={(title || name)}
            />
        </div>  
      </div>
    </div>
  )
}

export default BoxMarketing
