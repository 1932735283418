import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function ColoreVino2({ className }: Props) {
  return (
    <IconBase name="colore-vino2" w={36} className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.5002 27C10.2117 27 4.2723 21.2242 4.00928 14H18L18 26.9909C17.8341 26.997 17.6675 27 17.5002 27Z" stroke="none" className="icon__color1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M2.2941 6C1.92151 6 1.58002 6.21352 1.43204 6.55546C0.510652 8.68442 0 11.0326 0 13.5C0 23.165 7.83502 31 17.5 31C27.165 31 35 23.165 35 13.5C35 11.0326 34.4893 8.68442 33.568 6.55546C33.42 6.21352 33.0785 6 32.7059 6C31.9705 6 31.498 6.79478 31.7843 7.47219C32.5671 9.32512 33 11.362 33 13.5C33 22.0604 26.0604 29 17.5 29C8.93959 29 2 22.0604 2 13.5C2 11.362 2.43287 9.32512 3.21575 7.47219C3.50196 6.79478 3.02949 6 2.2941 6Z" stroke="none" fill="#989596" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.00021 14.0058C4.00014 14.0039 4.00007 14.0019 4 14H4.00021L4.00021 14.0058ZM18.0002 26.9906C25.0542 26.7289 30.725 21.0552 30.9818 14H18.0002L18.0002 26.9906Z" stroke="none" className="icon__color2"/>
    </IconBase>
  )
}

export default ColoreVino2
