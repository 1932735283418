import React, { useState } from 'react'
import { useTrans } from 'hooks'
import { Add } from 'components/atoms/Icons'
import { Button } from 'components/atoms'
import {
  MyAccountFormIndirizzoSpedizione,
  MyAccountFormIndirizzoFatturazione,
} from 'components/organisms'

interface Props {
  titolo?: string
  fatturazione?: boolean
  className?: string
  children?: any
}

const MyAccountIndirizziBlock = (props: Props) => {
  const { titolo = '', fatturazione = false, className = '', children = null } = props

  const t = useTrans()
  const [newFormOpen, setNewFormOpen] = useState(false)
  const formArgs = {
    afterSubmit: () => setNewFormOpen(false),
    onCancel: () => setNewFormOpen(false),
  }

  return (
    <div className={`myaccount-indirizzi-block ${className ? ` ${className}` : ''}`}>
      {titolo && <p className="myaccount-indirizzi-block__title">{titolo}</p>}
      <div className="myaccount-indirizzi-block__content">{children}</div>
      {newFormOpen ? (
        fatturazione ? (
          <MyAccountFormIndirizzoFatturazione
            titolo={t('Nuovo indirizzo di fatturazione')}
            {...formArgs}
          />
        ) : (
          <MyAccountFormIndirizzoSpedizione
            titolo={t('Nuovo indirizzo di spedizione')}
            {...formArgs}
          />
        )
      ) : (
        <Button
          label={t('Aggiungi un nuovo indirizzo')}
          variant="ghost"
          icon={<Add />}
          iconPos="left"
          onClick={() => setNewFormOpen(true)}
        />
      )}
    </div>
  )
}

export default MyAccountIndirizziBlock
