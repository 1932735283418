import { Element } from '@craftjs/core'
import { TextEditor } from '../../../../components/TextEditor'
import { Text } from '../../../../components/Text'
import { Button } from '../../../../components/Button'
import { Image } from '../../../../components/Image'
import { Video } from '../../../../components/Video'
import { CustomCode } from '../../../../components/CustomCode'
import { ProductsCarousel } from '../../../../components/ProductsCarousel'
import { ProductCardBlock } from 'react-pagebuilder/components/ProductCardBlock'
import { Quote } from '../../../../components/Quote'
import { Separator } from '../../../../components/Separator'
import {
  Text as TextIcon,
  Image as ImageIcon,
  Button as ButtonIcon,
  Video as VideoIcon,
  Quote as QuoteIcon,
} from 'react-pagebuilder/pb-components/icons'

export const TEXT = (
  <Element is={Text}>
    <TextEditor block={{
      text: '<p>Hello world!</p>',
      className: "text__content"
    }} />
  </Element>
)

export const QUOTE = (
  <Element is={Quote}>
    <TextEditor block={{
      className: "quote__content"
    }} />
  </Element>
)

export const BUTTON = (
  <Element is={Button}>
    <TextEditor 
      block={{
        text: 'Click me\n', // '\n' alla fine perchè Quill lo aggiunge in automatico se noHtml
        noHtml: true,
        className: "button__label"
      }}
    />
  </Element>
)
export const CUSTOM_CODE = <CustomCode />

export const IMAGE = <Image block={{ src: 'https://www.w3schools.com/css/paris.jpg' }} />

export const VIDEO = <Video block={{ src: 'https://www.youtube.com/watch?v=_oPWtGNDhnc' }} />

export const PRODUCTS_CAROUSEL = (
  <Element is={ProductsCarousel}>
    <ProductCardBlock />
  </Element>
)

export const SEPARATOR = <Element canvas is={Separator}></Element>

// possibilità di inserire icona per il block
export const conf = [
  {
    title: 'Basic',
    blocks: {
      text: {
        name: 'Text',
        icon: <TextIcon />,
        block: TEXT,
      },
      quote: {
        name: 'Quote',
        icon: <QuoteIcon />,
        block: QUOTE,
      },
      button: {
        name: 'Button',
        icon: <ButtonIcon />,
        block: BUTTON,
      },
      image: {
        name: 'Image',
        icon: <ImageIcon />,
        block: IMAGE,
      },
      video: {
        name: 'Video',
        icon: <VideoIcon />,
        block: VIDEO,
      },
      product_carousel: {
        name: 'Products Carousel',
        block: PRODUCTS_CAROUSEL,
      },
    custom_code: {
        name: 'CustomCode',
        block: CUSTOM_CODE,
      },
      
    },
  },
  {
    title: 'Layout',
    blocks: {
      section: {
        name: 'Separator',
        block: SEPARATOR,
      },
    },
  },
]
