import { useEffect, useState, useContext } from 'react'
import PagebuilderContextProvider, { PagebuilderContext } from '../services/pagebuilderContext'
import CustomBlocksContextProvider, { CustomBlocksContext } from '../services/customBlocksContext'
import GlobalCustomBlocksContextProvider, {
  GlobalCustomBlocksContext,
} from '../services/globalCustomBlocksContext'
import GlobalContextProvider from '../services/globalContext'

import { Placeholder } from './Placeholder'

export const PageBuilder = ({ contentTypeId, objectId, pkey, editMode, draft }) => {

  return (
    <GlobalContextProvider
      contentTypeId={contentTypeId}
      objectId={objectId}
      pkey={pkey}
      editMode={editMode}
      draft={draft}
    >
      <PagebuilderContextProvider>
        <CustomBlocksContextProvider>
            <Placeholder />
        </CustomBlocksContextProvider>
      </PagebuilderContextProvider>
    </GlobalContextProvider>
  )
}
