import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function ChevronLeft({ className }: Props) {
  return (
    <IconBase name="chevron-left" w={24} className={className}>
      <path d="M16 20L8 12L16 4" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </IconBase>
  )
}

export default ChevronLeft
