import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { useTrans } from 'hooks'
import { getFormFieldStatus } from 'utils/safe'
import { useUpdatePasswordMutation } from 'gql/graphql'
import { Alert } from 'components/atoms/Icons'
import { FormField, FormInputPassword, FormLayout, NotificationBar } from 'components/atoms'
import { MyAccountInlineForm } from 'components/molecules'

const MyAccountFormPassword = ({ onCancel }) => {
  const t = useTrans()
  const [updatePassword] = useUpdatePasswordMutation()
  const [error, setError] = useState<string | undefined | null>()

  return (
    <div className="myaccount-form-password">
      <Formik
        validateOnBlur
        validateOnChange
        validationSchema={yup.object().shape({
          old_password: yup.string().required(t('Campo obbligatorio')),
          password: yup.string().min(6, t('Minimo 6 caratteri')).required(t('Campo obbligatorio')),
        })}
        onSubmit={(values) => {
          updatePassword({
            variables: {
              input: {
                oldPassword: values.old_password,
                newPassword: values.password,
              },
            },
          }).then((res) => {
            if (res.data?.updatePassword?.message) {
              setError(res.data?.updatePassword?.message)
            } else {
              setError(null)
              onCancel()
            }
          })
        }}
        initialValues={{ old_password: '', password: '' }}
      >
        {(props) => (
          <Form>
            <MyAccountInlineForm onCancel={onCancel}>
              <FormLayout>
                <FormField
                  label={t('Vecchia password')}
                  status={getFormFieldStatus(props, 'old_password')}
                  errorMessage={props?.errors?.old_password}
                >
                  <FormInputPassword
                    onBlur={props.handleBlur('old_password')}
                    togglePassword
                    value={props.values?.old_password}
                    onChange={props.handleChange('old_password')}
                  />
                </FormField>
                <FormField
                  label={t('Nuova password')}
                  status={getFormFieldStatus(props, 'password')}
                  errorMessage={props?.errors?.password}
                >
                  <FormInputPassword
                    onBlur={props.handleBlur('password')}
                    togglePassword
                    value={props.values?.password}
                    onChange={props.handleChange('password')}
                  />
                </FormField>
              </FormLayout>
            </MyAccountInlineForm>
            {error && (
              <NotificationBar
                label={error}
                variant="alert"
                icon={<Alert />}
                className="form-login__error"
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default MyAccountFormPassword
