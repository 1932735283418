import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Sort({ className }: Props) {
  return (
    <IconBase name="sort" w={24} className={className}>
      <line x1="7" y1="4" x2="7" y2="19" strokeWidth="2" fill="none"/>
      <path d="M11 15L7 19L3 15" strokeWidth="2" fill="none"/>
      <line x1="17" y1="20" x2="17" y2="5" strokeWidth="2" fill="none"/>
      <path d="M13 9L17 5L21 9" strokeWidth="2" fill="none"/>
    </IconBase>
  )
}

export default Sort
