import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function BlogShape2({ className }: Props) {
  return (
    <IconBase name="blog-shape2" w={70} className={className}>
      <path d="M15.9104 64.1636C33.8206 72.1292 72.1552 48.9719 69.1527 30.166C66.1502 11.3601 48.176 4.80183 22.0159 7.52647C-4.14426 10.2511 -6.03001 54.4054 15.9104 64.1636Z" stroke="none"/>
    </IconBase>
  )
}

export default BlogShape2
