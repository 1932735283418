import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Mail({ className }: Props) {
  return (
    <IconBase name="mail" w={24} className={className}>
      <path d="M20.5713 6H3.57129C3.019 6 2.57129 6.44772 2.57129 7V17C2.57129 17.5523 3.019 18 3.57129 18H20.5713C21.1236 18 21.5713 17.5523 21.5713 17V7C21.5713 6.44772 21.1236 6 20.5713 6Z" strokeWidth="2" fill="none"/>
      <path d="M3.07129 6.5L11.4527 13.0982C11.8156 13.3839 12.327 13.3839 12.6899 13.0982L21.0713 6.5" strokeWidth="2" fill="none"/>
      <path d="M14.4919 11.7139L21.0713 17.4999M3.07129 17.4999L9.59039 11.7669L3.07129 17.4999Z" strokeWidth="2" fill="none"/>
    </IconBase>
  )
}

export default Mail
