import React, { useState, useEffect, useRef } from 'react'
import { useTrans } from 'hooks'
import { mockArray, mockIndirizzo } from 'utils/mock'
import { Add, Close } from 'components/atoms/Icons'
import { Button } from 'components/atoms'
import { CheckoutStepBlock, CheckoutAddressOption } from 'components/molecules'
import { CheckoutShippingAddressForm } from 'components/organisms'
import { gql, useQuery, useMutation } from '@apollo/client'
import { SET_INDIRIZZI, CREATE_INDIRIZZO, UPDATE_INDIRIZZO, DELETE_INDIRIZZO } from 'utils/queries'
import { createIndirizzoInput, updateIndirizzoInput } from 'utils/indirizzo'

interface AddressFormProps {
  edit?: boolean
  indirizzo?: any
  formRef: any
  handleClose: any
  handleSubmit: any
  firstAdrress?: boolean
}

const AddressForm = (props: AddressFormProps) => {
  const {
    edit = false,
    indirizzo = null,
    formRef = null,
    firstAdrress = false,
    handleClose = () => {},
    handleSubmit = () => {},
  } = props

  const t = useTrans()

  return (
    <>
      <div className="checkout-shipping-address-step-block__new-address">
        <button
          type="button"
          className="checkout-shipping-address-step-block__new-address__close"
          onClick={handleClose}
        >
          <Close />
        </button>
        <div className="checkout-shipping-address-step-block__new-address__content">
          <p className="checkout-shipping-address-step-block__new-address__title">
            {edit ? t('Modifica indirizzo di spedizione') : t('Nuovo indirizzo di spedizione')}
          </p>
          <div className="checkout-shipping-address-step-block__new-address__form">
            <CheckoutShippingAddressForm
              formRef={formRef}
              indirizzo={indirizzo}
              hideSaveAddress={edit || firstAdrress ? true : false}
            />
          </div>
        </div>
        <div
          className={`checkout-shipping-address-step-block__new-address__actions ${
            firstAdrress ? `first-address` : ''
          }`}
        >
          {!firstAdrress && (
            <Button
              variant="ghost"
              label={edit ? t('Annulla') : t('Elimina indirizzo')}
              onClick={handleClose}
              className="checkout-shipping-address-step-block__new-address__action checkout-shipping-address-step-block__new-address__action--close"
            />
          )}
          <Button
            label={edit ? t('Salva modifiche') : t('Spedisci a questo indirizzo')}
            labelMobile={edit ? t('Salva modifiche') : t('Usa questo indirizzo')}
            className="checkout-shipping-address-step-block__new-address__action checkout-shipping-address-step-block__new-address__action--confirm"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </>
  )
}

interface Props {
  className?: string
  indirizziSpedizione?: any
  shippingAddressAttivo?: number
  checkoutLoading?: boolean
  shippingAddressRef?: any
  addedShippingAddress?: any
  setAddedShippingAddress?: (e: any) => void
  refetchSistemiSpedizione?: (e: any) => void
}

const CheckoutShippingAddressStepBlock = (props: Props) => {
  const {
    className = '',
    indirizziSpedizione = [],
    shippingAddressAttivo = null,
    checkoutLoading = false,
    shippingAddressRef = null,
    addedShippingAddress = null,
    setAddedShippingAddress = () => {},
    refetchSistemiSpedizione = () => {},
  } = props

  const [indirizzi, setIndirizzi] = useState([])

  useEffect(() => {
    if (indirizziSpedizione?.indirizzi)
      setIndirizzi(indirizziSpedizione.indirizzi.edges.map(({ node }) => node))
  }, [indirizziSpedizione])

  const [createIndirizzo] = useMutation(
    gql`
      ${CREATE_INDIRIZZO}
    `
  )
  const [updateIndirizzo] = useMutation(
    gql`
      ${UPDATE_INDIRIZZO}
    `
  )
  const [deleteIndirizzo] = useMutation(
    gql`
      ${DELETE_INDIRIZZO}
    `
  )

  const [setIndirizziCheckout] = useMutation(
    gql`
      ${SET_INDIRIZZI}
    `
  )

  const t = useTrans()
  const [addFormOpen, setAddFormOpen] = useState(false)
  const [indirizzoChecked, setIndirizzoChecked] = useState(null)
  const [checkNascosto, setCheckNascosto] = useState(false)

  const [indirizzoEdit, setIndirizzoEdit] = useState(null)

  const createShippingAddressRef = useRef<any>()

  const handleDelete = async (pk) => {
    deleteIndirizzo({
      variables: {
        input: {
          pk: pk,
        },
      },
      refetchQueries: ['IndirizziSpedizione', 'Checkout'],
    }).then((res) => {
      handleClose()
    })
  }

  const handleCreateShippingAddress = async () => {
    if (createShippingAddressRef?.current?.isValid) {
      const values = createShippingAddressRef.current.values

      await createIndirizzo({
        variables: {
          input: createIndirizzoInput(values),
        },
        refetchQueries: ['IndirizziSpedizione'],
      }).then((res) => {
        const indirizzo = res.data.createIndirizzo.indirizzo
        setAddedShippingAddress(indirizzo)
        handleSetIndirizziCheckout(indirizzo.pk)

        handleClose()
      })
    }
  }

  const handleCreateFirstShippingAddress = async () => {
    if (shippingAddressRef?.current?.isValid) {
      const values = shippingAddressRef.current.values
      values.save_address = true

      await createIndirizzo({
        variables: {
          input: createIndirizzoInput(values),
        },
        refetchQueries: ['IndirizziSpedizione'],
      }).then((res) => {
        const indirizzo = res.data.createIndirizzo.indirizzo
        setAddedShippingAddress(indirizzo)
        handleSetIndirizziCheckout(indirizzo.pk)

        handleClose()
      })
    }
  }

  const handleUpdateShippingAddress = async (pk) => {
    if (createShippingAddressRef?.current?.isValid) {
      const values = createShippingAddressRef.current.values

      await deleteIndirizzo({
        variables: {
          input: {
            pk: pk,
          },
        },
        //refetchQueries: ['IndirizziSpedizione', 'Checkout'],
      }).then((res) => {
        values.save_address = true

        createIndirizzo({
          variables: {
            input: createIndirizzoInput(values),
          },
          //refetchQueries: ['IndirizziSpedizione', 'Checkout'],
        }).then((res) => {
          const indirizzo = res.data.createIndirizzo.indirizzo
          if (pk === addedShippingAddress?.pk) {
            setAddedShippingAddress(indirizzo)
          }

          handleSetIndirizziCheckout(indirizzo.pk)

          handleClose()
        })
      })
    }
  }

  useEffect(() => {
    if (shippingAddressAttivo) {
      setIndirizzoChecked(shippingAddressAttivo)
    }
  }, [shippingAddressAttivo])

  useEffect(() => {
    if (!checkoutLoading && indirizzi.length > 0 && !shippingAddressAttivo) {
      handleSetIndirizziCheckoutClick(indirizzi[0].pk)
    }
    if (!checkoutLoading && indirizzi.length > 0 && !checkNascosto) {
      if (
        shippingAddressAttivo &&
        !indirizzi.some((indirizzo) => indirizzo.pk === shippingAddressAttivo)
      )
        handleSetIndirizziCheckoutClick(indirizzi[0].pk)
      setCheckNascosto(true)
    }
  }, [checkoutLoading, indirizzi])

  const handleSetIndirizziCheckoutClick = (newIndirizzoChecked) => {
    if (newIndirizzoChecked === indirizzoChecked) {
      return
    }

    handleSetIndirizziCheckout(newIndirizzoChecked)
  }

  const handleSetIndirizziCheckout = (newIndirizzoChecked) => {
    setIndirizziCheckout({
      variables: {
        indirizzoSpedizione: newIndirizzoChecked,
      },
      refetchQueries: ['IndirizziSpedizione', 'SistemiSpedizioniSenzaIndirizzo', 'Checkout'],
    })
      .then((res) => {
        setIndirizzoChecked(newIndirizzoChecked)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleOpen = (pk = null) => {
    if (pk) {
      setIndirizzoEdit(pk)
      setAddFormOpen(false)
    } else {
      setAddFormOpen(true)
      setIndirizzoEdit(null)
    }
  }

  const handleClose = () => {
    if (indirizzoEdit) {
      setIndirizzoEdit(null)
      setAddFormOpen(false)
    } else {
      setAddFormOpen(false)
    }
  }

  return (
    <CheckoutStepBlock
      titolo={t('Indirizzo di spedizione')}
      descrizione={
        indirizzi.length
          ? t(
              'Seleziona un indirizzo di spedizione tra quelli salvati oppure inseriscine uno nuovo.'
            )
          : t('Inserisci i tuoi dati di spedizione')
      }
      className={`checkout-shipping-address-step-block${className ? ` ${className}` : ''}`}
    >
      {indirizzi.length === 0 ? (
        <AddressForm
          formRef={shippingAddressRef}
          handleClose={() => handleClose()}
          handleSubmit={handleCreateFirstShippingAddress}
          firstAdrress={true}
        />
      ) : (
        <>
          <div className="checkout-shipping-address-step-block__list">
            {indirizzi.map((indirizzo) =>
              indirizzo.pk !== indirizzoEdit ? (
                <CheckoutAddressOption
                  key={`checkout-shipping-address-step-block__address-option__${indirizzo.pk}`}
                  indirizzo={indirizzo}
                  checked={indirizzoChecked === indirizzo.pk}
                  onClick={() => handleSetIndirizziCheckoutClick(indirizzo.pk)}
                  onEdit={() => handleOpen(indirizzo.pk)}
                />
              ) : (
                <AddressForm
                  key={`checkout-shipping-address-step-block__address-option__${indirizzo.pk}`}
                  edit={true}
                  indirizzo={indirizzo}
                  formRef={createShippingAddressRef}
                  handleClose={() => handleClose()}
                  handleSubmit={() => handleUpdateShippingAddress(indirizzo.pk)}
                />
              )
            )}
            {addedShippingAddress && addedShippingAddress.nascondi && (
              <>
                {addedShippingAddress.pk !== indirizzoEdit ? (
                  <CheckoutAddressOption
                    indirizzo={addedShippingAddress}
                    checked={indirizzoChecked === addedShippingAddress.pk}
                    onClick={() => handleSetIndirizziCheckoutClick(addedShippingAddress.pk)}
                    onEdit={() => handleOpen(addedShippingAddress.pk)}
                  />
                ) : (
                  <AddressForm
                    edit={true}
                    indirizzo={addedShippingAddress}
                    formRef={createShippingAddressRef}
                    handleClose={() => handleClose()}
                    handleSubmit={() => handleUpdateShippingAddress(addedShippingAddress.pk)}
                  />
                )}
              </>
            )}
          </div>
          {addFormOpen ? (
            <AddressForm
              formRef={createShippingAddressRef}
              handleClose={() => handleClose()}
              handleSubmit={handleCreateShippingAddress}
            />
          ) : !addedShippingAddress ? (
            <div className="checkout-shipping-address-step-block__add">
              <Button
                variant="ghost"
                label={t('Invia ad un indirizzo diverso')}
                icon={<Add />}
                iconPos="left"
                onClick={() => handleOpen()}
              />
            </div>
          ) : null}
        </>
      )}
    </CheckoutStepBlock>
  )
}

export default CheckoutShippingAddressStepBlock
