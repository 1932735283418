import React from 'react'

interface Props {
  label: string
  value: any
  className?: string
}

const MyAccountDatiOrdine = (props: Props) => {
  const { label = '', value = '', className = '' } = props

  return (
    <div className={`myaccount-dati-ordine${className ? ` ${className}` : ''}`}>
      <p className="myaccount-dati-ordine__label">{label}</p>
      <p className="myaccount-dati-ordine__value">{value}</p>
    </div>
  )
}

export default MyAccountDatiOrdine
