import React, { useEffect, useState } from 'react'
import { useLocalStorage, useTrans } from 'hooks'
import { useSelector } from 'react-redux'
import { ImageBox, Modal } from 'components/atoms'
import { NewsletterForm } from 'components/organisms'


const NewsletterModal = () => {

  const t = useTrans()
  const { menu } = useSelector((state: any) => state.menu)

  const [open, setOpen] = useState(false)
  const [showNewsletterPopup, setShowNewsletterPopup] = useLocalStorage('newsletterPopup', true)

  const modalNewsletterItem = menu?.edges?.filter(
    ({ node }) => node?.key === 'newslettermodal' && node?.attivo === true
  )?.[0]?.node

  useEffect(() => {
    if (showNewsletterPopup) {
      setTimeout(() =>setOpen(true),3000)
      setShowNewsletterPopup(false)
    }
  }, [showNewsletterPopup])

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      size="lg"
      className='newsletterModal'
      closeButton
    >
      <div className='newsletterContainer'>
        <ImageBox
          src={modalNewsletterItem?.image}
          maxWidth={150}
          maxHeight={150}
          classPrefix="newsletter-modal"
        />
        <p className="newsletter-modal__title">
          {modalNewsletterItem?.name}
        </p>
        <div className="newsletter-modal__description" dangerouslySetInnerHTML={{ __html: modalNewsletterItem?.description }}/>
      </div>
      <NewsletterForm textSubmit={modalNewsletterItem?.textCta} className="newsletter-modal__form" setOpen={setOpen} />
    </Modal>
  )
}

export default NewsletterModal
