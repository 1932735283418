import React from 'react'
import { useTrans } from 'hooks'
import { ImageBox } from 'components/atoms'

interface Props {
  cantina: {
    descrizione?: string
    storiaImageUrl?: string
  }
  className?: string
}

const CantinaStoria = (props: Props) => {
  const {
    cantina = {
      descrizione: '',
      storiaImageUrl: '',
    },
    className = '',
  } = props

  const t = useTrans()

  return (
    <div className={`cantina-storia ${className ? ` ${className}` : ''}`}>
      <div className="cantina-storia__content">
        <p className="cantina-storia__title">{t('La storia')}</p>
        <div
          className="cantina-storia__description"
          dangerouslySetInnerHTML={{ __html: cantina.descrizione }}
        />
      </div>

      {!!cantina.regione.image && (
        <ImageBox
          src={cantina.regione.image}
          maxWidth={343}
          maxHeight={210}
          classPrefix="cantina-storia"
        />
      )}
    </div>
  )
}

export default CantinaStoria
