import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function FaqAssistenza({ className }: Props) {
  return (
    <IconBase name="faq-assistenza" w={32} className={className}>
      <path d="M21.55 26.8803C21.52 26.8803 21.48 26.8803 21.45 26.8803C18.78 26.7303 16.14 25.9303 13.82 24.5803C12.51 23.8203 11.34 22.9203 10.33 21.9103C9.28998 20.8703 8.36998 19.6603 7.59998 18.3103C6.28998 16.0203 5.50998 13.4203 5.35998 10.7903C5.30998 9.92026 5.87998 9.14026 6.71998 8.94026L9.00998 8.39026C9.47998 8.28026 9.98998 8.37026 10.4 8.63026C10.81 8.90026 11.09 9.33026 11.17 9.82026C11.31 10.6503 11.52 11.4603 11.8 12.2403C12 12.8003 11.93 13.4103 11.6 13.8903L10.23 15.8303C10.95 17.1203 11.85 18.3103 12.89 19.3503C13.8 20.2603 14.85 21.0703 16 21.7603L17.46 20.4703C17.98 20.0103 18.7 19.9003 19.36 20.1803C20.34 20.6003 21.37 20.9003 22.42 21.0703C22.9 21.1503 23.34 21.4303 23.61 21.8403C23.88 22.2503 23.97 22.7403 23.85 23.2303L23.3 25.5203C23.11 26.3303 22.38 26.8803 21.55 26.8803ZM9.42998 9.34026C9.36998 9.34026 9.30998 9.35026 9.24998 9.36026L6.94998 9.92026C6.58998 10.0003 6.33998 10.3503 6.35998 10.7403C6.49998 13.2103 7.22998 15.6603 8.46998 17.8203C9.19998 19.0903 10.06 20.2303 11.04 21.2103C11.98 22.1603 13.09 23.0003 14.32 23.7203C16.5 24.9903 18.99 25.7403 21.5 25.8903C21.9 25.9103 22.24 25.6603 22.33 25.3003L22.88 23.0003C22.93 22.7903 22.89 22.5803 22.78 22.4003C22.66 22.2203 22.48 22.1003 22.26 22.0603C21.13 21.8703 20.03 21.5503 18.97 21.1003C18.67 20.9703 18.35 21.0203 18.13 21.2203L16.4 22.7503C16.24 22.8903 16.01 22.9203 15.82 22.8103C14.46 22.0403 13.24 21.1103 12.19 20.0603C11 18.8703 9.99998 17.5203 9.19998 16.0303C9.10998 15.8603 9.11998 15.6603 9.22998 15.5103L10.78 13.3203C10.92 13.1203 10.95 12.8403 10.86 12.5903C10.56 11.7503 10.33 10.8803 10.18 9.99026C10.14 9.77026 10.03 9.59026 9.84998 9.47026C9.71998 9.38026 9.57998 9.34026 9.42998 9.34026Z" stroke="none"/>
      <path d="M22.94 16.2603C22.8 16.2603 22.69 16.1503 22.69 16.0103C22.69 12.1803 19.57 9.07031 15.75 9.07031C15.61 9.07031 15.5 8.96031 15.5 8.82031C15.5 8.68031 15.61 8.57031 15.75 8.57031C19.85 8.57031 23.19 11.9103 23.19 16.0103C23.19 16.1503 23.08 16.2603 22.94 16.2603Z" stroke="none"/>
      <path d="M26.39 16.2601C26.25 16.2601 26.14 16.1501 26.14 16.0101C26.14 10.2801 21.48 5.62012 15.75 5.62012C15.61 5.62012 15.5 5.51012 15.5 5.37012C15.5 5.23012 15.61 5.12012 15.75 5.12012C21.76 5.12012 26.64 10.0101 26.64 16.0101C26.64 16.1501 26.53 16.2601 26.39 16.2601Z" stroke="none"/>
    </IconBase>
  )
}

export default FaqAssistenza
