import React from 'react'
import { BootstrapOverlayTrigger, BootstrapTooltip } from 'components/atoms'

export interface Props {
  label: any
  id: string
  size?: 'md'
  className?: string
  pos?: 'top' | 'right' | 'bottom' | 'left'
  children?: any
}

const Tooltip = (mainProps: Props) => {
  const {
    label = '',
    id = '',
    pos = 'top',
    size = 'md',
    className = '',
    children = null,
  } = mainProps

  const classNames = `tooltip tooltip--${size} ${className ? ` ${className}` : ''}`

  const renderTooltip = (props: any) => (
    <BootstrapTooltip id={id} className={classNames} {...props}>
      {label}
    </BootstrapTooltip>
  )

  return (
    <BootstrapOverlayTrigger placement={pos} delay={{ show: 50, hide: 50 }} overlay={renderTooltip}>
      {children}
    </BootstrapOverlayTrigger>
  )
}

export default Tooltip
