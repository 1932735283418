import React from 'react'
import { useTrans } from 'hooks'
import { Check, LocalShipping } from 'components/atoms/Icons'

interface ItemProps {
  index: number
  step: string
  status: 'past' | 'active' | 'future'
}

interface Props {
  activeStepIndex: number
  className?: string
}

const spedizioneSteps = ['Confermato', 'In spedizione', 'Spedito', 'In consegna', 'Consegnato']

const SpedizioneStepBarItem = (props: ItemProps) => {
  const { index = 1, step = '', status = 'future' } = props

  const t = useTrans()

  return (
    <span className={`spedizione-step-bar-item spedizione-step-bar-item--${status}`}>
      <span className="spedizione-step-bar-item__content">
        <span className="spedizione-step-bar-item__label">{t(step)}</span>
        <span className="spedizione-step-bar-item__dot">
          {status === 'past' ? <Check /> : status === 'active' ? <LocalShipping /> : <></>}
        </span>
      </span>
    </span>
  )
}

const SpedizioneStepBar = (props: Props) => {

  const {  
    activeStepIndex = null,
    className = '',
  } = props

  return (
    <div className={`spedizione-step-bar${className ? ` ${className}` : ''}`}>
      {spedizioneSteps.map((step, index) => (
        <SpedizioneStepBarItem
          key={index}
          index={index + 1}
          step={step}
          status={
            index + 1 === activeStepIndex ? 'active' : index < activeStepIndex ? 'past' : 'future'
          }
        />
      ))}
    </div>
  )
}

export default SpedizioneStepBar
