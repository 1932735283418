import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function BlogBuonoASapersi({ className }: Props) {
  return (
    <IconBase name="blog-buono-a-sapersi" w={32} className={className}>
      <path d="M8.18994 13.1299H18.9199C19.0599 13.1299 19.1699 13.0199 19.1699 12.8799C19.1699 12.7399 19.0599 12.6299 18.9199 12.6299H8.18994C8.04994 12.6299 7.93994 12.7399 7.93994 12.8799C7.93994 13.0199 8.04994 13.1299 8.18994 13.1299Z" stroke="none"/>
      <path d="M28.1099 10.1798C28.0899 10.0498 28.0199 9.92984 27.9099 9.84984L24.7799 7.53984C24.5599 7.37984 24.2399 7.41984 24.0799 7.64984L23.2199 8.80984V4.08984C23.2199 3.80984 22.9999 3.58984 22.7199 3.58984H9.60988C9.58988 3.58984 9.56988 3.59984 9.54988 3.59984C9.52988 3.59984 9.50988 3.60984 9.48988 3.60984C9.38988 3.63984 9.31988 3.66984 9.24988 3.73984L4.02988 8.95984C3.95988 9.01984 3.92988 9.09984 3.90988 9.18984C3.89988 9.20984 3.89988 9.22984 3.88988 9.24984C3.88988 9.26984 3.87988 9.28984 3.87988 9.31984V27.9098C3.87988 28.1898 4.09988 28.4098 4.37988 28.4098H22.7299C23.0099 28.4098 23.2299 28.1898 23.2299 27.9098V17.0498L28.0199 10.5498C28.0999 10.4398 28.1299 10.3098 28.1099 10.1798ZM9.10988 5.29984V8.81984H5.58988L9.10988 5.29984ZM22.2299 27.4098H4.87988V9.81984H9.60988C9.88988 9.81984 10.1099 9.59984 10.1099 9.31984V4.58984H22.2399V10.1698L17.3199 16.8398H8.18988C8.04988 16.8398 7.93988 16.9498 7.93988 17.0898C7.93988 17.2298 8.04988 17.3398 8.18988 17.3398H16.9499L15.3999 19.4398C15.3599 19.4998 15.3299 19.5698 15.3199 19.6398C15.3199 19.6398 15.3199 19.6498 15.3099 19.6498L15.0599 21.0398H8.18988C8.04988 21.0398 7.93988 21.1498 7.93988 21.2898C7.93988 21.4298 8.04988 21.5398 8.18988 21.5398H14.9699L14.5399 23.9698C14.5099 24.1598 14.5799 24.3498 14.7399 24.4598C14.8299 24.5198 14.9299 24.5598 15.0399 24.5598C15.1199 24.5598 15.1999 24.5398 15.2699 24.4998L19.1599 22.4898H19.1699C19.1899 22.4798 19.1899 22.4598 19.2099 22.4498C19.2599 22.4198 19.3099 22.3898 19.3399 22.3398L22.2399 18.4098V27.4098H22.2299ZM16.1499 20.6098L17.9799 21.9598L15.6899 23.1398L16.1499 20.6098ZM18.8299 21.3398L16.4999 19.6298L24.5999 8.63984L26.9299 10.3498L18.8299 21.3398Z" stroke="none"/>
    </IconBase>
  )
}

export default BlogBuonoASapersi
