import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Pagamenti({ className }: Props) {
  return (
    <IconBase name="pagamenti" w={80} className={className}>
      <g clipPath="url(#pagamenti-clip0)">
      <path d="M60.85 17.5H25.85C24.0266 17.5 22.2779 18.2243 20.9886 19.5136C19.6993 20.803 18.975 22.5516 18.975 24.375V26.45H16.9C15.0895 26.4432 13.3494 27.1509 12.0576 28.4194C10.7657 29.6878 10.0263 31.4147 10 33.225V54.95C10.0066 56.769 10.7339 58.5113 12.0225 59.7952C13.3111 61.0791 15.056 61.8 16.875 61.8H51.875C53.694 61.8 55.4389 61.0791 56.7275 59.7952C58.0161 58.5113 58.7434 56.769 58.75 54.95V52.875H60.85C62.6734 52.875 64.422 52.1507 65.7113 50.8614C67.0007 49.572 67.725 47.8234 67.725 46V24.375C67.725 22.5516 67.0007 20.803 65.7113 19.5136C64.422 18.2243 62.6734 17.5 60.85 17.5ZM16.875 28.95H51.875C52.4504 28.9334 53.0234 29.0311 53.5608 29.2376C54.0981 29.4441 54.5892 29.7551 55.0054 30.1528C55.4217 30.5504 55.7549 31.0267 55.9857 31.5541C56.2165 32.0815 56.3403 32.6494 56.35 33.225V35.25H12.5V33.225C12.5261 32.0821 12.9986 30.9948 13.8162 30.1959C14.6339 29.3969 15.7318 28.9497 16.875 28.95ZM12.5 36.575H56.35V41.575H12.5V36.575ZM56.35 55.025C56.35 55.5984 56.2367 56.1661 56.0165 56.6955C55.7963 57.2249 55.4737 57.7055 55.0671 58.1098C54.6605 58.5141 54.178 58.834 53.6474 59.0511C53.1167 59.2682 52.5483 59.3783 51.975 59.375H16.975C15.819 59.375 14.71 58.9175 13.8902 58.1025C13.0705 57.2874 12.6066 56.181 12.6 55.025V42.925H56.35V55.025ZM65.3 46.075C65.2967 46.6549 65.1789 47.2285 64.9531 47.7628C64.7274 48.297 64.3983 48.7813 63.9848 49.1879C63.5712 49.5945 63.0814 49.9153 62.5434 50.1319C62.0054 50.3485 61.4299 50.4566 60.85 50.45H58.85V33.225C58.8434 31.406 58.1161 29.6637 56.8275 28.3798C55.5389 27.0959 53.794 26.375 51.975 26.375H21.45V24.3C21.4697 23.1484 21.9428 22.051 22.7665 21.246C23.5903 20.441 24.6982 19.9932 25.85 20H60.85C62.0103 20 63.1231 20.4609 63.9436 21.2814C64.7641 22.1019 65.225 23.2147 65.225 24.375L65.3 46.075Z" stroke="none"/>
      <path d="M28.8001 48.7754H17.6001C17.4343 48.7754 17.2754 48.8412 17.1582 48.9584C17.041 49.0757 16.9751 49.2346 16.9751 49.4004C16.9751 49.5662 17.041 49.7251 17.1582 49.8423C17.2754 49.9595 17.4343 50.0254 17.6001 50.0254H28.8001C28.9659 50.0254 29.1248 49.9595 29.242 49.8423C29.3593 49.7251 29.4251 49.5662 29.4251 49.4004C29.4251 49.2346 29.3593 49.0757 29.242 48.9584C29.1248 48.8412 28.9659 48.7754 28.8001 48.7754Z" stroke="none"/>
      <path d="M22.8501 53.2246H17.6001C17.4343 53.2246 17.2754 53.2905 17.1582 53.4077C17.041 53.5249 16.9751 53.6838 16.9751 53.8496C16.9751 54.0154 17.041 54.1743 17.1582 54.2916C17.2754 54.4088 17.4343 54.4746 17.6001 54.4746H22.8501C23.0159 54.4746 23.1748 54.4088 23.292 54.2916C23.4093 54.1743 23.4751 54.0154 23.4751 53.8496C23.4751 53.6838 23.4093 53.5249 23.292 53.4077C23.1748 53.2905 23.0159 53.2246 22.8501 53.2246Z" stroke="none"/>
      </g>
      <defs>
      <clipPath id="pagamenti-clip0">
      <rect width="57.8" height="44.4" transform="translate(10 17.5)" stroke="none"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default Pagamenti
