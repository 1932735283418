import React from 'react'
import { useTrans } from 'hooks'
import { Info } from 'components/atoms/Icons'
import { NotificationBar } from 'components/atoms'


interface Props {
  showNotification?: boolean
  showError?: boolean
  messageError?: string
  messageSuccess?: string
  onClose?: () => void
  className?: string
}

const NotificationViewer = (props: Props) => {
  const { 
    showNotification = false,
    showError = false,
    messageError = '',
    messageSuccess = '',
    onClose = () => {},
    className = '',
  } = props

  const t = useTrans()

  return (
    <div
        className={`notification-viewer${
          showNotification || showError
            ? " notification-viewer--visible"
            : ""
        }`}
      >
        <div
          className={`notification-viewer__success${
            showNotification
              ? " notification-viewer__success--visible"
              : ""
          }`}
        >
          {showNotification && <NotificationBar
            icon={<Info />}
            label={t(messageSuccess)}
            variant="success"
          />}
        </div>
        <div
          className={`notification-viewer__error${
            showError ? " notification-viewer__error--visible" : ""
          }`}
        >
          {showError && <NotificationBar
            label={messageError}
            variant="alert"
            onClose={onClose}
          />}
        </div>
      </div>
  )
}

export default NotificationViewer
