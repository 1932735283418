/* eslint-disable react/forbid-dom-props */
import React, { useState } from 'react'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'
import { Share } from 'components/atoms/Icons'

interface Props {
  className?: string
  shareUrl?: string
}

const ShareButtons = ({
  className,
  shareUrl = typeof window !== 'undefined' ? window.location.href : '',
}: Props) => {
  const [show, setShow] = useState(false)
  const socialButtonProps = {
    size: 16,
    url: shareUrl,
    iconFillColor: 'white',
  }

  const socialIconProps = {
    bgStyle: { ...{ fill: '#821230' } },
  }

  return (
    <>
      <div className="prodotto-main__share">
        {show ? (
          <div className={className} style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
            <TwitterShareButton {...socialButtonProps}>
              <TwitterIcon {...socialIconProps} />
            </TwitterShareButton>
            <EmailShareButton {...socialButtonProps}>
              <EmailIcon {...socialIconProps} />
            </EmailShareButton>
            <FacebookShareButton {...socialButtonProps}>
              <FacebookIcon {...socialIconProps} />
            </FacebookShareButton>

            <TelegramShareButton {...socialButtonProps}>
              <TelegramIcon {...socialIconProps} />
            </TelegramShareButton>

            <WhatsappShareButton {...socialButtonProps}>
              <WhatsappIcon {...socialIconProps} />
            </WhatsappShareButton>

            {/* <LinkedinShareButton {...socialButtonProps}>
              <LinkedinIcon {...socialIconProps} />
            </LinkedinShareButton> */}
            <button
              type="button"
              onClick={() => setShow((prev) => !prev)}
              style={{ cursor: 'pointer' }}
            >
              <Share />
            </button>
          </div>
        ) : (
          <button
            type="button"
            onClick={() => setShow((prev) => !prev)}
            style={{ cursor: 'pointer' }}
          >
            <Share />
          </button>
        )}
      </div>
    </>
  )
}

export default ShareButtons
