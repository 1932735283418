import React, { useState, useRef } from 'react'
import { getIconFiltriByChiave } from 'utils/icon'
import { CheckboxMarked, CheckboxUnmarked } from 'components/atoms/Icons'
import { trackingGAevent } from 'utils/gtm'

interface Props {
  filtro: {
    id: number
    chiave?: string
    label?: string
    valori_disponibili?: {
      id: number
      label?: string
      selezionato?: boolean
      disabilitato?: boolean
    }[]
  }
  onValoreToggle?: (a: number, v: number) => void
  className?: string
}

const FiltroValori = (props: Props) => {
  const {
    filtro = {
      id: 0,
      chiave: '',
      label: '',
      valori_disponibili: [],
    },
    onValoreToggle = () => {},
    className = '',
  } = props

  const listContentRef = useRef()
  const [open, setOpen] = useState(false)

  const handleSelection = (valore_id: number, valoreLabel: string) => {
    onValoreToggle(filtro.id, valore_id)
    trackingGAevent(6, 'listing', filtro.label, valoreLabel)
  }

  return (
    <div
      className={`filtro-valori${open ? ' filtro-valori--open' : ''}${
        className ? ` ${className}` : ''
      }`}
    >
      <button type="button" className="filtro-valori__head" onClick={() => setOpen(!open)}>
        <span className="filtro-valori__icon">{getIconFiltriByChiave(filtro.chiave)}</span>
        <span className="filtro-valori__title">{filtro.label}</span>
      </button>
      <div
        className="filtro-valori__list"
        style={{
          maxHeight: listContentRef?.current && open ? listContentRef.current.clientHeight : 0,
        }}
      >
        <div className="filtro-valori__list-content" ref={listContentRef}>
          {filtro.valori_disponibili.map((valore) => (
            <button
              type="button"
              disabled={valore.disabilitato}
              onClick={() => handleSelection(valore.id, valore.label)}
              className={`filtro-valori__item ${
                valore.disabilitato ? 'filtro-valori__item--disabled' : ''
              }`}
              key={`filtro-valori__list-content__item__${valore.id}`}
            >
              <span className="filtro-valori__item__icon">
                {valore.selezionato ? (
                  <CheckboxMarked />
                ) : (
                  <CheckboxUnmarked />
                )}
              </span>
              <span className="filtro-valori__item__label">{valore.label}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FiltroValori
