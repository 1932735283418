import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Download({ className }: Props) {
  return (
    <IconBase name="download" w={24} className={className}>
      <line x1="12" y1="4" x2="12" y2="16" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M18 11L12 17L6 11" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <line x1="19" y1="20" x2="5" y2="20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
    </IconBase>
  )
}

export default Download
