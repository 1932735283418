import React from 'react'
import Link from 'next/link'
import { Close } from 'components/atoms/Icons'

interface Props {
  label: string
  removable?: boolean
  href?: string
  onClick?: (e: any) => void
}

const Chip = (props: Props) => {
  const { label = '', removable = false, href = '', onClick = () => {} } = props

  const chipContent = (
    <>
      <div className="chip__label">{label}</div>
      {removable && (
        <span className="chip__icon">
          <Close />
        </span>
      )}
    </>
  )

  return removable || onClick ? (
    <button type="button" className="chip chip--clickable" onClick={onClick}>
      {chipContent}
    </button>
  ) : href ? (
    <Link legacyBehavior href={href}>
      <a className="chip">{chipContent}</a>
    </Link>
  ) : (
    <div className="chip">{chipContent}</div>
  )
}

export default Chip
