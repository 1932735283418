

export const tipoToPrivato = (tipo = "1") => {
    return tipo === "1" ? "privato" : tipo === "2" ? "azienda" : "libero"
}
export const privatoToTipo = (privato) => {
    return privato === "privato" ? "1" : privato === "azienda" ? "2" : privato === "libero" ? "3" : ""
}


export const createIndirizzoInput = (values) => {
    return {
        privato: tipoToPrivato(values.tipo),
        nome: values.tipo === "2" ? values.ragione_sociale : values.nome,
        cognome: values.cognome,
        presso: values.presso,
        indirizzo: values.indirizzo,
        citta: values.citta,
        cap: values.cap,
        provincia: values.provincia,
        telefono: values.telefono,
        cf: values.cf,
        piva: values.piva,
        codiceDestinatarioUnivoco: values.codice_destinatario_sdi,
        pec: values.pec,
        saveAddress: values.save_address,
        isFatturazione: values.is_fatturazione,
        nazione: values.nazione? values.nazione : 1
    }
}

export const updateIndirizzoInput = (pk, values) => {
    return {
        pk: pk,
        privato: tipoToPrivato(values.tipo),
        nome: values.tipo === "2" ? values.ragione_sociale : values.nome,
        cognome: values.cognome,
        presso: values.presso,
        indirizzo: values.indirizzo,
        citta: values.citta,
        cap: values.cap,
        provincia: values.provincia,
        telefono: values.telefono,
        cf: values.cf,
        piva: values.piva,
        codiceDestinatarioUnivoco: values.codice_destinatario_sdi,
        pec: values.pec,
        nazione: values.nazione
    }
}