

export const getPromoLabel = (prodotto) => {
    let label = ""
    if (prodotto.promoApplicata && prodotto.promoApplicata.mostraCounter) {
      if(prodotto.promoApplicata.percentuale) {
        label += `- ${prodotto.promoApplicata.valore}%`
      }
      else {
        label += `- ${prodotto.promoApplicata.valore}€`
      }
      label += ` ancora per ${prodotto.promoApplicata.timeUntil}`
    }
    return label
}

export const getPromoBundleLabel = (prodotto) => {
  let label = "SCONTO KIT "
    if (prodotto.promoApplicataNome) {
      let percentuale = Math.round(((prodotto.prezzo - prodotto.prezzoScontato) / prodotto.prezzo)*100)
      label += `-${percentuale}%`
    }
    return label
}

export const getSconto = (prodotto) => {
  let label = ""
  if(prodotto.promoApplicata && prodotto.promoApplicata.mostraCounter) {
    if(prodotto.promoApplicata.percentuale) {
      label += `-${prodotto.promoApplicata.valore}%`
    }
    else {
      label += `-${prodotto.promoApplicata.valore}€`
    }
  }
  return label
}
