import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { BRAND, DOMAIN } from 'utils/settings'

// DOCS https://github.com/garmeeh/next-seo

const MetaSeo = ({ seo, additionalMetaTags = null }) => {
  const { title, description, image } = seo

  const router = useRouter()
  const getCanonical = () => {
    return `${
      router.locale === 'en'
        ? router.asPath != '/'
          ? `${DOMAIN}/${router.locale}${router.asPath
              .split('?')[0]
              .split('/f/')[0]
              ?.toLowerCase()}`
          : DOMAIN + '/en'
        : router.asPath != '/'
        ? `${DOMAIN}${router.asPath.split('?')[0].split('/f/')[0]?.toLowerCase()}`
        : DOMAIN
    }`
  }
  //Creato fisso solo per IT e EN
  const getHreflang = () => {
    const hreflangIt =
      router.asPath != '/' ? DOMAIN + router.asPath.split('?')[0].split('/f/')[0] : DOMAIN
    const hreflangEn =
      router.asPath != '/'
        ? DOMAIN + '/en' + router.asPath.split('?')[0].split('/f/')[0]
        : DOMAIN + '/en'

    return [
      { hrefLang: 'it', href: hreflangIt.toLowerCase() },
      { hrefLang: 'en', href: hreflangEn.toLowerCase() },
    ]
  }

  const hreflangs = getHreflang()

  // TODO: default static image
  const ogImage = image ? image : ''

  const og = {
    locale: 'it_IT',
    site_name: BRAND,
    url: DOMAIN + router.asPath,
    title: title,
    description: description,
    images: ogImage ? [{ url: ogImage }] : [], // vl1 modifica qui
    type: 'website',
  }

  const canonical = getCanonical()

  return (
    <NextSeo
      title={title}
      description={description}
      additionalMetaTags={additionalMetaTags}
      openGraph={og}
      canonical={canonical}
      languageAlternates={hreflangs}
    />
  )
}

export default MetaSeo
