import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function ColoreVino({ className }: Props) {
  return (
    <IconBase name="colore-vino" w={16} className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M1.78223 6.22223C1.89913 9.43298 4.53888 12 7.77819 12C11.0175 12 13.6572 9.43298 13.7741 6.22223H1.78223Z" stroke="none"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0.748479 2.66667C0.268546 3.67695 0 4.80712 0 6C0 10.2955 3.48223 13.7778 7.77778 13.7778C12.0733 13.7778 15.5556 10.2955 15.5556 6C15.5556 4.80712 15.287 3.67695 14.8071 2.66667H13.808C14.3551 3.65443 14.6667 4.79083 14.6667 6C14.6667 9.80463 11.5824 12.8889 7.77778 12.8889C3.97315 12.8889 0.888889 9.80463 0.888889 6C0.888889 4.79083 1.20042 3.65443 1.74759 2.66667H0.748479Z" stroke="none" fill="#989596" />
    </IconBase>
  )
}

export default ColoreVino
