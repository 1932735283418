import React from 'react'

interface Props {
  variant?: '' | 'md' | 'sm' | 'xs' | 'none' | 'fluid'
  children?: any
  id?: string
}

const WContainer = (props: Props) => {
  const { variant = '', children = null, id } = props

  return variant === 'none' ? (
    <>{children}</>
  ) : (
    <div id={id} className={`w-container${variant ? `-${variant}` : ''}`}>
      {children}
    </div>
  )
}

export default WContainer
