import React, { useState } from 'react'
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useTrans } from 'hooks'
import { Add } from 'components/atoms/Icons'
import { Button, ImageBox, Modal, WContainer } from 'components/atoms'

SwiperCore.use([Pagination])

interface Props {
  prodotti: {
    variante: {
      prodotto: {
        pk?: number
        nomeFantasia?: string
        nome?: string
        descrizione?: string
        mainImage?: string
        url?: string
      }
    }
  }[]
  className?: string
}

interface ItemProps {
  prodotto: {
    nomeFantasia?: string
    nome?: string
    descrizione?: string
    mainImage?: string
    url?: string
  }
  setModalOpen: (v: boolean) => void
}

interface ModalItemProps {
  prodotto: {
    nomeFantasia?: string
    nome?: string
    descrizione?: string
    mainImage?: string
    url?: string
    disponibilita?: number
    acquistabile?: boolean
  }
  open: boolean
  setOpen: (v: boolean) => void
}

const ProdottiKitItemModal = (props: ModalItemProps) => {
  const {
    prodotto = {
      pk: null,
      nomeFantasia: '',
      nome: '',
      descrizione: '',
      mainImage: '',
      url: '',
      disponibilita: 0,
      acquistabile: false,
    },
    open = false,
    setOpen = () => {},
  } = props

  const t = useTrans()

  return (
    <Modal open={open} setOpen={setOpen} closeButton className="prodotti-kit-item__modal">
      <p className="prodotti-kit-item__modal__title">{prodotto.nomeFantasia}</p>
      <div
        className="prodotti-kit-item__modal__description"
        dangerouslySetInnerHTML={{ __html: prodotto.descrizione }}
      />
      {(prodotto.disponibilita !== 0 && prodotto.acquistabile) &&
      <Button
        label={t('Vai alla scheda prodotto')}
        variant="secondary"
        size="sm"
        href={prodotto.url}
      />
      }
    </Modal>
  )
}

const ProdottiKitItem = (props: ItemProps) => {
  const {
    prodotto = {
      pk: null,
      nomeFantasia: '',
      nome: '',
      descrizione: '',
      mainImage: '',
      url: '',
    },
    setModalOpen = () => {},
  } = props

  const t = useTrans()

  return (
    <>
      <div className="prodotti-kit-item">
        <ImageBox
          src={prodotto.mainImage}
          maxWidth={336}
          maxHeight={281}
          classPrefix="prodotti-kit-item"
        />
        <p className="prodotti-kit-item__title">{prodotto.nomeFantasia}</p>
        <div className="prodotti-kit-item__actions">
          <Button
            label={t('Mostra di più')}
            icon={<Add />}
            iconPos="right"
            variant="ghost"
            onClick={() => setModalOpen(true)}
            responsive
          />
        </div>
      </div>
    </>
  )
}

const ProdottiKit = (props: Props) => {
  const { prodotti = [], className = '' } = props

  const t = useTrans()
  const [openModalId, setopenModalId] = useState(null)

  return (
    <div className={`prodotti-kit${className ? ` ${className}` : ''}`}>
      <WContainer>
        <p className="prodotti-kit__title">{t("I prodotti all'interno del kit")}</p>
        <div className="prodotti-kit__list">
          <Swiper
            spaceBetween={16}
            slidesPerView={1}
            pagination={{ clickable: true }}
            watchOverflow
            breakpoints={{
              320: {
                slidesPerView: 1.5,
                enabled: true,
              },
              576: {
                slidesPerView: 2.5,
                enabled: true,
              },
              991: {
                slidesPerView: 3,
                enabled: true,
              },
            }}
          >
            {prodotti.map((item) => (
              <SwiperSlide key={`prodotto-kit__${item.variante.prodotto.pk}`}>
                <ProdottiKitItem
                  prodotto={item.variante.prodotto}
                  setModalOpen={(v) => setopenModalId(v ? item.variante.prodotto.pk : null)}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          {/* Le modal vanno a parte se no con Swiper si sminchiano */}
          {prodotti.map((item) => (
            <ProdottiKitItemModal
              prodotto={item.variante.prodotto}
              open={openModalId === item.variante.prodotto.pk}
              setOpen={(v) => setopenModalId(v ? item.variante.prodotto.pk : null)}
              key={item.variante.prodotto.pk}
            />
          ))}
        </div>
      </WContainer>
    </div>
  )
}

export default ProdottiKit
