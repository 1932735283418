import { ImageBox } from 'components/atoms'
import { Gift } from 'components/atoms/Icons'
import React from 'react'

interface Props {
  title?: string
  description?: string
  imageUrl?: string
  className?: string
}

const ProdottoOmaggioBox = (props: Props) => {
  const { title = '', description = '', imageUrl = '', className = '' } = props

  return (
    <div className={`prodotto-omaggio-box ${className ? ` ${className}` : ''}`}>
      <ImageBox src={imageUrl} maxWidth={109} maxHeight={127} classPrefix="prodotto-omaggio-box" />
      <div className="prodotto-omaggio-box__content">
        <p className="prodotto-omaggio-box__title">
          <Gift />
          <span>{title}</span>
        </p>
        <div
          className="prodotto-omaggio-box__description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  )
}

export default ProdottoOmaggioBox
