import React from 'react'
import Select from 'react-select'
import { DropdownArrowDown } from 'components/atoms/Icons'
import { FormError } from 'components/atoms'

interface Props {
  id?: string
  placeholder?: string
  name?: string
  value?: any
  size?: 'md'
  className?: string
  disabled?: boolean
  status?: null | 'success' | 'error' | string
  errorMessage?: string
  onBlur?: (e: any) => void
  onFocus?: (e: any) => void
  onChange?: (e: any) => void
  options: {
    label: string
    value: any
    selected?: boolean
  }[]
}

const FormSelectDropdownIndicator = () => (
  <div className="form-select__input__dropdown-indicator">
    <DropdownArrowDown />
  </div>
)

const FormSelect = (props: Props) => {
  const {
    id = '',
    placeholder = '',
    name = '',
    value = null,
    size = 'md',
    className = '',
    disabled = false,
    status = '',
    errorMessage = '',
    onBlur = () => {},
    onFocus = () => {},
    onChange = () => {},
    options = [],
  } = props

  const classNames = ` 
    form-select 
    form-select--${size}
    ${status ? ` form-select--${status}` : ''}
    ${className ? ` ${className}` : ''}`

  return (
    <div className={classNames}>
      <Select
        className="form-select__input"
        classNamePrefix="form-select__input"
        closeMenuOnSelect
        isClearable={false}
        isSearchable={false}
        isDisabled={disabled || false}
        instanceId={id}
        name={name}
        placeholder={placeholder}
        components={{ DropdownIndicator: FormSelectDropdownIndicator }}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        options={options}
        value={
          options?.find((option) => option.selected) ||
          options?.find((option) => option.value === value)
        }
      />
      <FormError
        className="form-select__error"
        message={errorMessage}
        visible={status === 'error'}
      />
    </div>
  )
}

export default FormSelect
