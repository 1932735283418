import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function BlogIspirazioni({ className }: Props) {
  return (
    <IconBase name="blog-ispirazioni" w={32} className={className}>
      <path d="M15.9696 5.79004C11.7396 5.79004 8.28955 9.23004 8.28955 13.47C8.28955 15.4 9.00955 17.24 10.3196 18.67L10.4796 18.83C12.3896 20.58 12.3996 22.16 12.3896 22.33C12.0196 22.66 11.7796 23.13 11.7796 23.66V25.9C11.7796 26.88 12.5696 27.67 13.5496 27.67H13.8296C13.9996 28.7 14.8996 29.49 15.9796 29.49C17.0596 29.49 17.9495 28.7 18.1295 27.67H18.4096C19.3896 27.67 20.1796 26.88 20.1796 25.9V23.66C20.1796 23.13 19.9396 22.65 19.5696 22.33C19.5596 22.16 19.5696 20.58 21.4996 18.8L21.6396 18.65C22.9396 17.23 23.6596 15.39 23.6596 13.46C23.6396 9.23004 20.1996 5.79004 15.9696 5.79004ZM15.9696 28.49C15.4396 28.49 14.9996 28.14 14.8396 27.67H17.0896C16.9396 28.15 16.4896 28.49 15.9696 28.49ZM19.1596 25.9C19.1596 26.33 18.8096 26.67 18.3896 26.67H17.6496H14.2796H13.5396C13.1096 26.67 12.7696 26.32 12.7696 25.9V23.66C12.7696 23.23 13.1196 22.89 13.5396 22.89H18.3896C18.8196 22.89 19.1596 23.24 19.1596 23.66V25.9ZM14.4696 15.51H13.4796C12.9296 15.51 12.4896 15.07 12.4896 14.52C12.4896 13.97 12.9296 13.53 13.4796 13.53C14.0296 13.53 14.4696 13.97 14.4696 14.52V15.51ZM16.9595 21.89H14.9796V16.01H16.9595V21.89ZM20.8896 17.97L20.7796 18.09C19.0896 19.64 18.6696 21.14 18.5696 21.91C18.5096 21.9 18.4495 21.89 18.3795 21.89H17.4496V16.01H18.4396C19.2596 16.01 19.9296 15.34 19.9296 14.52C19.9296 13.7 19.2596 13.03 18.4396 13.03C17.6196 13.03 16.9496 13.7 16.9496 14.52V15.51H14.9696V14.52C14.9696 13.7 14.2996 13.03 13.4796 13.03C12.6596 13.03 11.9896 13.7 11.9896 14.52C11.9896 15.34 12.6596 16.01 13.4796 16.01H14.4696V21.89H13.5396C13.4796 21.89 13.4196 21.9 13.3496 21.91C13.2496 21.15 12.8396 19.65 11.1696 18.12L11.0396 17.99C9.90955 16.76 9.27955 15.15 9.27955 13.47C9.27955 9.79004 12.2796 6.79004 15.9596 6.79004C19.6396 6.79004 22.6396 9.79004 22.6396 13.47C22.6396 15.14 22.0196 16.75 20.8896 17.97ZM17.4595 15.51V14.52C17.4595 13.97 17.8996 13.53 18.4496 13.53C18.9996 13.53 19.4396 13.97 19.4396 14.52C19.4396 15.07 18.9996 15.51 18.4496 15.51H17.4595Z" stroke="none"/>
      <path d="M16 4.6C16.14 4.6 16.25 4.49 16.25 4.35V2.75C16.25 2.61 16.14 2.5 16 2.5C15.86 2.5 15.75 2.61 15.75 2.75V4.35C15.75 4.49 15.86 4.6 16 4.6Z" stroke="none"/>
      <path d="M23.2397 5.65002L22.1297 6.76002C22.0297 6.86002 22.0297 7.02002 22.1297 7.11002C22.1797 7.16002 22.2397 7.18002 22.3097 7.18002C22.3797 7.18002 22.4397 7.16002 22.4897 7.11002L23.5997 6.00002C23.6997 5.90002 23.6997 5.74002 23.5997 5.65002C23.4997 5.56002 23.3397 5.56002 23.2397 5.65002Z" stroke="none"/>
      <path d="M9.50968 7.1102C9.55968 7.1602 9.61968 7.1802 9.68968 7.1802C9.75968 7.1802 9.81968 7.1602 9.86968 7.1102C9.96968 7.0102 9.96968 6.8502 9.86968 6.7602L8.75968 5.6502C8.65968 5.5502 8.49968 5.5502 8.40968 5.6502C8.31968 5.7502 8.30968 5.9102 8.40968 6.0002L9.50968 7.1102Z" stroke="none"/>
      <path d="M19.3099 5.24979C19.3399 5.25979 19.3699 5.26979 19.4099 5.26979C19.5099 5.26979 19.5999 5.20979 19.6399 5.11979L20.2499 3.64979C20.2999 3.51979 20.2399 3.37979 20.1099 3.31979C19.9799 3.26979 19.8399 3.32979 19.7799 3.45979L19.1699 4.92979C19.1199 5.04979 19.1799 5.19979 19.3099 5.24979Z" stroke="none"/>
      <path d="M6.39979 8.99997C6.26979 8.94997 6.12979 9.00997 6.06979 9.13997C6.01979 9.26997 6.07979 9.40997 6.20979 9.46997L7.61979 10.05C7.64979 10.06 7.67979 10.07 7.71979 10.07C7.81979 10.07 7.90979 10.01 7.94979 9.91997C7.99979 9.78997 7.93979 9.63997 7.80979 9.58997L6.39979 8.99997Z" stroke="none"/>
      <path d="M25.5998 9.00003L24.1898 9.59003C24.0598 9.64003 23.9998 9.79003 24.0498 9.92003C24.0898 10.02 24.1798 10.07 24.2798 10.07C24.3098 10.07 24.3398 10.06 24.3798 10.05L25.7898 9.46003C25.9198 9.41003 25.9798 9.26003 25.9298 9.13003C25.8798 9.00003 25.7298 8.95003 25.5998 9.00003Z" stroke="none"/>
      <path d="M12.59 5.27003C12.62 5.27003 12.65 5.26003 12.69 5.25003C12.82 5.20003 12.88 5.05003 12.83 4.92003L12.22 3.45003C12.17 3.32003 12.02 3.26003 11.89 3.31003C11.76 3.36003 11.7 3.51003 11.75 3.64003L12.36 5.11003C12.4 5.21003 12.49 5.27003 12.59 5.27003Z" stroke="none"/>
    </IconBase>
  )
}

export default BlogIspirazioni
