import React from 'react'

function Quote({ className }) {
  return (
    <svg
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--quote${className ? ' ' + className : ''}`}
    >
      <path d="M12 34h6l4-8v-12h-12v12h6zm16 0h6l4-8v-12h-12v12h6z" />
      <path d="M0 0h48v48h-48z" fill="none" />
    </svg>
  )
}

export default Quote
