import React from 'react'
import { Check, StepbarDot } from 'components/atoms/Icons'

interface ItemProps {
  index: number
  step: string
  status: 'past' | 'active' | 'future'
}

interface Props {
  steps: string[]
  activeStepIndex: number
  className?: string
}

const StepBarItem = (props: ItemProps) => {
  const { index = 1, step = '', status = 'future' } = props

  return (
    <span className={`step-bar-item step-bar-item--${status}`}>
      <span className="step-bar-item__content">
        <span className="step-bar-item__dot">
          <StepbarDot className="step-bar-item__dot-bg" />
          {status === 'past' ? <Check className="step-bar-item__dot-check" /> : index}
        </span>
        <span className="step-bar-item__label">{step}</span>
      </span>
    </span>
  )
}

const StepBar = (props: Props) => {
  const { steps = [], activeStepIndex = 1, className = '' } = props

  return (
    <div className={`step-bar${className ? ` ${className}` : ''}`}>
      {steps?.length &&
        steps.map((step, index) => (
          <StepBarItem
            key={index}
            index={index + 1}
            step={step}
            status={
              index + 1 === activeStepIndex ? 'active' : index < activeStepIndex ? 'past' : 'future'
            }
          />
        ))}
    </div>
  )
}

StepBar.Item = StepBarItem

export default StepBar
