import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function WineColor({ className }: Props) {
  return (
    <IconBase name="wine-color" w={40} h={41} className={className}>
      <g clipPath="url(#wine-color-clip0)">
      <path d="M25.9875 35.1627H13.025C12.8592 35.1627 12.7002 35.0969 12.583 34.9796C12.4658 34.8624 12.4 34.7035 12.4 34.5377C12.4 34.372 12.4658 34.213 12.583 34.0958C12.7002 33.9786 12.8592 33.9127 13.025 33.9127C16.35 33.9127 18.4875 30.7252 18.9125 30.0377V22.5377C16.6601 21.5019 14.6339 20.0322 12.95 18.2127C8.97497 14.2377 13.4 6.11272 13.6 5.77522C13.6508 5.67668 13.7279 5.59414 13.8228 5.53677C13.9177 5.4794 14.0266 5.44943 14.1375 5.45021H24.875C24.9871 5.45121 25.0971 5.48178 25.1936 5.53884C25.2902 5.59591 25.37 5.67745 25.425 5.77522C25.6125 6.11272 30.0375 14.2377 26.0625 18.2127C24.382 20.0306 22.3602 21.5002 20.1125 22.5377V30.0377C20.6451 31.1581 21.4741 32.1113 22.5097 32.7944C23.5452 33.4774 24.7479 33.8641 25.9875 33.9127C26.1532 33.9127 26.3122 33.9786 26.4294 34.0958C26.5466 34.213 26.6125 34.372 26.6125 34.5377C26.6125 34.7035 26.5466 34.8624 26.4294 34.9796C26.3122 35.0969 26.1532 35.1627 25.9875 35.1627ZM16.8875 33.9127H21.8875C20.9204 33.2455 20.0925 32.3964 19.45 31.4127C18.7445 32.3859 17.8778 33.2314 16.8875 33.9127ZM14.475 6.75022C13.725 8.23772 10.875 14.4627 13.7875 17.3752C15.3788 19.1298 17.3206 20.5308 19.4875 21.4877C21.6476 20.5255 23.5844 19.1251 25.175 17.3752C28.1 14.4627 25.175 8.23772 24.5 6.75022H14.475Z" stroke="none"/>
      <path d="M19.4875 20.2751C18.95 20.2751 17.85 19.9001 14.5625 16.6126C13.9448 15.9262 13.6021 15.036 13.6 14.1126V13.7876H25.375V14.1126C25.3729 15.036 25.0302 15.9262 24.4125 16.6126C22.725 18.2876 20.525 20.2751 19.4875 20.2751ZM14.275 14.3751C14.3135 15.0391 14.5782 15.6699 15.025 16.1626C18.0625 19.2001 19.1375 19.6501 19.5125 19.6501C19.8875 19.6501 21.4125 18.7376 23.9875 16.1626C24.4396 15.6733 24.7051 15.0405 24.7375 14.3751H14.275Z" stroke="none"/>
      </g>
      <defs>
      <clipPath id="wine-color-clip0">
      <rect width="16.4625" height="29.65" transform="translate(11.25 5.5)" stroke="none"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default WineColor
