import React from 'react'

interface Props {
  label: string
  className?: string
}

const PreHeader = (props: Props) => {
  const { label = '', className = '' } = props

  return (
    label && (
      <div className={`pre-header ${className ? `${className}` : ''}`}>
        <div className="pre-header__content" dangerouslySetInnerHTML={{ __html: label }}/>
      </div>
    )
  )
}

export default PreHeader
