/* eslint-disable import/no-unresolved */
/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import { useSelector } from "react-redux"


export default function useFindMenu(chiave: string) {
    const { menu } = useSelector((state: any) => state?.menu)

    const [menuItem, setMenuItem] = useState(null)

    const findMenu = (menu, chiave) => {
        for (let item of menu.edges) {
            if (item.node.key === chiave) return item.node
    
            if (item.node.children) {
                let newItem = findMenu(item.node.children, chiave)
                if (newItem) return newItem
            }
        }
        return false
    }

    useEffect(() => {
        setMenuItem(findMenu(menu, chiave))
    }, [menu])

    return {
        menuItem
    }
}
