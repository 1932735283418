import React, { useContext, useEffect, useRef, useState } from 'react'
import InnerImageZoom from 'react-inner-image-zoom'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css'
import { useTrans, useWishlist, useIsMobile, useUtente } from 'hooks'
import { priceNotation, scrollTo } from 'utils/safe'
import { getPromoLabel, getPromoBundleLabel } from 'utils/prodotto'
import { CartContext } from 'utils/context'
import {
  Info,
  HeartEmpty,
  HeartFilled,
  Share,
  Zoom,
  Cart,
  WineVitigno,
  WineColor,
  WineGradazione,
  WineAnnata,
  WineFormato,
  WineConsumo,
  WineAbbinamento,
  WineServizio,
} from 'components/atoms/Icons'
import {
  ChipsMenu,
  ImageBox,
  Label,
  QuantityHandler,
  Button,
  NotificationBar,
  BackToTop,
} from 'components/atoms'
import { ProdottoOmaggioBox, DatoProdottoMain, NotificationViewer } from 'components/molecules'
import ShareButtons from 'components/molecules/ShareButtons/ShareButtons'
import { trackingAddRemoveFromCart } from 'utils/gtm'
import { executeIfInitialized, trackConnectifCart } from 'utils/connectif'
import Head from 'next/head'
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

interface Props {
  prodotto: {
    varianteProdotto?: {
      pk?: number
    }
    nomeFantasia?: string
    nome?: string
    descrizione?: string
    mainImage?: string
    thumbnailImage?: string
    mondo?: string
    bundle?: boolean
    numeroPezziBundle?: number
    testoFlash?: string
    omaggio?: {
      nome?: string
      descrizione?: string
      prodottoOmaggio?: {
        prodotto?: {
          alias?: string
          nome?: string
          mainImage?: string
        }
      }
    }
    imagesSlider?: { preview?: string; main?: string }[]
    inWishlist?: boolean
    disponibilita?: number
    acquistabile?: boolean
    promoApplicataNome?: string
    promoApplicata?: {
      alias?: string
      percentuale?: boolean
      valore?: number
      dal?: string
      al?: string
      mostraCounter?: boolean
      timeUntil?: string
    }
    prezzo?: number
    prezzoOmnibus?: number
    prezzoScontato?: number
    cantina?: {
      nome?: string
    }
    attributi?: {
      vitigno?: string
      colore?: string
      gradazione?: string
      annata?: string
      formato?: string
      consumoIdeale?: string
      abbinamento?: string
      servizio?: string
      bicchiere?: string
    }
    datiSchedaTecnica?: {
      chiave?: string
      titolo?: string
      testo?: string
    }[]
  }
  premiRef?: any
  schedaTecnicaRef?: any
  className?: string
}

const ProdottoMain = (props: Props) => {
  const {
    prodotto = {
      varianteProdotto: {
        pk: 0,
      },
      nomeFantasia: '',
      nome: '',
      descrizione: '',
      mainImage: '',
      thumbnailImage: '',
      mondo: '',
      bundle: false,
      numeroPezziBundle: 0,
      testoFlash: '',
      omaggio: {
        nome: '',
        descrizione: '',
        prodottoOmaggio: {
          prodotto: {
            alias: '',
            nome: '',
            mainImage: '',
          },
        },
      },
      inWishlist: false,
      disponibilita: 0,
      acquistabile: false,
      promoApplicataNome: '',
      promoApplicata: {
        alias: '',
        percentuale: false,
        valore: 0,
        dal: '',
        al: '',
        mostraCounter: false,
        timeUntil: '',
      },
      prezzo: 0,
      prezzoOmnibus: 0,
      prezzoScontato: 0,
      cantina: {
        nome: '',
      },
      attributi: {
        vitigno: '',
        colore: '',
        gradazione: '',
        annata: '',
        formato: '',
        consumoIdeale: '',
        abbinamento: '',
        servizio: '',
        bicchiere: '',
      },
      datiSchedaTecnica: [],
      imagesSlider: [],
    },
    className = '',
    premiRef = null,
    schedaTecnicaRef = null,
  } = props

  const t = useTrans()
  const isMobile = useIsMobile()
  const [wishlist, setWishlist] = useState(prodotto.inWishlist)
  const [zoom, setZoom] = useState(false)
  const [sticky, setSticky] = useState(false)
  const [showAddNotification, setShowAddNotification] = useState(false)
  const [showAddError, setShowAddError] = useState(false)
  const [addMessage, setAddMessage] = useState('')
  const activateStickyRef = useRef()
  const { cart } = useContext(CartContext)

  useEffect(() => {
    const onScroll = (e) => {
      setSticky(
        activateStickyRef?.current &&
          e.target.documentElement.scrollTop > activateStickyRef.current.offsetTop
      )
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  useEffect(() => {
    if (showAddNotification) {
      setTimeout(() => {
        setShowAddNotification(false)
      }, 5000)
    }
  }, [showAddNotification])

  useEffect(() => {
    if (showAddError) {
      setTimeout(() => {
        setShowAddError(false)
      }, 5000)
    }
  }, [showAddError])

  const { addToCart } = useContext(CartContext)
  const [quantita, setQuantita] = useState(1)

  const onAddToCart = () => {
    addToCart(prodotto.varianteProdotto, quantita, isMobile ? false : true).then(
      ({ status, message }) => {
        if (isMobile && status) {
          setShowAddNotification(true)
        }
        if (message) {
          setAddMessage(message)
          setShowAddError(true)
        }
        if (status) trackingAddRemoveFromCart(prodotto, quantita)
        setQuantita(1)
        if (cart) executeIfInitialized(trackConnectifCart, cart)
      }
    )
  }

  useEffect(() => {
    if (prodotto) {
      setWishlist(prodotto.inWishlist)
    }
  }, [prodotto])

  const handleWishlist = useWishlist()

  const onWishlistClick = () => {
    handleWishlist(wishlist, prodotto).then((response) => {
      setWishlist(response)
    })
  }

  const onClose = () => {
    setShowAddError(false)
  }

  const goToPremi = () => {
    if (premiRef.current) scrollTo(premiRef, isMobile)
  }

  const goToSchedaTecnica = () => {
    if (schedaTecnicaRef.current) scrollTo(schedaTecnicaRef, isMobile)
  }

  return (
    <>
      <Head>
        <link rel="preload" as="image" href={prodotto.thumbnailImage} />
      </Head>
      <NotificationViewer
        showNotification={showAddNotification}
        showError={showAddError}
        messageError={addMessage}
        messageSuccess="Il prodotto è stato aggiunto al carrello"
        onClose={() => onClose()}
      />
      <div
        className={`prodotto-main${sticky ? ' prodotto-main--sticky' : ''}${
          className ? ` ${className}` : ''
        }`}
      >
        <ShareButtons />
        <div className="prodotto-main__left">
          <div className="prodotto-main__left__content">
            <div className="prodotto-main__image-box">
              {prodotto.imagesSlider && prodotto.imagesSlider.length > 1 ? (
                <Swiper slidesPerView={1} pagination={{ clickable: true }}>
                  {prodotto.imagesSlider.map((image) => (
                    <SwiperSlide>
                      <InnerImageZoom
                        src={image.preview}
                        zoomSrc={image.main}
                        hideCloseButton
                        hideHint
                        alt={prodotto.nomeFantasia}
                        title={prodotto.nomeFantasia}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <InnerImageZoom
                  src={prodotto.thumbnailImage}
                  zoomSrc={prodotto.mainImage}
                  hideCloseButton
                  hideHint
                  alt={prodotto.nomeFantasia}
                  title={prodotto.nomeFantasia}
                />
              )}
            </div>
            {prodotto.testoFlash && (
              <Label
                label={prodotto.testoFlash}
                variant="dark"
                size="sm"
                className="prodotto-main__label-omaggio"
              />
            )}
            <button
              type="button"
              className="prodotto-main__wishlist"
              onClick={() => onWishlistClick()}
            >
              {wishlist ? <HeartFilled /> : <HeartEmpty />}
            </button>
            <button type="button" className="prodotto-main__zoom" onClick={() => setZoom(!zoom)}>
              <Zoom />
            </button>
          </div>
        </div>
        <div className="prodotto-main__right">
          <div className="prodotto-main__cantina">
            {!!prodotto.cantina && <ChipsMenu label={prodotto.cantina.nome} />}
          </div>
          <h1 className="prodotto-main__title">{prodotto.nomeFantasia}</h1>
          {prodotto.bundle && (
            <p className="prodotto-main__num-prodotti-kit">
              {t('{0} prodotti', [`${prodotto.numeroPezziBundle}`])}
            </p>
          )}
          {prodotto.acquistabile && (
            <>
              <div className="prodotto-main__price">
                {prodotto.promoApplicata && prodotto.promoApplicata.mostraCounter && (
                  <Label label={getPromoLabel(prodotto)} className="prodotto-main__price__promo" />
                )}
                {prodotto.bundle && prodotto.promoApplicataNome === 'bundle' && (
                  <Label
                    label={getPromoBundleLabel(prodotto)}
                    className="prodotto-main__price__promo"
                  />
                )}
                <span className="prodotto-main__price__final">
                  {priceNotation(prodotto.prezzoScontato)}
                </span>
                {prodotto.prezzo !== prodotto.prezzoScontato && (
                  <span className="prodotto-main__price__full">
                    {priceNotation(prodotto.prezzo)}
                  </span>
                )}
              </div>
              <div>
                {prodotto.prezzo !== prodotto.prezzoScontato && (
                  <>
                    <span className="prodotto-main__omnibus">
                      {t('Prezzo piu basso negli ultimi 30 giorni')}:{' '}
                    </span>
                    <span className="prodotto-main__omnibus">
                      {priceNotation(prodotto.prezzoOmnibus)}
                    </span>
                  </>
                )}
              </div>
            </>
          )}
          {prodotto.disponibilita > 0 ? (
            <div className="prodotto-main__add">
              <QuantityHandler
                value={quantita}
                onValueChange={(newValue, action) => setQuantita(newValue <= 0 ? 1 : newValue)}
                className="prodotto-main__quantity-handler"
              />
              <Button
                label={t('Aggiungi al carrello')}
                onClick={onAddToCart}
                icon={<Cart />}
                iconPos="right"
                className="prodotto-main__add-cart"
              />
            </div>
          ) : (
            <div className="prodotto-main__non-disponibile">
              <span>{t('Prodotto non disponibile')}</span>
            </div>
          )}
          <div ref={activateStickyRef} />
          <p className="prodotto-main__caratteristiche">
            {prodotto.datiSchedaTecnica.find((dato) => dato.chiave === 'caratteristiche')?.testo}
          </p>
          <p className="prodotto-main__description">
            <>
              {prodotto.datiSchedaTecnica.find((dato) => dato.chiave === 'abstract')?.testo}
              {prodotto.mondo === 'accessori' && prodotto.descrizione}
            </>
          </p>
          {!prodotto.bundle && prodotto.mondo !== 'accessori' && (
            <div className="prodotto-main__actions">
              {!!premiRef.current && (
                <Button variant="ghost" label={t('Vedi i premi')} responsive onClick={goToPremi} />
              )}
              {!!premiRef.current && !!schedaTecnicaRef.current && <span>oppure</span>}
              {!!schedaTecnicaRef.current && (
                <Button
                  variant="ghost"
                  label={t('Leggi la scheda tecnica')}
                  responsive
                  onClick={goToSchedaTecnica}
                />
              )}
            </div>
          )}
          <div className="prodotto-main__dati">
            {prodotto.attributi.vitigno && (
              <DatoProdottoMain
                icon={<WineVitigno />}
                title={t('Vitigno')}
                description={prodotto.attributi.vitigno}
              />
            )}
            {prodotto.attributi.colore && (
              <DatoProdottoMain
                icon={<WineColor />}
                title={t('Colore')}
                description={prodotto.attributi.colore}
              />
            )}
            {prodotto.attributi.gradazione && (
              <DatoProdottoMain
                icon={<WineGradazione />}
                title={t('Gradazione')}
                description={prodotto.attributi.gradazione}
              />
            )}
            {prodotto.attributi.annata && (
              <DatoProdottoMain
                icon={<WineAnnata />}
                title={t('Annata')}
                description={prodotto.attributi.annata}
              />
            )}
            {prodotto.attributi.formato && (
              <DatoProdottoMain
                icon={<WineFormato />}
                title={t('Formato')}
                description={prodotto.attributi.formato}
              />
            )}
            {prodotto.attributi.consumoIdeale && (
              <DatoProdottoMain
                icon={<WineConsumo />}
                title={t('Consumo ideale')}
                description={prodotto.attributi.consumoIdeale}
              />
            )}
            {prodotto.attributi.abbinamento && (
              <DatoProdottoMain
                icon={<WineAbbinamento />}
                title={t('Abbinamento')}
                description={prodotto.attributi.abbinamento}
              />
            )}
            {prodotto.attributi.servizio && (
              <DatoProdottoMain
                icon={<WineServizio />}
                title={t('Servizio')}
                description={
                  t('Consigliato servire a ') +
                  prodotto.attributi.servizio +
                  (prodotto.attributi.bicchiere
                    ? t(' in bicchiere ') + prodotto.attributi.bicchiere
                    : '')
                }
              />
            )}
          </div>
          <div className="prodotto-main__omaggio-box">
            {prodotto.omaggio?.prodottoOmaggio && (
              <ProdottoOmaggioBox
                title={prodotto.omaggio.nome}
                description={prodotto.omaggio.descrizione}
                imageUrl={prodotto.omaggio.prodottoOmaggio.prodotto.mainImage}
              />
            )}
          </div>
        </div>
        {prodotto.disponibilita > 0 && (
          <div className="prodotto-main__sticky-add">
            <QuantityHandler
              className="prodotto-main__quantity-handler"
              value={quantita}
              onValueChange={(newValue, action) => setQuantita(newValue <= 0 ? 1 : newValue)}
            />
            <Button
              label={t('Aggiungi al carrello')}
              icon={<Cart />}
              iconPos="right"
              className="prodotto-main__add-cart"
              onClick={onAddToCart}
            />
          </div>
        )}
        <BackToTop className="prodotto-main__back-to-top" />
      </div>
    </>
  )
}

export default ProdottoMain
