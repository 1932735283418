import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function DropdownArrowUp({ className }: Props) {
  return (
    <IconBase name="dropdown-arrow-up" w={24} className={className}>
      <path d="M12 9L6 15L18 15L12 9Z" stroke="none"/>
    </IconBase>
  )
}

export default DropdownArrowUp
