import React, { useState } from 'react'
import { useTrans } from 'hooks'
import { FormError } from 'components/atoms'

interface Props {
  placeholder?: string
  name?: string
  value?: string
  size?: 'md'
  className?: string
  disabled?: boolean
  status?: '' | 'success' | 'error'
  errorMessage?: string
  limit?: number
  onBlur?: (e: any) => void
  onFocus?: (e: any) => void
  onChange?: (e: any) => void
}

const FormTextarea = (props: Props) => {
  const {
    placeholder = '',
    name = '',
    value = '',
    size = 'md',
    className = '',
    disabled = false,
    status = '',
    errorMessage = '',
    limit = 0,
    onBlur = () => {},
    onFocus = () => {},
    onChange = () => {},
  } = props

  const t = useTrans()

  const classNames = `
    form-textarea 
    form-textarea--${size}
    ${status ? ` form-textarea--${status}` : ''}
    ${className ? ` ${className}` : ''}`

  return (
    <div className={classNames}>
      <textarea
        name={name}
        value={value}
        className="form-textarea__input"
        placeholder={placeholder}
        disabled={disabled}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
      />
      {limit > 0 && (
        <div className="form-textarea__counter">
          <span>{value.length}</span>/{limit} {t('caratteri')}
        </div>
      )}
      <FormError
        className="form-textarea__error"
        message={errorMessage}
        visible={status === 'error'}
      />
    </div>
  )
}

export default FormTextarea
