import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function AbbinamentiCarne({ className }: Props) {
  return (
    <IconBase name="abbinamenti-carne" w={32} className={className}>
      <path d="M7.42002 15.9301L8.41002 16.5001C11.78 18.4601 16.4 21.1401 21.16 21.1401C22.5 21.1401 23.79 20.9201 24.99 20.4901C25.92 20.1501 26.5 19.7401 26.58 19.3401C26.66 18.9201 26.24 18.2501 25.43 17.5001C23.16 15.4101 22.97 14.9601 22.7 12.7501C22.67 12.4801 22.63 12.1801 22.59 11.8401C22.23 9.25008 18.65 8.33008 15.42 8.33008C13.84 8.33008 12.15 8.54008 10.53 8.93008C7.67002 9.62008 5.67002 11.1401 5.43002 12.8101C5.26002 13.9701 5.99002 15.1101 7.42002 15.9301ZM5.92002 12.8801C6.13002 11.4101 7.98002 10.0501 10.64 9.41008C12.22 9.03008 13.88 8.83008 15.42 8.83008C18.44 8.83008 21.78 9.64008 22.09 11.9101C22.14 12.2501 22.17 12.5501 22.21 12.8601C22.48 15.1201 22.72 15.6901 25.09 17.8701C25.98 18.6901 26.1 19.1601 26.09 19.2401C26.07 19.3101 25.81 19.6601 24.82 20.0201C23.67 20.4401 22.44 20.6401 21.16 20.6401C16.54 20.6401 11.99 17.9901 8.66002 16.0601L7.67002 15.4901C6.42002 14.7801 5.79002 13.8301 5.92002 12.8801Z" stroke="none"/>
      <path d="M28.0302 19.0501C27.9602 18.2401 27.4302 17.3602 26.4102 16.4302C24.4202 14.6002 24.4002 14.4301 24.1802 12.7301C24.1402 12.4201 24.0902 12.0601 24.0302 11.6401C23.8202 10.2801 23.1002 9.18015 21.8802 8.37015C19.4102 6.71015 14.9302 6.39015 10.1902 7.52015C6.6402 8.37015 4.3202 10.2701 3.9902 12.6101C3.9702 12.7601 3.9702 12.9201 3.9602 13.0701H3.9502V16.0101L4.0002 16.2501C4.2002 17.6201 5.1602 18.8601 6.7102 19.7401L7.6902 20.3101C11.1702 22.3301 16.0302 25.1501 21.1502 25.1501C22.5802 25.1501 24.0402 24.9301 25.4902 24.4101C27.1302 23.8201 27.9902 22.9802 28.0502 21.9001V19.0501H28.0302ZM4.9802 12.7401C5.2502 10.8601 7.3302 9.22015 10.4202 8.48015C12.2002 8.05015 13.9502 7.84015 15.5602 7.84015C17.9402 7.84015 19.9902 8.30015 21.3102 9.18015C22.2902 9.83015 22.8702 10.7101 23.0302 11.7801C23.0902 12.1901 23.1402 12.5401 23.1802 12.8501C23.4302 14.7701 23.5402 15.1501 25.7302 17.1601C26.6902 18.0401 27.1402 18.8201 27.0202 19.4201C26.8802 20.1402 25.9202 20.6201 25.1402 20.9001C18.9502 23.1301 12.4702 19.3701 8.1802 16.8801L7.2002 16.3201C5.6002 15.4001 4.7902 14.1001 4.9802 12.7401ZM25.1402 23.4701C18.9502 25.7001 12.4702 21.9402 8.1802 19.4502L7.2002 18.8801C5.9302 18.1501 5.1402 17.1702 4.9902 16.1102V15.7901C5.4302 16.3102 6.0102 16.7902 6.7102 17.1902L7.6902 17.7601C11.1702 19.7801 16.0302 22.6002 21.1502 22.6002C22.5802 22.6002 24.0402 22.3801 25.4902 21.8602C26.1302 21.6302 26.6502 21.3502 27.0502 21.0402V21.8502C27.0002 22.6402 25.9902 23.1601 25.1402 23.4701Z" stroke="none"/>
      <path d="M14.28 14.9704C16.13 14.9704 17.58 14.1304 17.58 13.0604C17.58 11.9904 16.13 11.1504 14.28 11.1504C12.43 11.1504 10.98 11.9904 10.98 13.0604C10.98 14.1304 12.43 14.9704 14.28 14.9704ZM14.28 11.6504C15.8 11.6504 17.08 12.3004 17.08 13.0604C17.08 13.8204 15.8 14.4704 14.28 14.4704C12.76 14.4704 11.48 13.8204 11.48 13.0604C11.48 12.3004 12.76 11.6504 14.28 11.6504Z" stroke="none"/>
    </IconBase>
  )
}

export default AbbinamentiCarne
