import React from 'react'
import Link from 'next/link'
import { TagCloudShape } from 'components/atoms/Icons'

interface Props {
  title?: string
  description?: string
  variant?: '' | 'fancy'
  tags: {
    node: {
      id: number
      name: string
      url: string
    }
  }[]
  className?: string
}

const TagCloud = (props: Props) => {
  const { title = '', description = '', variant = '', tags = [], className = '' } = props

  return (
    <div
      className={`tag-cloud${variant ? ` tag-cloud--${variant}` : ''} ${
        className ? ` ${className}` : ''
      }`}
    >
      {variant === 'fancy' && (
        <div className="tag-cloud__bg">
          <TagCloudShape />
        </div>
      )}
      <div className="tag-cloud__content">
        {title && <p className="tag-cloud__title">{title}</p>}
        {description && <p className="tag-cloud__description">{description}</p>}
        <div className="tag-cloud__list">
          {tags?.map((tag) =>
            tag?.node.url ? (
              <Link legacyBehavior href={tag?.node.url} key={`tag-cloud__item__${tag.node.id}`}>
                <a className="tag-cloud__item">{tag.node.name}</a>
              </Link>
            ) : (
              <a className="tag-cloud__item">{tag.node.name}</a>
            )
          )}
        </div>
      </div>
    </div>
  )
}

export default TagCloud
