import { useNode, useEditor } from '@craftjs/core'
import React, { useState, useEffect } from 'react'
import dynamic from 'next/dynamic'

import { ReactBlockSettings } from './ReactBlockSettings'

import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'

import getDynamicComponent from '../../services/dynamicComponent'

export const defaultProps = {
  componentName: '',
  componentProps: {},
}

export const dynamicProps = []

export const ReactBlock = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }
    const { componentName, componentProps } = blockProps

    const _ = props.renderValue

    const {
      id,
      connectors: { connect },
      selected,
      hovered,
    } = useNode((state) => ({
      selected: state.events.selected,
      hovered: state.events.hovered,
      customs: state.data.custom,
    }))

    const Component = getDynamicComponent(componentName, componentProps)

    /* const [ component, setComponent ] = useState()

    useEffect(() => {
        setComponent( getDynamicComponent(componentName, componentProps))
    }, [componentName, componentProps]) */

    return (
      <div
        ref={(ref) => props.retRef(ref)}
        className={'reactblock ' + props.className}
        style={{ position: 'relative' }}
      >
        {Component}
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

ReactBlock.craft = withCraft({
  name: 'ReactBlock',
  defaultProps: defaultProps,
  settings: ReactBlockSettings,
})
