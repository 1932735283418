import React from 'react'
import ContentLoader from 'react-content-loader'


interface Props {
    vista2?: boolean
  }

const SkeletonLoading = (props: Props) => {
    const { vista2 = false } = props

    return (
        <ContentLoader
            style={{ 
                marginRight: vista2 ? "8px" : "16px", 
                marginBottom: vista2 ? "20px" : "30px" 
            }}
            speed={2}
            width={vista2 ? "calc(48% - 4px)" : "calc(32% - 11px)"}
            height={300}
            //viewBox={props.vista2 ? "0 0 300 calc(48% - 4px)" : "0 0 300 calc(32% - 11px)"}
            backgroundColor="#f1f2f4"
            foregroundColor="#e0e2e6"
        >
            <rect
                x="0"
                y="0"
                rx="6"
                ry="6"
                width="100%"
                height="300"
            />
        </ContentLoader>
    )
}

export default SkeletonLoading
