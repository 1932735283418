import React from 'react'
import { FormError } from 'components/atoms'
import {
  RadioUnmarked,
  RadioMarked,
  CheckboxMarked,
  CheckboxUnmarked,
} from 'components/atoms/Icons'

interface OptionProps {
  id: string
  label?: string
  name?: string
  value?: any
  size?: 'md'
  variant?: 'radio' | 'checkbox'
  checked?: boolean
  className?: string
  readOnly?: boolean
  disabled?: boolean
  onClick?: (e: any) => void
}

interface Props {
  id: string
  name?: string
  value?: any
  size?: 'md'
  variant?: 'radio' | 'checkbox'
  className?: string
  readOnly?: boolean
  disabled?: boolean
  status?: '' | 'success' | 'error'
  errorMessage?: string
  onChange?: (event: any) => void
  options: OptionProps[]
}

const FormRadioOption = (props: OptionProps) => {
  const {
    id = '',
    label = '',
    name = '',
    value = '',
    size = 'md',
    variant = 'radio',
    checked = false,
    className = '',
    readOnly = false,
    disabled = false,
    onChange = () => {},
  } = props

  const classNames = `
    form-radio-option 
    form-radio-option--${size}
    ${disabled ? ' form-radio-option--disabled' : ''}
    ${className ? ` ${className}` : ''}`

  return (
    <div className={classNames}>
      <label htmlFor={id} className="form-radio-option__input-wrap">
        <input
          id={id}
          checked={checked}
          name={name}
          value={value}
          className="form-radio-option__input"
          type="radio"
          readOnly={readOnly}
          disabled={disabled}
          onChange={onChange}
        />
        <span className="form-radio-option__styledinput">
          {variant === 'checkbox' ? (
            checked ? (
              <CheckboxMarked />
            ) : (
              <CheckboxUnmarked />
            )
          ) : checked ? (
            <RadioMarked />
          ) : (
            <RadioUnmarked />
          )}
        </span>
        <span className="form-radio-option__label">{label}</span>
      </label>
    </div>
  )
}

const FormRadio = (props: Props) => {
  const {
    id = '',
    name = '',
    value = null,
    size = 'md',
    variant = 'radio',
    className = '',
    readOnly = false,
    disabled = false,
    status = '',
    errorMessage = '',
    onChange = () => {},
    options = [],
  } = props

  const onOptionChange = (e: any) => {
    if (!e.target.disabled && !e.target.readOnly) onChange(e)
  }

  const classNames = `
    form-radio 
    form-radio--${size}
    ${disabled ? ' form-checkbox--disabled' : ''}
    ${status ? ` form-checkbox--${status}` : ''}
    ${className ? ` ${className}` : ''}`

  return (
    <div className={classNames}>
      <div className="form-radio__options">
        {options?.map((option) => (
          <FormRadioOption
            id={`${id}-${option.value}`}
            name={name}
            label={option.label}
            value={option.value}
            variant={option.variant || variant}
            size={option.size || size}
            checked={option.value === value}
            onChange={onOptionChange}
            readOnly={readOnly || option.readOnly}
            disabled={disabled || option.disabled}
            key={`formradiooption-${id}-${option.value}`}
          />
        ))}
      </div>
      <FormError
        className="form-radio__error"
        message={errorMessage}
        visible={status === 'error'}
      />
    </div>
  )
}

export default FormRadio
