import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Info({ className }: Props) {
  return (
    <IconBase name="info" w={24} className={className}>
      <circle cx="12" cy="12" r="8" strokeWidth="2" fill="none"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12 17C11.4477 17 11 16.5523 11 16L11 11C11 10.4477 11.4477 10 12 10C12.5523 10 13 10.4477 13 11L13 16C13 16.5523 12.5523 17 12 17Z" stroke="none"/>
      <circle cx="12" cy="8" r="1" transform="rotate(-180 12 8)" stroke="none"/>
    </IconBase>
  )
}

export default Info
