import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function ChiSiamoProduttori({ className }: Props) {
  return (
    <IconBase name="chi-siamo-produttori" w={300} h={227} className={className}>
      <g clip-path="url(#chisiamoproduttori-clip0)">
        <path opacity="0.18" d="M268.801 172.746C266.859 180.889 264.061 188.951 259.116 195.704C251.595 205.977 239.834 212.247 228.043 217.058C218.324 221.019 208.109 224.259 197.61 224.433C183.353 224.672 169.521 219.262 155.258 218.89C138.39 218.454 122.062 225.073 105.235 226.027C87.485 226.969 69.9706 221.631 55.7631 210.95C52.5742 208.54 49.5951 205.865 46.8577 202.952C40.8815 196.691 36.253 189.271 33.2581 181.151C31.7961 177.04 30.725 172.8 30.0589 168.488C28.9421 161.589 28.6455 154.528 28.5291 147.513C28.1627 125.304 30.0299 101.496 43.6992 83.9879C48.498 77.8454 54.5416 72.7965 60.5212 67.7999L93.5429 40.2344C102.181 33.0216 110.859 25.7856 120.486 19.9572C131.131 13.5181 142.77 8.90542 154.438 4.58939C159.615 2.6815 164.874 0.802695 170.365 0.209388C182.394 -1.09356 194.248 3.91465 204.567 10.2316C214.246 16.1531 223.559 23.837 227.543 34.4816C230.894 43.3929 230.108 53.2988 232.086 62.623C236.21 82.0917 252.003 96.302 261.821 112.973C272.483 131.074 273.594 152.544 268.801 172.746Z" fill="#861E32"/>
        <path d="M117.543 61.7796C116.893 61.8395 116.272 62.0701 115.74 62.4481C115.209 62.8261 114.787 63.338 114.518 63.9318C113.999 65.1373 113.943 66.4919 114.361 67.736C114.568 68.2045 114.667 68.7132 114.652 69.2251C114.545 69.6508 114.347 70.0479 114.07 70.3884C113.038 71.7887 112.451 73.4665 112.383 75.2047C112.383 76.9497 113.378 78.7238 115.001 79.3055C113.965 81.4809 113.936 84.273 115.455 86.146C116.973 88.0189 120.189 88.4319 121.795 86.6346C122.231 87.443 122.903 88.0989 123.723 88.514C124.542 88.9292 125.469 89.0839 126.378 88.9572C127.288 88.8306 128.137 88.4288 128.812 87.8057C129.487 87.1826 129.955 86.368 130.153 85.4712C131.677 86.3263 133.748 85.6108 134.749 84.1799C135.21 83.4364 135.498 82.5984 135.591 81.7282C135.683 80.8581 135.578 79.9782 135.284 79.1542C134.684 77.5096 133.795 75.9851 132.66 74.6521C133.905 73.7098 135.208 72.7035 135.802 71.2609C136.395 69.8184 135.964 67.8232 134.51 67.2648C135.095 66.3793 135.413 65.344 135.425 64.2828C135.437 63.2216 135.143 62.1793 134.579 61.2806C134.015 60.3818 133.203 59.6646 132.242 59.2147C131.281 58.7647 130.211 58.6011 129.159 58.7433C128.036 58.8713 127.199 59.5286 126.088 59.0458C125.227 58.6677 124.697 57.5276 123.982 56.9401C120.806 54.3575 115.588 57.737 117.543 61.7796Z" fill="#861E32"/>
        <path d="M201.246 123.449C200.619 138.009 194.393 151.765 183.869 161.846C173.344 171.927 159.333 177.555 144.759 177.555C130.185 177.555 116.174 171.927 105.65 161.846C95.1252 151.765 88.9 138.009 88.2729 123.449H201.246Z" fill="#861E32"/>
        <path d="M178.141 123.449C178.141 123.449 169.835 167.825 160.935 169.436C152.036 171.047 178.141 172.874 191.281 152.852C202.711 135.443 201.245 123.449 201.245 123.449H178.141Z" fill="url(#chisiamoproduttori-paint0_linear)"/>
        <path d="M103.897 123.757C103.897 123.757 108.486 165.678 135.807 169.849C163.129 174.019 170.638 170.686 170.638 170.686C170.638 170.686 153.769 187.369 130.799 181.11C107.829 174.851 95.0378 150.322 95.0378 150.322C95.0378 150.322 87.6389 129.812 88.2613 123.449L103.897 123.757Z" fill="url(#chisiamoproduttori-paint1_linear)"/>
        <path d="M181.358 166.987C180.282 169.256 178.449 171.059 176.582 172.734C172.165 176.826 167.324 180.433 162.139 183.495C159.032 185.308 155.763 186.827 152.373 188.032C137.023 193.418 120.113 191.342 104.187 188.032C96.2007 186.374 87.9468 184.42 79.9778 186.147C74.7428 187.282 69.9905 189.928 65.3196 192.546L46.834 202.923C43.6778 199.587 40.8801 195.93 38.487 192.011C36.3819 188.576 34.628 184.937 33.2519 181.151C31.7899 177.04 30.7188 172.8 30.0527 168.488C33.1821 167.004 38.3125 167.447 41.2034 166.9C47.503 165.702 53.6687 163.852 59.817 162.008L76.8833 156.878C80.0942 155.912 83.2817 154.946 86.417 153.707C89.2904 152.544 92.0767 151.177 95.049 150.322C99.7897 148.955 104.798 148.978 109.725 149.002L134.219 149.147C134.688 149.107 135.158 149.195 135.58 149.403C136.679 150.089 136.162 151.805 135.353 152.812C131.968 157.07 126.157 158.46 120.759 159.006C119.159 159.169 117.507 159.297 116.077 160.019C114.646 160.74 113.459 162.241 113.651 163.834C113.924 166.243 116.786 167.278 119.159 167.761C126.108 169.145 133.112 170.206 140.169 170.942C144.107 171.344 148.098 171.629 152.012 171.041C162.366 169.494 170.998 162.06 181.218 159.803C182.632 161.88 182.434 164.724 181.358 166.987Z" fill="#E4A98A"/>
        <path d="M144.585 127.352C175.73 127.352 200.978 125.742 200.978 123.757C200.978 121.772 175.73 120.162 144.585 120.162C113.44 120.162 88.1914 121.772 88.1914 123.757C88.1914 125.742 113.44 127.352 144.585 127.352Z" fill="#3E3E54"/>
        <path d="M128.094 115.375C131.112 114.72 134.178 114.306 137.262 114.136C141.45 113.828 145.696 113.63 149.814 114.456C152.534 115.09 155.208 115.908 157.818 116.905C168.837 120.724 180.252 123.288 191.846 124.548C170.021 125.293 148.185 124.548 126.384 123.484C123.398 123.332 120.414 123.175 117.432 123.012C115.152 122.89 111.22 123.396 109.254 122.396C110.545 120.331 115.396 119.831 117.566 119.011C121.044 117.708 124.465 116.242 128.094 115.375Z" fill="#3E3E54"/>
        <path d="M131.06 116.253C131.06 116.253 129.653 84.3428 122.917 73.0815L124.947 72.1451C124.947 72.1451 134.492 116.096 134.649 116.568C134.806 117.039 133.405 118.441 131.06 116.253Z" fill="#3E3E54"/>
        <path d="M151.221 61.9657C150.593 61.7916 149.93 61.7871 149.3 61.9526C148.669 62.1181 148.094 62.4478 147.632 62.908C146.723 63.8487 146.191 65.0908 146.137 66.3981C146.166 66.9112 146.079 67.4241 145.882 67.8988C145.634 68.2658 145.308 68.5736 144.928 68.8004C143.467 69.7464 142.322 71.1077 141.641 72.7092C141.059 74.3321 141.327 76.3505 142.642 77.4732C140.897 79.1367 139.885 81.7368 140.635 84.0286C141.385 86.3204 144.259 87.8444 146.393 86.7276C146.514 87.6386 146.911 88.4908 147.53 89.1697C148.149 89.8487 148.962 90.3219 149.858 90.5258C150.754 90.7297 151.691 90.6545 152.543 90.3104C153.395 89.9662 154.121 89.3696 154.624 88.6006C155.747 89.9384 157.94 90.0024 159.382 89.0194C160.078 88.4872 160.644 87.8048 161.038 87.0232C161.433 86.2417 161.647 85.3811 161.662 84.5056C161.682 82.7565 161.393 81.0177 160.807 79.3694C162.302 78.9332 163.879 78.4504 164.943 77.3103C166.007 76.1702 166.316 74.1576 165.147 73.1164C166.015 72.4977 166.685 71.641 167.077 70.6498C167.47 69.6586 167.567 68.575 167.357 67.5298C167.148 66.4846 166.64 65.5224 165.896 64.7591C165.152 63.9958 164.203 63.4641 163.163 63.228C162.07 62.9488 161.052 63.2687 160.185 62.4252C159.516 61.768 159.429 60.5115 158.963 59.7088C156.904 56.1839 150.832 57.4927 151.221 61.9657Z" fill="#861E32"/>
        <path d="M152.222 88.5017C152.181 87.4314 152.152 86.2913 152.699 85.3723C153.043 84.8582 153.47 84.4043 153.961 84.0286C154.76 83.3093 155.636 82.6811 156.573 82.1556C157.521 81.6319 158.612 81.428 159.685 81.574C158.493 82.6675 156.777 83.034 155.532 84.0461C154.106 85.2655 153.003 86.8179 152.321 88.5657C151.373 91.7009 151.326 95.4876 150.838 98.7392C150.349 101.991 149.861 105.341 149.366 108.628C148.854 112.118 147.959 115.73 147.819 119.243C147.819 119.738 145.981 120.558 144.562 117.678C146.307 112.443 147.749 107.109 149.064 101.764C150.175 97.2094 151.158 92.6083 151.838 87.9666C152.147 85.8725 152.67 83.4877 152.56 81.3704C152.437 79.1309 150.076 76.938 148.191 75.9783C148.063 75.9085 147.929 75.8445 147.796 75.7863C147.598 75.9259 147.749 76.24 147.912 76.4261C149.343 78.0199 151.268 79.2938 152.019 81.3006C152.216 81.8299 152.321 82.3883 152.501 82.9234C152.385 82.5803 152.804 81.5798 152.752 81.0854C152.657 80.4264 152.606 79.7618 152.6 79.096C152.625 77.5378 152.51 75.9805 152.257 74.4426L154.491 74.2856C154.491 74.2856 153.548 80.5677 152.385 88.4377" fill="#3E3E54"/>
        <path d="M176.455 66.2585C175.827 66.0838 175.163 66.079 174.533 66.2445C173.902 66.4101 173.327 66.7401 172.866 67.2008C171.956 68.1414 171.424 69.3835 171.371 70.6909C171.397 71.2038 171.31 71.7163 171.115 72.1916C170.867 72.5586 170.541 72.8664 170.161 73.0932C168.701 74.0376 167.557 75.3968 166.875 76.9962C166.293 78.6249 166.555 80.6433 167.875 81.7659C166.13 83.4295 165.118 86.0296 165.868 88.3214C166.619 90.6132 169.492 92.1372 171.627 91.0204C171.748 91.9313 172.144 92.7835 172.764 93.4625C173.383 94.1415 174.195 94.6147 175.091 94.8186C175.987 95.0225 176.924 94.9473 177.776 94.6032C178.628 94.259 179.354 93.6624 179.858 92.8934C180.98 94.2312 183.173 94.2952 184.616 93.3122C185.309 92.7782 185.874 92.0955 186.268 91.3143C186.663 90.5331 186.877 89.6734 186.896 88.7984C186.914 87.0489 186.622 85.3101 186.035 83.6622C187.536 83.2259 189.106 82.7431 190.176 81.6031C191.247 80.463 191.543 78.4504 190.38 77.4092C191.248 76.7905 191.919 75.9338 192.311 74.9426C192.703 73.9513 192.8 72.8678 192.591 71.8226C192.381 70.7774 191.873 69.8152 191.129 69.0519C190.385 68.2886 189.436 67.7568 188.397 67.5207C187.303 67.2415 186.285 67.5615 185.418 66.718C184.744 66.0607 184.656 64.8043 184.197 64.0016C182.138 60.4825 176.065 61.7854 176.455 66.2585Z" fill="#861E32"/>
        <path d="M177.455 92.7945C177.414 91.7242 177.385 90.5841 177.926 89.6651C178.273 89.1509 178.701 88.697 179.194 88.3214C179.993 87.6021 180.869 86.9738 181.806 86.4484C182.754 85.9243 183.845 85.7203 184.918 85.8667C183.726 86.9603 182.01 87.3267 180.765 88.3389C179.339 89.5595 178.234 91.1115 177.548 92.8585C176.606 95.9937 176.559 99.7804 176.071 103.032C175.582 106.284 175.088 109.634 174.599 112.92C174.087 116.41 173.191 120.023 173.046 123.536C173.046 124.03 171.214 124.851 169.794 121.971C171.539 116.736 172.982 111.402 174.291 106.057C175.408 101.502 176.391 96.9011 177.071 92.2594C177.374 90.1653 177.903 87.7805 177.787 85.6632C177.67 83.4237 175.303 81.2308 173.424 80.271C173.296 80.2012 173.162 80.1373 173.029 80.0791C172.831 80.2187 172.976 80.5328 173.145 80.7189C174.576 82.3127 176.501 83.5866 177.252 85.5934C177.449 86.1227 177.554 86.6811 177.734 87.2162C177.618 86.873 178.037 85.8726 177.979 85.3781C177.89 84.7185 177.839 84.0543 177.827 83.3888C177.857 81.8304 177.743 80.2726 177.484 78.7354L179.724 78.5784C179.724 78.5784 178.781 84.8605 177.606 92.7305" fill="#3E3E54"/>
        <path d="M159.574 90.9273C159.224 90.8298 158.854 90.8275 158.502 90.9205C158.15 91.0135 157.83 91.1986 157.573 91.4566C157.063 91.9828 156.765 92.6787 156.736 93.411C156.75 93.6976 156.7 93.9837 156.59 94.2486C156.453 94.4539 156.272 94.6267 156.061 94.7547C155.245 95.2825 154.605 96.0421 154.223 96.936C154.046 97.3879 154.008 97.8819 154.111 98.356C154.214 98.83 154.456 99.2628 154.805 99.6C154.3 100.048 153.928 100.627 153.731 101.272C153.534 101.918 153.519 102.605 153.688 103.259C154.107 104.538 155.706 105.394 156.899 104.771C156.968 105.279 157.191 105.754 157.538 106.132C157.884 106.51 158.338 106.774 158.838 106.887C159.338 107 159.861 106.958 160.337 106.766C160.813 106.574 161.218 106.241 161.5 105.812C161.842 106.161 162.298 106.377 162.785 106.421C163.272 106.464 163.759 106.333 164.158 106.051C164.545 105.753 164.86 105.372 165.081 104.936C165.301 104.5 165.421 104.02 165.432 103.532C165.442 102.554 165.28 101.581 164.955 100.659C165.821 100.486 166.624 100.084 167.282 99.4953C167.863 98.8613 168.044 97.7503 167.398 97.1687C167.877 96.8202 168.246 96.3416 168.461 95.7897C168.676 95.2378 168.728 94.6358 168.611 94.0552C168.494 93.4746 168.213 92.9398 167.801 92.5142C167.389 92.0886 166.863 91.7902 166.287 91.6544C165.676 91.4973 165.124 91.6544 164.623 91.2065C164.245 90.84 164.199 90.1362 163.937 89.6883C162.75 87.699 159.365 88.4319 159.574 90.9273Z" fill="#861E32"/>
        <path d="M160.133 105.754C160.055 105.159 160.147 104.554 160.4 104.009C160.59 103.72 160.828 103.466 161.104 103.259C161.549 102.855 162.039 102.503 162.564 102.212C163.095 101.917 163.708 101.803 164.309 101.886C163.646 102.468 162.692 102.7 161.982 103.265C161.187 103.947 160.57 104.814 160.185 105.789C159.662 107.534 159.632 109.657 159.359 111.472L158.539 117.004C158.254 118.947 157.754 120.977 157.672 122.937C157.672 123.216 156.648 123.67 155.857 122.064C156.817 119.156 157.637 116.16 158.37 113.171C158.993 110.629 159.534 108.058 159.918 105.463C160.169 104.252 160.304 103.019 160.319 101.781C160.255 100.531 158.934 99.3034 157.882 98.7683L157.661 98.6578C157.55 98.7392 157.661 98.9137 157.725 99.0184C158.527 99.9084 159.603 100.618 160.022 101.741C160.133 102.037 160.191 102.322 160.29 102.648C160.226 102.456 160.458 101.898 160.429 101.624C160.377 101.254 160.347 100.881 160.342 100.507C160.36 99.6348 160.295 98.7623 160.15 97.9016L161.401 97.8143L160.237 105.719" fill="#3E3E54"/>
        <path d="M190.601 91.4275C190.252 91.2709 189.868 91.2094 189.488 91.2492C189.108 91.2891 188.745 91.4289 188.437 91.6544C187.827 92.1226 187.412 92.8003 187.273 93.5565C187.242 93.8543 187.146 94.1419 186.994 94.3999C186.82 94.5943 186.603 94.7457 186.36 94.842C185.43 95.2628 184.646 95.9513 184.109 96.8197C183.852 97.2579 183.731 97.7623 183.76 98.2694C183.789 98.7765 183.967 99.2637 184.272 99.6699C183.676 100.055 183.198 100.597 182.89 101.236C182.582 101.876 182.456 102.588 182.527 103.294C182.765 104.69 184.272 105.824 185.627 105.364C185.618 105.903 185.773 106.431 186.073 106.879C186.372 107.327 186.801 107.672 187.302 107.869C187.804 108.066 188.353 108.105 188.877 107.98C189.401 107.856 189.875 107.574 190.234 107.173C190.536 107.588 190.976 107.882 191.474 108.003C191.972 108.125 192.498 108.066 192.956 107.837C193.41 107.59 193.803 107.245 194.105 106.826C194.407 106.407 194.611 105.925 194.701 105.417C194.863 104.404 194.848 103.371 194.655 102.363C195.573 102.322 196.463 102.034 197.232 101.531C197.953 100.95 198.302 99.8269 197.72 99.1231C198.273 98.8376 198.732 98.3992 199.043 97.8601C199.354 97.321 199.503 96.7039 199.473 96.0824C199.443 95.4609 199.235 94.8611 198.874 94.3544C198.513 93.8478 198.013 93.4557 197.435 93.2249C196.854 92.969 196.208 93.0679 195.783 92.4978C195.446 92.0557 195.51 91.3345 195.312 90.8168C194.399 88.5715 190.775 88.7984 190.601 91.4275Z" fill="#861E32"/>
        <path d="M188.85 106.894C188.865 106.273 189.055 105.668 189.397 105.149C189.642 104.88 189.931 104.653 190.252 104.48C190.777 104.134 191.338 103.847 191.927 103.625C192.522 103.404 193.173 103.381 193.783 103.561C192.997 104.091 191.979 104.143 191.159 104.632C190.224 105.208 189.447 106.008 188.896 106.958C188.07 108.703 187.733 110.879 187.151 112.723L185.406 118.336C184.825 120.308 183.964 122.332 183.574 124.356C183.522 124.641 182.411 124.938 181.829 123.193C183.289 120.284 184.61 117.347 185.837 114.357C186.884 111.815 187.855 109.238 188.658 106.603C189.11 105.384 189.443 104.124 189.653 102.84C189.781 101.537 188.606 100.054 187.599 99.3499L187.384 99.2045C187.256 99.2685 187.314 99.4663 187.384 99.5884C188.076 100.635 189.077 101.543 189.339 102.776C189.403 103.096 189.414 103.433 189.472 103.759C189.437 103.55 189.769 103.003 189.781 102.712C189.784 102.323 189.813 101.934 189.868 101.549C190.019 100.645 190.089 99.7305 190.077 98.8148L191.386 98.9195L188.92 106.941" fill="#3E3E54"/>
        <path d="M97.8353 91.4275C97.4316 91.4634 97.0443 91.6041 96.7117 91.8357C96.3791 92.0673 96.1127 92.3818 95.939 92.7479C95.6066 93.4945 95.561 94.3373 95.8111 95.1153C95.937 95.411 95.9965 95.7307 95.9856 96.0518C95.9173 96.3195 95.7923 96.5695 95.6191 96.7848C94.9676 97.6512 94.5889 98.692 94.5314 99.7746C94.5128 100.316 94.6555 100.852 94.9412 101.312C95.227 101.773 95.6431 102.139 96.1368 102.363C95.7771 103.026 95.6083 103.775 95.6494 104.528C95.6905 105.281 95.9398 106.007 96.3695 106.627C97.3002 107.79 99.2778 108.087 100.319 106.976C100.581 107.49 100.996 107.911 101.507 108.181C102.018 108.45 102.599 108.556 103.172 108.483C103.745 108.41 104.281 108.161 104.707 107.772C105.134 107.382 105.429 106.871 105.554 106.307C106.046 106.541 106.606 106.593 107.132 106.452C107.659 106.312 108.118 105.988 108.428 105.539C108.72 105.077 108.905 104.556 108.969 104.014C109.034 103.471 108.976 102.921 108.8 102.404C108.436 101.376 107.892 100.42 107.194 99.5826C108.029 99.0727 108.714 98.35 109.178 97.4886C109.556 96.5928 109.3 95.348 108.398 94.9932C108.768 94.445 108.972 93.8022 108.986 93.1414C109.001 92.4806 108.825 91.8295 108.48 91.2657C108.135 90.7019 107.635 90.2491 107.04 89.9612C106.445 89.6734 105.78 89.5625 105.124 89.6418C104.426 89.7116 103.896 90.1188 103.21 89.8047C102.675 89.5662 102.355 88.8507 101.907 88.4784C99.9235 86.8265 96.6487 88.8914 97.8353 91.4275Z" fill="#861E32"/>
        <path d="M104.106 106.749C103.792 106.154 103.657 105.482 103.716 104.812C103.806 104.438 103.96 104.082 104.17 103.759C104.481 103.165 104.859 102.608 105.298 102.101C105.74 101.592 106.333 101.24 106.991 101.095C106.526 101.985 105.612 102.567 105.089 103.422C104.516 104.441 104.202 105.586 104.176 106.755C104.292 108.791 105.077 111.018 105.49 113.025C105.903 115.032 106.328 117.097 106.753 119.139C107.195 121.285 107.445 123.6 108.114 125.688C108.213 125.979 107.323 126.852 105.874 125.467C105.764 122.035 105.479 118.598 105.101 115.189C104.775 112.281 104.368 109.372 103.769 106.516C103.566 105.148 103.235 103.803 102.78 102.497C102.233 101.217 100.377 100.438 99.0686 100.275H98.7952C98.7079 100.397 98.865 100.548 98.9988 100.624C100.162 101.246 101.581 101.584 102.448 102.596C102.675 102.863 102.861 103.177 103.076 103.445C102.937 103.27 102.966 102.59 102.832 102.317C102.635 101.94 102.462 101.551 102.314 101.153C101.995 100.233 101.594 99.3434 101.116 98.4949L102.39 97.9132C102.39 97.9132 103.187 101.793 104.182 106.638" fill="#3E3E54"/>
        <path d="M128.681 82.3476C129.263 81.3471 130.13 80.4804 130.572 79.3927C130.957 78.2439 131.11 77.0297 131.02 75.8212C131.217 76.9225 131.293 78.0422 131.247 79.16C131.688 79.0223 132.155 78.9917 132.61 79.0706C133.065 79.1495 133.495 79.3357 133.864 79.6137C132.701 79.7185 131.491 79.8406 130.543 80.5153C129.379 81.3471 128.879 82.842 128.716 84.2613C128.553 85.6806 128.647 87.1348 128.332 88.5366C128.332 87.4896 127.809 86.4891 127.693 85.4887C127.576 84.4882 128.164 83.2027 128.681 82.3476Z" fill="#3E3E54"/>
        <path d="M122.306 77.1649C122.05 76.6298 121.847 76.0016 121.317 75.7282L123.243 79.416L120.224 80.3757C119.962 80.4572 119.642 80.6666 119.747 80.9167C120.579 80.9923 121.381 80.6259 122.207 80.5095C123.126 80.402 124.055 80.5942 124.856 81.0575C125.657 81.5207 126.287 82.2303 126.651 83.0805C126.576 82.8944 126.826 82.2022 126.826 81.9579C126.852 81.6033 126.784 81.2481 126.628 80.9283C126.366 80.5502 125.744 80.3874 125.36 80.1489C124.08 79.464 123.021 78.4291 122.306 77.1649Z" fill="#3E3E54"/>
        <path d="M113.285 93.4402C112.883 93.477 112.498 93.6197 112.169 93.8537C111.84 94.0876 111.579 94.4045 111.412 94.7722C111.088 95.5204 111.055 96.3624 111.319 97.1338C111.445 97.4254 111.504 97.7412 111.493 98.0586C111.43 98.3261 111.309 98.5763 111.139 98.7916C110.5 99.6617 110.138 100.703 110.097 101.781C110.086 102.318 110.234 102.846 110.523 103.299C110.812 103.751 111.228 104.108 111.72 104.323C111.372 104.987 111.213 105.735 111.263 106.483C111.312 107.231 111.567 107.951 111.999 108.564C112.936 109.727 114.937 109.977 115.926 108.86C116.196 109.361 116.613 109.768 117.121 110.025C117.629 110.282 118.204 110.378 118.768 110.3C119.331 110.221 119.858 109.972 120.276 109.586C120.695 109.2 120.985 108.695 121.108 108.139C121.601 108.365 122.159 108.409 122.681 108.262C123.203 108.114 123.656 107.787 123.959 107.336C124.243 106.875 124.42 106.357 124.478 105.818C124.535 105.279 124.471 104.735 124.29 104.224C123.917 103.205 123.365 102.259 122.661 101.432C123.484 100.916 124.157 100.192 124.61 99.3325C124.977 98.4367 124.709 97.2036 123.807 96.8546C124.169 96.3057 124.366 95.6643 124.373 95.0069C124.38 94.3495 124.198 93.7039 123.849 93.1471C123.499 92.5903 122.997 92.1458 122.402 91.8667C121.806 91.5876 121.143 91.4856 120.492 91.573C119.794 91.6544 119.276 92.0616 118.59 91.7649C118.055 91.5323 117.729 90.8226 117.281 90.4562C115.309 88.8449 112.075 90.9331 113.285 93.4402Z" fill="#861E32"/>
        <path d="M121.5 126C121.5 126 120.794 107.424 116.624 100.444L117.886 99.8618C117.886 99.8618 123.913 126.227 124 126.5C124.087 126.773 122.954 127.338 121.5 126Z" fill="#3E3E54"/>
        <path d="M120.189 106.185C120.621 105.605 121.01 104.995 121.353 104.358C121.59 103.645 121.683 102.892 121.626 102.142C121.748 102.825 121.795 103.519 121.766 104.213C122.039 104.128 122.329 104.109 122.611 104.158C122.893 104.206 123.159 104.321 123.389 104.492C122.663 104.5 121.952 104.701 121.329 105.074C120.991 105.363 120.716 105.719 120.521 106.119C120.326 106.52 120.215 106.956 120.195 107.4C120.165 108.288 120.087 109.173 119.962 110.053C119.867 109.416 119.735 108.784 119.567 108.162C119.569 107.455 119.786 106.766 120.189 106.185Z" fill="#3E3E54"/>
        <path d="M116.24 102.974C116.077 102.642 115.955 102.247 115.623 102.084L116.786 104.37L114.919 104.951C114.756 105.004 114.553 105.138 114.623 105.289C115.138 105.263 115.649 105.179 116.146 105.039C116.717 104.97 117.294 105.088 117.791 105.376C118.288 105.663 118.679 106.104 118.904 106.632C118.857 106.516 119.008 106.086 119.008 105.934C119.027 105.714 118.984 105.493 118.886 105.295C118.723 105.062 118.339 104.963 118.101 104.812C117.322 104.384 116.677 103.748 116.24 102.974Z" fill="#3E3E54"/>
        <path d="M167.823 38.978C175.597 38.978 181.899 32.6757 181.899 24.9015C181.899 17.1272 175.597 10.825 167.823 10.825C160.048 10.825 153.746 17.1272 153.746 24.9015C153.746 32.6757 160.048 38.978 167.823 38.978Z" fill="#861E32"/>
        <path d="M146.65 15.8506C148.859 19.3897 149.96 23.5079 149.813 27.6772C149.666 31.8466 148.277 35.8769 145.824 39.2514C149.238 39.4841 152.687 40.1995 155.642 41.9329C158.597 43.6663 161.005 46.5165 161.639 49.8844C164.275 46.2601 168.201 43.7846 172.607 42.9676C177.013 42.1507 181.565 43.0544 185.325 45.4927C184.744 40.5718 181.928 35.6857 183.313 30.9218C184.336 27.4318 187.384 24.9364 190.293 22.6969C187.684 20.8769 185.625 18.374 184.344 15.4623C183.063 12.5506 182.607 9.34235 183.028 6.18902C180.177 8.5797 176.077 9.09739 172.47 8.20743C169.451 7.43962 167.561 5.45611 164.908 4.16479C163.163 3.32136 163.611 3.37953 162.384 4.91515C161.517 5.99125 160.866 7.20695 159.947 8.25396C158.207 10.2365 156.148 11.9153 153.857 13.2215C153.176 13.6054 147.15 16.6243 146.65 15.8506Z" fill="url(#chisiamoproduttori-paint2_linear)"/>
        <path d="M162.5 180.383L162.14 183.495C159.033 185.308 155.763 186.827 152.373 188.032C137.023 193.418 120.114 191.342 104.187 188.032C96.201 186.374 87.9471 184.42 79.9781 186.147C74.7431 187.282 69.9908 189.928 65.3199 192.546L46.8343 202.923C43.6781 199.587 40.8804 195.93 38.4873 192.011C42.2682 190.056 66.6927 177.667 76.7847 178.818C87.7318 180.069 117.764 185.385 124.959 185.077C132.154 184.769 162.5 180.383 162.5 180.383Z" fill="url(#chisiamoproduttori-paint3_linear)"/>
      </g>
      <defs>
      <linearGradient id="chisiamoproduttori-paint0_linear" x1="161.284" y1="138.322" x2="249.216" y2="177.754" gradientUnits="userSpaceOnUse">
      <stop offset="0.02" stopColor="white" stopOpacity="0" stroke="none" fill="none"/>
      <stop offset="0.32" stopColor="white" stopOpacity="0.39" stroke="none" fill="none"/>
      <stop offset="0.68" stopColor="white" stopOpacity="0.68" stroke="none" fill="none"/>
      <stop offset="1" stopColor="white" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id="chisiamoproduttori-paint1_linear" x1="75.1969" y1="187.171" x2="144.021" y2="136.49" gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stroke="none" fill="none" stroke="none" fill="none"/>
      <stop offset="0.13" stopOpacity="0.69" stroke="none" fill="none"/>
      <stop offset="1" stopOpacity="0" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id="chisiamoproduttori-paint2_linear" x1="9471.86" y1="2502.27" x2="12871.1" y2="2502.27" gradientUnits="userSpaceOnUse">
      <stop offset="0.02" stopColor="white" stopOpacity="0" stroke="none" fill="none"/>
      <stop offset="0.32" stopColor="white" stopOpacity="0.39" stroke="none" fill="none"/>
      <stop offset="0.68" stopColor="white" stopOpacity="0.68" stroke="none" fill="none"/>
      <stop offset="1" stopColor="white" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id="chisiamoproduttori-paint3_linear" x1="16816.2" y1="11008.5" x2="16785.8" y2="5659.36" gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stroke="none" fill="none" stroke="none" fill="none"/>
      <stop offset="0.13" stopOpacity="0.69" stroke="none" fill="none"/>
      <stop offset="1" stopOpacity="0" stroke="none" fill="none"/>
      </linearGradient>
      <clipPath id="chisiamoproduttori-clip0">
      <rect width="300" height="226.108" stroke="none"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default ChiSiamoProduttori
