import React from 'react'
import { useTrans, useWindowDimensions } from 'hooks'
import { priceNotation } from 'utils/safe'
import { Button, ImageBox, Label } from 'components/atoms'

interface Props {
  ordine: {
    id: number
    stato: {
      nome: string
    }
    spedizioneLink?: string
    codice: string
    totale: number
    righeProdotti: {
      id: number
      prodotto: {
        id: number
        imageUrl: string
        nome: string
      }
      quantita: number
    }[]
    estero: boolean
  }
  className?: string
}

const Ordine = (props: Props) => {
  const {
    ordine = {
      id: 1,
      stato: {
        nome: '',
      },
      spedizioneLink: '',
      codice: '',
      totale: 0,
      righeProdotti: [],
      estero : false,
    },
    className = '',
  } = props

  const t = useTrans()
  const { width } = useWindowDimensions()
  const limit = width < 768 ? 3 : 4

  return (
    <div className={`ordine ${className ? ` ${className}` : ''}`}>
      <div className="ordine__info">
        <p className="ordine__title">{ordine.stato.nome}</p>
        <p className="ordine__code">
          {t('Codice ordine')} {ordine.codice}
        </p>
        <p className="ordine__totale">{priceNotation(ordine.totale)}</p>
        {ordine.spedizioneLink && !ordine.estero && (
          <Button
            label={t('Segui la spedizione')}
            variant="secondary"
            size="sm"
            className="ordine__spedizione-link"
            href={ordine.spedizioneLink}
            external={true}
            newPage={true}
          />
        )}
      </div>
      <div className="ordine__preview">
        {ordine.righeProdotti.length > 0 && (
          <div className="ordine__products">
            {ordine.righeProdotti.map((riga, index) =>
              ordine.righeProdotti.length <= limit || index + 1 < limit ? (
                <div className="ordine__product" key={`ordine__product__${riga.id}`}>
                  <ImageBox
                    src={riga.prodotto.imageUrl}
                    classPrefix="ordine__product"
                    maxWidth={81}
                    maxHeight={108}
                  >
                    {riga.quantita > 1 && (
                      <Label
                        label={`x${riga.quantita}`}
                        size="sm"
                        className="ordine__product__quantity"
                      />
                    )}
                  </ImageBox>
                </div>
              ) : index === limit ? (
                <div className="ordine__product-more" key={`ordine__product__${riga.id}`}>
                  <p className="ordine__product-more__label">
                    {t('+ altri {0}', [(ordine.righeProdotti.length - limit + 1).toString()])}
                  </p>
                </div>
              ) : (
                <></>
              )
            )}
          </div>
        )}
        <div className="ordine__link-box">
          <Button
            href={`/account/orders/${ordine.id}`}
            label={t('Visualizza ordine')}
            variant="ghost"
            size="sm"
            className="ordine__link"
          />
          <span className="ordine__link-box__text">{t('per scaricare la ricevuta')}</span>
        </div>
      </div>
    </div>
  )
}

export default Ordine
