import { gql, useQuery, useMutation } from '@apollo/client'
import Modal from 'components/atoms/Modal/Modal'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useTrans } from 'hooks'
import {
  CART,
  ADD_TO_CART,
  ADD_MULTIPLE_TO_CART,
  REMOVE_FROM_CART,
  SET_MESSAGGIO,
  SET_PROMO_CODE,
  REMOVE_PROMO_CODE,
} from 'utils/queries'
//import { trackingAddRemoveFromCart } from 'utils/gtm'

export const initialCart = {
  items: [],
  numProdotti: 0,
  totaleProdottiScontato: 0,
  prezzo: 0,
  promoApplicata: null,
  sistemaSpedizione: {
    prezzoScontato: 0,
  },
  promoCode: '',
}

export const CartContext = React.createContext(initialCart)

const CartContextProvider = (props) => {
  const router = useRouter()
  const t = useTrans()

  const { loading, data, refetch } = useQuery(
    gql`
      ${CART}
    `,
    { fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true }
  )

  const [addItem, { data: addItemPayload, loading: addItemLoading, error: addItemError }] =
    useMutation(
      gql`
        ${ADD_TO_CART}
      `
    )

  const [
    addMultipleItems,
    {
      data: addMultipleItemsPayload,
      loading: addMultipleItemsLoading,
      error: addMultipleItemsError,
    },
  ] = useMutation(
    gql`
      ${ADD_MULTIPLE_TO_CART}
    `
  )

  const [
    removeItem,
    { data: removeItemPayload, loading: removeItemLoading, error: removeItemError },
  ] = useMutation(
    gql`
      ${REMOVE_FROM_CART}
    `
  )

  const [
    setMessaggio,
    { data: setMessaggioPayload, loading: setMessaggioLoading, error: setMessaggioError },
  ] = useMutation(
    gql`
      ${SET_MESSAGGIO}
    `
  )

  const [setPromo, { data: setPromoPayload, loading: setPromoLoading, error: setPromoError }] =
    useMutation(
      gql`
        ${SET_PROMO_CODE}
      `
    )

  const [
    removePromo,
    { data: removePromoPayload, loading: removePromoLoading, error: removePromoError },
  ] = useMutation(
    gql`
      ${REMOVE_PROMO_CODE}
    `
  )

  const [cart, setCart] = useState(initialCart)
  const [showCart, setShowCart] = useState(false)
  const [infoMessage, setInfoMessage] = useState('')
  const [productNotAvailableInternational, setProductNotAvailableInternationl] = useState(false)

  const isLoading = loading || addItemLoading

  useEffect(() => {
    if (data) initCart(data.cart)
  }, [data])

  useEffect(() => {
    if (showCart) closeCart()
    refetch()
  }, [router.asPath])

  useEffect(() => {
    checkAvailabilityItems()
  }, [cart.items])

  function initCart(cart) {
    setCart(cart)
  }

  function checkAvailabilityItems() {
    const isAnyItemNotAvailableInternationl = cart?.items.some(
      (item) => !item.prodotto.prodotto.vendibileEstero
    )

    setProductNotAvailableInternationl(isAnyItemNotAvailableInternationl)
  }

  async function addToCart(item, quantita = 1, show = true) {
    const response = await addItem({
      variables: {
        item: item.pk,
        quantita,
      },
    })

    const {
      data: {
        addItem: { status, message, shortMessage, quantitaMax },
      },
    } = response

    if (status) {
      refetch()
      if (show) openCart()
    }
    return { status, message, shortMessage, quantitaMax }

    //trackingAddRemoveFromCart(item, quantita)
  }

  async function addMultipleToCart(items, show = true) {
    const response = await addMultipleItems({
      variables: {
        items: items,
      },
    })

    const {
      data: {
        addMultipleItems: { status },
      },
    } = response

    if (status) {
      refetch()
      if (show) openCart()
      return true
    } else {
      return false
    }
  }

  async function removeFromCart(item, show = true, estero = false, refetchQueries = []) {
    const response = await removeItem({
      variables: {
        item: item.pk,
        estero: estero,
      },
      refetchQueries,
    })

    const {
      data: {
        removeItem: { status },
      },
    } = response

    if (status) {
      refetch()
      if (show) openCart()
      return true
    } else {
      return false
    }

    //trackingAddRemoveFromCart(item, quantita)
  }

  async function addMessaggio(includiMessaggio, messaggio, messaggioFirma) {
    const response = await setMessaggio({
      variables: {
        includiMessaggio: includiMessaggio,
        messaggio: messaggio,
        messaggioFirma: messaggioFirma,
      },
    })

    const {
      data: {
        setMessaggio: { status },
      },
    } = response

    if (status) {
      return true
    } else {
      return false
    }

    //trackingAddRemoveFromCart(item, quantita)
  }

  async function setPromoCode(promoCode) {
    const response = await setPromo({
      variables: {
        promoCode: promoCode,
      },
    })

    const {
      data: {
        setPromoCode: { status, promozione, message },
      },
    } = response

    if (status) {
      refetch()
      return { status, promozione, message }
    } else {
      return { status, promozione, message }
    }

    //trackingAddRemoveFromCart(item, quantita)
  }

  async function removePromoCode(promoCode) {
    const response = await removePromo({
      variables: {
        promoCode: promoCode,
      },
    })

    const {
      data: {
        removePromoCode: { status },
      },
    } = response

    if (status) {
      refetch()
      return true
    } else {
      return false
    }

    //trackingAddRemoveFromCart(item, quantita)
  }

  function openCart() {
    setShowCart(true)
  }

  function closeCart() {
    setShowCart(false)
  }

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        addMultipleToCart,
        removeFromCart,
        setPromoCode,
        removePromoCode,
        addMessaggio,
        isLoading,
        showCart,
        openCart,
        closeCart,
        productNotAvailableInternational,
      }}
    >
      {props.children}
      <Modal open={infoMessage} setOpen={() => setInfoMessage('')} closeButton>
        <h3 className="modal__body__title">{t('Errore carrello')}</h3>
        <p className="modal__body__text">{infoMessage}</p>
      </Modal>
    </CartContext.Provider>
  )
}

export default CartContextProvider
