// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN || 'https://5e7f021aa55d449d856ebb7e908daed6@o19983.ingest.sentry.io/5891134',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.2,
  enabled: process.env.NODE_ENV !== 'development',

  // altrimenti aggiunge header sentry-trace ad ogni chiamata anche cors e quindi
  // verso doofinder (requirement specifico di vinicum) dava errore cors
  integrations: [
    new Sentry.Integrations.BrowserTracing({
      tracingOrigins: [
        '*',
      ],
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  // initialScope: {
  //   tags: { 'my-tag': 'my value' },
  //   user: { id: 42, email: 'john.doe@example.com' },
  // },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
