import React, { useEffect, useState } from 'react'
import { CheckoutOption } from 'components/molecules'
import PaypalFormContainer from 'components/organisms/PaypalForm/PaypalForm'
import { priceNotation } from 'utils/safe'

interface Props {
  metodoPagamento: any
  checked: boolean
  onClick: (e: any) => void
  paypalFormContent?: any
  className?: string
}

const CheckoutPaymentMethodOption = (props: Props) => {
  const {
    metodoPagamento = {},
    checked = false,
    onClick = () => {},
    paypalFormContent = null,
    className = '',
  } = props

  const [images, setImages] = useState([])

  const getImagesByChiave = (chiave) => {
    let images = []
    switch (chiave) {
      case 'amazon_pay':
        images = ['/images/amazonpay.png']
        break
      case 'paypal_form':
        images = [
          '/images/cc_mastercard.png',
          '/images/cc_maestro.png',
          '/images/cc_amex.png',
          '/images/cc_visa.png',
        ]
        break
      case 'stripe':
        images = [
          '/images/cc_mastercard.png',
          '/images/cc_maestro.png',
          '/images/cc_amex.png',
          '/images/cc_visa.png',
          '/images/cc_cb.jpg',
        ]
        break
      case 'bonifico':
        images = ['/images/my_bank.png']
        break
      case 'paypal_login':
        images = ['/images/paypal_big.png']
        break
    }
    return images
  }

  useEffect(() => {
    if (metodoPagamento) {
      setImages(getImagesByChiave(metodoPagamento.chiave))
    }
  }, [metodoPagamento])

  return (
    <CheckoutOption
      title={metodoPagamento.nome}
      description={metodoPagamento.descrizione}
      moreContent={metodoPagamento.chiave === 'paypal_form' && checked ? paypalFormContent : null}
      checked={checked}
      onClick={onClick}
      fullClick
      className={`checkout-payment-method-option${className ? ` ${className}` : ''}`}
      actions={
        <>
          {!!images?.length && (
            <div className="checkout-payment-method-option__images">
              {images.map((image, index) => (
                <img src={image} key={`checkout-payment-method-option__image__${index}`} />
              ))}
            </div>
          )}
          {!!metodoPagamento.prezzo && (
            <div className="checkout-payment-method-option__price">
              {priceNotation(metodoPagamento.prezzo)}
            </div>
          )}
        </>
      }
    />
  )
}

export default CheckoutPaymentMethodOption
