import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function PremioBottiglia({ className }: Props) {
  return (
    <IconBase name="premio-bottiglia" w={30} className={className}>
      <g clip-path="url(#premiobottiglia-clip0)">
      <path d="M15.0142 29.9757C10.3184 29.9757 9.84393 28.4671 9.80743 27.9927V11.2409C9.77214 10.5979 9.92183 9.95825 10.2388 9.39768C10.5557 8.83712 11.0266 8.3791 11.5958 8.07785C11.7677 7.97521 11.9269 7.85272 12.0702 7.71289C12.3489 7.40438 12.5122 7.00893 12.5325 6.59367V2.01946C12.4094 1.8639 12.3262 1.6806 12.2901 1.48552C12.2541 1.29045 12.2663 1.08952 12.3257 0.900238C12.5812 0.243304 13.4084 -0.0486679 15.0142 -0.0486679C16.6201 -0.0486679 17.3865 0.243303 17.6541 0.888072C17.7186 1.07446 17.7368 1.27373 17.7071 1.4687C17.6774 1.66368 17.6008 1.84852 17.4838 2.00729V6.59367C17.5107 7.00742 17.6732 7.40079 17.9461 7.71289C18.0963 7.84984 18.2592 7.97205 18.4327 8.07785C18.9909 8.37833 19.4534 8.82965 19.7675 9.38031C20.0815 9.93097 20.2345 10.5588 20.2089 11.1922V27.944C20.1724 28.4671 19.6979 29.9757 15.0142 29.9757Z" stroke="none"/>
      </g>
      <defs>
      <clipPath id="premiobottiglia-clip0">
      <rect width="10.4015" height="30" transform="translate(9.80768)" stroke="none"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default PremioBottiglia
