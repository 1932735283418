import { rewriteURIForGET } from '@apollo/client'
import dayjs from 'dayjs'
import { ORA_LIMITE_SPEDIZIONE_DOMANI, GIORNI_FESTIVI } from './settings'

export const getYearOptions = (yearFrom: number, yearNumber: number) =>
  [...Array.from({ length: yearNumber }).keys()].map((i) => ({
    label: (i + yearFrom).toString(),
    value: (i + yearFrom).toString(),
  }))

// da usare se dobbiamo centralizzare riscrittura url immagini o mettere prefessi
export const getImageUrl = (url) => `${url}`

export const numberNotation = (valore: any, numeroDecimali: number) => {
  const valoreSafe = Number.parseFloat(valore) || 0
  const [intPart, decimals] = valoreSafe.toFixed(numeroDecimali).toString().split('.')
  return intPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + (numeroDecimali > 0 ? `,${decimals}` : '')
}

// Lo distinguo da numberNotation, non si sa mai che i prezzi possano avere un formato diverso successivamente (es includere valuta)
export const priceNotation = (valore: any) => `€ ${numberNotation(valore, 2)}`

export const dateNotation = (valore: string) => dayjs(valore).format('DD/MM/YYYY')
export const dateNotationSeo = (valore: string) => dayjs(valore).format('YYYY-MM-DD').split('T')[0]

export const getFormFieldStatus = (formikProps: any, propName: string) =>
  formikProps.touched[propName] ? (formikProps.errors[propName] ? 'error' : 'success') : null

export const getShippingCountDown = () => {
  const now = dayjs()
  const oraLimiteSpedizione = dayjs()
    .set('hour', ORA_LIMITE_SPEDIZIONE_DOMANI)
    .set('minute', 0)
    .set('second', 0)
  if (now >= oraLimiteSpedizione) return null
  //Aggiungo il controllo che  non sia ne sabato ne domenica e che non sia tra i giorni festivi

  if (
    now.$d.getDay() == 6 ||
    now.$d.getDay() == 0 ||
    GIORNI_FESTIVI.includes(now.$d.toISOString().slice(0, 10))
  )
    return null

  const totalMinutes = oraLimiteSpedizione.diff(now, 'minute')
  const minutes = totalMinutes % 60
  const hours = Math.floor(totalMinutes / 60)
  const labelHours = hours === 0 ? '' : hours === 1 ? '1 ora' : '{0} ore'
  const labelMinutes = minutes === 0 ? '' : minutes === 1 ? '1 minuto' : '{1} minuti'

  return {
    hours,
    minutes,
    label:
      labelHours && labelMinutes ? `${labelHours} e ${labelMinutes}` : labelHours || labelMinutes,
  }
}

export const getFreeShippingRest = (
  limiteFreeShipping: number,
  totaleProdottiScontato: number,
  promoApplicata: any
) => {
  if (!limiteFreeShipping) {
    return 0
  }
  if (!promoApplicata) {
    return totaleProdottiScontato < limiteFreeShipping
      ? limiteFreeShipping - totaleProdottiScontato
      : 0
  } else {
    const percentuale = promoApplicata.percentuale
    const valore = promoApplicata.valore
    let totalForFreeShipping = 0

    if (percentuale) {
      totalForFreeShipping = limiteFreeShipping + (totaleProdottiScontato * valore) / 100
    } else {
      totalForFreeShipping = limiteFreeShipping + valore
    }
    if (totalForFreeShipping < totaleProdottiScontato) {
      return 0
    } else {
      return totalForFreeShipping - totaleProdottiScontato
    }
  }
}

export const getCategoriaSlug = (slug) => {
  if (typeof slug === 'string') {
    return [slug]
  }
  const categoriaSlug = []
  for (let i = 0; i < slug.length; i++) {
    if (slug[i] === 'f') break
    else {
      categoriaSlug.push(slug[i])
    }
  }
  return categoriaSlug
}

export const setFiltriSlug = (slug, filtri_slug) => {
  let newSlug = [...slug]
  if (filtri_slug) {
    const filtriSlug = filtri_slug.split('/')
    filtriSlug.unshift('f')
    newSlug = newSlug.concat(filtriSlug)
  }
  return newSlug
}

export const getFiltriSlug = (slug) => {
  const filtriSlug = []
  if (typeof slug === 'string') {
    return filtriSlug
  }
  const fIndex = slug.findIndex((item) => item === 'f')
  if (fIndex > -1) {
    for (let i = fIndex + 1; i < slug.length; i++) {
      filtriSlug.push(slug[i])
    }
  }
  return filtriSlug
}

export const scrollTo = (ref, isMobile, utente) => {
  const y = ref.current.offsetTop - (isMobile ? 55 : 0) // altezza header - nella desktop scompare allo scroll quindi è 0

  window.scrollTo({
    top: y,
    behavior: 'smooth',
  })
}
