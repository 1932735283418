import React from 'react'

interface Props {
  cantina?: {
    infoBox: {
      testoTop?: string
      testoBottom?: string
    }[]
  }
  className?: string
}

const CantinaInfo = (props: Props) => {
  const {
    cantina = {
      infoBox: [],
    },
    className = '',
  } = props

  return cantina?.infoBox?.length > 0 ? (
    <div className={`cantina-info ${className ? ` ${className}` : ''}`}>
      {cantina.infoBox
        .filter((item) => item.testoTop || item.testoBottom)
        .map((item, key) => (
          <div className="cantina-info__item" key={`cantina-info__${key}`}>
            <p className="cantina-info__item__title">
              {item.testoTop}
              <br />
              {item.testoBottom}
            </p>
          </div>
        ))}
    </div>
  ) : (
    <></>
  )
}

export default CantinaInfo
