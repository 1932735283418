import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function BlogShape4({ className }: Props) {
  return (
    <IconBase name="blog-shape4" w={70} className={className}>
      <path d="M59.8508 59.5796C44.0258 71.1466 1.6665 56.6037 0.631395 37.5877C-0.403709 18.5718 15.781 8.36693 41.9268 5.50774C68.0725 2.64855 79.2368 45.4097 59.8508 59.5796Z" stroke="none"/>
    </IconBase>
  )
}

export default BlogShape4
