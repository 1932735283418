import axios from 'axios'
import { useState, useEffect, useContext } from 'react'
import { GlobalContext } from '../../../../../services/globalContext'
import { API_HOST } from 'utils'

export const FileToolbarItem = ({ propKey, propSubKey, value, setProp, onChange }) => {
  const { leftSidebarTab, setLeftSidebarTab, assetId, setAssetId } = useContext(GlobalContext)

  const [waitingForFile, setWaitingForFile] = useState(false)

  const openFiler = (event) => {
    event.preventDefault()
    setWaitingForFile(true)
    setLeftSidebarTab('Assets')

    /* window.open(
      'http://localhost:9999/d/filer-wrapper/',
      'upload',
      'width=500,height=400'
    ) */
  }

  useEffect(() => {
    if (leftSidebarTab !== 'Assets') {
      setWaitingForFile(false)
    }
  }, [leftSidebarTab])

  useEffect(() => {
    const setFileFromServer = async () => {
      if (assetId) {
        if (!waitingForFile) {
          return
        }

        const response = await axios.get(
          API_HOST + '/d/filebrowser_filer/url_image/' + assetId + '/'
        )
        const data = response.data
        const url = API_HOST + data.url

        setProp((props) => {
          props[propKey][propSubKey] = onChange ? onChange(url) : url
        }, 500)

        setLeftSidebarTab(null)
        setAssetId(null)
      }
    }

    setFileFromServer()
  }, [assetId])

  return <input type="file" onClick={(e) => openFiler(e)} />
}
