import React from 'react'
import { ImageBox } from 'components/atoms'

interface ButtonsProp {
  children?: any
}

interface Props {
  intro?: any
  title?: string
  text?: string
  imageUrl?: string
  className?: string
  children?: any
  buttons?: any
}

const ThankYouPageButtons = (props: ButtonsProp) => {
  const { children = null } = props

  return <div className="thank-you-page__buttons">{children}</div>
}

const ThankYouPage = (props: Props) => {
  const {
    intro = '',
    title = '',
    text = '',
    imageUrl = '',
    className = '',
    children = null,
    buttons = null,
  } = props

  return (
    <div className={`thank-you-page ${className ? ` ${className}` : ''}`}>
      <div className="thank-you-page__content">
        {imageUrl && (
          <ImageBox classPrefix="thank-you-page" src={imageUrl} maxWidth={351} maxHeight={240} />
        )}
        {intro && <p className="thank-you-page__intro">{intro}</p>}
        {title && <p className="thank-you-page__title">{title}</p>}
        {text && (
          <div className="thank-you-page__text" dangerouslySetInnerHTML={{ __html: text }} />
        )}
        {children && <div className="thank-you-page__children">{children}</div>}
        {buttons && buttons.length > 0 && <ThankYouPageButtons>{buttons}</ThankYouPageButtons>}
      </div>
    </div>
  )
}

ThankYouPage.Buttons = ThankYouPageButtons

export default ThankYouPage
