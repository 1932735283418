import React from 'react'
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useTrans } from 'hooks'
import { ArticoloCard } from 'components/molecules'

SwiperCore.use([Pagination])
interface Props {
  articoli: {
    id: number
    pk: number
    imageUrl?: string
    categoria?: {
      nome?: string
    }
    titolo?: string
    abstract?: string
    url: string
  }[]
  title?: string
  className?: string
}

const ArticoliCorrelati = (props: Props) => {
  const { articoli = [], title = '', className = '' } = props

  const t = useTrans()

  return articoli.length > 0 ? (
    <div className={`articoli-correlati ${className ? ` ${className}` : ''}`}>
      <p className="articoli-correlati__title">{title || t('Lasciati ispirare')}</p>
      <div className="articoli-correlati__list">
        <Swiper
          spaceBetween={16}
          slidesPerView={1}
          pagination={{ clickable: true }}
          watchOverflow
          breakpoints={{
            320: { slidesPerView: 1.5 },
            576: {
              slidesPerView: 2.5,
            },
            991: { slidesPerView: 4 },
          }}
        >
          {articoli.map((articolo) => (
            <SwiperSlide key={`articolo-correlato__${articolo.pk}`}>
              <ArticoloCard articolo={articolo} />{' '}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default ArticoliCorrelati
