import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Search({ className }: Props) {
  return (
    <IconBase name="search" w={24} className={className}>
      <path d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17Z" strokeWidth="2" fill="none"/>
      <path d="M21.0833 21.0833L15 15" strokeWidth="2" fill="none"/>
    </IconBase>
  )
}

export default Search
