import React from 'react'
import Link from 'next/link'
import { useTrans } from 'hooks'
import { ArrowRight } from 'components/atoms/Icons'
import { Button, WImage } from 'components/atoms'

interface Props {
  imageUrl?: string
  imageMobileUrl?: string
  title?: string
  description?: string
  url?: string
  variant?: 'primary' | 'secondary'
  colorVariant?: '' | 'dark'
  textCta?: string
  onClick?: () => void
  className?: string
}

const BoxMarketingNavigation = (props: Props) => {
  const {
    imageUrl = '',
    imageMobileUrl = '',
    title = '',
    description = '',
    url = '',
    variant = 'primary',
    colorVariant = '',
    textCta = '',
    onClick = () => {},
    className = '',
  } = props

  const t = useTrans()

  return title || description ? (
    <div
      className={`box-marketing-navigation${
        variant ? ` box-marketing-navigation--${variant}` : ''
      } ${colorVariant ? ` box-marketing-navigation--${colorVariant}` : ''} ${
        className ? ` ${className}` : ''
      }`}
    >
      <div
        className={`box-marketing-navigation__bg ${
          imageMobileUrl ? 'box-marketing-navigation__bg--desktop' : ''
        }`}
      >
        <WImage
          layout="intrinsic"
          maxWidth={560}
          maxHeight={192}
          objectFit="cover"
          src={imageUrl}
          alt={title}
          title={title}
        />
      </div>
      {!!imageMobileUrl && (
        <div className="box-marketing-navigation__bg box-marketing-navigation__bg--mobile">
          <WImage
            layout="intrinsic"
            maxWidth={257}
            maxHeight={172}
            objectFit="cover"
            src={imageMobileUrl}
            alt={title}
            title={title}
          />
        </div>
      )}
      <div className="box-marketing-navigation__content">
        {title && <p className="box-marketing-navigation__title">{title}</p>}
        {description && <p className="box-marketing-navigation__description">{description}</p>}
        {url && textCta && (
          <Button
            label={textCta}
            variant={variant}
            colorVariant={variant === 'secondary' ? 'white' : ''}
            icon={<ArrowRight />}
            iconPos="right"
            size="sm"
            href={url}
            className="box-marketing-navigation__cta"
            onClick={onClick}
          />
        )}
      </div>
    </div>
  ) : (
    url && (
      <Link legacyBehavior href={url}>
        <a
          className={`box-marketing-navigation${
            imageMobileUrl ? ' box-marketing-navigation--desktop' : ''
          } ${className ? ` ${className}` : ''}`}
          onClick={onClick}
        >
          <div
            className={`box-marketing-navigation__bg ${
              imageMobileUrl ? 'box-marketing-navigation__bg--desktop' : ''
            }`}
          >
            <WImage
              layout="intrinsic"
              maxWidth={560}
              maxHeight={192}
              objectFit="cover"
              src={imageUrl}
              alt={title}
              title={title}
            />
          </div>
          {!!imageMobileUrl && (
            <div className="box-marketing-navigation__bg box-marketing-navigation__bg--mobile">
              <WImage
                layout="intrinsic"
                maxWidth={257}
                maxHeight={172}
                objectFit="cover"
                src={imageMobileUrl}
                alt={title}
                title={title}
              />
            </div>
          )}
        </a>
      </Link>
    )
  )
}

export default BoxMarketingNavigation
