import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Twitter({ className }: Props) {
  return (
    <IconBase name="twitter" w={24} className={className}>
      <mask id="twitter-mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="2" width="24" height="20">
      <path d="M0 2H24V21.5038H0V2Z" stroke="none"/>
      </mask>
      <g mask="url(#twitter-mask0)">
      <path fillRule="evenodd" clipRule="evenodd" d="M24 4.309C23.117 4.701 22.168 4.965 21.172 5.084C22.189 4.475 22.97 3.51 23.337 2.36C22.386 2.924 21.332 3.334 20.21 3.555C19.313 2.598 18.032 2 16.616 2C13.437 2 11.101 4.966 11.819 8.045C7.728 7.84 4.1 5.88 1.671 2.901C0.381 5.114 1.002 8.009 3.194 9.475C2.388 9.449 1.628 9.228 0.965 8.859C0.911 11.14 2.546 13.274 4.914 13.749C4.221 13.937 3.462 13.981 2.69 13.833C3.316 15.789 5.134 17.212 7.29 17.252C5.22 18.875 2.612 19.6 0 19.292C2.179 20.689 4.768 21.504 7.548 21.504C16.69 21.504 21.855 13.784 21.543 6.858C22.505 6.163 23.34 5.296 24 4.309Z" stroke="none"/>
      </g>
    </IconBase>
  )
}

export default Twitter
