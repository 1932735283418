import React from 'react'
import { useTrans } from 'hooks'

import { Button, WImage } from 'components/atoms'
import Link from 'next/link'

interface Props {
  cantine: []
  className?: string
}

const CantineListing = (props: Props) => {
  const { cantine = [], className = '' } = props

  const t = useTrans()

  return (
    <div className={`cantine-listing ${className ? ` ${className}` : ''}`}>
      <p className="cantine-listing__title">{t('Le cantine')}</p>
      <div className="cantine-listing__list">
        {cantine.map((cantina) => (
          <Link legacyBehavior href={cantina.node.url}>
            <a className="cantine-listing__item" key={`cantine-listing__item__${cantina.node.pk}`}>
              <WImage
                src={cantina.node.logoTrasparente?.image}
                maxWidth={174}
                maxHeight={174}
                classPrefix="cantiner-listing"
                alt={cantina.nome}
                title={cantina.nome}
                layout="intrinsic"
              />
            </a>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default CantineListing
