import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function FiltriTipo({ className }: Props) {
  return (
    <IconBase name="filtri-tipo" w={50} className={className}>
      <path d="M19.9062 27.4998C15.25 27.4998 11.4531 31.2967 11.4531 35.9529C11.4531 40.6092 15.25 44.406 19.9062 44.406C24.5781 44.406 28.3594 40.6092 28.3594 35.9529C28.3594 31.2967 24.5781 27.4998 19.9062 27.4998ZM19.9062 42.8592C16.1094 42.8592 13.0156 39.7654 13.0156 35.9685C13.0156 32.1717 16.1094 29.0779 19.9062 29.0779C23.7031 29.0779 26.7969 32.1717 26.7969 35.9685C26.7969 39.7654 23.7031 42.8592 19.9062 42.8592Z" stroke="none"/>
      <path d="M20.6875 20.2967C20.6875 16.7654 17.8125 13.8904 14.2813 13.8904C10.75 13.8904 7.875 16.7654 7.875 20.2967C7.875 23.8279 10.75 26.7029 14.2813 26.7029C17.8125 26.7029 20.6875 23.8279 20.6875 20.2967ZM9.45313 20.2967C9.45313 17.6248 11.625 15.4529 14.2969 15.4529C16.9688 15.4529 19.1406 17.6248 19.1406 20.2967C19.1406 22.9686 16.9688 25.1404 14.2969 25.1404C11.625 25.1404 9.45313 22.9686 9.45313 20.2967Z" stroke="none"/>
      <path d="M22.3594 21.4686C22.3594 24.3436 24.7031 26.7029 27.5937 26.7029C30.4687 26.7029 32.8281 24.3592 32.8281 21.4686C32.8281 18.5779 30.4844 16.2342 27.5937 16.2342C24.7031 16.2342 22.3594 18.5779 22.3594 21.4686ZM27.5781 17.7967C29.5937 17.7967 31.25 19.4373 31.25 21.4686C31.25 23.4842 29.6094 25.1404 27.5781 25.1404C25.5625 25.1404 23.9062 23.4998 23.9062 21.4686C23.9219 19.4529 25.5625 17.7967 27.5781 17.7967Z" stroke="none"/>
      <path d="M35.828 26.3437C32.3593 26.3437 29.5311 29.1718 29.5311 32.6405C29.5311 36.1093 32.3593 38.9374 35.828 38.9374C39.2968 38.9374 42.1249 36.1093 42.1249 32.6405C42.1249 29.1718 39.2968 26.3437 35.828 26.3437ZM35.828 37.3593C33.2186 37.3593 31.0936 35.2343 31.0936 32.6249C31.0936 30.0155 33.2186 27.8905 35.828 27.8905C38.4374 27.8905 40.5624 30.0155 40.5624 32.6249C40.5624 35.2343 38.4374 37.3593 35.828 37.3593Z" stroke="none"/>
      <path d="M36.8907 17.7968C39.7658 17.7968 42.1251 15.4531 42.1251 12.5625C42.1251 9.67184 39.7814 7.32809 36.8907 7.32809C34.0001 7.32809 31.6564 9.67184 31.6564 12.5625C31.6564 15.4531 34.0001 17.7968 36.8907 17.7968ZM36.8907 8.90622C38.9064 8.90622 40.5626 10.5468 40.5626 12.5781C40.5626 14.6093 38.922 16.25 36.8907 16.25C34.8595 16.25 33.2189 14.6093 33.2189 12.5781C33.2189 10.5468 34.8751 8.90622 36.8907 8.90622Z" stroke="none"/>
      <path d="M22.3281 13.078C24.3906 13.078 26.0781 11.3905 26.0781 9.32803C26.0781 7.26553 24.3906 5.57803 22.3281 5.57803C20.2656 5.57803 18.5781 7.26553 18.5781 9.32803C18.5781 11.3905 20.2656 13.078 22.3281 13.078ZM22.3281 7.14053C23.5312 7.14053 24.5156 8.1249 24.5156 9.32803C24.5156 10.5312 23.5312 11.5155 22.3281 11.5155C21.125 11.5155 20.1406 10.5312 20.1406 9.32803C20.1406 8.1249 21.125 7.14053 22.3281 7.14053Z" stroke="none"/>
    </IconBase>
  )
}

export default FiltriTipo
