export const province = [
    {
        "chiave": "AG",
        "nome": "Agrigento"
    },
    {
        "chiave": "AL",
        "nome": "Alessandria"
    },
    {
        "chiave": "AN",
        "nome": "Ancona"
    },
    {
        "chiave": "AO",
        "nome": "Aosta"
    },
    {
        "chiave": "AR",
        "nome": "Arezzo"
    },
    {
        "chiave": "AP",
        "nome": "Ascoli Piceno"
    },
    {
        "chiave": "AT",
        "nome": "Asti"
    },
    {
        "chiave": "AV",
        "nome": "Avellino"
    },
    {
        "chiave": "BA",
        "nome": "Bari"
    },
    {
        "chiave": "BT",
        "nome": "Barletta-Andria-Trani"
    },
    {
        "chiave": "BL",
        "nome": "Belluno"
    },
    {
        "chiave": "BN",
        "nome": "Benevento"
    },
    {
        "chiave": "BG",
        "nome": "Bergamo"
    },
    {
        "chiave": "BI",
        "nome": "Biella"
    },
    {
        "chiave": "BO",
        "nome": "Bologna"
    },
    {
        "chiave": "BZ",
        "nome": "Bolzano/Bozen"
    },
    {
        "chiave": "BS",
        "nome": "Brescia"
    },
    {
        "chiave": "BR",
        "nome": "Brindisi"
    },
    {
        "chiave": "CA",
        "nome": "Cagliari"
    },
    {
        "chiave": "CL",
        "nome": "Caltanissetta"
    },
    {
        "chiave": "CB",
        "nome": "Campobasso"
    },
    {
        "chiave": "CE",
        "nome": "Caserta"
    },
    {
        "chiave": "CT",
        "nome": "Catania"
    },
    {
        "chiave": "CZ",
        "nome": "Catanzaro"
    },
    {
        "chiave": "CH",
        "nome": "Chieti"
    },
    {
        "chiave": "CO",
        "nome": "Como"
    },
    {
        "chiave": "CS",
        "nome": "Cosenza"
    },
    {
        "chiave": "CR",
        "nome": "Cremona"
    },
    {
        "chiave": "KR",
        "nome": "Crotone"
    },
    {
        "chiave": "CN",
        "nome": "Cuneo"
    },
    {
        "chiave": "EN",
        "nome": "Enna"
    },
    {
        "chiave": "FM",
        "nome": "Fermo"
    },
    {
        "chiave": "FE",
        "nome": "Ferrara"
    },
    {
        "chiave": "FI",
        "nome": "Firenze"
    },
    {
        "chiave": "FG",
        "nome": "Foggia"
    },
    {
        "chiave": "FC",
        "nome": "Forlì-Cesena"
    },
    {
        "chiave": "FR",
        "nome": "Frosinone"
    },
    {
        "chiave": "GE",
        "nome": "Genova"
    },
    {
        "chiave": "GO",
        "nome": "Gorizia"
    },
    {
        "chiave": "GR",
        "nome": "Grosseto"
    },
    {
        "chiave": "IM",
        "nome": "Imperia"
    },
    {
        "chiave": "IS",
        "nome": "Isernia"
    },
    {
        "chiave": "SP",
        "nome": "La Spezia"
    },
    {
        "chiave": "AQ",
        "nome": "L’Aquila"
    },
    {
        "chiave": "LT",
        "nome": "Latina"
    },
    {
        "chiave": "LE",
        "nome": "Lecce"
    },
    {
        "chiave": "LC",
        "nome": "Lecco"
    },
    {
        "chiave": "LI",
        "nome": "Livorno"
    },
    {
        "chiave": "LO",
        "nome": "Lodi"
    },
    {
        "chiave": "LU",
        "nome": "Lucca"
    },
    {
        "chiave": "MC",
        "nome": "Macerata"
    },
    {
        "chiave": "MN",
        "nome": "Mantova"
    },
    {
        "chiave": "MS",
        "nome": "Massa-Carrara"
    },
    {
        "chiave": "MT",
        "nome": "Matera"
    },
    {
        "chiave": "ME",
        "nome": "Messina"
    },
    {
        "chiave": "MI",
        "nome": "Milano"
    },
    {
        "chiave": "MO",
        "nome": "Modena"
    },
    {
        "chiave": "MB",
        "nome": "Monza e Brianza"
    },
    {
        "chiave": "NA",
        "nome": "Napoli"
    },
    {
        "chiave": "NO",
        "nome": "Novara"
    },
    {
        "chiave": "NU",
        "nome": "Nuoro"
    },
    {
        "chiave": "OR",
        "nome": "Oristano"
    },
    {
        "chiave": "PD",
        "nome": "Padova"
    },
    {
        "chiave": "PA",
        "nome": "Palermo"
    },
    {
        "chiave": "PR",
        "nome": "Parma"
    },
    {
        "chiave": "PV",
        "nome": "Pavia"
    },
    {
        "chiave": "PG",
        "nome": "Perugia"
    },
    {
        "chiave": "PU",
        "nome": "Pesaro e Urbino"
    },
    {
        "chiave": "PE",
        "nome": "Pescara"
    },
    {
        "chiave": "PC",
        "nome": "Piacenza"
    },
    {
        "chiave": "PI",
        "nome": "Pisa"
    },
    {
        "chiave": "PT",
        "nome": "Pistoia"
    },
    {
        "chiave": "PN",
        "nome": "Pordenone"
    },
    {
        "chiave": "PZ",
        "nome": "Potenza"
    },
    {
        "chiave": "PO",
        "nome": "Prato"
    },
    {
        "chiave": "RG",
        "nome": "Ragusa"
    },
    {
        "chiave": "RA",
        "nome": "Ravenna"
    },
    {
        "chiave": "RC",
        "nome": "Reggio Calabria"
    },
    {
        "chiave": "RE",
        "nome": "Reggio Emilia"
    },
    {
        "chiave": "RI",
        "nome": "Rieti"
    },
    {
        "chiave": "RN",
        "nome": "Rimini"
    },
    {
        "chiave": "RM",
        "nome": "Roma"
    },
    {
        "chiave": "RO",
        "nome": "Rovigo"
    },
    {
        "chiave": "SA",
        "nome": "Salerno"
    },
    {
        "chiave": "SS",
        "nome": "Sassari"
    },
    {
        "chiave": "SV",
        "nome": "Savona"
    },
    {
        "chiave": "SI",
        "nome": "Siena"
    },
    {
        "chiave": "SR",
        "nome": "Siracusa"
    },
    {
        "chiave": "SO",
        "nome": "Sondrio"
    },
    {
        "chiave": "SU",
        "nome": "Sud Sardegna"
    },
    {
        "chiave": "TA",
        "nome": "Taranto"
    },
    {
        "chiave": "TE",
        "nome": "Teramo"
    },
    {
        "chiave": "TR",
        "nome": "Terni"
    },
    {
        "chiave": "TO",
        "nome": "Torino"
    },
    {
        "chiave": "TP",
        "nome": "Trapani"
    },
    {
        "chiave": "TN",
        "nome": "Trento"
    },
    {
        "chiave": "TV",
        "nome": "Treviso"
    },
    {
        "chiave": "TS",
        "nome": "Trieste"
    },
    {
        "chiave": "UD",
        "nome": "Udine"
    },
    {
        "chiave": "VA",
        "nome": "Varese"
    },
    {
        "chiave": "VE",
        "nome": "Venezia"
    },
    {
        "chiave": "VB",
        "nome": "Verbano Cusio Ossola"
    },
    {
        "chiave": "VC",
        "nome": "Vercelli"
    },
    {
        "chiave": "VR",
        "nome": "Verona"
    },
    {
        "chiave": "VV",
        "nome": "Vibo Valentia"
    },
    {
        "chiave": "VI",
        "nome": "Vicenza"
    },
    {
        "chiave": "VT",
        "nome": "Viterbo"
    }
]