import React from 'react'
import Link from 'next/link'
import { Props as ButtonProps } from 'components/atoms/Button/Button'
import { ArrowLeft } from 'components/atoms/Icons'
import { Button, WContainer, WImage } from 'components/atoms'

interface Props {
  backButton?: ButtonProps
  className?: string
}

const HeaderMinimal = (props: Props) => {
  const { backButton = null, className = '' } = props

  return (
    <div
      className={`header-minimal${backButton ? ' header-minimal--back-button' : ''} ${
        className ? ` ${className}` : ''
      }`}
    >
      <WContainer>
        <div className="header-minimal__content">
          {backButton && (
            <div className="header-minimal__back">
              <Button
                {...{
                  variant: 'ghost',
                  colorVariant: 'black',
                  icon: <ArrowLeft />,
                  iconPos: 'left',
                  ...backButton,
                  className: `header-minimal__back-button${
                    backButton.className ? ` ${backButton.className}` : ''
                  }`,
                }}
              />
            </div>
          )}
          <div className="header-minimal__logo">
            <Link legacyBehavior href="/">
              <a>
                <WImage src="/images/logo-vinicum-rosso.png" maxWidth={80} maxHeight={61} />
              </a>
            </Link>
          </div>
          <div className="header-minimal__logo-mobile">
            <Link legacyBehavior href="/">
              <a>
                <WImage
                  src="/images/logo-vinicum-cropped-rosso.png"
                  maxWidth={106}
                  maxHeight={26}
                />
              </a>
            </Link>
          </div>
        </div>
      </WContainer>
    </div>
  )
}

export default HeaderMinimal
