import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Menu({ className }: Props) {
  return (
    <IconBase name="menu" w={24} className={className}>
      <line x1="4" y1="8" x2="20" y2="8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <line x1="4" y1="16" x2="20" y2="16" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
    </IconBase>
  )
}

export default Menu
