import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Time({ className }: Props) {
  return (
    <IconBase name="time" w={24} className={className}>
      <path d="M12.5001 8.00006H11.0001V14.0001L15.7501 16.8501L16.5001 15.6201L12.5001 13.2501V8.00006ZM17.3371 1.81006L21.9441 5.65506L20.6641 7.19006L16.0541 3.34706L17.3371 1.81006ZM6.66313 1.81006L7.94513 3.34606L3.33713 7.19006L2.05713 5.65406L6.66313 1.81006ZM12.0001 4.00006C7.03013 4.00006 3.00013 8.03006 3.00013 13.0001C3.00013 17.9701 7.03013 22.0001 12.0001 22.0001C16.9701 22.0001 21.0001 17.9701 21.0001 13.0001C21.0001 8.03006 16.9701 4.00006 12.0001 4.00006ZM12.0001 20.0001C8.14013 20.0001 5.00013 16.8601 5.00013 13.0001C5.00013 9.14006 8.14013 6.00006 12.0001 6.00006C15.8601 6.00006 19.0001 9.14006 19.0001 13.0001C19.0001 16.8601 15.8601 20.0001 12.0001 20.0001Z" stroke="none"/>
    </IconBase>
  )
}

export default Time
