import React, { useEffect, useState } from 'react'
import { useTrans } from 'hooks'
import { GoogleMapMarkerInfo, GoogleMapWrap } from 'components/molecules'
import { Facebook, Twitter, Vector8, Youtube } from 'components/atoms/Icons'
import { GOOGLE_MAPS_URL } from 'utils'

interface Props {
  cantina: {
    nome?: string
    recapiti?: string
    sito?: string
    fb?: string
    twitter?: string
    youtube?: string
    latitudine?: number
    longitudine?: number
  }
  className?: string
}

const CantinaContatti = (props: Props) => {
  const initialMapCoords = {
    latitudine: 44.7096,
    longitudine: 10.6205,
  }

  const [mapCoords, setMapCoords] = useState(initialMapCoords)

  const {
    cantina = {
      nome: '',
      recapiti: '',
      sito: '',
      fb: '',
      twitter: '',
      youtube: '',
      latitudine: 0,
      longitudine: 0,
    },
    className = '',
  } = props

  const t = useTrans()

  useEffect(() => {
    setMapCoords({
      lat: cantina.latitudine,
      lng: cantina.longitudine,
    })
  }, [cantina])

  return (
    <div className={`cantina-contatti ${className ? ` ${className}` : ''}`}>
      <div className="cantina-contatti__vector">
        <Vector8 />
      </div>
      <div className="cantina-contatti__content">
        <p className="cantina-contatti__title">
          {cantina.recapiti ? t('Dove siamo') : t('Scopri di più')}
        </p>
        <div
          className="cantina-contatti__recapiti"
          dangerouslySetInnerHTML={{ __html: cantina.recapiti }}
        />
        {!!cantina.sito && (
          <div className="cantina-contatti__website">
            <a href={cantina.sito} rel="nofollow noreferrer noopener" target="_blank">
              {cantina.sito}
            </a>
          </div>
        )}
        {!!cantina.fb ||
          !!cantina.twitter ||
          (!!cantina.youtube && (
            <div className="cantina-contatti__social">
              {!!cantina.fb && (
                <a className="cantina-contatti__social__item" href={cantina.fb} target="_blank">
                  <Facebook />
                </a>
              )}
              {!!cantina.twitter && (
                <a
                  className="cantina-contatti__social__item"
                  href={cantina.twitter}
                  target="_blank"
                >
                  <Twitter />
                </a>
              )}
              {!!cantina.youtube && (
                <a
                  className="cantina-contatti__social__item"
                  href={cantina.youtube}
                  target="_blank"
                >
                  <Youtube />
                </a>
              )}
            </div>
          ))}
      </div>
      <div className="cantina-contatti__map">
        <GoogleMapWrap
          options={{ disableDefaultUI: true }}
          googleMapURL={GOOGLE_MAPS_URL}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          mapCoords={mapCoords}
          items={cantina}
        >
          <GoogleMapMarkerInfo
            key={`cantina-${cantina.pk}`}
            cantina={cantina}
            setSelectedCantina={(cantina) => null}
            selectedCantina={null}
            setMapCoords={setMapCoords}
          />
        </GoogleMapWrap>
      </div>
    </div>
  )
}

export default CantinaContatti
