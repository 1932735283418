import React, { useEffect, useState } from 'react'

interface Props {
  cantina: any
  className?: string
}

const CantinaVideo = (props: Props) => {
  const { cantina = null, className = '' } = props

  const [videoInfoBox, setVideoInfoBox] = useState(null)

  useEffect(() => {
    const videoInfoBox = cantina.infoBox.find((item) => item.mp4 || item.ogg || item.webm)
    if(videoInfoBox) {
      setVideoInfoBox(videoInfoBox)
    }
  }, [])

  if(!cantina.idYoutubeLinkVideo && !videoInfoBox){
    return null
  }

  return (
    <div className={`cantina-video ${className ? ` ${className}` : ''}`}>
      <div className="cantina-video__content">
        {cantina.idYoutubeLinkVideo ? (
          <iframe
            className="cantina-video__iframe"
            src={`https://www.youtube.com/embed/${cantina.idYoutubeLinkVideo}?rel=0&amp;controls=1&amp;showinfo=0`}
            frameBorder="0"
            allowFullScreen={true}
          ></iframe>
        ) : videoInfoBox ? (
          <video controls className="cantina-video__iframe" >
            <source
              src={videoInfoBox.mp4 || videoInfoBox.ogg || videoInfoBox.webm}
              type={`video/${videoInfoBox.mp4 ? "mp4" : videoInfoBox.ogg ? "ogg" : "webm"}`}
            ></source>
          </video>
        ) : null}
      </div>
    </div>
  )
}

export default CantinaVideo
