import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function WineGradazione({ className }: Props) {
  return (
    <IconBase name="wine-gradazione" w={40} h={41} className={className}>
      <g clipPath="url(#wine-gradazione-clip0)">
      <g clipPath="url(#wine-gradazione-clip1)">
      <path d="M22.4875 28.3749C22.1073 28.3715 21.7437 28.2189 21.475 27.9499C21.3416 27.8185 21.2356 27.6619 21.1633 27.4891C21.091 27.3164 21.0538 27.131 21.0538 26.9437C21.0538 26.7564 21.091 26.571 21.1633 26.3983C21.2356 26.2255 21.3416 26.0689 21.475 25.9374C21.7458 25.6733 22.1092 25.5254 22.4875 25.5254C22.8658 25.5254 23.2292 25.6733 23.5 25.9374C23.7634 26.2062 23.9109 26.5674 23.9109 26.9437C23.9109 27.32 23.7634 27.6812 23.5 27.9499C23.2298 28.2166 22.8671 28.3689 22.4875 28.3749ZM22.4875 26.1374C22.3697 26.136 22.253 26.1598 22.1452 26.2072C22.0374 26.2547 21.941 26.3246 21.8625 26.4124C21.7685 26.5301 21.7096 26.672 21.6926 26.8217C21.6756 26.9714 21.7013 27.1228 21.7666 27.2586C21.8319 27.3944 21.9342 27.5089 22.0617 27.5891C22.1893 27.6693 22.3369 27.7118 22.4875 27.7118C22.6382 27.7118 22.7857 27.6693 22.9133 27.5891C23.0408 27.5089 23.1431 27.3944 23.2084 27.2586C23.2737 27.1228 23.2994 26.9714 23.2824 26.8217C23.2654 26.672 23.2065 26.5301 23.1125 26.4124C23.0351 26.3233 22.9389 26.2525 22.8308 26.2049C22.7228 26.1574 22.6055 26.1343 22.4875 26.1374Z" stroke="none"/>
      <path d="M18.525 24.3251C18.1955 24.3228 17.8769 24.2064 17.6235 23.9957C17.3701 23.7849 17.1976 23.4929 17.1354 23.1693C17.0731 22.8456 17.125 22.5104 17.2821 22.2207C17.4392 21.931 17.6919 21.7048 17.9971 21.5804C18.3023 21.4561 18.6412 21.4414 18.956 21.5388C19.2708 21.6362 19.5422 21.8397 19.7238 22.1147C19.9054 22.3897 19.9861 22.7192 19.9522 23.047C19.9182 23.3748 19.7717 23.6807 19.5375 23.9126C19.266 24.1758 18.9031 24.3236 18.525 24.3251ZM18.525 22.1001C18.3666 22.0988 18.2115 22.1447 18.0792 22.2317C17.9469 22.3187 17.8434 22.4431 17.7818 22.589C17.7202 22.7349 17.7033 22.8958 17.7333 23.0513C17.7632 23.2068 17.8386 23.35 17.95 23.4626C18.0999 23.6101 18.3018 23.6926 18.5121 23.6924C18.7224 23.6921 18.9242 23.6091 19.0737 23.4612C19.2232 23.3133 19.3085 23.1125 19.3111 22.9022C19.3137 22.6919 19.2334 22.4891 19.0875 22.3376C19.0152 22.2614 18.9279 22.2009 18.8311 22.1601C18.7343 22.1192 18.6301 22.0988 18.525 22.1001Z" stroke="none"/>
      <path d="M18.3125 28.15C18.2514 28.1684 18.1861 28.1684 18.125 28.15C18.0877 28.1216 18.0573 28.0853 18.036 28.0436C18.0146 28.0019 18.003 27.956 18.0018 27.9092C18.0006 27.8624 18.0099 27.8159 18.029 27.7732C18.0482 27.7305 18.0767 27.6927 18.1125 27.6625L22.4625 21.6625C22.4862 21.6287 22.5163 21.5999 22.5512 21.5778C22.5861 21.5557 22.625 21.5408 22.6656 21.5338C22.7063 21.5268 22.748 21.528 22.7882 21.5372C22.8284 21.5464 22.8664 21.5635 22.9 21.5875C22.9573 21.6459 22.9893 21.7244 22.9893 21.8062C22.9893 21.888 22.9573 21.9666 22.9 22.025L18.5625 28.025C18.5334 28.0638 18.4957 28.0953 18.4523 28.117C18.4089 28.1387 18.361 28.15 18.3125 28.15Z" stroke="none"/>
      <path d="M20.3375 36.3249C15.525 36.3249 15.0375 34.7499 15 34.2749V17.0499C14.9919 16.4062 15.1614 15.7728 15.49 15.2193C15.8186 14.6657 16.2935 14.2135 16.8625 13.9124C17.0376 13.8036 17.2047 13.6825 17.3625 13.5499C17.6463 13.2264 17.8135 12.8171 17.8375 12.3874V7.6624C17.7068 7.50233 17.6179 7.31235 17.5786 7.10948C17.5393 6.90661 17.551 6.69717 17.6125 6.4999C17.875 5.8249 18.725 5.5249 20.375 5.5249C22.025 5.5249 22.875 5.8249 23.1 6.4874C23.1662 6.67892 23.1849 6.88366 23.1544 7.084C23.1239 7.28433 23.0452 7.47426 22.925 7.6374V12.3624C22.9458 12.7891 23.1136 13.1954 23.4 13.5124C23.5509 13.648 23.7141 13.7694 23.8875 13.8749C24.4614 14.1842 24.9375 14.6477 25.2622 15.2131C25.5869 15.7785 25.7471 16.4233 25.725 17.0749V34.2249C25.6125 34.7499 25.15 36.3249 20.3375 36.3249ZM16.25 34.2124C16.25 34.2124 16.8625 35.0749 20.3375 35.0749C23.8125 35.0749 24.4375 34.1124 24.45 34.1124V17.0499C24.4769 16.6065 24.3727 16.1651 24.1504 15.7805C23.9282 15.396 23.5976 15.0854 23.2 14.8874C22.9604 14.732 22.7346 14.5564 22.525 14.3624C22.0056 13.8138 21.7073 13.0926 21.6875 12.3374V7.3374C21.6855 7.25345 21.7012 7.17002 21.7335 7.0925C21.7658 7.01498 21.814 6.94511 21.875 6.8874C21.3915 6.72983 20.8824 6.66619 20.375 6.6999C19.8685 6.66795 19.3605 6.72722 18.875 6.8749C18.9374 6.9348 18.9873 7.00656 19.0216 7.08598C19.056 7.1654 19.0741 7.25088 19.075 7.3374V12.3374C19.0541 13.0948 18.7509 13.817 18.225 14.3624C18.0202 14.562 17.7938 14.7381 17.55 14.8874C17.1534 15.0866 16.8238 15.3975 16.6017 15.7818C16.3796 16.166 16.2747 16.6068 16.3 17.0499V34.2249L16.25 34.2124Z" stroke="none"/>
      </g>
      </g>
      <defs>
      <clipPath id="wine-gradazione-clip0">
      <rect width="40" height="40" transform="translate(0 0.5)" stroke="none"/>
      </clipPath>
      <clipPath id="wine-gradazione-clip1">
      <rect width="10.6875" height="30.825" transform="translate(15 5.5)" stroke="none"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default WineGradazione
