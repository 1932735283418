import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Vector1({ className }: Props) {
  return (
    <IconBase name="vector1" w={414} h={1149} className={className}>
      <path d="M-470.776 765.555C-324.886 866.099 -267.792 1124.61 -24.4516 1023.24C218.889 921.868 335.466 572.237 185.864 332.545C79.9563 162.859 -344.907 125.806 -512.214 319.255C-679.521 512.703 -616.665 665.011 -470.776 765.555Z" stroke="none"/>
    </IconBase>
  )
}

export default Vector1
