import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function CheckboxMarked({ className }: Props) {
  return (
    <IconBase name="checkbox-marked" w={24} className={className}>
      <rect x="4" y="4" width="16" height="16" rx="1" strokeWidth="2" strokeLinejoin="round" fill="none"/>
      <path d="M7.04166 11.7084L10.5417 15.2084L16.9583 8.79169" strokeWidth="2" fill="none"/>
    </IconBase>
  )
}

export default CheckboxMarked
