import React, { useContext, useEffect, useState } from 'react'
import { useTrans } from 'hooks'
import { indirizzoMock } from 'utils/mock'
import { Button, Modal } from 'components/atoms'

import { Edit } from 'components/atoms/Icons'
import { CartContext } from 'utils/context'
import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { CHECKOUT } from 'utils/queries'

interface ItemProps {
  title?: string
  value?: any
  className?: string
}

interface Props {
  id?: string
  onEdit: any
  checkout: any
  showSistemaPagamento?: any
  hideEditButtonInDesktop?: boolean
  fullButton?: boolean
  className?: string
}

const RiepilogoSpedizioneCheckoutItem = (props: ItemProps) => {
  const { title = '', value = '', className = '' } = props

  return (
    <div className={`riepilogo-spedizione-checkout-item${className ? ` ${className}` : ''}`}>
      <p className="riepilogo-spedizione-checkout-item__title">{title}</p>
      <div className="riepilogo-spedizione-checkout-item__value">{value}</div>
    </div>
  )
}

const RiepilogoSpedizioneCheckout = (props: Props) => {
  const {
    id = null,
    onEdit = () => {},
    checkout = null,
    showSistemaPagamento = false,
    hideEditButtonInDesktop = true,
    fullButton = true,
    className = '',
  } = props

  const t = useTrans()

  const richiediFattura = checkout?.richiediFattura
  const indirizzoSpedizione = checkout?.indirizzoSpedizione
  const indirizzoFatturazione = checkout?.indirizzoFatturazione
  const sistemaSpedizione = checkout?.sistemaSpedizione
  const sistemaPagamento = checkout?.sistemaPagamento
  const context = useContext(CartContext)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (checkout?.sistemaSpedizione?.estero) {
      let items_to_remove = checkout.items.filter((item) => !item.prodotto.prodotto.vendibileEstero)
      if (items_to_remove.length > 0) {
        setOpen(true)
        items_to_remove.map((item) => {
          context.removeFromCart(item.prodotto, false, true, ['Checkout'])
        })
      }
    }
  }, [])

  return (
    <div className={`riepilogo-spedizione-checkout ${className ? ` ${className}` : ''}`}>
      <Modal
        open={open}
        setOpen={() => setOpen(false)}
        size="lg"
        className="prodottiEsteroModal"
        closeButton
      >
        <div className="prodottiEsteroContainer">
          <p className="prodottiEstero-modal__title">
            <img src="/images/alert_icon.png"></img>
          </p>
          <div className="prodottiEstero-modal__description">
            <div className="prodottiEstero-modal__description__italian">
              {t(
                'Alcuni prodotti sono stati rimossi in quanto non disponibili per la spedizione estero, verifica il tuo ordine prima di proseguire.'
              )}
            </div>
          </div>
        </div>
      </Modal>

      <div className="riepilogo-spedizione-checkout__head">
        <p className="riepilogo-spedizione-checkout__title">{t('Riepilogo spedizione')}</p>
        {fullButton ? (
          <Button
            id={id}
            label={t('Modifica')}
            variant="ghost"
            icon={<Edit />}
            iconPos="left"
            className={`riepilogo-spedizione-checkout__edit${
              hideEditButtonInDesktop ? '__hidden' : ''
            }`}
            onClick={onEdit}
          />
        ) : (
          <div>
            <button
              id={id}
              type="button"
              onClick={onEdit}
              className={`riepilogo-spedizione-checkout__edit${
                hideEditButtonInDesktop ? '__hidden' : ''
              } riepilogo-spedizione-checkout__edit__only-icon`}
            >
              <Edit />
            </button>
          </div>
        )}
      </div>
      <div className="riepilogo-spedizione-checkout__list">
        <RiepilogoSpedizioneCheckoutItem
          title={t('Spedisci a')}
          value={
            <>
              {indirizzoSpedizione.nome} {indirizzoSpedizione.cognome}
              <br />
              {indirizzoSpedizione.indirizzo}
              {' - '}
              {indirizzoSpedizione.cap} {indirizzoSpedizione.citta}{' '}
              {indirizzoSpedizione.provincia && `(${indirizzoSpedizione.provincia})`}
            </>
          }
          className="riepilogo-spedizione-checkout-item--shipping-address"
        />
        {richiediFattura && indirizzoFatturazione && (
          <RiepilogoSpedizioneCheckoutItem
            title={t('Indirizzo di fatturazione')}
            value={
              <>
                {indirizzoFatturazione.nome} {indirizzoFatturazione.cognome}
                <br />
                {indirizzoFatturazione.indirizzo}
                <br />
                {indirizzoFatturazione.cap} {indirizzoFatturazione.citta}{' '}
                {indirizzoFatturazione.provincia && `(${indirizzoFatturazione.provincia})`}
                <br />
                {indirizzoFatturazione.cf}
                {indirizzoFatturazione.piva && (
                  <>
                    <br />
                    {t('PI')}
                    {': '}
                    {indirizzoFatturazione.piva}
                  </>
                )}
              </>
            }
            className="riepilogo-spedizione-checkout-item--billing-address"
          />
        )}
        {sistemaSpedizione && (
          <RiepilogoSpedizioneCheckoutItem
            title={t('Metodo di spedizione')}
            value={sistemaSpedizione.nome}
            className="riepilogo-spedizione-checkout-item--shipping-method"
          />
        )}
        {showSistemaPagamento && sistemaPagamento && (
          <RiepilogoSpedizioneCheckoutItem
            title={t('Metodo di pagamento')}
            value={sistemaPagamento.nome}
            className="riepilogo-spedizione-checkout-item--shipping-method"
          />
        )}
      </div>
    </div>
  )
}

export default RiepilogoSpedizioneCheckout
