/* eslint-disable global-require */
import React, { useState } from 'react'
import { useTrans } from 'hooks'
import { Search, Close } from 'components/atoms/Icons'
import { Doofinder } from '..'
import { executeIfInitialized, trackConnectifSearch } from 'utils/connectif'

interface Props {
  className?: string
}

const SearchForm = (props: Props) => {
  const { className = '' } = props

  const t = useTrans()
  const [searchValue, setSearchValue] = useState('')
  const [showReset, setShowReset] = useState(false)

  const handleChangeSearch = (e) => {
    setSearchValue(e.target.value)
    if (e.target.value.length >= 3) {
      executeIfInitialized(trackConnectifSearch, e.target.value)
    }
    setShowReset(e.target.value.length > 0)
  }
  const handleReset = () => {
    setSearchValue('')
    setShowReset(false)
  }

  return (
    <div
      className={`search-form${showReset ? ' search-form--reset' : ''} ${
        className ? ` ${className}` : ''
      }`}
    >
      {showReset && (
        <button type="button" className="search-form__reset" onClick={handleReset}>
          <Close />
        </button>
      )}
      <input
        type="text"
        name="q"
        value={searchValue}
        className="search-form__input"
        placeholder={t('Cerca “prosecco”')}
        onChange={handleChangeSearch}
      />
      <button type="submit" className="search-form__submit">
        <Search />
      </button>
    </div>
  )
}

export default SearchForm
