import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { useTrans } from 'hooks'
import { getFormFieldStatus } from 'utils/safe'
import { UPDATE_INDIRIZZO, CREATE_INDIRIZZO, DELETE_INDIRIZZO, NAZIONI } from 'utils/queries'
import { gql, useMutation, useQuery } from '@apollo/client'
import { updateIndirizzoInput, createIndirizzoInput } from 'utils/indirizzo'
import { Button, FormField, FormInput, FormLayout, FormSelect } from 'components/atoms'
import { Bin } from 'components/atoms/Icons'
import { province } from 'utils/province'

interface Props {
  titolo?: string
  indirizzo?: {
    pk?: number
    nome?: string
    cognome?: string
    presso?: string
    indirizzo?: string
    citta?: string
    cap?: string
    provincia?: string
    telefono?: string
    nazione?: {
      pk : number
      nome: string
    }
  }
  afterSubmit: () => void
  onCancel: (e: any) => void
  className?: string
}

const MyAccountFormIndirizzoSpedizione = (propsForm: Props) => {
  const {
    titolo = '',
    indirizzo = null,
    afterSubmit = () => {},
    onCancel = () => {},
    className = '',
  } = propsForm

  const [updateIndirizzo] = useMutation(
    gql`
      ${UPDATE_INDIRIZZO}
    `
  )
  const [createIndirizzo] = useMutation(
    gql`
      ${CREATE_INDIRIZZO}
    `
  )
  const [deleteIndirizzo] = useMutation(
    gql`
      ${DELETE_INDIRIZZO}
    `
  )
  const t = useTrans()

  function handleUpdateSubmit(values) {
    updateIndirizzo({
      variables: {
        input: updateIndirizzoInput(indirizzo.pk, values),
      },
      refetchQueries: ['IndirizziSpedizione'],
    }).then((res) => {
      afterSubmit()
    })
  }

  function handleCreateSubmit(values) {
    createIndirizzo({
      variables: {
        input: createIndirizzoInput(values),
      },
      refetchQueries: ['IndirizziSpedizione'],
    }).then((res) => {
      afterSubmit()
    })
  }

  function handleDelete() {
    deleteIndirizzo({
      variables: {
        input: {
          pk: indirizzo.pk,
        },
      },
      refetchQueries: ['IndirizziSpedizione'],
    }).then((res) => {
      afterSubmit()
    })
  }

  const { data: optionsData } = useQuery(gql`${NAZIONI}`)
  const [options, setOptions] = useState([])

  useEffect(() => {
    setOptions(optionsData?.nazioni.edges.map(({ node }) => ( {value: node.pk, label: node.nome})))
  }, [optionsData])


  return (
    <div className={`myaccount-form-indirizzo-spedizione ${className ? ` ${className}` : ''}`}>
      {!!titolo && <p className="myaccount-form-indirizzo-spedizione__title">{titolo}</p>}
      <Formik
        validateOnBlur
        validateOnChange
        validationSchema={yup.object().shape({
          nome: yup.string().min(3, t('Troppo corto')).required(t('Campo obbligatorio')),
          cognome: yup.string().min(3, t('Troppo corto')).required(t('Campo obbligatorio')),
          presso: yup.string().max(22, t('Troppo lungo, inserisci massimo 22 caratteri')),
          indirizzo: yup.string().max(25, t('Troppo lungo, inserisci massimo 25 caratteri')).required(t('Campo obbligatorio')),
          citta: yup.string().required(t('Campo obbligatorio')),
          cap: yup.string().required(t('Campo obbligatorio')),
          provincia : yup.string().when("nazione", (nazione) => {
            if(nazione && nazione == 1)
              return yup.string().max(10, t('Troppo lungo')).required("Campo obbligatorio")
            else{
              return yup.string().max(10, t('Troppo lungo')).nullable()
            }
          }),
          telefono: yup
            .string()
            .required(t('Campo obbligatorio'))
            .matches(/^\d+$/gi, t('Non è stato inserito un numero di telefono valido'))
            .min(6, t('Numero troppo corto'))
            .test(
              'validate',
              t('Non è stato inserito un numero di telefono valido'),
              (val) => Number.isInteger(Number.parseFloat(val)) && Number.parseFloat(val) >= 0
            )
            .nullable(),
            nazione: yup.string().required(t('Campo obbligatorio')),
        })}
        onSubmit={(values) => {
          if (indirizzo) {
            handleDelete();
            handleCreateSubmit(values)
          }
          else handleCreateSubmit(values)
        }}
        initialValues={{
          nome: indirizzo?.nome,
          cognome: indirizzo?.cognome,
          presso: indirizzo?.presso,
          indirizzo: indirizzo?.indirizzo,
          citta: indirizzo?.citta,
          cap: indirizzo?.cap,
          provincia: indirizzo?.provincia,
          telefono: indirizzo?.telefono,
          save_address: true,
          is_fatturazione: false,
          nazione: indirizzo?.nazione.pk || -1,
        }}
      >
        {(props) => (
          <Form>
            <FormLayout>
              <FormField
                label={`${t('Nome')}*`}
                status={getFormFieldStatus(props, 'nome')}
                errorMessage={props?.errors?.nome}
              >
                <FormInput
                  onBlur={props.handleBlur('nome')}
                  placeholder="Nome"
                  value={props.values?.nome}
                  onChange={props.handleChange('nome')}
                />
              </FormField>
              <FormField
                label={`${t('Cognome')}*`}
                status={getFormFieldStatus(props, 'cognome')}
                errorMessage={props?.errors?.cognome}
              >
                <FormInput
                  onBlur={props.handleBlur('cognome')}
                  placeholder="Cognome"
                  value={props.values.cognome}
                  onChange={props.handleChange('cognome')}
                />
              </FormField>
              <FormField
                layout='full'
                label={`${t('Indirizzo')}*`}
                status={getFormFieldStatus(props, 'indirizzo')}
                errorMessage={props?.errors?.indirizzo}
              >
                <FormInput
                  onBlur={props.handleBlur('indirizzo')}
                  placeholder="Indirizzo"
                  value={props.values?.indirizzo}
                  onChange={props.handleChange('indirizzo')}
                />
              </FormField>
              <FormField
                label={`${t('Nazione')}*`}
                status={getFormFieldStatus(props, 'nazione')}
                errorMessage={props.errors?.nazione}
              >
                <FormSelect
                  placeholder={t('Nazione')}
                  value={props.values?.nazione}                  
                  onChange={selectedOption => {
                    props.setFieldValue('nazione', selectedOption.value, true);
                    if(selectedOption.value !== 1){props.setFieldValue('provincia', '', true) 
                  }}}
                  options={options}
                />
              </FormField>
              <FormField
                label={`${t('Città')}*`}
                status={getFormFieldStatus(props, 'citta')}
                errorMessage={props?.errors?.citta}
              >
                <FormInput
                  onBlur={props.handleBlur('citta')}
                  placeholder="Città"
                  value={props.values.citta}
                  onChange={props.handleChange('citta')}
                />
              </FormField>
              <FormField
                label={`${t('CAP')}*`}
                status={getFormFieldStatus(props, 'cap')}
                errorMessage={props?.errors?.cap}
                layout="sm"
              >
                <FormInput
                  onBlur={props.handleBlur('cap')}
                  placeholder="CAP"
                  value={props.values.cap}
                  onChange={props.handleChange('cap')}
                />
              </FormField>
              {
                props?.values?.nazione === 1 &&
                <FormField
                  label={`${t('Provincia')}*`}
                  status={getFormFieldStatus(props, 'provincia')}
                  errorMessage={props?.errors?.provincia}
                  layout="sm"
                >
                  <FormSelect
                    options={province?.map((item) => ({
                      value: item.chiave,
                      label: item.nome,
                    }))}
                    value={props.values.provincia}
                    placeholder={t('Provincia')}
                    onBlur={props.handleBlur('provincia')}
                    onChange={(selectedOption) => props.setFieldValue('provincia', selectedOption.value, true)}
                  />
                </FormField>
              }
              <FormField
                label={`${t('Numero di cellulare')}*`}
                status={getFormFieldStatus(props, 'telefono')}
                errorMessage={props?.errors?.telefono}
              >
                <FormInput
                  onBlur={props.handleBlur('telefono')}
                  placeholder="Numero di cellulare"
                  value={props.values.telefono}
                  onChange={props.handleChange('telefono')}
                />
              </FormField>
              <FormField
                layout='full'
                label={`${t('Consegna presso')}`}
                status={getFormFieldStatus(props, 'presso')}
                errorMessage={props?.errors?.presso}
              >
                <FormInput
                  onBlur={props.handleBlur('presso')}
                  placeholder={t('Consegna presso')}
                  value={props.values.presso}
                  onChange={props.handleChange('presso')}
                />
              </FormField>
            </FormLayout>
            <div className="myaccount-form-indirizzo-spedizione__actions">
              <div className="myaccount-form-indirizzo-spedizione__actions__delete">
                {indirizzo && (
                  <Button
                    icon={<Bin />}
                    iconPos="left"
                    label={t('Elimina indirizzo')}
                    size="sm"
                    variant="ghost"
                    onClick={() => handleDelete()}
                  />
                )}
              </div>
              <div className="myaccount-form-indirizzo-spedizione__actions__buttons">
                <Button label={t('Annulla')} size="sm" variant="ghost" onClick={onCancel} />
                <Button type="submit" label={t('Salva modifiche')} size="sm" variant="primary" />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default MyAccountFormIndirizzoSpedizione
function setFieldValue(arg0: string, value: any, arg2: boolean) {
  throw new Error('Function not implemented.')
}

