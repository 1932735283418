import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function TipologiaChampagne({ className }: Props) {
  return (
    <IconBase name="tipologia-champagne" w={32} className={className}>
      <path d="M13.5302 8.68035L8.61016 7.92035C8.43016 7.90035 8.25016 7.97035 8.13016 8.11035C8.06016 8.20035 6.40016 10.4204 6.61016 14.6004C6.79016 18.1204 8.17016 20.0304 8.67016 20.6004L7.99016 25.0704C7.83016 25.3904 6.90016 27.0904 5.04016 26.8104C4.76016 26.7704 4.51016 26.9604 4.47016 27.2304C4.43016 27.5004 4.62016 27.7604 4.89016 27.8004L11.1102 28.7504C11.1402 28.7504 11.1602 28.7604 11.1902 28.7604C11.4302 28.7604 11.6502 28.5804 11.6802 28.3404C11.7202 28.0704 11.5302 27.8104 11.2602 27.7704C9.02016 27.4304 8.97016 25.3104 8.96016 25.3004L9.65016 20.7704C10.3102 20.3604 12.2002 18.9404 13.4102 15.6604C14.8702 11.7304 13.9502 9.12035 13.9102 9.01035C13.8602 8.83035 13.7102 8.71035 13.5302 8.68035ZM8.77016 8.96035L13.0502 9.62035C13.1702 10.1504 13.3702 11.4104 13.0702 13.1504C12.5902 13.0604 11.7502 12.8404 10.5102 12.3204C9.29016 11.8104 8.35016 11.8204 7.76016 11.9204C8.02016 10.3904 8.52016 9.39035 8.77016 8.96035ZM7.48016 27.1904C7.77016 26.9904 8.02016 26.7504 8.23016 26.5104C8.34016 26.8004 8.50016 27.1004 8.71016 27.3804L7.48016 27.1904ZM9.30016 19.8004C8.81016 19.1604 7.76016 17.4504 7.61016 14.5604C7.57016 13.7804 7.60016 13.0804 7.68016 12.4504C8.16016 12.3404 9.08016 12.2704 10.3202 12.7904C11.6002 13.3204 12.4602 13.5504 12.9702 13.6504C12.8602 14.1704 12.7102 14.7204 12.4902 15.3104C11.4802 18.0204 9.96016 19.3304 9.30016 19.8004Z" stroke="none"/>
      <path d="M27.5003 22.4704C27.4003 22.2104 27.1103 22.0904 26.8503 22.1904C24.7403 23.0304 23.6303 21.2304 23.6203 21.2304L21.9203 16.9604C22.2703 16.2904 23.1903 14.1204 22.5803 10.6504C21.8503 6.53041 19.7303 4.74041 19.6403 4.67041C19.5003 4.55041 19.3103 4.52041 19.1403 4.59041L14.5003 6.43041C14.3303 6.50041 14.2103 6.65041 14.1903 6.83041C14.1803 6.95041 13.8603 9.70041 16.1703 13.2004C18.1003 16.1304 20.2603 17.0804 20.9903 17.3404L22.6603 21.5404C22.6903 21.9104 22.7503 23.8304 21.0003 24.5304C20.7403 24.6304 20.6203 24.9204 20.7203 25.1804C20.8003 25.3804 20.9903 25.5004 21.1803 25.5004C21.2403 25.5004 21.3003 25.4904 21.3603 25.4604L27.2103 23.1304C27.4703 23.0204 27.6003 22.7304 27.5003 22.4704ZM19.2003 5.63041C19.6403 6.08041 20.6603 7.34041 21.2903 9.54041C20.6603 9.30041 19.6803 9.07041 18.3803 9.25041C17.0003 9.44041 16.0103 9.40041 15.4803 9.35041C15.2103 8.37041 15.1703 7.62041 15.1703 7.24041L19.2003 5.63041ZM17.0003 12.6504C16.3303 11.6304 15.9103 10.6904 15.6403 9.87041C16.2303 9.91041 17.1703 9.93041 18.4403 9.75041C19.9203 9.54041 20.9303 9.90041 21.4403 10.1504C21.4903 10.3704 21.5503 10.5904 21.5903 10.8204C22.1003 13.6804 21.4503 15.5704 21.1203 16.3104C20.3703 16.0104 18.6003 15.0704 17.0003 12.6504ZM23.2903 23.6104C23.4403 23.2904 23.5303 22.9604 23.5903 22.6404C23.8403 22.8304 24.1203 23.0104 24.4403 23.1504L23.2903 23.6104Z" stroke="none"/>
      <path d="M11.6999 6.0102C11.7499 6.0802 11.8299 6.1202 11.9099 6.1202C11.9599 6.1202 11.9999 6.1102 12.0499 6.0802C12.1699 6.0002 12.1999 5.8502 12.1199 5.7302L10.8699 3.8002C10.7999 3.6802 10.6399 3.6502 10.5199 3.7302C10.3999 3.8102 10.3699 3.9602 10.4499 4.0802L11.6999 6.0102Z" stroke="none"/>
      <path d="M10.9601 7.42002C11.0001 7.44002 11.0301 7.44002 11.0701 7.44002C11.1601 7.44002 11.2501 7.39002 11.3001 7.30002C11.3601 7.18002 11.3101 7.03001 11.1801 6.97001L9.10005 5.97001C8.97005 5.91001 8.82005 5.96002 8.76005 6.09002C8.70005 6.21002 8.75005 6.36002 8.88005 6.42002L10.9601 7.42002Z" stroke="none"/>
      <path d="M13.4199 5.94023C13.5599 5.94023 13.6699 5.83023 13.6699 5.69023V3.49023C13.6699 3.35023 13.5599 3.24023 13.4199 3.24023C13.2799 3.24023 13.1699 3.35023 13.1699 3.49023V5.69023C13.1699 5.83023 13.2799 5.94023 13.4199 5.94023Z" stroke="none"/>
    </IconBase>
  )
}

export default TipologiaChampagne
