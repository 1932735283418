import React from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { useTrans, useIsMobile } from 'hooks'
import { getFormFieldStatus, scrollTo } from 'utils/safe'
import { CheckboxMarked, CheckboxUnmarked, Gift, Info } from 'components/atoms/Icons'
import {
  Button,
  FormLayout,
  FormField,
  FormInput,
  FormTextarea,
  FormCheckbox,
} from 'components/atoms'

interface Props {
  messaggio: string
  messaggioFirma: string
  onChangeMessaggio: any
  onChangeMessaggioFirma: any
  open: boolean
  setOpen: any
  confezioniRef: any
  className?: string
}

const RegaloForm = (props: Props) => {
  const {
    messaggio = '',
    messaggioFirma = '',
    onChangeMessaggio = () => {},
    onChangeMessaggioFirma = () => {},
    open = false,
    setOpen = () => {},
    confezioniRef = null,
    className = '',
  } = props

  const t = useTrans()
  const isMobile = useIsMobile()
  
  const gotoConfezioni = () => {
    if (confezioniRef.current) scrollTo(confezioniRef, isMobile)
  }

  const validationSchema = yup.object().shape({
    messaggio: yup.string().max(200),
    messaggioFirma: yup.string(),
  })

  const initialValues = {
    messaggio: messaggio,
    messaggioFirma: messaggioFirma,
  }

  return (
    <div
      className={`regalo-form${open ? ' regalo-form--open' : ''} ${
        className ? ` ${className}` : ''
      }`}
    >
      <div className="regalo-form__head">
        <button className="regalo-form__open-trigger" onClick={() => setOpen(!open)}>
          {open ? <CheckboxMarked /> : <CheckboxUnmarked />}
        </button>
        <div className="regalo-form__content">
          <p className="regalo-form__title">{t('È un regalo?')}</p>
          <p className="regalo-form__description">
            {t('Selezionando questa opzione potrai aggiungere un biglietto di auguri')}
          </p>
        </div>
        <div className="regalo-form__icon">
          <a href={`/d/ordini/gift_message/ANTEPRIMAgift_message.pdf?messaggio=${messaggio}&messaggio_firma=${messaggioFirma}`} target="_blank">
            <Gift />
          </a>
        </div>
      </div>
      <div className="regalo-form__body">
        <div className="regalo-form__form">
          <Formik
            validateOnBlur
            validateOnChange
            validationSchema={validationSchema}
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={(values) => {
              // TODO: gestione del submit
              console.log(values)
            }}
          >
            {({ values, touched, errors, handleBlur, handleChange, setFieldValue, isValid }) => (
              <Form>
                <FormLayout>
                  <FormField
                    layout="full"
                    label={`${t('Messaggio')}*`}
                    status={getFormFieldStatus({ touched, errors }, 'messaggio')}
                    errorMessage={errors?.messaggio}
                  >
                    <FormTextarea
                      limit={200}
                      value={values?.messaggio}
                      onBlur={handleBlur('messaggio')}
                      onChange={(e) => {
                        onChangeMessaggio(e.target.value)
                      }}
                    />
                  </FormField>
                  <FormField
                    layout="full"
                    label={`${t('Firma')}*`}
                    status={getFormFieldStatus({ touched, errors }, 'messaggioFirma')}
                    errorMessage={errors?.messaggioFirma}
                  >
                    <FormInput
                      value={values?.messaggioFirma}
                      onBlur={handleBlur('messaggioFirma')}
                      onChange={(e) => {
                        onChangeMessaggioFirma(e.target.value)
                      }}
                    />
                  </FormField>
                </FormLayout>
              </Form>
            )}
          </Formik>
          <div className="regalo-form__compilazione-message">
            <Info />
            <div>
              <span>
                <b>{t('Vuoi scrivere il tuo messaggio a mano?')}</b>
              </span>
              <span>{t('Non compilare i campi per ricevere un biglietto vuoto')}</span>
            </div>
          </div>
        </div>
        <div className="regalo-form__goto-confezioni">
          <p className="regalo-form__goto-confezioni__label">
            {t('Vuoi rendere il tuo regalo ancora più speciale?')}
          </p>
          <Button
            label={t('Acquista una confezione regalo')}
            variant="simple"
            size="sm"
            onClick={gotoConfezioni}
          />
        </div>
      </div>
    </div>
  )
}

export default RegaloForm
