import React from 'react'
import { useTrans } from 'hooks'
import { FiltroValori, FiltroRange } from 'components/atoms'

interface Props {
  filtri?: any[]
  onValoreToggle?: (a: number, v: number) => void
  minPrice?: number
  maxPrice?: number
  minRangePrice?: number
  maxRangePrice?: number
  onRangePrezziChange?: (v: number[]) => void
  onRangePrezziRelease?: () => void
  className?: string
}

const ProdottiFiltri = (props: Props) => {
  const {
    filtri = [],
    onValoreToggle = () => {},
    minPrice = 0,
    maxPrice = 0,
    minRangePrice = minPrice,
    maxRangePrice = maxPrice,
    onRangePrezziChange = () => {},
    onRangePrezziRelease = () => {},
    className = '',
  } = props

  const t = useTrans()

  return (
    <div className={`prodotti-filtri ${className ? ` ${className}` : ''}`}>
      {filtri.map(
        (filtro) =>
          filtro.valori_disponibili.length > 1 && (
            <FiltroValori
              filtro={filtro}
              onValoreToggle={onValoreToggle}
              key={`prodotti-filtri__item__${filtro.id}`}
            />
          )
      )}
      {maxPrice > 0 && (
        <FiltroRange
          label={t('Fascia di prezzo')}
          min={minPrice}
          max={maxPrice}
          value={[minRangePrice || minPrice, maxRangePrice || maxPrice]}
          valuesFormat={'{0}€ - {1}€'}
          onChange={onRangePrezziChange}
          onAfterChange={onRangePrezziRelease}
        />
      )}
    </div>
  )
}

export default ProdottiFiltri
