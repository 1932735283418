import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Filters({ className }: Props) {
  return (
    <IconBase name="filters" w={24} className={className}>
      <line x1="3" y1="12" x2="21" y2="12" strokeWidth="2" fill="none"/>
      <line x1="3" y1="5" x2="21" y2="5" strokeWidth="2" fill="none"/>
      <line x1="3" y1="19" x2="21" y2="19" strokeWidth="2" fill="none"/>
      <circle cx="17" cy="12" r="2" stroke="none"/>
      <circle cx="8" cy="19" r="2" stroke="none"/>
      <circle cx="12" cy="5" r="2" stroke="none"/>
    </IconBase>
  )
}

export default Filters
