export default function reducer(
    state = {
      fetching: true,
      pagine: null,
    },
    action
  ) {
    switch (action.type) {
      case 'SET_PAGINE': {
        return {
          ...state,
          fetching: false,
          pagine: action.payload.pagine !== undefined ? action.payload.pagine : undefined,
        }
      }
  
      default: {
        return state
      }
    }
  }