import React, { useState, useEffect } from 'react'
import { useNode, useEditor } from '@craftjs/core'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'
import { defaultProps, dynamicProps } from './index'
import { getAllPaths, getDynamicValue, setDynamicValue } from '../../utils/dynamic'

export const ReactBlockSettings = () => {
  const { props, parentId, customs } = useNode((node) => ({
    props: node.data.props,
    parentId: node.data.parent,
    customs: node.data.custom,
  }))
  const { query } = useEditor()

  return (
    <>
      <ToolbarSection title="Style">
        <p>React Block Settings ...</p>
      </ToolbarSection>
    </>
  )
}
