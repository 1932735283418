import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function TagCloudShape({ className }: Props) {
  return (
    <IconBase name="tag-cloud-shape" w={752} h={381} className={className}>
      <path d="M475.759 357.288C325.462 348.892 162.794 438.875 39.6001 310.361C-83.5941 181.846 105.977 15.6688 332.182 1.86375C522.567 -9.75515 722.415 53.9597 748.478 194.983C774.542 336.007 626.056 365.684 475.759 357.288Z" stroke="none"/>
    </IconBase>
  )
}

export default TagCloudShape
