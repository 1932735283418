import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Minus({ className }: Props) {
  return (
    <IconBase name="minus" w={24} className={className}>
      <path d="M21.1597 12.1118H3.06443" strokeWidth="2" fill="none"/>
    </IconBase>
  )
}

export default Minus
