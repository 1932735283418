import React from 'react'
import { useEditor } from '@craftjs/core'
import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'

export const defaultProps = {}

export const dynamicProps = []

export const ROOT = withBlock(
  (props) => {
    const { children } = props

    const { enabled } = useEditor((state) => ({
      enabled: state.options.enabled,
    }))

    return (
      <div
        ref={(ref) => props.retRef(ref, 'connect')}
        className={props.className}
        onDoubleClick={props.onDoubleClick}
        style={{
          position: 'relative',
          display: 'block',
          width: '100%',
          backgroundColor: 'white',
          padding: enabled ? '10px' : '0px',
          minHeight: '75px',
        }}
      >
        {children}
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

ROOT.craft = withCraft({
  name: 'ROOT',
  defaultProps: defaultProps,
  settings: null,
})
