import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function ColoreVino3({ className }: Props) {
  return (
    <IconBase name="colore-vino3" w={36} className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.5002 27C10.2117 27 4.2723 21.2242 4.00928 14H18L18 26.9909C17.8341 26.997 17.6675 27 17.5002 27Z" stroke="none" fill="#821230" />
      <path fillRule="evenodd" clipRule="evenodd" d="M2.2941 6C1.92151 6 1.58002 6.21352 1.43204 6.55546C0.510652 8.68442 0 11.0326 0 13.5C0 23.165 7.83502 31 17.5 31C27.165 31 35 23.165 35 13.5C35 11.0326 34.4893 8.68442 33.568 6.55546C33.42 6.21352 33.0785 6 32.7059 6C31.9705 6 31.498 6.79478 31.7843 7.47219C32.5671 9.32512 33 11.362 33 13.5C33 22.0604 26.0604 29 17.5 29C8.93959 29 2 22.0604 2 13.5C2 11.362 2.43287 9.32512 3.21575 7.47219C3.50196 6.79478 3.02949 6 2.2941 6Z" stroke="none" fill="#797677" />
      <path fillRule="evenodd" clipRule="evenodd" d="M30.9818 14H22V26.2286C27.0893 24.4257 30.7759 19.6569 30.9818 14Z" stroke="none" fill="#F3E5C0" />
      <path fillRule="evenodd" clipRule="evenodd" d="M13 14V26.235C14.4049 26.7305 15.9164 27 17.4909 27C19.0722 27 20.5899 26.7281 22 26.2286L22 14H13Z" stroke="none" fill="#E3A997" />
    </IconBase>
  )
}

export default ColoreVino3
