import React, { useState } from 'react'
import Link from 'next/link'
import { Menu } from 'components/atoms/Icons'
import { WContainer, WImage } from 'components/atoms'
import { PreHeader } from 'components/molecules'
import { SearchForm, HeaderMenu, Navigation, CartPanel } from 'components/organisms'

interface Props {
  preHeaderLabel?: string
  className?: string
}

const Header = (props: Props) => {
  const { preHeaderLabel = '', className = '' } = props

  const [navMobileOpen, setNavMobileOpen] = useState(false)
  const [cartPanelOpen, setCartPanelOpen] = useState(false)

  return (
    <>
      <div className={`header ${className ? ` ${className}` : ''}`}>
        {!!preHeaderLabel && <PreHeader label={preHeaderLabel} className={className} />}
        <div className="header__main">
          <WContainer>
            <div className="header__main__content">
              <button
                className="header__nav-mobile-trigger"
                onClick={() => setNavMobileOpen(!navMobileOpen)}
              >
                <Menu />
              </button>
              <div className="header__logo-mobile">
                <Link legacyBehavior href="/">
                  <a>
                    <WImage
                      src={'/images/logo-vinicum-cropped-bianco.png'}
                      maxWidth={123}
                      maxHeight={30}
                    />
                  </a>
                </Link>
              </div>
              <div className="header__logo">
                <Link legacyBehavior href="/">
                  <a>
                    <WImage src={'/images/logo-vinicum-bianco.png'} maxWidth={80} maxHeight={61} />
                  </a>
                </Link>
              </div>
              <div className="header__search">
                <SearchForm />
              </div>
              <div className="header__top-menu">
                <HeaderMenu cartPanelOpen={cartPanelOpen} setCartPanelOpen={setCartPanelOpen} />
              </div>
            </div>
          </WContainer>
        </div>
        <Navigation navMobileOpen={navMobileOpen} setNavMobileOpen={setNavMobileOpen} />
      </div>
      <CartPanel open={cartPanelOpen} setOpen={setCartPanelOpen} />
    </>
  )
}

export default Header
