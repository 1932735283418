import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import { useTrans } from 'hooks'
import { useOrdiniQuery } from 'gql/graphql'
import { stati } from 'utils/stati'
import {
  ArrowRight,
  BoxPacco,
  Cart,
  HeartEmpty,
  Logout,
  Mail,
  Profile,
} from 'components/atoms/Icons'
import { Button, NotificationBar } from 'components/atoms'
import { Ordine } from 'components/molecules'

interface ItemProps {
  label?: string
  link: string
  icon?: any
  active?: boolean
  className?: string
}

interface Props {
  activeItemKey?: string
}

const MyAccountMenuItem = (props: ItemProps) => {
  const { label = '', link = '#', icon = null, active = false, className = '' } = props

  return (
    <Link legacyBehavior href={link}>
      <a
        className={`myaccount-menu-item${active ? ' myaccount-menu-item--active' : ''}${
          className ? ` ${className}` : ''
        }`}
      >
        {icon && <span className="myaccount-menu-item__icon">{icon}</span>}
        {label && <span className="myaccount-menu-item__label">{label}</span>}
      </a>
    </Link>
  )
}

const MyAccountMenu = (props: Props) => {
  const [ordineInCorsoLast, setOrdineInCorsoLast] = useState(null)
  const [ordini, setOrdini] = useState([])
  const { activeItemKey = '' } = props

  const { data: dataOrdini } = useOrdiniQuery()
  const t = useTrans()

  useEffect(() => {
    if (dataOrdini?.ordini) {
      setOrdini(
        dataOrdini.ordini.edges.map(({ node }) => ({
          id: node.pk,
          stato: {
            nome: node.stato.alias,
            chiave: node.stato.chiave,
          },
          spedizioneLink: node.spedizioneOrdine?.tracking
            ? `http://www.tnt.it/tracking/Tracking.do?consigNos=${node.spedizioneOrdine?.tracking}&wt=1`
            : null,
          codice: node.codice,
          totale: node.prezzoTotaleScontato,
          dataOrdine: node.data,
          righeProdotti: node.righeOrdine
            .filter((riga) => riga.tipologia?.toLowerCase() === 'prodotto')
            .map((riga) => ({
              id: riga.pk,
              quantita: riga.quantitaOrdinata,
              prodotto: {
                id: riga.prodotto?.pk,
                imageUrl: riga.prodotto?.prodotto?.mainImage,
                nome: riga.prodotto?.prodotto?.nomeFantasia,
              },
            })),
        }))
      )
    }
  }, [dataOrdini])

  useEffect(() => {
    if (ordini.length > 0) {
      const ordiniInCorso = ordini.filter((ordine) =>
        stati
          .filter((stato) => stato.chiave !== 'consegnato')
          .some(({ chiave }) => chiave === ordine.stato.chiave)
      )
      setOrdineInCorsoLast(ordiniInCorso.length > 0 ? ordiniInCorso[0] : null)
    }
  }, [ordini])

  return (
    <div className="myaccount-menu">
      <MyAccountMenuItem
        label={t('Dati personali')}
        icon={<Profile />}
        link="/account"
        active={activeItemKey === 'account'}
      />
      <MyAccountMenuItem
        label={t('Indirizzi')}
        icon={<Mail />}
        link="/account/addresses"
        active={activeItemKey === 'indirizzi'}
      />
      <MyAccountMenuItem
        label={t('Ordini')}
        icon={<Cart />}
        link="/account/orders"
        active={activeItemKey === 'ordini'}
      />
      {/* <MyAccountMenuItem label={t("Wishlist")} icon={<HeartEmpty />} link="/account/wishlist" active={activeItemKey === 'wishlist'} /> */}
      {ordineInCorsoLast && (
        <>
          <Ordine ordine={ordineInCorsoLast} className="myaccount-menu__ordine-in-corso" />
          <Button
            label={t('Vedi tutti gli ordini')}
            icon={<ArrowRight />}
            iconPos="right"
            variant="secondary"
            href="/account/orders"
            className="myaccount-menu__ordini-link"
          />
        </>
      )}
      <NotificationBar
        icon={<BoxPacco />}
        label={t('Hai avuto problemi con un ordine?')}
        button={<Button label={t('Contattaci')} variant="ghost" size="sm" href="/contattaci" />}
      />
      <MyAccountMenuItem
        label={t('Logout')}
        icon={<Logout />}
        link="/logout"
        className="myaccount-menu__logout"
      />
    </div>
  )
}

export default MyAccountMenu
