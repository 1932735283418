export const PAGINA = `
  query Pagina($preview: Boolean) {
    pagina(chiave: "__chiave__", path: "__path__", preview: $preview) {
      titolo
      adminUrl
      url
      descrizione
      chiave
      contentTypeId
      pk
      breadcrumbs {
        titolo
        url
      }
      seo {
        title
        description
        image
      }
    }
  }
`

export const PAGINE = `
  query Pagine {
    pagine {
      edges {
        node {
          chiave
          url
        }
      }
    }
  }
`

export const PAGINE_CATCH_ALL = `
  query PagineCatchAll {
    pagine(tipologia: "page") {
      edges {
        node {
          url
        }
      }
    }
  }
`

export const LOGOUT = `
  mutation Logout {
    logout {
      status
    }
  }
`

export const PREZZI_PRODOTTI = `
  query PrezziProdotti($idProdotti: [Int]) {
    prezziProdotti(idProdotti: $idProdotti) {
      pk
      prezzoScontato
      prezzoOmnibus
      prezzo
      promoApplicataNome
      promoApplicata {
        percentuale
        valore
        alias
        descrizione
        dal
        al
        timeUntil
        mostraCounter
      }
      scontoPercentuale
      disponibilita
      quantitaCarrello
      inWishlist
    }
  }
`

export const WISHLIST = `
  query Wishlist {
    wishlist {
      numProdotti
      items {
        pk
        prodotto {
          contentTypeId
          pk
          varianteProdotto {
            pk
          }
          slug
          alias
          nomeFantasia
          nome
          descrizione
          mainImage
          url
          breadcrumbs {
            titolo
            url
          }
          codice
          prezzo
          prezzoOmnibus
          prezzoScontato
          scontoPercentuale
          promoApplicata {
            percentuale
            valore
            alias
            descrizione
            dal
            al
            timeUntil
            mostraCounter
          }
          omaggio {
            nome
            descrizione 
            prodottoOmaggio {
              prodotto {
                alias
                nome
                mainImage
              }
            }
          }
          inWishlist
          disponibilita
          acquistabile
          url
          mondo
          bundle
          prodottiBundle {
            variante {
              prodotto {
                pk
                nomeFantasia
                nome
                descrizione
                mainImage
                url
              }
            }
            quantita
          }
          magnum
          testoFlash
          categoria {
            alias
          }
          cantina {
            nome
            slug
          }
          attributi {
            annata
            vitigno
            abbinamento
            gradazione
            formato
            colore
            consumoIdeale
            servizio
            bicchiere
          }
          datiSchedaTecnica {
            chiave
            alias
            testo
            titolo
          }
          voti {
            premio {
              nome
              sigla
              chiave
              stile
            }
            testo
            valore
            annata
            ordinamento
            id
          }
        }
      }
    }
  }
`

export const ADD_ITEM_WISHLIST = `
  mutation AddItemWishlist($item: Int!) {
    addItemWishlist(item: $item) {
      status
    }
  }
`

export const REMOVE_ITEM_WISHLIST = `
  mutation RemoveItemWishlist($item: Int!) {
    removeItemWishlist(item: $item) {
      status
    }
  }
`

export const PRODOTTI_CORRELATI_CATEGORIA = `
  query ProdottiCorrelatiCategoria {
    correlatiCategoria(chiave: "__chiave__") {
      contentTypeId
      pk
      varianteProdotto {
        pk
      }
      slug
      alias
      nomeFantasia
      nome
      descrizione
      mainImage
      url
      codice
      prezzo
      prezzoScontato
      prezzoOmnibus
      scontoPercentuale
      promoApplicata {
        percentuale
        valore
        alias
        descrizione
        dal
        al
        timeUntil
        mostraCounter
      }
      omaggio {
        nome
        descrizione 
        prodottoOmaggio {
          prodotto {
            alias
            nome
            mainImage
          }
        }
      }
      inWishlist
      disponibilita
      acquistabile
      url
      magnum
      testoFlash
      bundle
      categoria {
        alias
      }
      cantina {
        nome
      }
      voti {
        premio {
          nome
          sigla
          chiave
          stile
        }
        testo
        valore
        annata
        ordinamento
        id
      }
    }
  }
`

export const PRODOTTO = `
  query Prodotto {
    prodotto(slug: "__slug__") {
      redirect{
        pk
        url
      }
      statusCodeRedirect
      prodotto{
      modelClass
      pk
      ean
      adminUrl
      varianteProdotto {
        pk
        sku
      }
      slug
      alias
      nomeFantasia
      nome
      descrizione
      mainImage
      thumbnailImage
      url
      breadcrumbs {
        titolo
        url
      }
      codice
      prezzo
      prezzoOmnibus
      prezzoScontato
      scontoPercentuale 
      imagesSlider {
        preview
        main
      }
      promoApplicata {
        percentuale
        valore
        alias
        descrizione
        dal
        al
        timeUntil
        mostraCounter
      }
      omaggio {
        nome
        descrizione 
        prodottoOmaggio {
          prodotto {
            alias
            nome
            mainImage
          }
        }
      }
      inWishlist
      disponibilita
      acquistabile
      url
      mondo
      bundle
      numeroPezziBundle
      prodottiBundle {
        variante {
          prodotto {
            pk
            nomeFantasia
            nome
            descrizione
            mainImage
            url
            disponibilita
            acquistabile
          }
        }
        quantita
      }
      magnum
      testoFlash
      categoria {
        alias
      }
      cantina {
        nome
        slug
      }
      attributi {
        annata
        vitigno
        abbinamento
        gradazione
        formato
        colore
        consumoIdeale
        servizio
        regione
        denominazione
        bicchiere
      }
      datiSchedaTecnica {
        chiave
        alias
        testo
        titolo
      }
      voti {
        premio {
          nome
          sigla
          chiave
          stile
        }
        testo
        valore
        annata
        ordinamento
        id
      }
      pagina{
        breadcrumbs {
          titolo
          url
        }
        seo {
          title
          description
          image
        }
        chiave
        titolo
        adminUrl
      }
      stato{
        pk
        azione
        codice
      }
    }
  }
  }
`

export const PRODOTTI_CORRELATI = `
  query ProdottiCorrelati {
    prodotto(slug: "__slug__") {
    prodotto{
      correlati {
        contentTypeId
        pk
        varianteProdotto {
          pk
          sku
        }
        slug
        alias
        nomeFantasia
        nome
        descrizione
        mainImage
        url
        codice
        prezzo
        prezzoScontato
        prezzoOmnibus
        scontoPercentuale
        promoApplicata {
          percentuale
          valore
          alias
          descrizione
          dal
          al
          timeUntil
          mostraCounter
        }
        omaggio {
          nome
          descrizione 
          prodottoOmaggio {
            prodotto {
              alias
              nome
              mainImage
            }
          }
        }
        inWishlist
        disponibilita
        acquistabile
        url
        magnum
        testoFlash
        bundle
        categoria {
          alias
        }
        cantina {
          nome
        }
        voti {
          premio {
            nome
            sigla
            chiave
            stile
          }
          testo
          valore
          annata
          ordinamento
          id
        }
      }
    }
}
  }
`

export const PRODOTTI = `
  query Prodotti {
    prodotti(first: 10) {
      edges {
        node {
          slug
        }
      }
    }
  }
`

export const CANTINE = `
  query Cantine {
     cantine {
      edges {
        node {
          pk
          nome
          url
          slug
          latitudine
          longitudine
          recapiti
          sito
          logo {
            id
            image
          } 
          logoTrasparente {
            id
            image
          } 
          
          listingVideo {
            id
          } 
        }
      }
    }
  }
`

export const CANTINA = `
  query Cantina {
    cantina(slug: "__slug__", vinicum: true) {
      pk
      adminUrl
      modelClass
      nome
      slug
      titolo
      sottotitolo
      sito
      recapiti
      fb
      twitter
      youtube
      instagram
      flickr
      latitudine
      longitudine
      idYoutubeLinkVideo
      regione{
        id
        image
      }
      infoBox {
    	  id
        testoTop
        testoBottom
        mp4
        ogg
        webm
    	}
      mainImage {
          id
          image
      }
      logoTrasparente {
        id
        image
      } 
      logo {
        image
      }
      url
      video {
        id
        idYoutube
      }
      statement
      descrizione
      pagina{
        adminUrl
        breadcrumbs {
            titolo
            url
        }
        seo {
            title
            description
            image
        }
        chiave
      }
      numeroProdotti
      prodotti__numeroProdotti__ {
        edges {
          node {
            pk
            varianteProdotto {
              pk
              sku
            }
            slug
            alias
            nomeFantasia
            nome
            descrizione
            mainImage
            omaggio {
              nome
              descrizione
              prodottoOmaggio {
                id
                prodotto {
                  alias
                  nome
                  mainImage
                }
              }
            }
            promoApplicata {
              percentuale
              valore
              alias
              descrizione
              dal
              al
              mostraCounter
              timeUntil
            }
            inWishlist
            disponibilita
            acquistabile
            codice
            prezzo
            prezzoOmnibus
            prezzoScontato
            scontoPercentuale
            url
            categoria {
              alias
            }
            bundle
            magnum
            testoFlash
            contentTypeId
            cantina {
              nome
              slug
            }
            attributi {
              annata
              vitigno
              abbinamento
              gradazione
              formato
              colore
              consumoIdeale
              servizio
              bicchiere
            }
            datiSchedaTecnica {
              chiave
              testo
              alias
              titolo
            }
            voti {
              premio {
                nome
                sigla
                chiave
                stile
              }
              testo
              valore
              annata
              ordinamento
              id
            }
          }
        }
      }
    }
  }
`

export const CANTINA_BOX = `
  query CantinaBox {
    cantina(slug: "__slug__", vinicum: true) {
      pk
      nome
      slug
      logoTrasparente {
        id
        image
      } 
      url
      statement
      prodotti(first: 2) {
        edges {
          node {
            pk
            varianteProdotto {
              pk
            }
            slug
            alias
            nomeFantasia
            nome
            descrizione
            mainImage
            omaggio {
              nome
              descrizione
              prodottoOmaggio {
                id
                prodotto {
                  alias
                  nome
                  mainImage
                }
              }
            }
            promoApplicata {
              percentuale
              valore
              alias
              descrizione
              dal
              al
              mostraCounter
              timeUntil
            }
            inWishlist
            disponibilita
            acquistabile
            codice
            prezzo
            prezzoOmnibus
            prezzoScontato
            scontoPercentuale
            url
            categoria {
              alias
            }
            bundle
            magnum
            testoFlash
            contentTypeId
            cantina {
              nome
              slug
            }
            voti {
              premio {
                nome
                sigla
                chiave
                stile
              }
              testo
              valore
              annata
              ordinamento
              id
            }
          }
        }
      }
    }
  }
`

export const CART = `
  query cart {
    cart {
      items {
        pk
        quantita
        omaggio
        prodotto {
          pk
          sku
          prodotto {
            pk
            alias
            nome
            nomeFantasia
            prezzoScontato
            prezzoOmnibus
            mainImage
            thumbnailImage
            vendibileEstero
            url
            descrizione
            prezzo
            categoria {
              alias
            }
            attributi {
              annata
              vitigno
              abbinamento
              gradazione
              formato
              regione
              denominazione
            }
            cantina {
              nome
            }
            promoApplicata {
              id
              alias
              valore
              percentuale
              codice
              conCodiceUnivoco
              conCodice
              soloLoggati
            }
          }
        }
      }
      erroreApisEstero
      prezzo
      totaleScontato
      totaleProdottiScontato
      scontoAssoluto
      scontoPercentuale
      numProdotti
      iva
      accise
      spedizioneEstero
      sistemaSpedizione {
        prezzoScontato
        alias
        estero
        scontoPercentuale
        promoApplicata {
          alias
          valore
          percentuale
          conCodice
          soloLoggati
        }
      }
      promoApplicata {
        alias
        valore
        percentuale
        conCodice
        soloLoggati
        descrizionePopup
      }
      promoCode
      includiMessaggio
      messaggio
      messaggioFirma
      limiteFreeShipping
    }
    
  }
`

export const ADD_TO_CART = `
  mutation AddItem($item: Int!, $quantita: Int!) {
    addItem(item: $item, quantita: $quantita) {
      status
      message
      shortMessage
      quantitaMax
    }
  }
`

export const ADD_MULTIPLE_TO_CART = `
  mutation AddMultipleItems($items: [AddItemInput]!) {
    addMultipleItems(items: $items) {
      status
    }
  }
`

export const REMOVE_FROM_CART = `
  mutation removeItem($item: Int!, $estero: Boolean!) {
    removeItem(item: $item, estero: $estero) {
      status
      #message
    }
  }
`

export const PRODOTTI_CORRELATI_CART = `
  query ProdottiCorrelatiCart {
    cart {
      correlati {
        contentTypeId
        pk
        varianteProdotto {
          pk
        }
        slug
        alias
        nomeFantasia
        nome
        descrizione
        mainImage
        url
        codice
        prezzo
        prezzoScontato
        prezzoOmnibus
        scontoPercentuale
        promoApplicata {
          percentuale
          valore
          alias
          descrizione
          dal
          al
          timeUntil
          mostraCounter
        }
        omaggio {
          nome
          descrizione 
          prodottoOmaggio {
            prodotto {
              alias
              nome
              mainImage
            }
          }
        }
        inWishlist
        disponibilita
        acquistabile
        url
        magnum
        testoFlash
        bundle
        categoria {
          alias
        }
        cantina {
          nome
        }
        voti {
          premio {
            nome
            sigla
            chiave
            stile
          }
          testo
          valore
          annata
          ordinamento
          id
        }
      }
    }
  }
`

export const CONFEZIONI_CORRELATE_CART = `
  query ConfezioniCorrelateCart {
    cart {
      confezioniCorrelate {
        contentTypeId
        pk
        varianteProdotto {
          pk
        }
        slug
        alias
        nomeFantasia
        nome
        descrizione
        mainImage
        url
        codice
        prezzo
        prezzoScontato
        prezzoOmnibus
        scontoPercentuale
        promoApplicata {
          percentuale
          valore
          alias
          descrizione
          dal
          al
          timeUntil
          mostraCounter
        }
        omaggio {
          nome
          descrizione 
          prodottoOmaggio {
            prodotto {
              alias
              nome
              mainImage
            }
          }
        }
        inWishlist
        disponibilita
        acquistabile
        url
        magnum
        testoFlash
        bundle
        categoria {
          alias
        }
        cantina {
          nome
        }
        voti {
          premio {
            nome
            sigla
            chiave
            stile
          }
          testo
          valore
          annata
          ordinamento
          id
        }
      }
    }
  }
`

export const SET_MESSAGGIO = `
  mutation setMessaggio($includiMessaggio: Boolean!, $messaggio: String!, $messaggioFirma: String!) {
    setMessaggio(includiMessaggio: $includiMessaggio, messaggio: $messaggio, messaggioFirma: $messaggioFirma) {
      status
    }
  }
`

export const SET_PROMO_CODE = `
  mutation SetPromoCode($promoCode: String!) {
    setPromoCode(promoCode: $promoCode) {
      status
      message
      promozione {
        alias
        descrizione
        attiva
        codice
        percentuale
        valore
        descrizionePopup
      }
    }
  }
`

export const REMOVE_PROMO_CODE = `
  mutation RemovePromoCode($promoCode: String!) {
    removePromoCode(promoCode: $promoCode) {
      status
      message
    }
  }
`

export const CHECKOUT = `
  query Checkout {
    checkout {
      sistemaPagamento {
        pk
        alias
        chiave
        prezzo
      }
      sistemaSpedizione {
        pk
        alias
        nome
        prezzoScontato
        estero
      }
      indirizzoSpedizione {
        pk
        indirizzo
        presso
        nome
        cognome
        citta
        provincia
        cap
        telefono
        nazione{
          pk
          nome
          isoCode
        }
      }
      indirizzoFatturazione {
        pk
        indirizzo
        nome
        cognome
        citta
        provincia
        cap
        cf
        piva
        nazione{
          pk
          nome
          isoCode
        }
      }
      erroreApisEstero
      richiediFattura
      totaleSenzaPromozioni
      prezzo
      scontoAssoluto
      scontoPercentuale
      totaleScontato
      totaleScontatoNetto
      totaleProdottiScontato
      accise
      iva
      spedizioneEstero
      promoApplicata {
        percentuale
        valore
        alias
        descrizione
        dal
        al
        mostraCounter
        timeUntil
      }
      sistemiPagamento {
        pk
        alias
        chiave
        nome
        descrizione
        image
        prezzo
      }
      sistemiSpedizione {
        pk
        alias
        nome
        descrizione
        prezzoScontato
        enableForCap
        enableForCovid
      }
      items {
        quantita
        omaggio
        prodotto {
          pk
          sku
          prodotto {
            pk
            alias
            nome
            nomeFantasia
            prezzoScontato
            mainImage
            vendibileEstero
            prezzo
            categoria {
              alias
            }
            attributi {
              annata
              vitigno
              abbinamento
              gradazione
              formato
              regione
              denominazione
            }
            cantina {
              nome
            }
            promoApplicata {
              id
            }
          }
        }
      }
    }
  }
`

export const ORDINE_CHECKOUT = `
query OrdineCheckout {
  ordineCheckout {
    pk
    codice
    prezzoTotaleScontato
    deltaGiorniAcquisto
    data
    numeroProdotti
    promozione {
      codice
    }
    stato {
      pk
      chiave
    }
    righeOrdine{
      pk
      omaggio
      tipologia
      quantitaOrdinata
      prezzoUnitarioScontato
      prezzoTotaleScontato
      prodotto {
        pk
        sku
        prodotto{
          nome
          prezzoScontato
          url
          mainImage
          descrizione
          categoria {
            alias
          }
          cantina {
            nome
          }
          attributi {
            annata
            vitigno
            abbinamento
            gradazione
            formato
            regione
            denominazione
          }
        }
      }
    }
  }
}
`

export const SISTEMI_SPEDIZIONE_SENZA_INDIRIZZO = `
query SistemiSpedizioniSenzaIndirizzo($cap: String){
  sistemiSpedizioneSenzaIndirizzo(cap: $cap) {
        pk
        alias
        nome
        descrizione
        prezzoScontato
        enableForCap
        enableForCovid
        estero
	}
}
`

export const INDIRIZZI_SPEDIZIONE = `
query IndirizziSpedizione {
  indirizzi(isFatturazione: false) {
    edges {
      node {
        pk
        nome
        cognome
        indirizzo
        presso
        numeroCivico
        telefono
        citta
        provincia
        cap
        cf
        isFatturazione
        cancellato
        nazione{
          pk
          nome
          isoCode
        }
      }
    }
  }
}
`

export const INDIRIZZI_FATTURAZIONE = `
  query IndirizziFatturazione {
    indirizzi(isFatturazione: true) {
      edges {
        node {
          pk
          privato
          nome
          cognome
          indirizzo
          numeroCivico
          privato
          telefono
          citta
          provincia
          cap
          cf
          piva
          codiceDestinatarioUnivoco
          pec
          isFatturazione
          cancellato
        }
      }
    }
  }
`

export const NAZIONI = `
  query Nazioni {
    nazioni{
      edges{
        node{
          pk
          nome
          isoCode
        }
      }
    }
  }
`

export const CREATE_INDIRIZZO = `
  mutation CreateIndirizzo($input: IndirizzoInput!) {
    createIndirizzo(input: $input) {
      indirizzo {
          pk
          privato
          nome
          cognome
          indirizzo
          presso
          telefono
          citta
          provincia
          nazione{
            pk
            nome
            isoCode
          }
          cap
          cf
          piva
          codiceDestinatarioUnivoco
          pec
          isFatturazione
          nascondi
          cancellato
      }
    }
  }
`

export const UPDATE_INDIRIZZO = `
  mutation UpdateIndirizzo($input: IndirizzoUpdateInput!) {
    updateIndirizzo(input: $input) {
      indirizzo {
          pk
          cancellato
      }
    }
  }
`

export const DELETE_INDIRIZZO = `
  mutation DeleteIndirizzo($input: IndirizzoDeleteInput!) {
    deleteIndirizzo(input: $input) {
      message
    }
  }
`

export const SET_INDIRIZZI = `
mutation SetIndirizzi($indirizzoSpedizione: Int, $indirizzoFatturazione: Int, $richiediFattura: Boolean){
  setIndirizzi(indirizzoSpedizione: $indirizzoSpedizione, indirizzoFatturazione: $indirizzoFatturazione, richiediFattura: $richiediFattura) {
    status
  }
}
`

export const SET_SPEDIZIONE = `
mutation SetSpedizione($sistemaSpedizione: Int!) {
  setSpedizione(sistemaSpedizione: $sistemaSpedizione) {
    status
  }
}
`

// export const GET_QUOTE_SHIPPING_ESTERO = `
// mutation GetQuoteShippingEstero($country: String, $cap: String) {
//   getQuoteShippingEstero(country: $country, cap: $cap) {
//     status
//     shipping
//     accise
//     vat
//     vatLabel
//     vatShipping
//     vatShippingLabel
//     daziDestino
//   }
// }
// `

export const SET_PAGAMENTO = `
  mutation SetPagamento($sistemaPagamento: Int!) {
    setPagamento(sistemaPagamento: $sistemaPagamento) {
      status
    }
  }
`

export const SET_CHECKOUT = `
  mutation SetCheckout($sistemaPagamento: Int!, $amazonCheckoutSessionId: String) {
    setCheckout(sistemaPagamento: $sistemaPagamento, amazonCheckoutSessionid: $amazonCheckoutSessionId) {
      url
      status
      message
    }
  }
`

export const CATEGORIA = `
  query categoria {
    categoria(slugTree: "__slugTree__"){
      pk
      alias
      slug
      slugTree
      adminUrl
      modelClass
      breadcrumbs {
        titolo
        url
      }
      seoH1
      nome
      descrizione
      sottoCategorie {
        slug
        voceMenu
        chiave
        url
      }
      cantina {
        nome
        url
        logoTrasparente {
          image
        }
      }
      pagina{
        breadcrumbs {
          titolo
          url
        }
        seo {
          title
          description
          image
        }
        chiave
        titolo
        adminUrl
      }
    }
  }
`

export const ALL_NEWS = `
  query AllNews {
    allNews {
      edges {
        node {
          slug
        }
      }
    }
  }
`

export const NEWS = `
  query News {
    news(slug: "__slug__") {
      contentTypeId
      adminUrl
      url
      modelClass
      pk
      titolo
      sottotitolo
      testo
      data
      mainImage
      categoria {
        nome
      }
      newsCorrelate {
        pk
        titolo
        sottotitolo
        data
        listingImage
        url
        categoria {
          pk
          nome
        }
      }
      pagina {
        adminUrl
        breadcrumbs {
            titolo
            url
        }
        seo {
            title
            description
            image
        }
        chiave
      }
      prodottiCorrelati {
        contentTypeId
        pk
        varianteProdotto {
          pk
        }
        slug
        alias
        nomeFantasia
        nome
        descrizione
        mainImage
        url
        codice
        prezzo
        prezzoScontato
        scontoPercentuale
        promoApplicata {
          percentuale
          valore
          alias
          descrizione
          dal
          al
          timeUntil
          mostraCounter
        }
        omaggio {
          nome
          descrizione 
          prodottoOmaggio {
            prodotto {
              alias
              nome
              mainImage
            }
          }
        }
        inWishlist
        disponibilita
        acquistabile
        url
        magnum
        testoFlash
        bundle
        categoria {
          alias
        }
        cantina {
          nome
        }
        voti {
          premio {
            nome
            sigla
            chiave
            stile
          }
          testo
          valore
          annata
          ordinamento
          id
        }
      }
    }
  }
`

export const MENU_GET = `
query MenuItems {
    menuitems(parent_Isnull: true, attivo: true) {
        edges {
          node {
            id
            pk
            alias
            key
            attivo
            name
            image
            stile
            description
            url: finalUrl
            textCta
            newsCollegata {
              titolo
              sottotitolo
              listingImage
              url
              categoria {
                pk
                nome
              }
            }
            children(attivo: true) {
              edges {
                node {
                  id
                  pk
                  alias
                  key
                  attivo
                  name
                  image
                  imageMobile
                  imageTablet
                  stile
                  description
                  url: finalUrl
                  textCta
                  blank
                  newsCollegata {
                      pk
                      titolo
                      sottotitolo
                      listingImage
                      url
                      categoria {
                        pk
                        nome
                      }
                   }
                  children(attivo: true) {
                      edges {
                        node {
                          id
                          pk
                          alias
                          key
                          attivo
                          name
                          image
                          imageMobile
                          imageTablet
                          stile
                          description
                          url: finalUrl
                          textCta
                          newsCollegata {
                              pk
                              titolo
                              sottotitolo
                              listingImage
                              url
                              categoria {
                                pk
                                nome
                              }
                           }
                          children(attivo: true) {
                              edges {
                                node {
                                  id
                                  pk
                                  alias
                                  key
                                  attivo
                                  name
                                  image
                                  imageMobile
                                  imageTablet
                                  stile
                                  description
                                  url: finalUrl
                                  textCta
                                  newsCollegata {
                                      pk
                                      titolo
                                      sottotitolo
                                      listingImage
                                      url
                                      categoria {
                                        pk
                                        nome
                                      }
                                    }
                                  children(attivo: true) {
                                    edges {
                                      node {
                                        id
                                        pk
                                        alias
                                        key
                                        attivo
                                        name
                                        image
                                        imageMobile
                                        imageTablet
                                        stile
                                        description
                                        url: finalUrl
                                        textCta
                                        newsCollegata {
                                          pk
                                          titolo
                                          sottotitolo
                                          listingImage
                                          url
                                          categoria {
                                            pk
                                            nome
                                          }
                                        }                       
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                      }
                    }
                }
              }
            }
          }
        }
       }
    }
`

export const ALLCATEGORIESPATHS = `
  query AllCategories {
    categorie (after: "__cursor__") {
      edges {
        node {
          slugTree
          url
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export const ALLPRODUCTSPATHS = `
  query AllProducts {
    prodotti (after: "__cursor__") {
      edges {
        node {
          slug
          url
          stato{
              pk
              azione
              codice
          }
        }
      }
      
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`
export const ALLCANTINEPATHS = `
  query AllCantine {
    cantine (after: "__cursor__") {
      edges {
        node {
          slug
          url
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export const OMAGGIO = `
  query Omaggio {
    omaggio {
      nome
      descrizione 
      prodottoOmaggio {
        prodotto {
          alias
          nome
          mainImage
        }
      }
    }
  }
`
