import React, { useState } from 'react'
import { useSocialAuthMutation } from 'gql/graphql'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
//import GoogleLogin from 'react-google-login'
import axios from 'axios'
import { useRouter } from 'next/router'
import { SocialButton, Button } from 'components/atoms'
import useTrans from './useTrans'
import useLocalStorage from './useLocalStorage'
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google'

function useSocialLogin() {
  const router = useRouter()
  const t = useTrans()

  const [socialLogin, { loading }] = useSocialAuthMutation()
  const [profile, setProfile] = useState({ nome: '', cognome: '', email: '' })
  const [error, setError] = useState<string | null>()
  const [visibilityButtonGoogle, setVisibilityButtonGoogle] = useState(true)
  const [, setToken] = useLocalStorage('token', null)
  const [, setUserId] = useLocalStorage('userId', null)

  async function handleSocialLogin(provider, accessToken, profileData) {
    if (error) setError(null)
    setProfile(profileData)
    socialLogin({
      variables: {
        provider,
        accessToken,
      },
    })
      .then((res) => {
        setUserId(res.data?.socialAuth?.social?.user?.pk)
        setToken(res.data?.socialAuth?.token)
        router.push('/account/')
      })
      .catch((err) => {
        if (err.message === 'user_not_active') {
          setError(t('Registrazione utente non confermata'))
        } else
          router.push(
            `/registrazione?social=true&nome=${profileData?.nome}&cognome=${profileData?.cognome}&email=${profileData?.email}`
          )
      })
  }

  function prepareFacebookLogin(response) {
    if (response.status) {
      if (response.status !== 'unknown') setError(t('Si è verificato un errore.'))
    } else if (response.accessToken) {
      const data = {
        nome: response.first_name || '',
        cognome: response.last_name || '',
        email: response.email || '',
      }
      handleSocialLogin('facebook', response?.accessToken, data)
    }
  }

  function prepareGoogleLogin(response) {
    if (response.error) {
      if (response.error !== 'popup_closed_by_user') setError('Si è verificato un errore.')
    } else if (response.access_token) {
      axios
        .get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            Authorization: `Bearer ${response.access_token}`,
          },
        })
        .then((res) => {
          const data = {
            nome: res.data.given_name,
            cognome: res.data.family_name,
            email: res.data.email,
          }
          handleSocialLogin('google-oauth2', response.access_token, data)
        })
    }
  }

  function failureGoogleLogin(response) {
    if (response?.error === 'idpiframe_initialization_failed') {
      setVisibilityButtonGoogle(false)
    } else setError('Si è verificato un errore.')
  }

  const loginGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => prepareGoogleLogin(tokenResponse),
    onError: (credentialResponse) => failureGoogleLogin(credentialResponse),
    scope: 'profile email',
  })

  const FacebookLoginButton = () => (
    <FacebookLogin
      isMobile={false}
      appId={process.env.FACEBOOK_APP_ID}
      callback={prepareFacebookLogin}
      fields="first_name,last_name,email"
      render={(renderProps) => (
        <SocialButton
          social="facebook"
          className="login-template__social-button"
          onClick={renderProps.onClick}
          label={t('Accedi con Facebook')}
        />
      )}
    />
  )

  const GoogleLoginButton = () =>
    visibilityButtonGoogle ? (
      <SocialButton
        social="google"
        className="login-template__social-button"
        onClick={() => loginGoogle()}
        label={t('Accedi con Google')}
      />
    ) : null

  return { FacebookLoginButton, GoogleLoginButton, socialLogin, error, loading, profile }
}

export default useSocialLogin
