import Script from 'next/script'

const isProduction = process.env.ENV === 'production'

const ConnectifScript = () => {
  return (
    <>
      {isProduction ? (
        <Script
          id="__cn_generic_script__7a14ae04-e862-41e4-8bb4-8e478108cde3"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
           !function(e){function t(){if(!e.querySelector("#__cn_client_script_7a14ae04-e862-41e4-8bb4-8e478108cde3")){var t=e.createElement("script");t.setAttribute("src","https://cdn.connectif.cloud/eu7/client-script/7a14ae04-e862-41e4-8bb4-8e478108cde3"),e.body.appendChild(t)}}"complete"===e.readyState||"interactive"===e.readyState?t():e.addEventListener("DOMContentLoaded",t)}(document);
           `,
          }}
        />
      ) : (
        <Script
          id="__cn_generic_script__c8c83e9f-f493-4b91-a906-7181a18690d3"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
              !function(e){function t(){if(!e.querySelector("#__cn_client_script_c8c83e9f-f493-4b91-a906-7181a18690d3")){var t=e.createElement("script");t.setAttribute("src","https://cdn.connectif.cloud/eu7/client-script/c8c83e9f-f493-4b91-a906-7181a18690d3"),e.body.appendChild(t)}}
              "complete"===e.readyState||"interactive"===e.readyState?t():e.addEventListener("DOMContentLoaded",t)}(document);
            `,
          }}
        />
      )}
    </>
  )
}
export default ConnectifScript
