import React, { useState, useEffect, useContext } from 'react'
import { useEditor } from '@craftjs/core'
import { Close } from 'components/atoms/Icons'
import { GlobalContext } from 'react-pagebuilder/services/globalContext'

export const FloatingSettings = ({ parentPos }) => {
  const { selected, query, actions } = useEditor((state) => ({
    selected: [...state.events.selected]?.[0],
  }))

  const { floatingSettingsOpen, setFloatingSettingsOpen, setSidebarTab } = useContext(GlobalContext)

  const [pos, setPos] = useState()

  useEffect(() => {
    if (parentPos) {
      const left = parentPos.left
      const top = parentPos.top
      const screenW = window.innerWidth
      const screenH = window.innerHeight

      let pos = {}
      if (left > screenW / 2) {
        pos['right'] = '0'
      } else {
        pos['left'] = '2px'
      }

      if (top > screenH / 2) {
        pos['bottom'] = 'calc(100% + 10px)'
      } else {
        pos['top'] = 'calc(100% + 10px)'
      }
      setPos(pos)
      setVisible(true)
    }
  }, [parentPos])

  const [visible, setVisible] = useState()

  const [node, setNode] = useState()

  useEffect(() => {
    if (selected) {
      const node = query.node(selected).get()
      setNode(node)
    }
  }, [selected])

  const onShowAllSettings = () => {
    setSidebarTab('tab-options')
    setFloatingSettingsOpen(false)
  }

  return (
    <>
      {floatingSettingsOpen && visible && node && (
        <div className="floating-settings-menu" style={pos}>
          <div className="floating-settings-menu-header">
            <div className="floating-settings-menu-header-title">
              <h3>{node.data.name} Options</h3>
            </div>
            <div
              className="floating-settings-menu-header-close"
              onClick={() => setFloatingSettingsOpen(false)}
            >
              <Close />
            </div>
          </div>
          <div className="floating-settings-menu-content">
            {React.createElement(node.related.floatingToolbar)}
          </div>
          <div className="floating-settings-menu-footer">
            <button onClick={() => onShowAllSettings()}>Show All Options {'>'}</button>
          </div>
        </div>
      )}
    </>
  )
}
