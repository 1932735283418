import { useState, useEffect } from 'react'
import { useEditor } from '@craftjs/core'
import {
  isDroppable,
  isCopyable,
  isClonable,
  isDeletable,
  isDisabled,
  serializeNodeTree,
  deserializeNodeTree,
  cloneNodeTree,
  addNodeTree,
  deleteNodeTree,
} from '../utils/node'
import { parseNodeTree } from '../utils/block'
import { canDropBlock } from '../utils/rules'
import { Text } from '../components/Text'
const ContextMenuItem = ({ label, onClick, disabled }) => {
  return (
    <>
      {disabled ? (
        <div className="context-menu-item disabled">
          <p>{label}</p>
        </div>
      ) : (
        <div className="context-menu-item" onClick={onClick}>
          <p>{label}</p>
        </div>
      )}
    </>
  )
}
export const ContextMenu = () => {
  const { selected, query, actions } = useEditor((state) => ({
    selected: [...state.events.selected]?.[0],
  }))
  // mantiene il node selected anche quando viene cliccato il contextmenu-item
  // dove selected diventerebbe null
  const [nodeSelected, setNodeSelected] = useState()
  const [deletable, setDeletable] = useState()
  const [clonable, setClonable] = useState()
  const [copyable, setCopyable] = useState()
  const [cuttable, setCuttable] = useState()
  const [disabled, setDisabled] = useState()
  const [droppable, setDroppable] = useState()
  useEffect(() => {
    if (selected) {
      setNodeSelected(selected)
      setDeletable(isDeletable(selected, query))
      setClonable(isClonable(selected, query))
      setCopyable(isCopyable(selected, query))
      setCuttable(isDeletable(selected, query) && isCopyable(selected, query))
      setDisabled(isDisabled(selected, query))
      setDroppable(isDroppable(selected, query))
    }
  }, [selected])
  const [style, setStyle] = useState()
  const [visible, setVisible] = useState(false)
  const onRightClick = (event) => {
    if (selected && !query.node(selected).get().data.custom.editing) {
      event.preventDefault()
      const clickX = event.pageX
      const clickY = event.pageY
      const screenW = window.innerWidth
      const screenH = window.innerHeight
      let style = {}
      if (clickX > screenW / 2) {
        style['right'] = screenW - clickX
      } else {
        style['left'] = clickX
      }
      if (clickY > screenH / 2) {
        style['bottom'] = screenH - clickY
      } else {
        style['top'] = clickY
      }
      setStyle(style)
      setVisible(true)
    } else {
      onLeftClick()
    }
  }
  const onLeftClick = () => {
    setVisible(false)
  }
  const onScroll = () => {
    setVisible(false)
  }
  useEffect(() => {
    const dom = document.querySelector('.craftjs-renderer')
    if (dom) {
      document.addEventListener('click', onLeftClick)
      dom.addEventListener('scroll', onScroll)
      dom.addEventListener('contextmenu', onRightClick)
    }
    return () => {
      const dom = document.querySelector('.craftjs-renderer')
      if (dom) {
        document.removeEventListener('click', onLeftClick)
        dom.removeEventListener('scroll', onScroll)
        dom.removeEventListener('contextmenu', onRightClick)
      }
    }
  }, [selected])
  const copy = async () => {
    if (isCopyable(nodeSelected, query)) {
      const parent = query.node(nodeSelected).get().data.parent
      const parentType = query.node(parent).get().data.type
      let idToCopy = nodeSelected
      if (parentType === Text) {
        idToCopy = parent
      }
      const nodeTree = query.node(idToCopy).toNodeTree()
      const serializedNodeTree = serializeNodeTree(nodeTree, query)
      await navigator.clipboard.writeText(JSON.stringify(serializedNodeTree))
    }
  }
  const cut = async () => {
    if (isClonable(nodeSelected, query)) {
      const parent = query.node(nodeSelected).get().data.parent
      const parentType = query.node(parent).get().data.type
      let idToClone = nodeSelected
      if (parentType === Text) {
        idToClone = parent
      }
      const nodeTree = query.node(idToClone).toNodeTree()
      const serializedNodeTree = serializeNodeTree(nodeTree, query)
      await navigator.clipboard.writeText(JSON.stringify(serializedNodeTree))
      deleteNodeTree(idToClone, actions)
    }
  }
  const paste = async () => {
    const clipboard = await navigator.clipboard.readText()
    try {
      const nodeTree = deserializeNodeTree(JSON.parse(clipboard), query)
      const currentNode = nodeTree['nodes'][nodeTree['rootNodeId']]
      const targetNode = query.node(nodeSelected).get()
      if (canDropBlock(currentNode, targetNode)) {
        const elements = parseNodeTree(nodeTree, nodeTree['rootNodeId'], 0, null, disabled)
        const clonedNodeTree = query.parseReactElement(elements).toNodeTree()
        addNodeTree(clonedNodeTree, nodeSelected, actions)
      }
    } catch (err) {
      console.log(err)
    }
  }
  const clone = () => {
    if (isClonable(nodeSelected, query)) {
      const parent = query.node(nodeSelected).get().data.parent
      let idToClone = nodeSelected
      let parentToClone = parent
      const parentType = query.node(parent).get().data.type
      if (parentType === Text) {
        idToClone = parent
        parentToClone = query.node(parent).get().data.parent
      }
      const nodeTree = cloneNodeTree(idToClone, query)
      const index = query.node(parentToClone).get().data.nodes.indexOf(idToClone)
      addNodeTree(nodeTree, parentToClone, actions, false, index + 1)
    }
  }
  const cancel = () => {
    if (isDeletable(nodeSelected, query)) {
      const parent = query.node(nodeSelected).get().data.parent
      const parentType = query.node(parent).get().data.type
      if (parentType === Text) {
        deleteNodeTree(parent, actions)
      } else {
        deleteNodeTree(nodeSelected, actions)
      }
    }
  }
  return (
    <>
      {visible && (
        <div className="context-menu" style={{ ...style }}>
          <div className="context-menu-items">
            <ContextMenuItem label="Copy" onClick={copy} disabled={!copyable} />
            <ContextMenuItem label="Cut" onClick={cut} disabled={!cuttable} />
            <ContextMenuItem label="Paste" onClick={paste} disabled={!droppable} />
            <ContextMenuItem label="Clone" onClick={clone} disabled={!clonable} />
            <ContextMenuItem label="Cancel" onClick={cancel} disabled={!deletable} />
          </div>
        </div>
      )}
    </>
  )
}
