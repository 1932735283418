import React from 'react'

interface Props {
  titolo?: string
  descrizione?: string
  children?: any
  className?: string
}

const CheckoutStepBlock = (props: Props) => {
  const { titolo = '', descrizione = '', className = '', children = null } = props

  return (
    <div className={`checkout-step-block ${className ? ` ${className}` : ''}`}>
      {titolo && <p className="checkout-step-block__title">{titolo}</p>}
      {descrizione && <p className="checkout-step-block__description">{descrizione}</p>}
      {children && <div className="checkout-step-block__content">{children}</div>}
    </div>
  )
}

export default CheckoutStepBlock
