import { gql, useMutation } from '@apollo/client'
import { ADD_ITEM_WISHLIST, REMOVE_ITEM_WISHLIST } from 'utils/queries'


export default function useWishlist() {
    const [addItemWishlist, { data: dataAddItemWishlist }] = useMutation(gql`${ADD_ITEM_WISHLIST}`)
    const [removeItemWishlist, { data: dataRemoveItemWishlist }] = useMutation(gql`${REMOVE_ITEM_WISHLIST}`)
  
    const handleWishlist = async (active, prodotto) => {
      if (active) {
        const response = await removeItemWishlist({
          variables: {
            item: prodotto.pk,
          },
          refetchQueries: ['Wishlist'],
        })
        return !response.data.removeItemWishlist.status
      } else {
        const response = await addItemWishlist({
          variables: {
            item: prodotto.pk,
          },
          refetchQueries: ['Wishlist'],
        })
        return response.data.addItemWishlist.status
      }
    }
  
    return handleWishlist
  }