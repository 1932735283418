import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function SpiritsVodka({ className }: Props) {
  return (
    <IconBase name="spirits-vodka" w={32} className={className}>
      <path d="M21.4498 8.03023C21.4498 7.67023 21.4498 6.99023 15.9998 6.99023C10.5498 6.99023 10.5498 7.67023 10.5498 8.03023C10.5498 8.10023 10.5498 8.19023 10.5998 8.29023C10.7998 9.06023 11.9398 13.5302 12.0498 16.2402L12.3598 23.7102C12.3598 24.9302 15.1398 25.0302 15.9898 25.0302C16.8398 25.0302 19.6198 24.9402 19.6198 23.7402L19.9298 16.2502C20.0398 13.5402 21.1798 9.07023 21.3798 8.30023C21.4498 8.19023 21.4498 8.10023 21.4498 8.03023ZM19.4198 8.04023C17.3798 8.23023 14.6198 8.23023 12.5798 8.04023C15.1398 7.80023 16.8598 7.80023 19.4198 8.04023ZM15.9998 24.0102C14.5398 24.0102 13.5998 23.7402 13.3598 23.5802L13.2298 20.4202C14.0098 21.0402 14.9798 21.3802 15.9998 21.3802C17.0198 21.3802 17.9898 21.0402 18.7698 20.4202L18.6398 23.5802C18.3998 23.7502 17.4598 24.0102 15.9998 24.0102ZM18.9498 16.1902L18.7998 19.6902C18.0498 20.4302 17.0698 20.8702 15.9998 20.8702C14.9298 20.8702 13.9398 20.4302 13.1998 19.6902L13.0498 16.1902C12.9498 13.8702 12.1698 10.4402 11.7598 8.81024C12.5498 8.96023 13.8398 9.07023 15.9998 9.07023C18.1598 9.07023 19.4498 8.96023 20.2398 8.81024C19.8298 10.4402 19.0398 13.8702 18.9498 16.1902Z" stroke="none"/>
    </IconBase>
  )
}

export default SpiritsVodka
