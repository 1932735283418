import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function ChevronRight({ className }: Props) {
  return (
    <IconBase name="chevron-right" w={24} className={className}>
      <path d="M8 4L16 12L8 20" strokeWidth="2" fill="none"/>
    </IconBase>
  )
}

export default ChevronRight
