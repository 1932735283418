import React from 'react'
import { Minus, Add } from 'components/atoms/Icons'

interface Props {
  value?: number
  step?: number
  size?: 'sm' | 'md'
  inputDisabled?: boolean
  className?: string
  onValueChange?: (newValue: number, action: string) => void
  error?: boolean
}

const QuantityHandler = (props: Props) => {
  const {
    value = 1,
    step = 1,
    size = 'md',
    inputDisabled = false,
    onValueChange = () => {},
    className = '',
    error = false,
  } = props

  return (
    <div
      style={{ zIndex: 99 }}
      className={`quantity-handler quantity-handler--${size}${className ? ` ${className}` : ''}${ error ? "  quantity-handler--error" : ""}`}
    >
      <button
        type="button"
        className="quantity-handler__button quantity-handler__button--minus"
        onClick={() => onValueChange(value - step, 'remove')}
      >
        <Minus />
      </button>
      <input
        className="quantity-handler__input"
        type="number"
        value={value}
        disabled={inputDisabled}
        onChange={(e) => onValueChange(Number.parseInt(e.target.value, 10), 'set')}
      />
      <button
        type="button"
        className={`quantity-handler__button quantity-handler__button--plus${ error ? "  quantity-handler__button--error" : ""}`}
        onClick={() => onValueChange(value + step, 'add')}
        disabled={error}
      >
        <Add />
      </button>
    </div>
  )
}

export default QuantityHandler
