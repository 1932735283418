import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function BlogShape1({ className }: Props) {
  return (
    <IconBase name="blog-shape1" w={70} className={className}>
      <path d="M62.665 12.5855C48.5249 -0.989596 4.60393 7.77153 1.04087 26.4793C-2.5222 45.1872 12.1562 57.4602 37.6867 63.7824C63.2172 70.1045 79.9871 29.2153 62.665 12.5855Z" stroke="none"/>
    </IconBase>
  )
}

export default BlogShape1
