import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Link from 'next/link'
import { useTrans } from 'hooks'
import { getIconAbbinamentiByChiave, getIconByChiave } from 'utils/icon'
import {
  ArrowRight,
  ColoreVino,
  ColoreVino2,
  ColoreVino3,
  Close,
  Profile,
  ArrowLeft,
} from 'components/atoms/Icons'
import { Button, WContainer } from 'components/atoms'

import { ArticoloCard, BoxMarketingNavigation } from 'components/molecules'

interface SubItemProps {
  item: any
  closeNavigation: () => void
}

interface ItemProps {
  item: any
  main?: boolean
  open?: boolean
  setOpen?: (v: boolean) => void
  closeNavMobile?: () => void
}

interface Props {
  navMobileOpen?: boolean
  setNavMobileOpen?: (v: boolean) => void
  className?: string
}

const NavigationItem = (props: ItemProps) => {
  const {
    item = null,
    main = false,
    open = false,
    setOpen = () => {},
    closeNavMobile = () => {},
  } = props

  const t = useTrans()
  const [hover, setHover] = useState(false)

  const closeNavigation = () => {
    setHover(false)
    closeNavMobile()
  }

  return (
    <li
      className={`navigation-item${main ? ' navigation-item--main' : ''}${
        open ? ` navigation-item--open` : ''
      }${hover ? ` navigation-item--hover` : ''}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {item.url ? (
        <div className="navigation-item__head">
          <Link legacyBehavior href={item.url}>
            <a className="navigation-item__link" onClick={closeNavigation}>
              <span>{item.name}</span>
            </a>
          </Link>

          {item?.children?.edges.length > 0 && (
            <button
              type="button"
              onClick={() => setOpen(true)}
              className="navigation-item__arrow-mobile"
            >
              <ArrowRight />
            </button>
          )}
        </div>
      ) : item.children?.edges.length > 0 ? (
        <div
          className="navigation-item__head navigation-item__head--nolink"
          onClick={() => setOpen(true)}
        >
          <span>{item.name}</span>
          <span className="navigation-item__arrow-mobile">
            <ArrowRight />
          </span>
        </div>
      ) : (
        <></>
      )}
      {item?.children?.edges.length > 0 && (
        <div className="navigation-item__dropdown">
          <div className="navigation-item__dropdown__head">
            <button
              type="button"
              onClick={() => setOpen(false)}
              className="navigation-item__dropdown__back"
            >
              <ArrowLeft />
            </button>

            <span className="navigation-item__dropdown__title">{item.name}</span>

            <button
              type="button"
              onClick={closeNavigation}
              className="navigation-item__dropdown__close"
            >
              <Close />
            </button>
          </div>
          <div className="navigation-item__dropdown__content">
            <WContainer>
              <ul
                className={`navigation-item__dropdown__blocks${
                  item.key ? ` navigation-item__dropdown__blocks--${item.key}` : ''
                }`}
              >
                {item.key === 'offerte' || item.key === 'idee-regalo'
                  ? item?.children?.edges.map((box) => (
                      <li
                        className="navigation__dropdown__block"
                        key={`navigation__dropdown__block__${box.node.id}`}
                      >
                        <BoxMarketingNavigation
                          imageUrl={box.node.image}
                          imageMobileUrl={box.node.imageMobile}
                          title={box.node.name}
                          description={box.node.description}
                          url={box.node.url}
                          variant={box.node.stile ? box.node.stile : 'primary'}
                          textCta={box.node.textCta}
                          onClick={closeNavigation}
                        />
                      </li>
                    ))
                  : item?.children?.edges.map((item2) => (
                      <li
                        key={`navigation-item__dropdown__block__${item2.node.id}`}
                        className={`navigation-item__dropdown__block${
                          item2.node.key
                            ? ` navigation-item__dropdown__block--${item2.node.key}`
                            : ''
                        }${
                          item2.node.stile
                            ? ` navigation-item__dropdown__block--${item2.node.stile}`
                            : ''
                        }`}
                      >
                        {item2.node.name && (
                          <span className="navigation-item__dropdown__title">
                            {item2.node.name}
                          </span>
                        )}
                        <ul className="navigation-item__dropdown__list">
                          {item2?.node?.children?.edges.map((item3, key3) => (
                            <NavigationSubItem
                              item={item3.node}
                              key={`navigation__subitem__${key3}`}
                              closeNavigation={closeNavigation}
                            />
                          ))}
                          {item2.node.url && (
                            <li className="navigation-item__dropdown__all">
                              <Button
                                label={t('Vedi tutti')}
                                variant="ghost"
                                href={item2.node.url}
                                size="sm"
                                onClick={closeNavigation}
                              />
                            </li>
                          )}
                        </ul>
                      </li>
                    ))}
              </ul>
            </WContainer>
          </div>
        </div>
      )}
    </li>
  )
}

const NavigationSubItem = (props: SubItemProps) => {
  const { item = null, closeNavigation = () => {} } = props

  const stileParts = item.stile ? item.stile.split('_') : []

  return (
    <li className="navigation-subitem">
      {item.stile === 'articolo' && item.newsCollegata ? (
        <ArticoloCard variant="navigation" articolo={item.newsCollegata} />
      ) : item.stile === 'boxmarketing' || item.stile === 'boxmarketingdark' ? (
        <BoxMarketingNavigation
          title={item.name}
          description={item.description}
          url={item.url}
          imageUrl={item.image}
          imageMobileUrl={item.imageMobile}
          textCta={item.textCta}
          onClick={() => closeNavigation()}
          colorVariant={item.stile === 'boxmarketingdark' ? 'dark' : ''}
        />
      ) : (
        item.url && (
          <Link legacyBehavior href={item.url}>
            <a
              className={`navigation-subitem__link${
                stileParts.length === 2
                  ? ` navigation-subitem__link--${stileParts[0]}`
                  : ' navigation-subitem__link--simple'
              }`}
              onClick={() => closeNavigation()}
            >
              {stileParts.length === 2 && (
                <span
                  className={`navigation-subitem__icon navigation-subitem__icon--${stileParts[1]}`}
                >
                  {stileParts[0] === 'colore' && stileParts[1].split('-').length === 1 && (
                    <ColoreVino />
                  )}
                  {stileParts[0] === 'colore' && stileParts[1].split('-').length === 2 && (
                    <ColoreVino2 />
                  )}
                  {stileParts[0] === 'colore' && stileParts[1].split('-').length === 3 && (
                    <ColoreVino3 />
                  )}
                  {getIconAbbinamentiByChiave(stileParts[1]) || getIconByChiave(stileParts[1])}
                </span>
              )}

              {getIconByChiave(item.stile) && (
                <span
                  className={`navigation-subitem__icon navigation-subitem__icon--${item.stile}`}
                >
                  {getIconByChiave(item.stile)}
                </span>
              )}

              <span>{item.name}</span>
            </a>
          </Link>
        )
      )}
    </li>
  )
}

const Navigation = (props: Props) => {
  const { navMobileOpen = false, setNavMobileOpen = () => {}, className = '' } = props

  const t = useTrans()
  const [openChild, setOpenChild] = useState(null)

  const { menu } = useSelector((state) => state.menu)

  useEffect(() => {
    if (!navMobileOpen) setOpenChild(null)
    if (navMobileOpen) document.body.classList.add('w-noscroll')
    else document.body.classList.remove('w-noscroll')
  }, [navMobileOpen])

  return (
    <>
      {navMobileOpen && (
        <div className="navigation-overlay" onClick={() => setNavMobileOpen(false)} />
      )}
      <nav
        className={`navigation${navMobileOpen ? ' navigation--mobile-open' : ''} ${
          className ? ` ${className}` : ''
        }`}
      >
        <button type="button" onClick={() => setNavMobileOpen(false)} className="navigation__close">
          <Close />
        </button>
        <div className="navigation__content">
          <WContainer>
            <ul className="navigation__main">
              {menu?.edges
                .filter((item) => item.node.key === 'main')
                .map((item) =>
                  item.node.children.edges.map((item2) => (
                    <NavigationItem
                      main
                      item={item2.node}
                      open={openChild === item2.node.pk}
                      setOpen={(v) => setOpenChild(v ? item2.node.pk : null)}
                      key={`navigation-main__item__${item2.node.pk}`}
                      closeNavMobile={() => setNavMobileOpen(false)}
                    />
                  ))
                )}
            </ul>
            <ul className="navigation__secondary">
              {menu?.edges
                .filter((item) => item.node.key === 'menu_top_header')
                .map((item) =>
                  item.node.children.edges.map((item2) => (
                    <NavigationItem
                      item={item2.node}
                      open={openChild === item2.node.pk}
                      setOpen={(v) => setOpenChild(v ? item2.node.pk : null)}
                      key={`navigation-secondary__item__${item2.node.pk}`}
                      closeNavMobile={() => setNavMobileOpen(false)}
                    />
                  ))
                )}
            </ul>
            <Link legacyBehavior href="/account">
              <a className="navigation__login" onClick={() => setNavMobileOpen(false)}>
                <span className="navigation__login__icon">
                  <Profile />
                </span>
                <span className="navigation__login__label">{t('Accedi')}</span>
              </a>
            </Link>
          </WContainer>
        </div>
      </nav>
    </>
  )
}

export default Navigation
