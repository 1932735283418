export default function reducer(
  state = {
    fetching: true,
    menu: null,
  },
  action
) {
  switch (action.type) {
    case 'SET_MENU': {
      return {
        ...state,
        fetching: false,
        menu: action.payload.data?.menuitems,
      }
    }

    default: {
      return state
    }
  }
}