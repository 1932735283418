//export {}
import axios from 'axios'


const ENV_API_HOST  = process.env.API_HOST

export const API_HOST = ENV_API_HOST
export const API_PREFIX = '/d/api/'
export const API_FULL_PATH = `${API_HOST}${API_PREFIX}`

export const GRAPHENE_HOST = API_HOST
export const GRAPHENE_PREFIX = '/d/graphql/'
export const GRAPHENE_FULL_PATH = `${GRAPHENE_HOST}${GRAPHENE_PREFIX}`

export const graphene = axios.create({
  baseURL: GRAPHENE_FULL_PATH,
  withCredentials: true,
})

export const api = axios.create({
  baseURL: API_FULL_PATH,
  withCredentials: true,
})

export const isEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

export function getCurrentDate() {
  const today = new Date()
  const dd = String(today.getDate()).padStart(2, '0')
  const mm = String(today.getMonth() + 1).padStart(2, '0')
  const yyyy = today.getFullYear()

  return mm + '/' + dd + '/' + yyyy
}

export function getFormattedDate(dateString) {
  // from "dd/mm/yyyy" to "mm/dd/yyyy"

  const dateSplitted = dateString.split("/")

  if (dateSplitted.length < 3) return ""

  const date = new Date(dateSplitted[2], dateSplitted[1] - 1, dateSplitted[0])

  const year = date.getFullYear()

  let month = (1 + date.getMonth()).toString()
  month = month.length > 1 ? month : '0' + month

  let day = date.getDate().toString()
  day = day.length > 1 ? day : '0' + day
  
  return month + '/' + day + '/' + year
}

export const RES_SM_MIN = 768
export const GOOGLE_MAPS_KEY = 'AIzaSyCJ6fnLVvyf6gZMNfEWdP8EMs8vtlMETcw'
export const GOOGLE_MAPS_URL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${GOOGLE_MAPS_KEY}`