import { useNode } from '@craftjs/core'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'

import { defaultProps, dynamicProps } from './index'
import { getAllPaths, getDynamicValue, setDynamicValue } from '../../utils/dynamic'
import * as icons from 'components/atoms/Icons'
import React, { useEffect, useState } from 'react'
//import { useQuery, gql } from '@apollo/client'
//import { initializeApollo } from 'utils/apollo'
//import { PAGINE } from 'utils/queries'

const typeOptions = ['primary', 'secondary', 'ghost']
const sizeOptions = ['sm', 'md']
const iconPosOptions = ['left', 'right']
const alignOptions = ['left', 'center', 'right', 'justify']

export const ButtonSettings = (props) => {
  const { customs } = useNode((node) => ({
    customs: node.data.custom,
  }))

  // const client = initializeApollo(null,null,null,locale)

  const [urlOpts, setUrlOpts] = useState()

  useEffect(() => {
    //const { data } = await client.query({ query: PAGINE })
    const data = null
    if (data) {
      const pagine = data.pagine.edges
      const onlyDetailPagine = pagine.filter((paginaNode) => {
        return paginaNode.node.tipologia === 'PAGE_APP'
      })
      const finalUrlOpts = onlyDetailPagine.map((paginaNode) =>
        paginaNode.node.path === '' ? '/' : paginaNode.node.path
      )
      setUrlOpts(finalUrlOpts)
    }
  }, [])

  return (
    <>
      <ToolbarSection title="Style" initOpen>
        <ToolbarItem
          propKey="block"
          propSubKey="btype"
          type="select"
          label="Type"
          values={typeOptions}
          dynamic={dynamicProps.includes('btype') && customs.dynamicData}
          dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
          defaultValue={defaultProps['btype']}
        />
        <ToolbarItem
          propKey="block"
          propSubKey="size"
          type="select"
          label="Size"
          values={sizeOptions}
          dynamic={dynamicProps.includes('size') && customs.dynamicData}
          dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
          defaultValue={defaultProps['size']}
        />
        <ToolbarItem
          propKey="block"
          propSubKey="iconName"
          type="select"
          label="Icon"
          values={Object.keys(icons)}
          nullable={true}
          dynamic={dynamicProps.includes('iconName') && customs.dynamicData}
          dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
          defaultValue={defaultProps['iconName']}
        />
        <ToolbarItem
          propKey="block"
          propSubKey="iconPos"
          type="select"
          label="Icon Position"
          values={iconPosOptions}
          dynamic={dynamicProps.includes('iconPos') && customs.dynamicData}
          dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
          defaultValue={defaultProps['iconPos']}
        />
        <ToolbarItem
          propKey="block"
          propSubKey="align"
          type="select"
          label="Alignment"
          values={alignOptions}
          dynamic={dynamicProps.includes('align') && customs.dynamicData}
          dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
          defaultValue={defaultProps['align']}
        />
      </ToolbarSection>
      {!props.floating && (
        <ToolbarSection title="Settings">
          <ToolbarItem
            propKey="block"
            propSubKey="text"
            type="text"
            label="Label"
            dynamic={dynamicProps.includes('text') && customs.dynamicData}
            dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
            defaultValue={defaultProps['text']}
          />
          <ToolbarItem
            propKey="block"
            propSubKey="url"
            type="text"
            label="URL"
            dynamic={dynamicProps.includes('url') && customs.dynamicData}
            dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
            defaultValue={defaultProps['url']}
          />
          {urlOpts && (
            <ToolbarItem
              propKey="block"
              propSubKey="url"
              type="select"
              label="URL"
              values={urlOpts}
              nullable
              defaultValue={defaultProps['url']}
            />
          )}
          <ToolbarItem
            propKey="block"
            propSubKey="openNewTab"
            type="switch"
            label="Open in new tab"
            dynamic={dynamicProps.includes('openNewTab') && customs.dynamicData}
            dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
            defaultValue={defaultProps['openNewTab']}
          />
        </ToolbarSection>
      )}
    </>
  )
}

export const ButtonFloatingSettings = () => React.createElement(ButtonSettings, { floating: true })
