import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function ArrowDown({ className }: Props) {
  return (
    <IconBase name="arrow-down" w={24} className={className}>
      <line x1="11.5" y1="4" x2="11.5" y2="20" strokeWidth="2" fill="none"/>
      <path d="M19 12.5L11.5 20L4 12.5" strokeWidth="2" fill="none"/>
    </IconBase>
  )
}

export default ArrowDown
