import React from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { useTrans } from 'hooks'
import { getFormFieldStatus } from 'utils/safe'
import { useUpdateUtenteMutation } from 'gql/graphql'
import { FormField, FormInput, FormLayout } from 'components/atoms'
import { MyAccountInlineForm } from 'components/molecules'

const MyAccountFormNome = ({ onCancel, utente }) => {
  const t = useTrans()
  const [updateUtente] = useUpdateUtenteMutation()

  async function handleSubmit(values) {
    await updateUtente({
      variables: {
        input: {
          nome: values.nome,
          cognome: values.cognome,
        },
      },
      refetchQueries: ['Me'],
    })

    onCancel()
  }

  return (
    <div className="myaccount-form-nome">
      <Formik
        validateOnBlur
        validateOnChange
        validationSchema={yup.object().shape({
          nome: yup.string().required(t('Campo obbligatorio')),
          cognome: yup.string().required(t('Campo obbligatorio')),
        })}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
        initialValues={{ nome: utente.nome, cognome: utente.cognome }}
      >
        {(props) => (
          <Form>
            <MyAccountInlineForm onCancel={onCancel}>
              <FormLayout>
                <FormField
                  label={t('Nome')}
                  status={getFormFieldStatus(props, 'nome')}
                  errorMessage={props?.errors?.nome}
                >
                  <FormInput
                    onBlur={props.handleBlur('nome')}
                    placeholder="Nome"
                    value={props.values?.nome}
                    onChange={props.handleChange('nome')}
                  />
                </FormField>
                <FormField
                  label={t('Cognome')}
                  status={getFormFieldStatus(props, 'cognome')}
                  errorMessage={props?.errors?.cognome}
                >
                  <FormInput
                    onBlur={props.handleBlur('cognome')}
                    placeholder="Cognome"
                    value={props.values.cognome}
                    onChange={props.handleChange('cognome')}
                  />
                </FormField>
              </FormLayout>
            </MyAccountInlineForm>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default MyAccountFormNome
