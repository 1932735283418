import React, { useState } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useResetPasswordMutation } from 'gql/graphql'
import { useRouter } from 'next/router'
import { useTrans } from 'hooks'
import { getFormFieldStatus } from 'utils/safe'
import { Alert } from 'components/atoms/Icons'
import { Button, FormField, FormInputPassword, FormLayout, NotificationBar } from 'components/atoms'

const validationSchema = (t: any) =>
  yup.object().shape({
    password: yup
      .string()
      .nullable()
      .required(t('Campo obbligatorio'))
      .min(8, t('Minimo 8 Caratteri')),
  })

const FormResetPassword = ({ token }: { token: string }) => {
  const t = useTrans()
  const router = useRouter()
  const [error, setError] = useState(null)
  const [resetPassword, { loading }] = useResetPasswordMutation()
  return (
    <div className="form-reset-password">
      <Formik
        validateOnChange
        validateOnMount={false}
        validationSchema={validationSchema(t)}
        onSubmit={(values) =>
          resetPassword({
            variables: {
              token,
              password: values?.password,
            },
          })
            .then((res) =>
              res?.data?.resetPassword?.status
                ? router.push('/reset-password/thank-you')
                : setError(t('Il link per il cambio password non è più valido.'))
            )
            .catch((err) => setError(t('Il link per il cambio password non è più valido.')))
        }
        initialValues={{
          password: '',
        }}
      >
        {(props) => (
          <>
            {error && (
              <NotificationBar
                label={error}
                variant="alert"
                icon={<Alert />}
                className="form-reset-password__error"
              />
            )}
            <FormLayout>
              <FormField
                label={`${t('Nuova password')}*`}
                layout="full"
                status={getFormFieldStatus(props, 'password')}
                errorMessage={props?.errors?.password}
              >
                {/* Non previsto in origine dalla grafica 
                helper={{
                  id: 'password-helper',
                  label: (
                    <>
                      La password deve contenere:
                      <br /> - minimo 8 caratteri
                      <br /> - almeno una lettera maiuscola
                    </>
                  ),
                  pos: 'right',
                }} */}
                <FormInputPassword
                  togglePassword
                  onBlur={props.handleBlur('password')}
                  placeholder={t('Inserisci nuova password')}
                  value={props.values?.password}
                  onChange={props.handleChange('password')}
                />
              </FormField>
            </FormLayout>
            <Button
              loading={loading}
              type="submit"
              disabled={!props.isValid}
              onClick={() => props.handleSubmit()}
              variant="primary"
              label={t('Imposta la Password')}
              className="form-reset-password__submit"
            />
          </>
        )}
      </Formik>
    </div>
  )
}

export default FormResetPassword
