import React from 'react'
import { Info } from 'components/atoms/Icons'

interface Props {
  message?: string
  visible?: boolean
  className?: string
}

const FormError = (props: Props) => {
  const { message = '', visible = false, className = '' } = props

  return visible && message ? (
    <div className={'form-error' + (className ? ' ' + className : '')}>
      <Info className="form-error__icon" />
      {message}
    </div>
  ) : (
    <></>
  )
}

export default FormError
