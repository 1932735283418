/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { ApolloClient, InMemoryCache, createHttpLink, gql } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import isEqual from 'lodash/isEqual'
import { useMemo } from 'react'
import merge from 'deepmerge'
import Router from 'next/router'

const GQL_HOST = process.env.GQL_HOST

let apolloClient
let prevToken

export default function createApolloClient(
  initialState = null,
  jwt = null,
  cookies = null,
  locale = null
) {
  // The `ctx` (NextPageContext) will only be present on the server.
  // use it to extract auth headers (ctx.req) or similar.

  // Log any GraphQL errors or network error that occurred
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      )
    if (networkError) console.log(`[Network error]: ${networkError}`)
  })

  const link = createHttpLink({
    credentials: 'include', // 'same-origin'
    headers: {
      'Authorization': `Bearer ${jwt || ''}`,
      'Content-Language': locale,
    },
    uri: GQL_HOST, // Server URL (must be absolute)
  })

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',

    link: errorLink.concat(link),
    cache: new InMemoryCache(),
  })
}

export function initializeApollo(initialState = null, jwt = null, cookies = null, locale = null) {
  const _apolloClient =
    jwt !== prevToken
      ? createApolloClient(initialState, jwt, cookies, locale)
      : apolloClient ?? createApolloClient(initialState, jwt, cookies, locale)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  prevToken = jwt

  return _apolloClient
}

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

export function useApollo(pageProps, cookies = null, locale = null) {
  const jwt = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('token')) : null
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  const store = useMemo(
    () => initializeApollo(state, jwt, cookies, locale),
    [state, jwt, cookies, locale]
  )
  return store
}

export async function queryPagination(apollo_client, query_name, base_query) {
  let data = [],
    cursor = '',
    next_page = true

  while (next_page) {
    const { data: query_data } = await apollo_client.query({
      query: gql`
        ${base_query.replace('__cursor__', cursor)}
      `,
    })

    if (!query_data) return false

    const query_output = query_data[query_name]

    next_page = query_output.pageInfo.hasNextPage
    cursor = query_output.pageInfo.endCursor

    data = data.concat(query_output.edges.map((x) => x.node))
  }

  return data
}
