import React, { useContext, useEffect, useState } from 'react'
import { useTrans } from 'hooks'
import { Button, Modal } from 'components/atoms'
import { useRouter } from 'next/router'
import { CartContext } from 'utils/context'
import { useFindMenu } from 'hooks'

const PromoLinkModal = () => {
  const t = useTrans()
  const router = useRouter()

  const { menuItem: menuOfferte } = useFindMenu('offerte-del-momento')

  const [open, setOpen] = useState(false)
  const { setPromoCode } = useContext(CartContext)
  const [promoAttivata, setPromoAttivata] = useState(null)

  useEffect(() => {
    const promoCode = router.query['promo']
    if (promoCode) {
      setPromoCode(promoCode).then((data) => {
        setPromoAttivata(data.promozione)
        setOpen(true)
      })
    } else {
      setOpen(false)
    }
  }, [router.query])

  return (
    <Modal open={open} setOpen={setOpen} closeButton className="promo_code__modal">
      <p className="promo_code__modal__title">{t('Promozioni')}</p>
      {promoAttivata && promoAttivata.descrizionePopup ? (
        <div
          className="promo_code__modal__description"
          dangerouslySetInnerHTML={{ __html: promoAttivata.descrizionePopup }}
        ></div>
      ) : (
        <p className="promo_code__modal__description">
          {promoAttivata ? (
            t(
              'La promozione selezionata è attiva: la vedrai una volta che avrai inserito i tuoi prodotti nel carrello.'
            )
          ) : (
            <>
              {t('Siamo spiacenti, la promozione selezionata non è attiva al momento.')}
              {menuOfferte && menuOfferte.url && (
                <Button
                  variant="ghost"
                  label={t('Scopri tutte le promozioni attive!')}
                  href={menuOfferte.url}
                />
              )}
            </>
          )}
        </p>
      )}
    </Modal>
  )
}

export default PromoLinkModal
