import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ArrowLeft } from 'components/atoms/Icons'
import { WContainer, Breadcrumbs, MetaSeo, Button } from 'components/atoms'
import { PreFooter, Toolbar } from 'components/molecules'
import { Header, Footer, HeaderMinimal } from 'components/organisms'
import { Props as ButtonProps } from 'components/atoms/Button/Button'

interface Props {
  pagina: any
  className?: string
  containerVariant?: '' | 'xs' | 'sm' | 'md' | 'none' | 'fluid'
  headContainerVariant?: '' | 'xs' | 'sm' | 'md' | 'none' | 'fluid'
  childrenContainerVariant?: '' | 'xs' | 'sm' | 'md' | 'none' | 'fluid'
  backButton?: ButtonProps
  hideBreadcrumbs?: boolean
  hideHead?: boolean
  hideDescrizione?: boolean
  hidePrefooter?: boolean
  headerType?: '' | 'minimal' | 'none'
  footerType?: '' | 'none'
  prefooterVariant?: '' | 'white'
  contentStyle?: 'basic' | 'fancy'
  topBg?: 'left' | 'center'
  customContent?: any // al posto di titolo e descrizione - potenzialmente anche vuoto
  children?: any
  item?: any
}

const Page = (props: Props) => {
  const {
    pagina = {},
    className = '',
    containerVariant = '',
    headContainerVariant = '',
    childrenContainerVariant = '',
    backButton = null,
    hideBreadcrumbs = false,
    hideHead = false,
    hideDescrizione = false,
    hidePrefooter = false,
    headerType = '',
    footerType = '',
    prefooterVariant = '',
    contentStyle = 'fancy',
    topBg = null,
    customContent = false,
    children = null,
    item = {},
  } = props

  const [windowScroll, setWindowScroll] = useState(0)

  const handleScroll = () => {
    if (window.scrollY > 0) document.body.classList.add('w-scrolled')
    else document.body.classList.remove('w-scrolled')
    if (window.pageYOffset < windowScroll) document.body.classList.add('w-scrollup')
    else document.body.classList.remove('w-scrollup')
    setWindowScroll(window.pageYOffset)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [windowScroll])

  const { menu } = useSelector((state: any) => state.menu)
  const preHeaderItem = menu?.edges?.filter(({ node }) => node?.key === 'preheader')?.[0]?.node

  return (
    <>
      <Toolbar page={pagina} item={item} />
      <div
        className={`page page--${contentStyle}${topBg ? ` page--top-bg-${topBg}` : ''}${
          headerType === '' ? ' page--header' : ''
        }${headerType === 'minimal' ? ' page--header-minimal' : ''}${
          !!preHeaderItem && headerType === '' ? ' page--preheader' : ''
        }${className ? ` ${className}` : ''}`}
      >
        {pagina?.seo && <MetaSeo seo={pagina.seo} />}
        {headerType === '' && (
          <Header
            preHeaderLabel={preHeaderItem ? preHeaderItem.name : null}
            className={preHeaderItem ? preHeaderItem.stile : null}
          />
        )}
        {headerType === 'minimal' && <HeaderMinimal backButton={backButton} />}
        <div className="page__content">
          {!hideHead && (
            <div className="page__head-box">
              <WContainer variant={headContainerVariant || containerVariant}>
                <div className="page__head">
                  {headerType !== 'minimal' && backButton && (
                    <Button
                      {...{
                        variant: 'ghost',
                        colorVariant: 'black',
                        icon: <ArrowLeft />,
                        iconPos: 'left',
                        ...backButton,
                        className: `page__back${
                          backButton.className ? ` ${backButton.className}` : ''
                        }`,
                      }}
                    />
                  )}
                  {!hideBreadcrumbs && !!pagina?.breadcrumbs && (
                    <Breadcrumbs breadcrumbs={pagina.breadcrumbs} />
                  )}
                  {customContent ? (
                    customContent
                  ) : (
                    <>
                      {!!pagina?.titolo && <h1 className="page__title">{pagina?.titolo}</h1>}
                      {!hideDescrizione && !!pagina?.descrizione && (
                        <div
                          className="page__description"
                          dangerouslySetInnerHTML={{ __html: pagina?.descrizione }}
                        />
                      )}
                    </>
                  )}
                </div>
              </WContainer>
            </div>
          )}
          <WContainer variant={childrenContainerVariant || containerVariant} id="page__body">
            {children}
          </WContainer>
        </div>
        {!hidePrefooter && <PreFooter variant={prefooterVariant} />}
        {footerType === '' && <Footer />}
      </div>
    </>
  )
}

export default Page
