import React from 'react'
import { useTrans } from 'hooks'
import { Button } from 'components/atoms'

interface Props {
  icon?: any
  title?: string
  onClick?: () => void
  className?: string
}

const FaqCategoryCard = (props: Props) => {
  const { icon = null, title = '', onClick = () => {}, className = '' } = props

  const t = useTrans()

  return (
    <div className={`faq-category-card ${className ? ` ${className}` : ''}`}>
      <div className="faq-category-card__icon">{icon}</div>
      <p className="faq-category-card__title">{title}</p>
      {!!onClick && (
        <Button
          variant="ghost"
          label={t('Vedi tutto')}
          onClick={onClick}
          className="faq-category-card__cta"
        />
      )}
    </div>
  )
}

export default FaqCategoryCard
