import { useNode } from '@craftjs/core'
import React from 'react'
import { QuoteSettings } from './QuoteSettings'

import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'
//import BoxQuote from 'components/atoms/BoxQuote/BoxQuote'

export const defaultProps = {
  colore: '',
  size: '',
  noQuotes: false,
}

export const dynamicProps = []

export const Quote = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }
    const { colore, size, noQuotes } = blockProps

    const { children } = props

    const _ = props.renderValue

    const {
      id,
      connectors: { connect, drag },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode((state) => ({
      selected: state.events.selected,
      dragged: state.events.dragged,
      hovered: state.events.hovered,
      customs: state.data.custom,
    }))

    return (
      <div ref={(ref) => props.retRef(ref)} className={'quote ' + props.className}>
        <div>{children.props.children[0]}</div>
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

Quote.craft = withCraft({
  name: 'Quote',
  defaultProps: defaultProps,
  settings: QuoteSettings,
})
