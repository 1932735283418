import React from 'react'
import { Button } from 'components/atoms'

interface Props {
  title?: string
  description?: string
  button?: any
  className?: string
}

const ContattiBox = (props: Props) => {
  const { title = '', description = '', button = {}, className = '' } = props

  return (
    <div className={`contatti-box ${className ? ` ${className}` : ''}`}>
      {!!title && <p className="contatti-box__title">{title}</p>}
      {!!description && <p className="contatti-box__description">{description}</p>}
      {!!button && (
        <div className="contatti-box__cta">
          <Button {...button} />
        </div>
      )}
    </div>
  )
}

export default ContattiBox
