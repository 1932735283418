import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Pagamenti2({ className }: Props) {
  return (
    <IconBase name="pagamenti2" w={60} h={61} className={className}>
      <g clipPath="url(#pagamenti-2-clip0)">
      <path d="M45.6375 13.625H19.3875C18.02 13.625 16.7085 14.1682 15.7415 15.1352C14.7745 16.1022 14.2312 17.4137 14.2312 18.7812V20.3375H12.675C11.3171 20.3324 10.0121 20.8632 9.04316 21.8145C8.07426 22.7659 7.51974 24.061 7.5 25.4187V41.7125C7.50496 43.0768 8.0504 44.3835 9.01684 45.3464C9.98329 46.3093 11.292 46.85 12.6562 46.85H38.9062C40.2705 46.85 41.5792 46.3093 42.5456 45.3464C43.5121 44.3835 44.0575 43.0768 44.0625 41.7125V40.1562H45.6375C47.005 40.1562 48.3165 39.613 49.2835 38.646C50.2505 37.679 50.7937 36.3675 50.7937 35V18.7812C50.7937 17.4137 50.2505 16.1022 49.2835 15.1352C48.3165 14.1682 47.005 13.625 45.6375 13.625ZM12.6562 22.2125H38.9062C39.3378 22.2 39.7676 22.2734 40.1706 22.4282C40.5736 22.5831 40.9419 22.8164 41.2541 23.1146C41.5663 23.4128 41.8161 23.7701 41.9892 24.1656C42.1623 24.5611 42.2552 24.9871 42.2625 25.4187V26.9375H9.375V25.4187C9.3946 24.5616 9.74892 23.7461 10.3622 23.1469C10.9754 22.5477 11.7988 22.2123 12.6562 22.2125ZM9.375 27.9313H42.2625V31.6812H9.375V27.9313ZM42.2625 41.7688C42.2625 42.1988 42.1775 42.6245 42.0124 43.0216C41.8472 43.4187 41.6053 43.7791 41.3003 44.0823C40.9954 44.3855 40.6335 44.6255 40.2355 44.7883C39.8375 44.9512 39.4113 45.0337 38.9812 45.0312H12.7312C11.8642 45.0313 11.0325 44.6881 10.4177 44.0768C9.80284 43.4655 9.45494 42.6357 9.44999 41.7688V32.6938H42.2625V41.7688ZM48.975 35.0562C48.9725 35.4912 48.8841 35.9214 48.7149 36.3221C48.5456 36.7227 48.2987 37.086 47.9886 37.3909C47.6784 37.6959 47.311 37.9365 46.9075 38.0989C46.504 38.2614 46.0724 38.3424 45.6375 38.3375H44.1375V25.4187C44.1325 24.0545 43.5871 22.7478 42.6206 21.7848C41.6542 20.8219 40.3455 20.2812 38.9812 20.2812H16.0875V18.725C16.1023 17.8613 16.4571 17.0383 17.0749 16.4345C17.6927 15.8307 18.5237 15.4949 19.3875 15.5H45.6375C46.5077 15.5 47.3423 15.8457 47.9577 16.4611C48.573 17.0764 48.9187 17.911 48.9187 18.7812L48.975 35.0562Z" stroke="none"/>
      <path d="M21.6002 37.0813H13.2002C13.0759 37.0813 12.9567 37.1306 12.8687 37.2185C12.7808 37.3065 12.7314 37.4257 12.7314 37.55C12.7314 37.6743 12.7808 37.7936 12.8687 37.8815C12.9567 37.9694 13.0759 38.0188 13.2002 38.0188H21.6002C21.7245 38.0188 21.8437 37.9694 21.9317 37.8815C22.0196 37.7936 22.069 37.6743 22.069 37.55C22.069 37.4257 22.0196 37.3065 21.9317 37.2185C21.8437 37.1306 21.7245 37.0813 21.6002 37.0813Z" stroke="none"/>
      <path d="M17.1377 40.4187H13.2002C13.0759 40.4187 12.9567 40.4681 12.8687 40.556C12.7808 40.6439 12.7314 40.7632 12.7314 40.8875C12.7314 41.0118 12.7808 41.131 12.8687 41.219C12.9567 41.3069 13.0759 41.3562 13.2002 41.3562H17.1377C17.262 41.3562 17.3812 41.3069 17.4692 41.219C17.5571 41.131 17.6065 41.0118 17.6065 40.8875C17.6065 40.7632 17.5571 40.6439 17.4692 40.556C17.3812 40.4681 17.262 40.4187 17.1377 40.4187Z" stroke="none"/>
      </g>
      <defs>
      <clipPath id="pagamenti-2-clip0">
      <rect width="43.35" height="33.3" transform="translate(7.5 13.625)" stroke="none"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default Pagamenti2
