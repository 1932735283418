import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function FiltriColore({ className }: Props) {
  return (
    <IconBase name="filtri-colore" w={50} className={className}>
      <path d="M33.1406 42.8437C28.4375 42.8437 26.2031 38.8593 25.7813 38.0312V28.625C26.0156 28.5625 26.2344 28.5 26.4531 28.3906C27.9063 27.6875 30.2656 26.2343 33.2188 23.2812C38.1875 18.3125 32.6563 8.17184 32.4219 7.73434C32.2813 7.48434 32.0156 7.32809 31.7344 7.32809H18.25C17.9688 7.32809 17.7031 7.48434 17.5625 7.73434C17.3281 8.15622 11.7969 18.3125 16.7656 23.2812C19.7188 26.2343 22.0781 27.6875 23.5313 28.3906C23.75 28.5 23.9688 28.5625 24.2031 28.625V37.9843C23.6719 38.8437 21 42.8281 16.8438 42.8281C16.4063 42.8281 16.0625 43.1718 16.0625 43.6093C16.0625 44.0468 16.4063 44.3906 16.8438 44.3906H33.1094C33.5469 44.3906 33.8906 44.0468 33.8906 43.6093C33.8906 43.1718 33.5625 42.8437 33.1406 42.8437ZM17.8906 22.1718C14.25 18.5156 17.8125 10.75 18.7344 8.89059H31.2656C32.2031 10.75 35.7656 18.5156 32.1094 22.1718C29.1563 25.125 26.875 26.4531 25.7656 26.9843C25.2813 27.2187 24.7031 27.2187 24.2188 26.9843C23.125 26.4531 20.8281 25.125 17.8906 22.1718ZM21.75 42.8437C23.2188 41.8593 24.2969 40.6093 24.9375 39.7187C25.5313 40.625 26.5156 41.8593 27.9688 42.8437H21.75Z" stroke="none"/>
      <path d="M25 26.1562C25.1094 26.1562 25.2188 26.125 25.3282 26.0781C26.4688 25.5312 29.4375 23.6093 31.4063 21.4531C32.2969 20.4843 32.7344 19.1406 32.6875 17.4687C32.6875 17.25 32.5157 17.0937 32.2969 17.0937H17.6875C17.4688 17.0937 17.2969 17.2656 17.2969 17.4687C17.2657 19.1875 17.6875 20.5312 18.5938 21.4687C20.8282 23.7812 23.4532 25.5 24.6719 26.0937C24.7813 26.125 24.8907 26.1562 25 26.1562ZM18.0938 17.8593H31.9219C31.8907 19.1406 31.5313 20.1718 30.8438 20.9218C28.9688 22.9687 26.0782 24.8593 25.0157 25.375C24.2032 24.9843 21.5938 23.4218 19.1719 20.9218C18.4844 20.2031 18.125 19.1875 18.0938 17.8593Z" stroke="none"/>
    </IconBase>
  )
}

export default FiltriColore
