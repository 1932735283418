import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Tumblr({ className }: Props) {
  return (
    <IconBase name="tumblr" w={24} className={className}>
      <mask id="tumblr-mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="4" y="0" width="16" height="24">
      <path d="M4 0H19.3872V23.9625H4V0Z" stroke="none"/>
      </mask>
      <g mask="url(#tumblr-mask0)">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.5692 11.5285C13.5692 11.2286 13.8123 10.9854 14.1123 10.9854H18.8441C19.144 10.9854 19.3872 10.7422 19.3872 10.4422V6.82046C19.3872 6.52053 19.144 6.27729 18.8441 6.27729H14.1123C13.8123 6.27729 13.5692 6.0342 13.5692 5.7342V0.54309C13.5692 0.243162 13.326 0 13.026 0H9.59566C9.29566 0 9.0525 0.243162 9.0525 0.54309V1.76795C9.0525 4.25842 7.03356 6.27729 4.54316 6.27729C4.24316 6.27729 4 6.52053 4 6.82046V10.4422C4 10.7422 4.24316 10.9854 4.54316 10.9854H6.21281C6.51274 10.9854 6.7559 11.2286 6.7559 11.5285V17.8679C6.7559 17.8679 6.74362 20.8357 8.16048 22.3257C9.57734 23.8159 11.4095 23.9626 13.2172 23.9626C15.0249 23.9626 18.5426 23.4739 19.2511 22.0326C19.2511 22.0326 19.3341 21.8548 19.3341 21.5035V17.6011C19.3341 17.6011 19.296 17.2024 18.8498 17.2024C18.4036 17.2024 17.6772 18.9589 15.2418 18.9589C13.6852 18.9589 13.575 17.7699 13.5692 17.5163V11.5285Z" stroke="none"/>
      </g>
    </IconBase>
  )
}

export default Tumblr
