import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function BlogShape3({ className }: Props) {
  return (
    <IconBase name="blog-shape3" w={70} className={className}>
      <path d="M21.2052 5.60534C40.2681 1.04087 73.7345 30.8033 67.3514 48.7458C60.9684 66.6883 42.0993 69.8573 16.8753 62.4058C-8.34868 54.9542 -2.14728 11.1969 21.2052 5.60534Z" stroke="none"/>
    </IconBase>
  )
}

export default BlogShape3
