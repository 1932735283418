import Script from 'next/script'

const AmazonPayScript = () => {

    return (
        <>
            <Script
                dangerouslySetInnerHTML={{
                __html: `
                    var amazon_script ='//static-eu.payments-amazon.com/checkout.js';
                    (function(d,t){var f=d.createElement(t),s=d.getElementsByTagName(t)[0];f.async=1;
                    f.src=('https:'==location.protocol?'https:':'http:')+amazon_script;
                    f.setAttribute('charset','utf-8');
                    s.parentNode.insertBefore(f,s)}(document,'script'));

                    `,
                }}
            />
        </>
    )
}

export default AmazonPayScript