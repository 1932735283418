import React from 'react'
import { WImage } from 'components/atoms'

interface Props {
  src?: string
  alt?: string
  title?: string
  classPrefix?: string
  maxWidth: number
  maxHeight: number
  layout?: "fixed" | "fill" | "intrinsic" | "responsive"
  objectFit?: "contain" | "cover" | "fill" | "none" | "scale-down"
  children?: any
}

const ImageBox = (props: Props) => {

  const { 
    src = '', 
    alt = '', 
    title = '', 
    classPrefix = '',
    maxWidth = 0, 
    maxHeight = 0, 
    layout = null,
    objectFit = null,
    children = null,
  } = props

  return (
    <div className={`image-box${classPrefix ? ` ${classPrefix}__image-box` : ''}`}>
      <WImage
        src={src}
        alt={alt}
        title={title}
        classPrefix={classPrefix}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        layout={layout}
        objectFit={objectFit}
      />
      {children}
    </div>
  )
}

export default ImageBox
