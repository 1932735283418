import React from 'react'
import { Info } from 'components/atoms/Icons'
import { FormError, Tooltip } from 'components/atoms'
import { Props as TooltipProps } from 'components/atoms/Tooltip/Tooltip'

interface Props {
  label?: string
  size?: 'md'
  layout?: 'sm' | 'md' | 'lg' | 'full'
  space?: 'none' | 'sm' | 'md' | 'lg' | 'xl'
  status?: '' | 'success' | 'error'
  errorMessage?: any // inserito Any a causa di Formik
  className?: string
  helper?: TooltipProps
  children?: any
}

const FormField = (props: Props) => {
  const {
    label = '',
    size = 'md',
    layout = 'md',
    space = 'md',
    status = '',
    errorMessage = '',
    className = '',
    helper = null,
    children = null,
  } = props

  const classNames = `form-field
    ${size ? ` form-field--${size}` : ''}
    ${size ? ` form-field--layout-${layout}` : ''}
    ${space ? ` form-field--space-${space}` : ''}
    ${status ? ` form-field--${status}` : ''}
    ${className ? ` ${className}` : ''}`

  return (
    <div className={classNames}>
      {!!(label || helper) && (
        <div className="form-field__head">
          {!!label && <span className="form-field__label">{label}</span>}
          {!!helper && (
            <Tooltip {...helper}>
              <span className="form-field__helper">
                <Info />
              </span>
            </Tooltip>
          )}
        </div>
      )}
      <div className="form-field__children">{children}</div>
      <FormError message={errorMessage} visible={status === 'error'} />
    </div>
  )
}

export default FormField
