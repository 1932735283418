import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Direction({ className }: Props) {
  return (
    <IconBase name="direction" w={24} className={className}>
      <path d="M6 21V13C6 10.2386 8.23858 8 11 8H19" strokeWidth="2" fill="none"/>
      <path d="M15 3L20 8L15 13" strokeWidth="2" fill="none"/>
    </IconBase>
  )
}

export default Direction
