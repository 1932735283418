import React, { useState, useEffect } from 'react'
import { ProdottoCard } from 'components/molecules'
import { useProdottoQuery } from 'gql/graphql'
import ContentLoader from 'react-content-loader'

const Skeleton = (props) => {
  return (
    <ContentLoader
        style={{ 
            marginRight:"16px",  
        }}
        speed={2}
        width={"100%"}
        height={300}
        //viewBox={props.vista2 ? "0 0 300 calc(48% - 4px)" : "0 0 300 calc(32% - 11px)"}
        backgroundColor="#f1f2f4"
        foregroundColor="#e0e2e6"
        {...props}
    >
        <rect
            x="0"
            y="0"
            rx="0"
            ry="0"
            width="100%"
            height="300"
        />
    </ContentLoader>
  )
}

export const ProductCard = ({ slug }) => {

  const { data, loading, error, refetch } = useProdottoQuery({
    variables: {
      slug: slug
    },
  })

  useEffect(() => {
    if(slug){
      refetch({
        slug: slug
      })
    }
  }, [slug])


  return (loading || !data?.prodotto) ?
    <Skeleton />
  : 
    <ProdottoCard prodotto={data?.prodotto} />

}
