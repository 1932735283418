import React from 'react'
import { Edit } from 'components/atoms/Icons'
import { CheckoutOption } from 'components/molecules'

import { useTrans } from 'hooks'

interface Props {
  indirizzo: any
  checked: boolean
  onClick: (e: any) => void
  onEdit: (e: any) => void
  className?: string
}

const CheckoutAddressOption = (props: Props) => {
  const {
    indirizzo = {},
    checked = false,
    onClick = () => {},
    onEdit = () => {},
    className = '',
  } = props

  const t = useTrans()

  return (
    <CheckoutOption
      title={`${indirizzo.nome} ${indirizzo.cognome}`}
      description={
        <>
          {!indirizzo.isFatturazione ? (
            <>
              {indirizzo.indirizzo}
              {indirizzo.numeroCivico ? `,${indirizzo.numeroCivico}` : ''} - {indirizzo.cap} -{' '}
              {indirizzo.citta} {indirizzo.provincia ? `(${indirizzo.provincia})` : ''} -{' '}
              {indirizzo.nazione.isoCode.toUpperCase()}
            </>
          ) : (
            <>
              {indirizzo.indirizzo}
              <br />
              {indirizzo.cap} {indirizzo.citta} -{' '}
              {indirizzo.provincia && `(${indirizzo.provincia})`}
              <br />
              {indirizzo.cf}
              {indirizzo.piva && (
                <>
                  <br />
                  {t('PI')}
                  {': '}
                  {indirizzo.piva}
                </>
              )}
            </>
          )}
        </>
      }
      checked={checked}
      onClick={onClick}
      className={`checkout-address-option${className ? ` ${className}` : ''}`}
      actions={
        <>
          <button type="button" onClick={onEdit} className="checkout-address-option__edit">
            <Edit />
          </button>
        </>
      }
    />
  )
}

export default CheckoutAddressOption
