import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function BoxPacco({ className }: Props) {
  return (
    <IconBase name="box-pacco" w={24} className={className}>
      <g clipPath="url(#box-pacco-clip0)">
      <path d="M7.63635 4.27246L16.0909 9.18155V13.5452" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M11.7273 11.6366L20.4546 6.72754V17.0912L11.7273 22.0003V11.6366Z" strokeWidth="1.5" strokeLinejoin="round" fill="none"/>
      <path d="M11.7273 11.6366L3.00002 6.72754V17.0912L11.7273 22.0003V11.6366Z" strokeWidth="1.5" strokeLinejoin="round" fill="none"/>
      <path d="M3 6.72754L11.8755 1.56988L20.4545 6.72754L11.7271 11.6368L3 6.72754Z" strokeWidth="1.5" strokeLinejoin="round" fill="none"/>
      </g>
      <defs>
      <clipPath id="box-pacco-clip0">
      <rect width="24" height="24" stroke="none"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default BoxPacco
