import React from 'react'
import { useTrans } from 'hooks'
import { ProdottoCardCarrello } from 'components/molecules'

interface Props {
  righeProdotti: any[]
  className?: string
}

const RiepilogoProdotti = (props: Props) => {
  const { righeProdotti = [], className = '' } = props

  const t = useTrans()

  return (
    <div className={`riepilogo-prodotti ${className ? ` ${className}` : ''}`}>
      <p className="riepilogo-prodotti__title">{t('Riepilogo prodotti')}</p>
      <div className="riepilogo-prodotti__list">
        {righeProdotti.map((riga) => (
          <ProdottoCardCarrello prodotto={riga.prodotto} quantita={riga.quantita} omaggio={riga.omaggio} checkout />
        ))}
      </div>
    </div>
  )
}

export default RiepilogoProdotti
