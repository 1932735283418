import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function CaratteristicheAbbinamento({ className }: Props) {
  return (
    <IconBase name="caratteristiche-abbinamento" w={32} className={className}>
      <path d="M27.5798 21.6501H27.3698C27.1098 15.7701 22.3798 11.0401 16.4998 10.7801V10.5901C17.0898 10.3801 17.5198 9.82014 17.5198 9.16014C17.5198 8.32014 16.8398 7.64014 15.9998 7.64014C15.1598 7.64014 14.4798 8.32014 14.4798 9.16014C14.4798 9.82014 14.9098 10.3801 15.4998 10.5901V10.7801C9.61982 11.0301 4.88982 15.7601 4.62982 21.6501H4.41982C3.67982 21.6501 3.06982 22.2501 3.06982 23.0301C3.06982 23.7701 3.66982 24.3801 4.41982 24.3801H27.5798C28.3198 24.3801 28.9298 23.7801 28.9298 23.0001C28.9298 22.2501 28.3198 21.6501 27.5798 21.6501ZM15.9998 8.63014C16.2898 8.63014 16.5198 8.86014 16.5198 9.15014C16.5198 9.44014 16.2898 9.67014 15.9998 9.67014C15.7098 9.67014 15.4798 9.44014 15.4798 9.15014C15.4798 8.86014 15.7098 8.63014 15.9998 8.63014ZM15.9998 11.7501C21.5598 11.7501 26.0998 16.1501 26.3698 21.6401H5.62982C5.89982 16.1501 10.4398 11.7501 15.9998 11.7501ZM27.5798 23.3701H4.41982C4.22982 23.3701 4.06982 23.2101 4.06982 22.9901C4.06982 22.8001 4.22982 22.6401 4.41982 22.6401H27.5798C27.7698 22.6401 27.9298 22.8001 27.9298 23.0201C27.9298 23.2201 27.7698 23.3701 27.5798 23.3701Z" stroke="none"/>
    </IconBase>
  )
}

export default CaratteristicheAbbinamento
