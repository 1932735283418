import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Check({ className }: Props) {
  return (
    <IconBase name="check" w={20} className={className}>
      <path d="M2.91675 9.58335L7.91675 14.5834L17.0834 5.41669" strokeWidth="2" fill="none"/>
    </IconBase>
  )
}

export default Check
