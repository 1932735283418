import React from 'react'

interface Props {
  children?: any
}

const FormLayout = ({ children }: Props) => {
  return <div className="form-layout">{children}</div>
}

export default FormLayout
