import Link from 'next/link'
import React from 'react'
import { useTrans } from 'hooks'

interface Props {
  url: privacy_url
  type: 'privacy'
}

const OptinLabel = (props: Props) => {
  const { type, url } = props
  const t = useTrans()

  const informativa = (
    <Link legacyBehavior href={url}>
      <a className="form-checkbox-privacy__label privacy" target="_blank">
        {t('dell’Informativa Privacy')}
      </a>
    </Link>
  )

  switch (type) {
    case 'privacy':
      return (
        <>
          Con la registrazione al presente format dichiaro di aver preso visione dell’{informativa}
        </>
      )

    default:
      return null
  }
}

export default OptinLabel
