import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function FaqAccount({ className }: Props) {
  return (
    <IconBase name="faq-account" w={32} className={className}>
      <path d="M16.0002 4.9502C9.9102 4.9502 4.9502 9.9102 4.9502 16.0002C4.9502 22.0902 9.9102 27.0502 16.0002 27.0502C22.0902 27.0502 27.0502 22.0902 27.0502 16.0002C27.0502 9.9102 22.0902 4.9502 16.0002 4.9502ZM9.2502 23.4302C9.7902 21.0602 12.4002 18.7002 15.8202 18.7002C19.3502 18.7002 22.0602 21.2702 22.4402 23.7102C20.6902 25.1702 18.4502 26.0502 16.0002 26.0502C13.4002 26.0502 11.0402 25.0502 9.2502 23.4302ZM22.8702 23.3202C22.2402 20.4602 19.1902 18.2102 15.8202 18.2102C12.5902 18.2102 9.6002 20.3502 8.8402 23.0502C7.0502 21.2302 5.9502 18.7502 5.9502 16.0002C5.9502 10.4602 10.4602 5.9502 16.0002 5.9502C21.5402 5.9502 26.0502 10.4602 26.0502 16.0002C26.0502 18.8902 24.8302 21.4802 22.8702 23.3202Z" stroke="none"/>
      <path d="M16.0004 9.55029C13.7604 9.55029 11.9404 11.3703 11.9404 13.6103C11.9404 15.8503 13.7604 17.6703 16.0004 17.6703C18.2404 17.6703 20.0604 15.8503 20.0604 13.6103C20.0604 11.3703 18.2404 9.55029 16.0004 9.55029ZM16.0004 17.1603C14.0404 17.1603 12.4404 15.5603 12.4404 13.6003C12.4404 11.6403 14.0404 10.0403 16.0004 10.0403C17.9604 10.0403 19.5604 11.6403 19.5604 13.6003C19.5604 15.5603 17.9604 17.1603 16.0004 17.1603Z" stroke="none"/>
    </IconBase>
  )
}

export default FaqAccount
