import { useNode, useEditor } from '@craftjs/core'
import React, { useState, useEffect } from 'react'
import { CustomCodeSettings } from './CustomCodeSettings'

import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'


export const defaultProps = {
  code: '<p>Hello world!</p>',
}

export const dynamicProps = ['code']

export const CustomCode = withBlock(
  props => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }
    const { code } = blockProps

    const _ = props.renderValue

    const { enabled } = useEditor(state => ({
      enabled: state.options.enabled,
    }))

    const {
      id,
      connectors: { connect, drag },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(state => ({
      selected: state.events.selected,
      dragged: state.events.dragged,
      hovered: state.events.hovered,
      customs: state.data.custom,
    }))

    return (
      <div ref={ref => props.retRef(ref)} className={'block-custom-code ' + props.className}>
        <div dangerouslySetInnerHTML={{ __html: _(code) }}></div>
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

CustomCode.craft = withCraft({
  name: 'CustomCode',
  defaultProps: defaultProps,
  settings: CustomCodeSettings,
})
