import React, { useContext, useEffect, useState } from 'react'
import Link from 'next/link'
import { Profile, HeartEmpty, Cart } from 'components/atoms/Icons'
import { Dropdown, NotificationBadge } from 'components/atoms'
import { CartContext } from 'utils/context'
import { useSelector } from 'react-redux'
import { DOMAIN } from 'utils/settings'
import { useRouter } from 'next/dist/client/router'
import { trackingGAevent } from 'utils/gtm'
import { useTrans, useUtente } from 'hooks'

interface Props {
  cartPanelOpen?: boolean
  setCartPanelOpen?: (v: boolean) => void
  className?: string
}

interface ItemProps {
  item: any
  onClick?: () => void
  badge?: string
  visibleMobile?: boolean
  className?: string
}

const HeaderMenuItem = (props: ItemProps) => {
  const { item = null, onClick = null, badge = '', visibleMobile = false, className = '' } = props
  const { utente, loading } = useUtente()
  const router = useRouter()

  const [selectedLanguage, setSelectedLanguage] = useState()

  return (
    <div
      className={`header-menu-item${item.icon ? ' header-menu-item--icon' : ''}${
        visibleMobile ? ' header-menu-item--visible-mobile' : ''
      }${className ? ` ${className}` : ''}`}
    >
      {onClick ? (
        <button className="header-menu-item__button" onClick={onClick}>
          {item.icon ? item.icon : item.name}
          {!!badge && <NotificationBadge label={badge} className="header-menu-item__badge" />}
        </button>
      ) : (
        <Link legacyBehavior href={item.url ? item.url : ''}>
          <a
            className="header-menu-item__link"
            onClick={() =>
              item.nome == 'Accedi' && !utente
                ? trackingGAevent(4, 'user', 'login-step-1', DOMAIN + router.asPath)
                : null
            }
          >
            {item.icon ? item.icon : item.name}
            {!!badge && <NotificationBadge label={badge} className="header-menu-item__badge" />}
          </a>
        </Link>
      )}
    </div>
  )
}

const HeaderMenu = (props: Props) => {
  const { cartPanelOpen = false, setCartPanelOpen = () => {}, className = '' } = props
  const router = useRouter()
  const { menu } = useSelector((state) => state.menu)
  const { cart } = useContext(CartContext)
  const [lingue, setLingue] = useState([])
  const [selectedLanguage, setSelectedLanguage] = useState(router.locale)
  const t = useTrans()

  const lingueOption = () => {
    return router.locales?.map((lingua) => ({
      label: lingua,
      value: lingua,
      selected: lingua === selectedLanguage ? true : false,
    }))
  }

  useEffect(() => {
    setLingue(lingueOption)
    setSelectedLanguage(router.locale)
  }, [])

  const handleLanguage = async (value) => {
    setSelectedLanguage(value)
    for (let i = 0; i < lingue.length; i++) lingue[i].selected = lingue[i].value === value
    setLingue(lingueOption)
    router.push(router.asPath, router.asPath, { locale: value }).then((res) => router.reload())
  }

  return (
    <div className={`header-menu ${className ? ` ${className}` : ''}`}>
      {menu?.edges
        .filter((item) => item.node.key === 'menu_top_header')
        .map((item) =>
          item.node.children.edges.map((item2, key2) => (
            <HeaderMenuItem item={item2.node} key={`header-menu__item__${key2}`} />
          ))
        )}
      {/* VOCI FISSE */}
      <Dropdown
        label={selectedLanguage}
        options={lingue}
        onChange={handleLanguage}
        className="dropdown__language"
      />
      <HeaderMenuItem item={{ id: -1, nome: 'Accedi', icon: <Profile />, url: '/account' }} />
      <HeaderMenuItem item={{ id: -2, icon: <HeartEmpty />, url: '/wishlist' }} visibleMobile />
      <HeaderMenuItem
        item={{ id: -3, icon: <Cart /> }}
        visibleMobile
        badge={cart?.numProdotti.toString()}
        onClick={() => setCartPanelOpen(!cartPanelOpen)}
      />
    </div>
  )
}

export default HeaderMenu
