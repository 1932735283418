import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useTrans } from 'hooks'
import { getImageUrl, priceNotation } from 'utils/safe'
import { FUNDING } from '@paypal/react-paypal-js'
import { Promo } from 'components/atoms/Icons'
import { Button, WImage } from 'components/atoms'
import AmazonPayButton from 'components/molecules/AmazonPayButton/AmazonPayButton'
import { PayPalMessage } from 'components/molecules/'
import CheckoutPaymentMethodStepBlockStories from 'components/organisms/CheckoutPaymentMethodStepBlock/CheckoutPaymentMethodStepBlock.stories'
import { gql, useMutation } from '@apollo/client'
import { SET_PAGAMENTO } from 'utils/queries'
import PaypalButton from '../PaypalButton/PaypalButton'

interface Props {
  ordine: any
  checkout?: boolean
  cart?: boolean
  setMessaggioRegalo?: any
  messaggioRegalo?: any
  className?: string
  setPaypalClicked?: any
  paypalClicked?: any
  iva?: Number
  accise?: Number
  estero?: boolean
  erroreApisEstero?: boolean
}

const RiepilogoOrdine = (props: Props) => {
  const {
    ordine = {
      totaleProdotti: 0,
      speseSpedizione: 0,
      speseContrassegno: 0,
      totale: 0,
      promo: {
        alias: '',
        valore: 0,
      },
    },
    checkout = false,
    cart = false,
    setMessaggioRegalo = () => {},
    messaggioRegalo = {},
    setPaypalClicked = () => {},
    paypalClicked = false,
    className = '',
    iva = 0,
    accise = 0,
    estero = false,
    erroreApisEstero = false,
  } = props

  const t = useTrans()

  const router = useRouter()
  const [paypalCompleted, setPaypalCompleted] = useState(false)
  const [setPagamento, { data: setPagamentoPayload }] = useMutation(
    gql`
      ${SET_PAGAMENTO}
    `
  )

  const onCheckoutClick = async () => {
    await setMessaggioRegalo(
      messaggioRegalo?.includiMessaggio,
      messaggioRegalo?.messaggio,
      messaggioRegalo?.messaggioFirma
    )
    router.push('/carrello/checkout')
  }

  const onPaypalClick = async () => {
    await setMessaggioRegalo(
      messaggioRegalo?.includiMessaggio,
      messaggioRegalo?.messaggio,
      messaggioRegalo?.messaggioFirma
    )
    try {
      await setPagamento({
        variables: {
          sistemaPagamento: 10,
        },
      })

      router.push('/carrello/checkout?paypal=1')
    } catch (e) {
      router.replace(`/login?next=${router.asPath}`)
      router.push('/carrello/')
    }
  }

  useEffect(() => {
    if (router.query['paypal']) {
      setPaypalClicked(true)
    }
  }, [router.query])

  return (
    <div className={`riepilogo-ordine ${className ? ` ${className}` : ''}`}>
      <p className="riepilogo-ordine__title">{t("Riepilogo dell'ordine")}</p>
      <div className="riepilogo-ordine__table">
        <div className="riepilogo-ordine__row">
          <span>{t('Totale prodotti')}</span>
          <span>{priceNotation(ordine.totaleProdotti)}</span>
        </div>
        {ordine.promo && (
          <div className="riepilogo-ordine__row riepilogo-ordine__row--promo">
            <span>
              <span>{t('Sconto promo {0}', [ordine.promo.alias])}</span>
              <Promo />
            </span>
            <span>- {priceNotation(ordine.scontoAssoluto)}</span>
          </div>
        )}

        {ordine.speseSpedizione === 0 && (
          <div className="riepilogo-ordine__row riepilogo-ordine__row--spedizione--gratis">
            <span>{t('Spese di spedizione')}</span>
            <span>{t('Gratis')}</span>
          </div>
        )}
        {ordine.speseSpedizione !== 0 && (
          <div className="riepilogo-ordine__row riepilogo-ordine__row--spedizione">
            <span>{t('Spese di spedizione')}</span>
            <span>{priceNotation(ordine.speseSpedizione)}</span>
          </div>
        )}
        {estero && (
          <>
            <div className="riepilogo-ordine__row riepilogo-ordine__row--spedizione">
              <span>{t('Accise')}</span>
              <span>{priceNotation(accise)}</span>
            </div>

            <div className="riepilogo-ordine__row riepilogo-ordine__row--spedizione">
              <span>{t('Iva Spedizione')}</span>
              <span>{priceNotation(iva)}</span>
            </div>
          </>
        )}

        {ordine.speseContrassegno != null && (
          <div className="riepilogo-ordine__row">
            <span>{t('Contrassegno')}</span>
            <span>{priceNotation(ordine.speseContrassegno)}</span>
          </div>
        )}
        <div className="riepilogo-ordine__row riepilogo-ordine__row--totale">
          <span>
            <span>{t('Totale')}</span>
            <span className="riepilogo-ordine__iva-label">({t('IVA inclusa')})</span>
          </span>
          <span>{priceNotation(ordine.totale)}</span>
        </div>
      </div>
      {erroreApisEstero && estero && (
        <div className="cart-panel__error__estero">
          <img src="/images/alert_icon.png"></img>
          <div>
            <p className="cart-panel__error__message__italian">
              {t('Impossibile effettuare ordini esteri')}
            </p>
          </div>
        </div>
      )}
      {cart && (
        <>
          <div className="riepilogo-ordine__checkout">
            <Button
              label={t("Procedi all'acquisto")}
              className="riepilogo-ordine__checkout__cta"
              onClick={onCheckoutClick}
            />
          </div>
          <div className="riepilogo-ordine__amazon">
            <AmazonPayButton
              type="PayAndShip"
              placement="Cart"
              setMessaggioRegalo={setMessaggioRegalo}
              messaggioRegalo={messaggioRegalo}
            />
          </div>
          <div className="riepilogo-ordine__paypal">
            <Button
              label={t('Paypal')}
              className="riepilogo-ordine__checkout__cta__paypal"
              onClick={onPaypalClick}
              children={
                <WImage
                  layout="intrinsic"
                  maxWidth={69}
                  maxHeight={20}
                  src="/images/pay_pal_no_logo.svg"
                />
              }
            />
            <div className="riepilogo-ordine__checkout__message">
              <PayPalMessage style={{ layout: 'text' }} forceReRender={[{ layout: 'text' }]} />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default RiepilogoOrdine
