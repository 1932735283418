import React, { useContext, useEffect, useState } from 'react'
import { useTrans } from 'hooks'
import { priceNotation } from 'utils/safe'
import { CartContext, initialCart } from 'utils/context'
import { Cart, Check, Close, Promo } from 'components/atoms/Icons'
import { NotificationBar, Button } from 'components/atoms'
import { ProdottoCardCarrello } from 'components/molecules'
import { trackingAddRemoveFromCart, trackingRemoveProductFromCart } from 'utils/gtm'
import { executeIfInitialized, trackConnectifCart } from 'utils/connectif'
import PromoCodeForm from '../PromoCodeForm/PromoCodeForm'

interface Props {
  open: boolean
  setOpen: any
  className?: string
}

const CartPanel = (props: Props) => {
  const context = useContext(CartContext)
  const cart = context ? context.cart : initialCart
  const showCart = context ? context.showCart : false
  const closeCart = context ? context.closeCart : () => {}
  const [errorsList, setErrorsList] = useState([])

  const { open = false, setOpen = () => {}, className = '' } = props
  const [openSconto, setOpenSconto] = useState(false)
  const [openNotification, setOpenNotification] = useState(true)

  const t = useTrans()

  useEffect(() => {
    if (open) document.body.classList.add('w-noscroll')
    else document.body.classList.remove('w-noscroll')
  }, [open])

  const onClose = () => {
    setOpen(false)
    closeCart()
  }
  useEffect(() => {
    if (cart) {
      executeIfInitialized(trackConnectifCart, cart)
    }
  }, [cart])

  const onCloseNotification = () => {
    setOpenNotification(false)
  }

  const onQuantitaChange = (item, quantita) => {
    context
      .addToCart(item.prodotto, quantita - item.quantita, false)
      .then(({ status, shortMessage, quantitaMax }) => {
        if (shortMessage && !errorsList.some(({ pk }) => pk === item.pk)) {
          setErrorsList(errorsList.concat([{ pk: item.pk, message: shortMessage }]))
        }
        if (!shortMessage && errorsList.some(({ pk }) => pk === item.pk)) {
          setErrorsList(errorsList.filter(({ pk }) => pk !== item.pk))
        }
        if (status && quantitaMax) {
          trackingAddRemoveFromCart(item, quantitaMax - item.quantita)
          if (cart) executeIfInitialized(trackConnectifCart, cart)
        } else if (status) {
          trackingAddRemoveFromCart(item, quantita - item.quantita)
          if (cart) executeIfInitialized(trackConnectifCart, cart)
        }
      })
  }

  const onRemove = (item) => {
    context.removeFromCart(item.prodotto, false)
    trackingRemoveProductFromCart(item)
  }

  return (
    <>
      {(open || showCart) && <div className="cart-panel-overlay" onClick={() => setOpen(false)} />}
      <div
        className={`cart-panel${open || showCart ? ' cart-panel--open' : ''}${
          className ? ` ${className}` : ''
        }`}
      >
        <div className="cart-panel__content">
          <div className="cart-panel__head">
            {cart.sistemaSpedizione?.prezzoScontato === 0 && (
              <div className="cart-panel__container__notification">
                <NotificationBar
                  label={t('Hai ottenuto la spedizione gratuita!')}
                  icon={<Check />}
                  variant="success"
                  onClose={onCloseNotification}
                  open={openNotification}
                  setOpen={setOpenNotification}
                />
              </div>
            )}
            <div className="cart-panel__button_close">
              <button
                type="button"
                aria-label={t('Chiudi')}
                onClick={onClose}
                className="cart-panel__close"
              >
                <Close />
              </button>
            </div>

            <p className="cart-panel__title">
              <Cart />
              <span>
                {t('Il mio carrello')} (
                {cart.numProdotti === 1
                  ? t('1 prodotto')
                  : t('{0} prodotti', [cart.numProdotti.toString()])}
                )
              </span>
            </p>
          </div>
          <div className="cart-panel__list">
            {cart.items.map((item) => (
              <ProdottoCardCarrello
                prodotto={item.prodotto.prodotto}
                quantita={item.quantita}
                omaggio={item.omaggio}
                onQuantitaChange={(newValue, action) => {
                  onQuantitaChange(item, newValue)
                }}
                onRemove={() => onRemove(item)}
                size="sm"
                key={`cart-panel-prodotto__${item.pk}`}
                message={
                  errorsList.some(({ pk }) => pk === item.pk)
                    ? errorsList.find(({ pk }) => pk === item.pk).message
                    : ''
                }
              />
            ))}
          </div>
          <div className="cart-panel__footer">
            <div className="cart-panel__details">
              <div className="cart-panel__detail">
                <span className="cart-panel__detail__label">{t('Totale prodotti')}</span>
                <span className="cart-panel__detail__value">
                  {priceNotation(cart.totaleProdottiScontato)}
                </span>
              </div>

              {cart.promoApplicata && (
                <div className="cart-panel__detail cart-panel__detail--promo">
                  <span>
                    <span>{t('Sconto promo {0}', [cart.promoApplicata.alias])}</span>
                    <Promo />
                  </span>
                  <span>- {priceNotation(cart.scontoAssoluto)}</span>
                </div>
              )}
              {cart.sistemaSpedizione?.prezzoScontato === 0 && (
                <div className="cart-panel__detail cart-panel__detail--spedizione--gratis">
                  <span className="cart-panel__detail__label">{t('Spese di spedizione')}</span>
                  <span className="cart-panel__detail__value">{t('Gratis')}</span>
                </div>
              )}
              {cart.sistemaSpedizione?.prezzoScontato !== 0 && (
                <div className="cart-panel__detail cart-panel__detail--spedizione">
                  <span className="cart-panel__detail__label">{t('Spese di spedizione')}</span>
                  <span className="cart-panel__detail__value">
                    {cart?.erroreApisEstero && cart?.sistemaSpedizione?.estero
                      ? priceNotation(0)
                      : priceNotation(cart.sistemaSpedizione?.prezzoScontato)}
                  </span>
                </div>
              )}
              {cart.sistemaSpedizione?.estero && (
                <>
                  <div className="cart-panel__detail cart-panel__detail--iva">
                    <span className="cart-panel__detail__label">{t('Iva')}</span>
                    <span className="cart-panel__detail__value">{priceNotation(cart.iva)}</span>
                  </div>
                  <div className="cart-panel__detail cart-panel__detail--accise">
                    <span className="cart-panel__detail__label">{t('Accise')}</span>
                    <span className="cart-panel__detail__value">{priceNotation(cart.accise)}</span>
                  </div>
                </>
              )}
              <div className="cart-panel__detail cart-panel__detail--final">
                <span className="cart-panel__detail__label">{t('Importo totale')}</span>
                <span className="cart-panel__detail__value">
                  {priceNotation(cart.totaleScontato)}
                </span>
              </div>
              {cart?.erroreApisEstero && cart.sistemaSpedizione?.estero && (
                <div className="cart-panel__error__estero">
                  <img src="/images/alert_icon.png"></img>
                  <div>
                    <p className="cart-panel__error__message__italian">
                      {t('Impossibile effettuare ordini esteri')}
                    </p>
                  </div>
                </div>
              )}
              <PromoCodeForm open={openSconto} setOpen={setOpenSconto} />
            </div>
          </div>
        </div>
        <div className="cart-panel__actions">
          <Button
            label={t('Visualizza carrello')}
            variant="secondary"
            href="/carrello"
            onClick={() => setOpen(false)}
          />
          <Button label={t('Vai al checkout')} variant="primary" href="/carrello/checkout" />
        </div>
      </div>
    </>
  )
}

export default CartPanel
