import React from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { useTrans } from 'hooks'
import { getFormFieldStatus } from 'utils/safe'
import { useUpdateUtenteMutation } from 'gql/graphql'
import { DOMAIN } from 'utils/settings'
import { useRouter } from 'next/dist/client/router'
import { trackingGAevent } from 'utils/gtm'
import { getCurrentDate } from 'utils'
import { FormField, FormCheckbox, FormLayout } from 'components/atoms'
import { MyAccountInlineForm } from 'components/molecules'


const MyAccountFormNewsletter = ({ onCancel, utente }) => {
  const t = useTrans()
  const [updateUtente] = useUpdateUtenteMutation()
  const router = useRouter()

  async function handleSubmit(values) {
    await updateUtente({
      variables: {
        input: {
          privacyCommerciale: values.newsletter,
        },
      },
      refetchQueries: ['Me'],
    }).then((res) => {
      if (res?.data?.updateUtente?.iscrizioneNewsletter) {
        trackingGAevent(
          2,
          'user',
          'newsletter',
          DOMAIN + router.asPath,
          getCurrentDate(),
        )
      }
    })

    onCancel()
  }

  return (
    <div className="myaccount-form-newsletter">
      <Formik
        validateOnBlur
        validateOnChange
        validationSchema={yup.object().shape({})}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
        initialValues={{ newsletter: utente.privacyCommerciale }}
      >
        {(props) => (
          <Form>
            <MyAccountInlineForm onCancel={onCancel}>
              <FormLayout>
                <FormField
                  label={t('Iscrizione alla newsletter')}
                  layout="full"
                  space="none"
                  status={getFormFieldStatus(props, 'newsletter')}
                  errorMessage={props?.errors?.newsletter}
                  className="myaccount-home-template__newsletter-formfield"
                >
                  <FormCheckbox
                    id="myaccount-form-newsletter__newsletter"
                    label={t(
                      'Acconsento al trattamento dei miei dati personali per ricevere offerte e comunicazioni commerciali da parte di Vinicum.'
                    )}
                    onBlur={props.handleBlur('newsletter')}
                    checked={props.values?.newsletter}
                    onChange={props.handleChange('newsletter')}
                  />
                </FormField>
              </FormLayout>
            </MyAccountInlineForm>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default MyAccountFormNewsletter
