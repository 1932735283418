import { useRouter } from 'next/router'
import React from 'react'
import { api } from 'utils'
import { useUtente } from 'hooks'
import { TOOLBAR } from 'utils/endpoint'
import { Button, Tooltip } from 'components/atoms'

api.defaults.xsrfCookieName = 'csrftoken'
api.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

interface Props {
  page?: any
  item?: any
}

const Toolbar = (props: Props) => {
  const { page = null, item = null } = props

  const router = useRouter()

  const { utente, loading } = useUtente()

  if (!utente) return null

  if (!(utente.isStaff || utente.isImpersonate)) return null

  const handleSwitchMode = (edit) => {
    api
      .post(TOOLBAR + 'set_edit/', { edit })
      .then(() => {
        router.reload()
      })
      .catch((error) => console.log(error))
  }

  const paginaContienePagebuilder = document.getElementsByClassName('custom-content').length
    ? true
    : false

  const previewModeAbilitata = true

  return (
    <div className="toolbar">
      {utente.isImpersonate ? (
        <div className="toolbar__user toolbar__user--impersonate">
          Stai impersonando{' '}
          <span>
            {utente.nome} {utente.cognome}
          </span>{' '}
          - <a href={utente.stopImpersonateUrl}>Smetti di impersonare</a>
        </div>
      ) : (
        <>
          <div className="toolbar__admin-link">
            <a href="/d/admin" target="_blank">
              ADMIN
            </a>
          </div>
          <div className="toolbar__user">
            {utente.nome} {utente.cognome}
          </div>
          <section className="toolbar__left"></section>
          <section className="toolbar__right">
            {item && item.adminUrl && (
              <a
                href={item.adminUrl}
                className="toolbar__button toolbar__button--ghost"
                target="_blank"
              >
                Modifica {item.modelClass}
              </a>
            )}
            {page && page.adminUrl && (
              <a
                href={page.adminUrl}
                className="toolbar__button toolbar__button--ghost"
                target="_blank"
              >
                Admin Pagina
              </a>
            )}
            {paginaContienePagebuilder &&
              (utente.edit ? (
                <button
                  className="toolbar__button toolbar__right__pubblicata"
                  onClick={() => handleSwitchMode(false)}
                >
                  VEDI PUBBLICATA
                </button>
              ) : (
                <button
                  className="toolbar__button toolbar__right__modifica"
                  onClick={() => handleSwitchMode(true)}
                >
                  MODIFICA
                </button>
              ))}
            {previewModeAbilitata &&
              (router.isPreview ? (
                <Tooltip
                  id={`tooltip-toolbar-stop-preview`}
                  label={
                    'Con la modalità preview disattivata non potrai visualizzare le pagine nello stato "non visibile"'
                  }
                  pos="bottom"
                >
                  <div>
                    <Button
                      external
                      label="Disattiva modalità preview"
                      size="sm"
                      href={`/api/stop-preview?secret=${process.env.PREVIEW_MODE_SECRET}&redirect=${router.asPath}`}
                      className={'toolbar__button toolbar__right__preview'}
                    />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip
                  id={`tooltip-toolbar-attiva-preview`}
                  label={
                    'Con la modalità preview attiva puoi visualizzare le pagine anche se nello stato "non visibile"'
                  }
                  pos="bottom"
                >
                  <div>
                    <Button
                      external
                      label="Attiva modalità preview"
                      size="sm"
                      href={`/api/preview?secret=${process.env.PREVIEW_MODE_SECRET}&redirect=${router.asPath}`}
                      className={'toolbar__button toolbar__right__preview'}
                    />
                  </div>
                </Tooltip>
              ))}
          </section>
        </>
      )}
    </div>
  )
}

export default Toolbar
