import { DynamicToolbarItem } from './DynamicToolbarItem'
import { useState, useEffect } from 'react'
import { FormSelect } from 'react-pagebuilder/pb-components/atoms'

export const SelectToolbarItem = ({
  propKey,
  propSubKey,
  values,
  value,
  setProp,
  onChange,
  onlyDynamic,
  dynamic,
  dynamicValues,
  defaultValue,
  nullable,
}) => {
  const [state, setState] = useState()

  useEffect(() => {
    setState(value)
  }, [value])

  return (
    <>
      {state !== undefined && (
        <>
          {!onlyDynamic && state !== undefined && (
            <FormSelect
              value={state}
              options={(nullable ? [{ label: 'No value', value: 'no_value' }] : []).concat(
                values.map((v) => ({ label: v, value: v }))
              )}
              onChange={(e) =>
                setProp((props) => {
                  let value = e.target.value
                  if (value === 'no_value') {
                    value = defaultValue
                  }
                  props[propKey][propSubKey] = onChange ? onChange(value) : value
                }, 1000)
              }
            />
          )}
          {dynamic && (
            <DynamicToolbarItem
              propKey={propKey}
              propSubKey={propSubKey}
              value={state}
              values={dynamicValues}
              setProp={setProp}
              onChange={onChange}
              defaultValue={defaultValue}
            />
          )}
        </>
      )}
    </>
  )
}
