import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function StepbarDot({ className }: Props) {
  return (
    <IconBase name="stepbar-dot" w={32} className={className}>
      <path d="M28.6469 5.75338C22.1828 -0.452369 2.10466 3.55272 0.475832 12.1049C-1.153 20.657 5.55715 26.2675 17.2282 29.1577C28.8993 32.0478 36.5655 13.3556 28.6469 5.75338Z" stroke="none"/>
    </IconBase>
  )
}

export default StepbarDot
