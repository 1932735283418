import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function ArrowLeft({ className }: Props) {
  return (
    <IconBase name="arrow-left" w={24} className={className}>
      <line x1="20" y1="11.5" x2="4" y2="11.5" strokeWidth="2" fill="none"/>
      <path d="M11.5 19L4 11.5L11.5 4" strokeWidth="2" fill="none"/>
    </IconBase>
  )
}

export default ArrowLeft
