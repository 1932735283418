import React, { useState, useRef } from 'react'

interface Props {
  label?: string
  content?: any
  className?: string
}

const Accordion = (props: Props) => {
  const { label = '', content = null, className = '' } = props

  const [open, setOpen] = useState(false)
  const innerContentRef = useRef()

  return (
    <div
      className={`accordion${open ? ' accordion--open' : ''}${className ? ` ${className}` : ''}`}
    >
      <button type="button" className="accordion__trigger" onClick={() => setOpen(!open)}>
        {label}
      </button>
      <div
        className="accordion__content"
        style={{
          maxHeight: innerContentRef?.current && open ? innerContentRef.current.clientHeight : 0,
        }}
      >
        <div className="accordion__inner-content" ref={innerContentRef}>
          {content}
        </div>
      </div>
    </div>
  )
}

export default Accordion
