import React from 'react'
import { useTrans } from 'hooks'

import { Facebook, Twitter, Pinterest } from '../Icons'
import { FacebookShareButton, PinterestShareButton, TwitterShareButton } from 'react-share'

interface Props {
  className?: string
}

export const handleShare = (key, children) => {
  const shareUrl = typeof window !== 'undefined' ? window.location.href : ''
  const socialButtonProps = {
    size: 16,
    url: shareUrl,
    iconFillColor: 'white',
    media: shareUrl,
  }

  switch (key) {
    case 'facebook':
      return <FacebookShareButton {...socialButtonProps}>{children}</FacebookShareButton>

    case 'pinterest':
      return <PinterestShareButton {...socialButtonProps}>{children}</PinterestShareButton>

    case 'twitter':
      return <TwitterShareButton {...socialButtonProps}>{children}</TwitterShareButton>

    default:
      break
  }
}

const SocialShare = (props: Props) => {
  const { className = '' } = props

  const t = useTrans()

  // FAKE
  const social = [
    { icon: <Facebook />, key: 'facebook', onClick: () => console.log('Facebook') },
    { icon: <Pinterest />, key: 'pinterest', onClick: () => console.log('Pinterest') },
    { icon: <Twitter />, key: 'twitter', onClick: () => console.log('Twitter') },
  ]

  return (
    <div className={`social-share ${className ? ` ${className}` : ''}`}>
      <span className="social-share__label">{t('Condividi su')}</span>
      <div className="social-share__list">
        {social.map((s) =>
          handleShare(
            s.key,
            <div className="social-share__item" onClick={s.onClick}>
              {s.icon}
            </div>
          )
        )}
      </div>
    </div>
  )
}

export default SocialShare
