import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function SottocategorieListShape1({ className }: Props) {
  return (
    <IconBase name="sottocategorie-list-shape1" w={81} h={80} className={className}>
      <path d="M72.1173 14.3834C55.9571 -1.13098 5.76178 8.88174 1.6897 30.2621C-2.38238 51.6425 14.393 65.6688 43.5707 72.8941C72.7484 80.1194 91.914 33.3889 72.1173 14.3834Z" stroke="none"/>
    </IconBase>
  )
}

export default SottocategorieListShape1
