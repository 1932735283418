import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Edit({ className }: Props) {
  return (
    <IconBase name="edit" w={24} className={className}>
      <path d="M21.5193 7.51933L17 3L6.02447 13.9755L10.5438 18.4949L21.5193 7.51933Z" strokeWidth="2" strokeLinejoin="round" fill="none"/>
      <path d="M6.02441 13.9756L4.41037 20.109L10.5437 18.4949" strokeWidth="2" strokeLinejoin="round" fill="none"/>
    </IconBase>
  )
}

export default Edit
