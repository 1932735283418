import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function TipologiaFermo({ className }: Props) {
  return (
    <IconBase name="tipologia-fermo" w={32} className={className}>
      <path d="M22.0999 13.5601C22.0999 13.2801 21.8799 13.0601 21.5999 13.0601H6.16992C5.88992 13.0601 5.66992 13.2801 5.66992 13.5601C5.66992 13.8401 5.88992 14.0601 6.16992 14.0601H21.5999C21.8699 14.0601 22.0999 13.8401 22.0999 13.5601Z" stroke="none"/>
      <path d="M25.5399 17.9399H10.6899C10.4099 17.9399 10.1899 18.1599 10.1899 18.4399C10.1899 18.7199 10.4099 18.9399 10.6899 18.9399H25.5399C25.8199 18.9399 26.0399 18.7199 26.0399 18.4399C26.0399 18.1599 25.8199 17.9399 25.5399 17.9399Z" stroke="none"/>
      <path d="M24.6501 22.8101H7.6001C7.3201 22.8101 7.1001 23.0301 7.1001 23.3101C7.1001 23.5901 7.3201 23.8101 7.6001 23.8101H24.6501C24.9301 23.8101 25.1501 23.5901 25.1501 23.3101C25.1501 23.0301 24.9301 22.8101 24.6501 22.8101Z" stroke="none"/>
      <path d="M25.8299 8.18994H13.8799C13.5999 8.18994 13.3799 8.40994 13.3799 8.68994C13.3799 8.96994 13.5999 9.18994 13.8799 9.18994H25.8299C26.1099 9.18994 26.3299 8.96994 26.3299 8.68994C26.3299 8.40994 26.1099 8.18994 25.8299 8.18994Z" stroke="none"/>
    </IconBase>
  )
}

export default TipologiaFermo
