import React, { useState } from 'react'
import { useTrans } from 'hooks'
import { Cart } from 'components/atoms/Icons'
import { ProdottoCardCarrello } from 'components/molecules'

interface Props {
  righeProdotti: any[]
  estero?: boolean
  className?: string
}

const RiepilogoProdottiCheckout = (props: Props) => {
  const { righeProdotti = [], className = '', estero = false } = props
  const t = useTrans()
  const [open, setOpen] = useState(false)

  return (
    <div
      className={`riepilogo-prodotti-checkout${open ? ' riepilogo-prodotti-checkout--open' : ''}${
        className ? ` ${className}` : ''
      }`}
    >
      <button
        type="button"
        onClick={() => setOpen(!open)}
        className="riepilogo-prodotti-checkout__head"
      >
        <span className="riepilogo-prodotti-checkout__icon">
          <Cart />
        </span>
        <span className="riepilogo-prodotti-checkout__label">{t('I tuoi prodotti')}</span>
      </button>
      <div className="riepilogo-prodotti-checkout__body">
        <div className="riepilogo-prodotti-checkout__list">
          {righeProdotti.map((item) => (
            <ProdottoCardCarrello
              prodotto={item.prodotto.prodotto}
              quantita={item.quantita}
              omaggio={item.omaggio}
              errore_vendibilita_estero={!item.prodotto.prodotto.vendibileEstero && estero}
              checkout
              key={`riepilogo-prodotti-checkout__item__${item.prodotto.prodotto.pk}`}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default RiepilogoProdottiCheckout
