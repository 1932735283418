import React from 'react'
import Link from 'next/link'
import { getImageUrl, dateNotation } from 'utils/safe'
import { getIconCategoriaNews } from 'utils/icon'
import { BlogShape1, BlogShape2, BlogShape3, BlogShape4 } from 'components/atoms/Icons'
import { ImageBox } from 'components/atoms'

interface Props {
  articolo: {
    pk?: number
    listingImage?: string
    categoria?: {
      nome?: string
      pk?: number
    }
    titolo?: string
    sottotitolo?: string
    data?: string
    url: string
  }
  variant?: '' | 'navigation'
  className?: string
}

const ArticoloCard = (props: Props) => {
  const {
    articolo = {
      pk: null,
      listingImage: null,
      categoria: { nome: '', pk: 0 },
      titolo: '',
      sottotitolo: '',
      data: '',
      url: '',
    },
    variant = '',
    className = '',
  } = props

  const categoriaIcon = getIconCategoriaNews(articolo.categoria?.pk)
  return (
    <Link legacyBehavior href={articolo.url}>
      <a
        className={`articolo-card${variant ? ` articolo-card--${variant}` : ''} ${
          className ? ` ${className}` : ''
        }`}
      >
        <div className="articolo-card__image-wrap">
          <ImageBox
            src={articolo.listingImage}
            maxWidth={470}
            maxHeight={200}
            layout="intrinsic"
            objectFit="cover"
            classPrefix="articolo-card"
            alt={articolo.titolo}
            title={articolo.titolo}
          />
          {!!categoriaIcon && (
            <span className="articolo-card__symbol">
              {articolo.pk % 4 === 0 && <BlogShape1 />}
              {articolo.pk % 4 === 1 && <BlogShape2 />}
              {articolo.pk % 4 === 2 && <BlogShape3 />}
              {articolo.pk % 4 === 3 && <BlogShape4 />}
              {categoriaIcon}
            </span>
          )}
        </div>
        <div className="articolo-card__content">
          {!!articolo.categoria?.nome && (
            <p className="articolo-card__category">{articolo.categoria.nome}</p>
          )}
          <p className="articolo-card__title">{articolo.titolo}</p>
          <div className="articolo-card__description">{articolo.sottotitolo}</div>
          <p className="articolo-card__date">{dateNotation(articolo.data)}</p>
        </div>
      </a>
    </Link>
  )
}

export default ArticoloCard
