import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Close({ className }: Props) {
  return (
    <IconBase name="close" w={24} className={className}>
      <path d="M20.1118 4.11182L4.11184 20.1118" strokeWidth="2" fill="none"/>
      <path d="M20.1118 20.1118L4.11184 4.11184" strokeWidth="2" fill="none"/>
    </IconBase>
  )
}

export default Close
