import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function AbbinamentiPizza({ className }: Props) {
  return (
    <IconBase name="abbinamenti-pizza" w={32} className={className}>
      <path d="M16.89 25.45C16.27 25.45 15.64 25.41 14.98 25.3C14.33 25.19 13.67 25.12 13.03 25.05C11.69 24.9 10.42 24.76 9.25 24.34C7.32 23.65 5.94 22.39 4.71 21.28L4.42 21.02C2.9 19.65 2.25 18.1 2.25 15.86C2.25 14.26 2.47 11.94 4.41 10.21C6.23 8.57997 8.07 7.52997 9.87 7.07997C12.08 6.53997 14.33 6.41997 16.2 6.73997C18.08 7.06997 20.17 7.64997 22.08 8.37997C24.07 9.13997 26.04 10.25 27.49 11.42C29.51 13.06 29.75 15.46 29.75 17.13C29.75 19.61 28.92 21.48 27.22 22.86C25.72 24.07 23.65 24.46 21.46 24.87L21.09 24.94C19.75 25.19 18.38 25.45 16.89 25.45ZM13.99 7.54997C12.75 7.54997 11.42 7.70997 10.11 8.03997C8.46 8.44997 6.77 9.41997 5.08 10.94C3.82 12.07 3.26 13.59 3.26 15.85C3.26 17.81 3.79 19.09 5.1 20.27L5.39 20.54C6.55 21.59 7.86 22.78 9.6 23.4C10.65 23.78 11.86 23.91 13.15 24.06C13.81 24.13 14.48 24.21 15.15 24.32C17.24 24.66 19.11 24.31 20.91 23.97L21.28 23.9C23.43 23.5 25.29 23.15 26.6 22.09C28.06 20.92 28.76 19.3 28.76 17.14C28.76 14.78 28.18 13.26 26.88 12.2C25.51 11.09 23.64 10.04 21.75 9.31997C19.9 8.60997 17.87 8.04997 16.05 7.72997C15.4 7.60997 14.71 7.54997 13.99 7.54997Z" stroke="none"/>
      <path d="M20.8799 22.5999C20.0499 22.5999 19.1299 22.3399 18.1699 22.0699C17.1399 21.7799 16.0699 21.4799 15.0399 21.4799C14.2099 21.4799 13.3899 21.5599 12.5899 21.6399C10.9899 21.7899 9.48992 21.9399 8.16992 21.4299C5.06992 20.2299 4.66992 18.1799 4.66992 16.5299C4.66992 14.3199 5.74992 12.9099 8.27992 11.8299C8.76992 11.6199 9.28992 11.3599 9.82992 11.0799C11.3699 10.2899 13.1199 9.3999 15.0399 9.3999C16.8999 9.3999 18.4699 10.3299 19.8599 11.1499C20.3999 11.4699 20.9199 11.7799 21.4099 11.9999L21.9199 12.2399C24.3699 13.3599 27.1499 14.6299 27.1499 16.5299C27.1499 18.4799 24.6999 21.2799 22.0099 22.3999C21.6499 22.5399 21.2699 22.5999 20.8799 22.5999ZM15.0299 20.9699C16.1299 20.9699 17.2299 21.2799 18.2999 21.5799C19.6299 21.9599 20.8799 22.3099 21.8099 21.9199C24.2999 20.8899 26.6499 18.2599 26.6499 16.5099C26.6499 14.9299 24.0299 13.7399 21.7099 12.6799L21.1899 12.4399C20.6799 12.1999 20.1499 11.8899 19.5999 11.5599C18.2699 10.7699 16.7599 9.8799 15.0399 9.8799C13.2399 9.8799 11.5499 10.7399 10.0499 11.5099C9.49992 11.7899 8.97992 12.0599 8.46992 12.2699C5.55992 13.5099 5.15992 15.0399 5.15992 16.5099C5.15992 18.0899 5.53992 19.8599 8.33992 20.9399C9.54992 21.4099 10.9999 21.2699 12.5399 21.1199C13.3399 21.0599 14.1799 20.9699 15.0299 20.9699Z" stroke="none"/>
      <path d="M10.0998 17.29C8.6198 17.29 7.0498 16.94 7.0498 15.97C7.0498 15.08 8.5098 14.27 10.0998 14.27C11.6998 14.27 13.1498 15.08 13.1498 15.97C13.1598 16.94 11.5798 17.29 10.0998 17.29ZM10.0998 14.77C8.6198 14.77 7.5498 15.49 7.5498 15.97C7.5498 16.44 8.6498 16.79 10.0998 16.79C11.5598 16.79 12.6498 16.44 12.6498 15.97C12.6598 15.48 11.5798 14.77 10.0998 14.77Z" stroke="none"/>
      <path d="M16.0398 19.7299C14.3598 19.7299 14.0098 19.0599 14.0098 18.5099C14.0098 17.7199 14.7698 16.8999 16.0398 16.8999C17.2398 16.8999 18.2598 17.6399 18.2598 18.5099C18.2598 19.2799 17.4498 19.7299 16.0398 19.7299ZM16.0398 17.3999C15.0798 17.3999 14.5098 17.9599 14.5098 18.5099C14.5098 18.9899 15.0298 19.2299 16.0398 19.2299C16.4398 19.2299 17.7598 19.1799 17.7598 18.5099C17.7598 17.9799 17.0298 17.3999 16.0398 17.3999Z" stroke="none"/>
      <path d="M16.7602 14.7498C15.7002 14.7498 14.9302 14.2598 14.9302 13.5998C14.9302 12.9498 15.6502 12.5298 16.7602 12.5298C17.9102 12.5298 18.7202 12.9698 18.7202 13.5998C18.7102 14.2498 17.8502 14.7498 16.7602 14.7498ZM16.7602 13.0298C15.9902 13.0298 15.4302 13.2698 15.4302 13.5998C15.4302 13.9198 15.9302 14.2498 16.7602 14.2498C17.5902 14.2498 18.2202 13.8998 18.2202 13.5998C18.2102 13.3198 17.6302 13.0298 16.7602 13.0298Z" stroke="none"/>
      <path d="M22.32 19.38C20.72 19.38 19.27 18.57 19.27 17.68C19.27 17.41 19.41 17.15 19.67 16.92C20.24 16.43 21.32 16.2 22.34 16.36L22.68 16.41C24.19 16.64 25.38 16.82 25.38 17.68C25.38 18.57 23.92 19.38 22.32 19.38ZM21.67 16.81C20.91 16.81 20.29 17.04 19.99 17.3C19.89 17.39 19.77 17.52 19.77 17.68C19.77 18.16 20.84 18.88 22.32 18.88C23.8 18.88 24.87 18.16 24.87 17.68C24.87 17.25 23.71 17.08 22.59 16.91L22.24 16.86C22.05 16.83 21.86 16.81 21.67 16.81Z" stroke="none"/>
    </IconBase>
  )
}

export default AbbinamentiPizza
