import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function FiltriAbbinamenti({ className }: Props) {
  return (
    <IconBase name="filtri-abbinamenti" w={50} className={className}>
      <path d="M43.0936 33.8282H42.7655C42.3593 24.6407 34.9686 17.2501 25.7811 16.8438V16.547C26.703 16.2188 27.3749 15.3438 27.3749 14.3126C27.3749 13.0001 26.3124 11.9376 24.9999 11.9376C23.6874 11.9376 22.6249 13.0001 22.6249 14.3126C22.6249 15.3438 23.2968 16.2188 24.2186 16.547V16.8438C15.0311 17.2345 7.6405 24.6251 7.23425 33.8282H6.90613C5.74988 33.8282 4.79675 34.7657 4.79675 35.9845C4.79675 37.1407 5.73425 38.0938 6.90613 38.0938H43.0936C44.2499 38.0938 45.203 37.1563 45.203 35.9376C45.203 34.7657 44.2499 33.8282 43.0936 33.8282ZM24.9999 13.4845C25.453 13.4845 25.8124 13.8438 25.8124 14.297C25.8124 14.7501 25.453 15.1095 24.9999 15.1095C24.5468 15.1095 24.1874 14.7501 24.1874 14.297C24.1874 13.8438 24.5468 13.4845 24.9999 13.4845ZM24.9999 18.3595C33.6874 18.3595 40.7811 25.2345 41.203 33.8126H8.79675C9.21863 25.2345 16.3124 18.3595 24.9999 18.3595ZM43.0936 36.5157H6.90613C6.60925 36.5157 6.35925 36.2657 6.35925 35.922C6.35925 35.6251 6.60925 35.3751 6.90613 35.3751H43.0936C43.3905 35.3751 43.6405 35.6251 43.6405 35.9688C43.6405 36.2813 43.3905 36.5157 43.0936 36.5157Z" stroke="none"/>
    </IconBase>
  )
}

export default FiltriAbbinamenti
