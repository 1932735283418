import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function FiltriRegione({ className }: Props) {
  return (
    <IconBase name="filtri-regione" w={50} className={className}>
      <path d="M20.3124 23.9374C18.1249 23.9374 16.0781 25.078 14.7031 27.078L9.14057 35.1718C9.01557 35.3437 9.06244 35.5937 9.23432 35.7187C9.29682 35.7499 9.37494 35.7812 9.45307 35.7812C9.57807 35.7812 9.70307 35.7187 9.78119 35.6093L15.3437 27.5155C16.5624 25.7343 18.3749 24.7187 20.3124 24.7187C20.5312 24.7187 20.7031 24.5468 20.7031 24.328C20.7031 24.1093 20.5156 23.9374 20.3124 23.9374Z" stroke="none"/>
      <path d="M45.7812 37.3594L39.7187 28.5469C37.8125 25.7813 34.6718 24.125 31.3125 24.125C30.0625 24.125 28.8281 24.3594 27.6875 24.7969C25.7968 22.4688 23.1562 21.125 20.2968 21.125C17.1718 21.125 14.2812 22.7188 12.375 25.4688L4.20308 37.3438C4.0312 37.5781 4.01558 37.8906 4.1562 38.1563C4.29683 38.4063 4.56245 38.5781 4.8437 38.5781H17.4843H35.7187H45.0937C45.3906 38.5781 45.6562 38.4219 45.7812 38.1563C45.9531 37.9063 45.9375 37.5938 45.7812 37.3594ZM17.0937 37.0156H6.35933L13.6875 26.3594C15.2968 24.0313 17.7031 22.6875 20.3125 22.6875C22.5781 22.6875 24.6718 23.7031 26.25 25.5C24.9531 26.25 23.8125 27.2656 22.9375 28.5469L17.0937 37.0156ZM19 37.0156L24.2187 29.4375C25.8593 27.0625 28.4374 25.7031 31.3281 25.7031C34.2187 25.7031 36.7968 27.0625 38.4375 29.4375L43.6562 37.0156H19Z" stroke="none"/>
      <path d="M31.3279 26.9374C28.8592 26.9374 26.6404 28.1092 25.2342 30.1405L21.7811 35.1717C21.6561 35.3436 21.7029 35.5936 21.8748 35.7186C21.9373 35.7655 22.0154 35.7811 22.0936 35.7811C22.2186 35.7811 22.3436 35.7186 22.4217 35.6092L25.8748 30.578C27.1248 28.7499 29.1092 27.7186 31.3123 27.7186C31.5311 27.7186 31.7029 27.5467 31.7029 27.328C31.7029 27.1092 31.5311 26.9374 31.3279 26.9374Z" stroke="none"/>
      <path d="M31.3125 19.7187C34.4531 19.7187 37 17.1718 37 14.0312C37 10.8905 34.4531 8.34366 31.3125 8.34366C28.1719 8.34366 25.625 10.8905 25.625 14.0312C25.625 17.1562 28.1875 19.7187 31.3125 19.7187ZM31.3125 9.89054C33.5938 9.89054 35.4375 11.7499 35.4375 14.0155C35.4375 16.2968 33.5781 18.1405 31.3125 18.1405C29.0469 18.1405 27.1875 16.2812 27.1875 14.0155C27.1875 11.7499 29.0469 9.89054 31.3125 9.89054Z" stroke="none"/>
    </IconBase>
  )
}

export default FiltriRegione
