import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function PremioStella({ className }: Props) {
  return (
    <IconBase name="premio-stella" w={30} className={className}>
      <g clip-path="url(#premiostella-clip0)">
      <path d="M15 0.576946L19.6337 9.97L30 11.4769L22.5031 18.7854L24.2738 29.1077L15 24.2354L5.73252 29.1077L7.50314 18.7854L0 11.4769L10.3663 9.97L15 0.576946Z" stroke="none"/>
      </g>
      <defs>
      <clipPath id="premiostella-clip0">
      <rect width="30" height="28.5308" transform="translate(0 0.576946)" stroke="none"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default PremioStella
