import React from 'react'
import { Loader as LoaderIcon } from 'components/atoms/Icons'

interface Props {
  className?: string
}

const Loader = (props: Props) => {
  const { className = '' } = props

  return (
    <div className={`loader ${className ? ` ${className}` : ''}`}>
      <LoaderIcon />
    </div>
  )
}

export default Loader
