import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Logout({ className }: Props) {
  return (
    <IconBase name="logout" w={24} className={className}>
      <line x1="15" y1="12" x2="3" y2="12" strokeWidth="2" fill="none"/>
      <path d="M7.5 16.5L3 12L7.5 7.5" strokeWidth="2" fill="none"/>
      <path d="M11 9V4H21V20H11V15" strokeWidth="2" fill="none"/>
    </IconBase>
  )
}

export default Logout
