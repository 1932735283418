import React, { useEffect, useState } from 'react'
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useTrans } from 'hooks'
import { useGetAllNewsQuery } from 'gql/graphql'
import { Button, WContainer } from 'components/atoms'
import { ArticoloCard } from 'components/molecules'

SwiperCore.use([Pagination])

interface Props {
  className?: string
}

const ArticoliHome = (props: Props) => {
  const { className = '' } = props

  const t = useTrans()
  const [articoli, setArticoli] = useState([])

  const { data } = useGetAllNewsQuery({
    variables: {
      offset: 0,
      first: 4,
    },
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    if (data?.allNews) setArticoli(data.allNews)
  }, [data?.allNews])

  return (
    <div className={`articoli-home ${className ? ` ${className}` : ''}`}>
      <WContainer>
        <div className="articoli-home__content">
          <p className="articoli-home__intro">{t('Blog')}</p>
          <p className="articoli-home__title">{t('Tante curiosità sul mondo del vino')}</p>
          <p className="articoli-home__description">
            {t(
              'Visita il nostro blog per restare sempre aggiornato sulle novità del mondo Vinicum e per scoprire curiosità sul mondo del vino'
            )}
          </p>
          <div className="articoli-home__cta-box">
            <Button label={t('Leggi tutti gli articoli')} variant="secondary" href="/blog" />
          </div>
        </div>
        <div className="articoli-home__list">
          <Swiper
            spaceBetween={16}
            slidesPerView={1}
            pagination={{ clickable: true }}
            watchOverflow
            breakpoints={{
              320: {
                slidesPerView: 1.5,
              },
              576: {
                slidesPerView: 2.5,
              },
              991: {
                slidesPerView: 4,
              },
            }}
          >
            {articoli?.edges?.map((articolo) => (
              <SwiperSlide key={`articoli-home__item__${articolo.node.pk}`}>
                <ArticoloCard articolo={articolo.node} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </WContainer>
    </div>
  )
}

export default ArticoliHome
