import React from 'react'
import Slider from 'rc-slider'

const createSliderWithTooltip = Slider.createSliderWithTooltip
const Range = createSliderWithTooltip(Slider.Range)

interface Props {
  label?: string
  valuesFormat?: string
  min?: number
  max: number
  value: [number, number]
  onChange: (v: number[]) => void
  onAfterChange: () => void
  className?: string
}

const FiltroRange = (props: Props) => {
  const {
    label = '',
    valuesFormat = '{0} - {1}',
    min = 0,
    max = 0,
    value = [],
    onChange = () => {},
    onAfterChange = () => {},
    className = '',
  } = props

  return max > 0 ? (
    <div className={`filtro-range ${className ? ` ${className}` : ''}`}>
      <p className="filtro-range__title">{label}</p>
      <div className="filtro-range__slider">
        <Range
          min={min}
          max={max}
          value={value.length ? value : [min, max]}
          onChange={onChange}
          onAfterChange={onAfterChange}
          tipFormatter={(v) => `€ ${v}`}
        />
      </div>
      {value.length === 2 && (
        <div className="filtro-range__values">
          {valuesFormat.replace('{0}', value[0].toString()).replace('{1}', value[1].toString())}
        </div>
      )}
    </div>
  ) : (
    <></>
  )
}

export default FiltroRange
