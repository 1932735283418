import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function SpiritsGin({ className }: Props) {
  return (
    <IconBase name="spirits-gin" w={32} className={className}>
      <path d="M18.13 17.36C18.15 17.36 18.16 17.34 18.18 17.34C21.7 16.3 24.25 13.05 24.25 9.21998V8.96998C24.22 7.61998 23.99 6.22998 23.58 4.84998L23.16 3.44998C23.13 3.06998 22.61 2.22998 16 2.22998C9.4 2.22998 8.88 3.06998 8.84 3.44998L8.42 4.84998C8.01 6.22998 7.78 7.61998 7.75 8.97998V9.22998C7.75 13.07 10.32 16.33 13.86 17.36H13.87C13.94 17.38 15.51 17.78 15.51 20.11V25.92C15.51 26.41 14.01 27.49 12.44 27.74C11.04 28.09 11.04 28.61 11.04 28.78C11.04 30.05 14.52 30.18 16.01 30.18C17.5 30.18 20.98 30.04 20.98 28.78C20.98 28.09 20.08 27.83 19.53 27.73C18 27.49 16.5 26.41 16.5 25.92V20.11C16.5 17.77 18.07 17.38 18.13 17.36ZM16 3.22998C18.33 3.22998 20.15 3.36998 21.23 3.53998C20.15 3.70998 18.33 3.84998 16 3.84998C13.67 3.84998 11.85 3.70998 10.77 3.53998C11.85 3.37998 13.67 3.22998 16 3.22998ZM8.75 9.22998V8.99998C8.78 7.72998 8.99 6.43998 9.38 5.13998L9.63 4.28998C10.49 4.58998 12.27 4.85998 16 4.85998C19.73 4.85998 21.51 4.58998 22.37 4.28998L22.62 5.13998C23.01 6.43998 23.22 7.72998 23.25 8.99998V9.22998C23.25 13.19 20.15 16.47 16.18 16.69C16.18 16.69 16.05 16.7 15.99 16.7C15.93 16.7 15.81 16.69 15.8 16.69C11.84 16.46 8.75 13.19 8.75 9.22998ZM16.18 17.69C16.12 17.78 16.05 17.85 16 17.95C15.95 17.85 15.88 17.78 15.82 17.7C15.87 17.7 15.94 17.71 15.98 17.71H15.99C16.05 17.69 16.12 17.69 16.18 17.69ZM19.36 28.72C19.45 28.74 19.55 28.76 19.65 28.79C19.05 28.98 17.82 29.19 16 29.19C14.21 29.19 12.99 28.99 12.38 28.8C12.46 28.77 12.55 28.74 12.63 28.72C13.75 28.54 15.24 27.88 16 27.03C16.76 27.88 18.25 28.54 19.36 28.72Z" stroke="none"/>
    </IconBase>
  )
}

export default SpiritsGin
