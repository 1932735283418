import React from 'react'
import { OverlayView, Marker } from 'react-google-maps'
import { useIsMobile } from 'utils/hooks'

const getPixelPositionOffset = (pixelOffset) => (width, height) => ({
  x: -(width / 2) + pixelOffset.x,
  y: -(height / 2) + pixelOffset.y,
})


const GoogleMapMarkerInfo = ({
  cantina,
  setSelectedCantina,
  selectedCantina,
  setMapCoords,

}) => {
  const isMobile = useIsMobile()

  const markerClick = () => {
    setMapCoords({ lat: cantina.latitudine, lng: cantina.longitudine })
    setSelectedCantina(cantina)
  }

  return (
    <Marker
      position={{
        lat: Number(cantina.latitudine),
        lng: Number(cantina.longitudine),
      }}
      onClick={() => markerClick()}
       icon={{
        url: `/images/marker_cantina.svg`,
      }}

    >
      {selectedCantina && selectedCantina.pk === cantina.pk && !isMobile && (
        <OverlayView
          position={{
            la: Number(cantina.latitudine),
            lng: Number(cantina.longitudine),
          }}
          key={Math.random()}
          defaultMapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={getPixelPositionOffset({ x: 0, y: -150 })}
        >
        </OverlayView>
      )}
    </Marker>
  )
}

export default GoogleMapMarkerInfo
