import React, { useState, useEffect } from 'react'
import { useTrans } from 'hooks'
import { CheckoutStepBlock } from 'components/molecules'

import CheckoutPaymentMethodOption from 'components/molecules/CheckoutPaymentMethodOption/CheckoutPaymentMethodOption'
import { Button } from 'components/atoms'
import { gql, useMutation } from '@apollo/client'
import { SET_PAGAMENTO } from 'utils/queries'

interface Props {
  paymentMethods: any
  paymentMethodAttivo: number
  paypalFormContent?: any
  className?: string
}

const CheckoutPaymentMethodStepBlock = (props: Props) => {
  const { 
    paymentMethods = [], 
    paymentMethodAttivo = null,
    paypalFormContent = null,
    className = '',
  } = props

  const [setPagamento, { data: setPagamentoPayload }] = useMutation(gql`${SET_PAGAMENTO}`)

  const t = useTrans()
  // togliendo amazon pay, rimane fuori soltanto un metodo. Quindi togliamo il "Mostra altri"
  const [more, setMore] = useState(true)
  const [paymentMethodChecked, setPaymentMethodChecked] = useState(null)

  useEffect(() => {
    if (paymentMethodAttivo) {
      setPaymentMethodChecked(paymentMethodAttivo)
    }
  }, [paymentMethodAttivo])

  const handleChangeSistemaPagamento = async (newSistemaPagamento) => {
    if (newSistemaPagamento === paymentMethodChecked) {
      return
    }

    await setPagamento({
      variables: {
        sistemaPagamento: newSistemaPagamento,
      },
      refetchQueries: ['Checkout']
    }).then((res) => {
      setPaymentMethodChecked(newSistemaPagamento)
    })
  }


  // FAKE - recuperarli da backend?
  const metodiPagamento = [
    {
      pk: 1,
      nome: 'Carta di credito',
      descrizione:
        'Sarai reindirizzato alla pagina del nostro circuito di pagamento per inserire i tuoi dati della carta e confermare l’ordine',
      images: [
        '/images/cc_mastercard.png',
        '/images/cc_maestro.png',
        '/images/cc_visa.png',
      ],
    },
    {
      pk: 2,
      nome: 'Paypal',
      descrizione:
        'Consegna entro le ore 12 del giorno lavorativo successivo per ordini effettuati entro le 15:00',
      images: ['/images/paypal.png'],
    },
    {
      pk: 3,
      nome: 'Contrassegno',
      descrizione: 'Pagamento in contanti alla consegna',
      price: 5,
    },
    {
      pk: 4,
      nome: 'Amazon pay',
      descrizione: 'Pagamento in contanti alla consegna',
      images: ['/images/amazonpay.png'],
    },
    {
      pk: 5,
      nome: 'Bonifico bancario',
      descrizione:
        'Seleziona la tua Banca dalla lista, sarai reindirizzato alla pagina per effettuare il bonifico online. Ricordati di avere a portata di mano i tuoi codici token. Attendi la conferma della transazione senza chiudere la pagina.',
    },
  ]

  return (
    <CheckoutStepBlock
      titolo={t('Metodo di pagamento')}
      descrizione={t('Seleziona un metodo di pagamento tra quelli disponibili')}
      className={`checkout-payment-method-step-block${
        more ? ' checkout-payment-method-step-block--more' : ''
      }${className ? ` ${className}` : ''}`}
    >
      <div className="checkout-payment-method-step-block__list">
        {paymentMethods.map((metodoPagamento) => (
          <CheckoutPaymentMethodOption
            key={`checkout-payment-method-step-block__payment-method-option__${metodoPagamento.pk}`}
            metodoPagamento={metodoPagamento}
            checked={paymentMethodChecked === metodoPagamento.pk}
            onClick={() => handleChangeSistemaPagamento(metodoPagamento.pk)}
            paypalFormContent={paypalFormContent}
          />
        ))}
      </div>
      <div className="checkout-payment-method-step-block__more">
        <span>{t('Cerchi un altro metodo di pagamento?')}</span>
        <Button
          label={t('Mostra altri metodi di pagamento')}
          variant="ghost"
          onClick={() => setMore(true)}
        />
      </div>
    </CheckoutStepBlock>
  )
}

export default CheckoutPaymentMethodStepBlock
