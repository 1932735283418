import React from 'react'
import { FormError } from 'react-pagebuilder/pb-components/atoms'

const FormInput = ({
  options = [],
  placeholder = '',
  name = '',
  value = '',
  className = '',
  readOnly = false,
  disabled = false,
  status = '',
  errorMessage = '',
  onBlur = () => {},
  onFocus = () => {},
  onChange = () => {},
}) => {
  const classNames = `
    pb-form-select 
    ${status ? ` pb-form-select--${status}` : ''}
    ${className ? ` ${className}` : ''}`

  return (
    <div className={classNames}>
      <select
        name={name}
        value={value}
        className="pb-form-select__input"
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {options.map((option) => (
          <option value={option.value} selected={value === option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <FormError
        className="pb-form-input__error"
        message={errorMessage}
        visible={status === 'error'}
      />
    </div>
  )
}

export default FormInput
