import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Vector8({ className }: Props) {
  return (
    <IconBase name="vector8" w={724} h={511} className={className}>
      <path d="M256.238 453.833C400.324 458.79 550.222 588.201 675.747 439.968C801.273 291.735 664.821 0.540937 442.126 0.540775C257.778 0.540645 39.1296 48.6474 5.62557 222.54C-27.8785 396.434 112.152 448.876 256.238 453.833Z" stroke="none"/>
    </IconBase>
  )
}

export default Vector8
