import Link from 'next/link'
import React from 'react'
import { Loader } from '../Icons'

export interface Props {
  id?: string
  label?: string
  labelMobile?: string
  type?: 'button' | 'submit'
  variant?: 'primary' | 'secondary' | 'ghost' | 'simple'
  colorVariant?: '' | 'gray' | 'black' | 'white'
  size?: 'sm' | 'md'
  responsive?: boolean
  disabled?: boolean
  icon?: any
  iconPos?: 'left' | 'right'
  hideIconMobile?: boolean
  onClick?: (event: any) => void
  href?: string
  newPage?: boolean
  className?: string
  loading?: boolean
  external?: boolean
  children?: any
}

const Button = (props: Props) => {
  const {
    id = null,
    label = '',
    labelMobile = '',
    type = 'button',
    variant = 'primary',
    colorVariant = '',
    size = 'md',
    responsive = false,
    disabled = false,
    icon = null,
    iconPos = 'left',
    hideIconMobile = false,
    onClick = () => {},
    href = null,
    newPage = false,
    className = '',
    loading = false,
    external = false,
    children = null,
  } = props

  const classeNames = `
    button 
    button--${variant} 
    button--${size}
    ${colorVariant ? ` button--color-${colorVariant}` : ''}
    ${responsive ? ' button--responsive' : ''}
    ${icon && iconPos === 'right' ? ' button--icon-right' : ''}
    ${icon && hideIconMobile ? ' button--icon-mobile-hidden' : ''}
    ${loading ? ' button--loading' : ''}
    ${className ? ` ${className}` : ''}`

  const buttonContent = (
    <>
      {icon && <span className="button__icon">{icon}</span>}
      {(label || children) && children ? (
        children
      ) : (
        <span className={`button__label${labelMobile ? ' button__label--desktop' : ''}`}>
          {label}
        </span>
      )}
      {labelMobile && <span className="button__label button__label--mobile">{labelMobile}</span>}
      {loading && (
        <span className="button__loader">
          <Loader />
        </span>
      )}
    </>
  )

  return href ? (
    external ? (
      <a
        className={classeNames}
        href={href}
        target={newPage ? '_blank' : null}
        rel={newPage || external ? 'noopener noreferrer' : undefined}
        onClick={onClick}
      >
        {buttonContent}
      </a>
    ) : (
      <Link legacyBehavior href={href}>
        <a
          className={classeNames}
          target={newPage ? '_blank' : null}
          rel="noreferrer"
          onClick={onClick}
        >
          {buttonContent}
        </a>
      </Link>
    )
  ) : id ? (
    <button id={id} type={type} className={classeNames} disabled={disabled} onClick={onClick}>
      {buttonContent}
    </button>
  ) : (
    <button type={type} className={classeNames} disabled={disabled} onClick={onClick}>
      {buttonContent}
    </button>
  )
}

export default Button
