import React from 'react'
import { Close } from 'components/atoms/Icons'

interface Props {
  label: any
  icon?: any
  variant?: '' | 'alert' | 'success'
  size?: 'md' | 'sm'
  button?: any
  open?: boolean
  setOpen?: (v: boolean) => void
  onClose?: (e: any) => void
  className?: string
}

const NotificationBar = (props: Props) => {
  const {
    label = '',
    icon = null,
    variant = '',
    size = 'md',
    button = null,
    open = true,
    setOpen = () => {},
    onClose = null,
    className = '',
  } = props
  
  const close = (e: any) => {
    e.stopPropagation()
    if (setOpen) setOpen(false)
  }



  return open ? (
    <div
      style={{ zIndex: 99 }}
      className={`notification-bar${variant ? ` notification-bar--${variant}` : ''}${
        size ? ` notification-bar--${size}` : ''
      }${className ? ` ${className}` : ''}`}
    >
      {icon && <span className="notification-bar__icon">{icon}</span>}
      <div className="notification-bar__content">
        <span className="notification-bar__label">{label}</span>
        {button && <span className="notification-bar__button">{button}</span>}
      </div>
      {onClose && (
        <button
          className="notification-bar__close"
          type="button"
          aria-label="Close"
          onClick={close}
        >
          <Close />
        </button>
      )}
    </div>
  )
  : (
    <></>
  )
}

export default NotificationBar
