import React, { useState, useRef } from 'react'

interface Props {
  label?: string
  options?: {
    label: string
    value: string
    selected: boolean
  }[]
  onChange: (v: string) => void
  className?: string
}

const Dropdown = (props: Props) => {
  const { label = '', options = [], onChange = () => {}, className = '' } = props

  const [open, setOpen] = useState(false)
  const dropdownContentRef = useRef()

  const handleClick = (value) => {
    onChange(value)
    setOpen(false)
  }

  return (
    <div className={`dropdown${open ? ' dropdown--open' : ''}${className ? ` ${className}` : ''}`}>
      <button type="button" className="dropdown__trigger" onClick={() => setOpen(!open)}>
        {label}
      </button>
      <div
        className="dropdown__dropdown"
        style={{
          maxHeight:
            dropdownContentRef?.current && open ? dropdownContentRef.current.clientHeight : 0,
        }}
      >
        <div className="dropdown__list" ref={dropdownContentRef}>
          {options.map((option) => (
            <button
              type="button"
              className={`dropdown__option${option.selected ? ' dropdown__option--selected' : ''}`}
              key={`dropdown__option__${option.value}`}
              onClick={() => handleClick(option.value)}
            >
              {option.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Dropdown
