import React from 'react'
import ContentLoader from 'react-content-loader'


interface Props {
    vista2?: boolean
  }

const SkeletonLoadingPrezzoProdotto = (props: Props) => {
    const { vista2 = false } = props

    return (
      <ContentLoader
        style={{
          marginTop: 'auto',
        }}
        speed={2}
        width={vista2 ? '100%' : '100%'}
        height={100}
        backgroundColor="#f1f2f4"
        foregroundColor="#e0e2e6"
      >
        <rect
          x={vista2 ? 'calc((100% - 100px)/2)' : 'calc((100% - 90px)/2)'}
          y="15"
          rx="4"
          ry="4"
          width={vista2 ? '100' : '90'}
          height="35"
        />
        <rect
          x={vista2 ? 'calc((100% - 200px)/2)' : 'calc((100% - 180px)/2)'}
          y="60"
          rx="4"
          ry="4"
          width={vista2 ? '200' : '180'}
          height="40"
        />
      </ContentLoader>
    )
}

export default SkeletonLoadingPrezzoProdotto
