import React, { useEffect, useState } from 'react'
import { useTrans } from 'hooks'
import { CheckoutShippingMethodOption, CheckoutStepBlock } from 'components/molecules'

import { gql, useMutation, useQuery } from '@apollo/client'
import { SET_SPEDIZIONE } from 'utils/queries'

interface Props {
  className?: string
  shippingMethods: any
  shippingMethodAttivo: number
}

const CheckoutShippingMethodStepBlock = (props: Props) => {
  const { className = '', shippingMethods = [], shippingMethodAttivo = null } = props

  const [setSpedizione] = useMutation(
    gql`
      ${SET_SPEDIZIONE}
    `
  )

  const t = useTrans()
  const [shippingMethodChecked, setShippingMethod] = useState(null)

  useEffect(() => {
    if (shippingMethodAttivo) {
      setShippingMethod(shippingMethodAttivo)
    }
  }, [shippingMethodAttivo])

  const handleSetSistemaSpedizioneCheckout = (newSistemaSpedizione) => {
    if (newSistemaSpedizione === shippingMethodChecked) {
      return
    }

    setSpedizione({
      variables: {
        sistemaSpedizione: newSistemaSpedizione,
      },
      refetchQueries: ['Checkout'],
    })
      .then((res) => {
        setShippingMethod(newSistemaSpedizione)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <CheckoutStepBlock
      titolo={t('Metodo di spedizione')}
      descrizione={t('Seleziona un metodo di spedizione.')}
      className={`checkout-shipping-method-step-block${className ? ` ${className}` : ''}`}
    >
      <div className="checkout-shipping-method-step-block__list">   
        {shippingMethods.filter( metodoSpedizione => metodoSpedizione.enableForCap && metodoSpedizione.enableForCovid).map(metodoSpedizione_filtrato => (
            <CheckoutShippingMethodOption
            key={`checkout-shipping-method-step-block__shipping-method__${metodoSpedizione_filtrato.pk}`}
            metodoSpedizione={metodoSpedizione_filtrato}
            checked={shippingMethodChecked === metodoSpedizione_filtrato.pk}
            onClick={() => handleSetSistemaSpedizioneCheckout(metodoSpedizione_filtrato.pk)}
            />
        ))}
      </div>
    </CheckoutStepBlock>
  )
}

export default CheckoutShippingMethodStepBlock
