import React from 'react'

interface Props {
  label: string
  active?: boolean
  className?: string
}

const ChipsMenu = (props: Props) => {
  const { label = '', active = false, className = '' } = props

  return (
    <div
      className={`chips-menu${active ? ` chips-menu--active` : ''}${
        className ? ` ${className}` : ''
      }`}
    >
      {label}
    </div>
  )
}

export default ChipsMenu
