import React, { useState } from 'react'
import { FormError } from 'components/atoms'

import EyeHide from '../Icons/EyeHide'
import EyeShow from '../Icons/EyeShow'

interface Props {
  placeholder?: string
  name?: string
  value?: string
  size?: 'md'
  className?: string
  readOnly?: boolean
  disabled?: boolean
  status?: '' | 'success' | 'error'
  errorMessage?: string
  onBlur?: (e: any) => void
  onFocus?: (e: any) => void
  onChange?: (e: any) => void
  togglePassword: boolean
}

const FormInputPassword = (props: Props) => {
  const {
    placeholder = '',
    name = '',
    value = '',
    size = 'md',
    className = '',
    readOnly = false,
    disabled = false,
    status = '',
    errorMessage = '',
    onBlur = () => {},
    onFocus = () => {},
    onChange = () => {},
    togglePassword = false,
  } = props

  const [showPassword, setShowPassword] = useState(false)

  const classNames = `
    form-input-password 
    form-input-password--${size}
    ${status ? ` form-input-password--${status}` : ''}
    ${togglePassword ? ` form-input-password--with-toggler` : ''}
    ${className ? ` ${className}` : ''}`

  return (
    <div className={classNames}>
      <div className="form-input-password__input-wrap">
        <input
          type={showPassword ? 'text' : 'password'}
          name={name}
          value={value}
          className="form-input-password__input"
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
        />
        {togglePassword && (
          <button
            type="button"
            className="form-input-password__toggler"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <EyeHide /> : <EyeShow />}
          </button>
        )}
      </div>
      <FormError message={errorMessage} visible={status === 'error'} />
    </div>
  )
}

export default FormInputPassword
