import React, { useState, useRef, useEffect } from 'react'
import { useTrans } from 'hooks'

interface Props {
  mondo: string
  dati: {
    chiave?: string
    alias?: string
    testo?: string
    titolo?: string
  }[]
  descrizione?: string
  className?: string
  schedaTecnicaRef?: any
}

interface ItemProps {
  item: {
    alias?: string
    testo?: string
    titolo?: string
  },
  testo_extra?: string
}

const ProdottoSchedaTecnicaItem = (props: ItemProps) => {
  const {
    item = {
      alias: '',
      testo: '',
      titolo: '',
    },
    testo_extra = '',
  } = props
  const [openMobile, setOpenMobile] = useState(false)
  const innerContentRef = useRef()

  return (
    <div
      className={`prodotto-scheda-tecnica-item${
        openMobile ? ` prodotto-scheda-tecnica-item--open-mobile` : ''
      }`}
    >
      <button
        className="prodotto-scheda-tecnica-item__title"
        onClick={() => setOpenMobile(!openMobile)}
      >
        {item.titolo}
        
      </button>
      <div
        className="prodotto-scheda-tecnica-item__description"
        style={{
          maxHeight:
            innerContentRef?.current && openMobile ? innerContentRef.current.clientHeight : 0,
        }}
      >
        <div
          className="prodotto-scheda-tecnica-item__description-content"
          ref={innerContentRef}
          dangerouslySetInnerHTML={{ __html: testo_extra ? item.testo + "<br/>" + testo_extra : item.testo }}
        />
      </div>
    </div>
  )
}

const ProdottoSchedaTecnica = (props: Props) => {
  const { mondo = '', dati = [], descrizione = '', className = '', schedaTecnicaRef = null } = props

  const t = useTrans()

  const getDato = (chiave) => {
    return dati.find((dato) => dato.chiave === chiave && dato.testo)
  }

  const [firstList, setFirstList] = useState([])
  const [secondList, setSecondList] = useState([])

  useEffect(() => {
    initLists()
  }, [])

  const initLists = () => {
    let itemsToDivide = []

    if(descrizione) {
      itemsToDivide.push(<ProdottoSchedaTecnicaItem item={{alias:'Descrizione', titolo: t('Descrizione'), testo: descrizione}} />)
    }
    if(mondo === "vino") {
      if(getDato('provenienza')){
        itemsToDivide.push(<ProdottoSchedaTecnicaItem item={getDato('provenienza')} />)
      }
      if(getDato('vinificazione_affinamento')) {
        itemsToDivide.push(<ProdottoSchedaTecnicaItem item={getDato('vinificazione_affinamento')} />)
      }
      if(getDato('allergeni')){
        itemsToDivide.push(<ProdottoSchedaTecnicaItem item={getDato('allergeni')} />)
      }
      if(getDato('degustazione')) {
        itemsToDivide.push(
          <ProdottoSchedaTecnicaItem
            item={getDato('degustazione')}
            testo_extra={
              getDato('abbinamenti_text')
                ? t('Si consiglia con: ') + getDato('abbinamenti_text')?.testo.charAt(0).toLowerCase() + getDato('abbinamenti_text')?.testo.slice(1)
                : ''
            }
          />
        )
      }
    }
    else if(mondo === "spirits") {
      if(getDato('provenienza')) {
        itemsToDivide.push(<ProdottoSchedaTecnicaItem item={getDato('provenienza')} />)
      }
      if(getDato('produzione')) {
        itemsToDivide.push(<ProdottoSchedaTecnicaItem item={getDato('produzione')} />)
      }
      if(getDato('botaniche')) {
        itemsToDivide.push(<ProdottoSchedaTecnicaItem item={getDato('botaniche')} />)
      }
      if(getDato('naso')) {
        itemsToDivide.push(<ProdottoSchedaTecnicaItem item={getDato('naso')} />)
      }
      if(getDato('palato')) {
        itemsToDivide.push(<ProdottoSchedaTecnicaItem item={getDato('palato')} />)
      }
      if(getDato('finale')) {
        itemsToDivide.push(<ProdottoSchedaTecnicaItem item={getDato('finale')} />)
      }
    }

    // Dividiamo gli elementi in due liste bilanciate
    setFirstList(itemsToDivide.slice(0, Math.floor(itemsToDivide.length / 2)))
    setSecondList(itemsToDivide.slice(Math.floor(itemsToDivide.length / 2), itemsToDivide.length))
  }

  return (
    <div
      className={`prodotto-scheda-tecnica ${className ? ` ${className}` : ''}`}
      ref={schedaTecnicaRef}
    >
      <p className="prodotto-scheda-tecnica__title">{t('Scheda tecnica')}</p>
      <div className="prodotto-scheda-tecnica__list">
        <div>
          {firstList.map((item, key) => <div key={key}>{item}</div>)}
        </div>
        <div>
          {secondList.map((item, key) => <div key={key}>{item}</div>)}
        </div>
      </div>
    </div>
  )
}

export default ProdottoSchedaTecnica
