import { ImageBox } from 'components/atoms'
import React from 'react'

interface Props {
  description?: string
  imageUrl?: string
  className?: string
}

const BannerInformativo = (props: Props) => {
  const { description = '', imageUrl = '', className = '' } = props

  return (
    <div className={`banner-informativo-box${className ? ` ${className}` : ''}`}>
      <ImageBox src={imageUrl} maxWidth={109} maxHeight={127} />
      <div
          className="banner-informativo-box__description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
    </div>
  )
}

export default BannerInformativo