import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useTrans } from 'hooks'
import { CANTINE } from 'utils/queries'
import { gql, useQuery } from '@apollo/client'
import { ArrowRight } from 'components/atoms/Icons'
import { Button, WImage } from 'components/atoms'

SwiperCore.use([Pagination])

interface Props {
  className?: string
}

const CantineHomeBox = (props: Props) => {
  const { className = '' } = props

  const t = useTrans()
  const [cantine, setCantine] = useState({ edges: [] })
  const { data: dataCantine, loading: loadingCantine } = useQuery(
    gql`
      ${CANTINE}
    `
  )

  useEffect(() => {
    if (!loadingCantine && dataCantine) setCantine(dataCantine.cantine)
  }, [loadingCantine])

  return (
    <div className={`cantine-home-box ${className ? ` ${className}` : ''}`}>
      <div className="cantine-home-box__content">
        <p className="cantine-home-box__intro">{t('Cantine')}</p>
        <p className="cantine-home-box__title">{t('Le nostre cantine')}</p>
        <p className="cantine-home-box__description">
          {t(
            'Dietro ogni singola bottiglia di vino c’è un mondo fatto di dedizione, ricerca, cura e passione. C’è il nostro mondo, che vogliamo farti vivere in un modo unico.'
          )}
        </p>
        <Button
          label={t('Scopri tutte le cantine')}
          icon={<ArrowRight />}
          iconPos="right"
          variant="ghost"
          className="cantine-home-box__cta"
          href="/le-cantine" // @TODO mettere dinamico
        />
      </div>
      <div className="cantine-home-box__list">
        {cantine.edges.map((cantina) => (
          <Link
            legacyBehavior
            href={cantina.node.url}
            key={`cantine-home-box__list-item__${cantina.node.pk}`}
          >
            <a className="cantine-home-box__item">
              <WImage
                src={cantina.node.logoTrasparente?.image}
                maxWidth={79}
                maxHeight={79}
                layout="fixed"
                alt={cantina.node.nome}
                title={cantina.node.nome}
              />
            </a>
          </Link>
        ))}
      </div>
      <div className="cantine-home-box__slider">
        <Swiper
          spaceBetween={16}
          watchOverflow
          slidesPerView="auto"
          pagination={{ clickable: true }}
        >
          {/* Raggruppate a due a due nelle slide */}
          {cantine.edges.map(
            (cantina, index) =>
              index % 2 === 0 && (
                <SwiperSlide key={`cantine-home-box__slider-item__${cantina.node.pk}`}>
                  <Link legacyBehavior href={cantina.node.url}>
                    <a className="cantine-home-box__item">
                      <WImage
                        src={cantina.node.logoTrasparente?.image}
                        maxWidth={120}
                        maxHeight={120}
                        layout="fixed"
                        alt={cantina.node.nome}
                        title={cantina.node.nome}
                      />
                    </a>
                  </Link>
                  {cantine.edges.length > index + 1 && (
                    <Link legacyBehavior href={cantine.edges[index + 1].node.url}>
                      <a className="cantine-home-box__item">
                        <WImage
                          src={cantine.edges[index + 1].node.logoTrasparente?.image}
                          maxWidth={120}
                          maxHeight={120}
                          layout="fixed"
                          alt={cantina.node.nome}
                          title={cantina.node.nome}
                        />
                      </a>
                    </Link>
                  )}
                </SwiperSlide>
              )
          )}
        </Swiper>
      </div>
    </div>
  )
}

export default CantineHomeBox
